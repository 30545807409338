/* -------------------------------------------
    Shop Page
        - Shop Banner

        - Toolbox
            (support toolbox, toolbox-left, toolbox-right)
            (support label, select-box)
        - Toolbox Item
            (support full)
        - Layout Button
        - Select Menu
            (support as sidebar's widget < 992px)
            (support toggle)
        - Select Items
        - Toolbox Pagination
        - Toolbox Horizontal
        - Toolbox Navigation
            (support as sidebar-fixed)
            (please place sidebar before toolbox: to know sidebar's close status)
---------------------------------------------- */

// Shop Banner
.shop-banner-default,
.shop-boxed-banner {
  .banner-subtitle {
    margin-bottom: .6rem;
    font-size: 2em;
  }

  .banner-title {
    margin-bottom: 2.4rem;
    font-size: 4em;
    letter-spacing: -.035em;
  }

  .btn {
    padding: 1.2em 2.8em;
    border-color: #919EB2;

    i {
      vertical-align: middle;
      margin-left: .7rem;
      font-size: 2.2rem;
    }

    &:hover,
    &:active,
    &:focus {
      border-color: $white-color;
    }
  }
}

.shop-banner-default {
  padding: 7.5rem 5.7% 8.3rem;
}

.shop-boxed-banner {
  padding: 7.4rem 7% 8.4rem;
}


// Toolbox, Filter Clean Widget, Filter Price Widget

.toolbox,
.toolbox-left,
.toolbox-right,
.toolbox-item,
.toolbox-item-full {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.toolbox {
  justify-content: space-between;
}

.toolbox {
  font-size: 1.3rem;
  line-height: 1.5;
  padding: 3.5rem .2rem 1rem;
  transition: margin .4s;
  background-color: #fff;

  &.sticky-toolbox {
    padding-top: 3rem;
  }

  &,
  &-left,
  &-right {
    >*:not(:last-child) {
      margin-right: 1.8rem;
    }
  }

  label {
    margin-right: .8rem;
    font-weight: 600;
    text-transform: uppercase;
  }

  select {
    cursor: pointer;
    padding-top: .85rem;
    padding-bottom: .85rem;
    border-color: #ccc;
  }

  .select-box .form-control {
    color: #222;
  }

  .select-box::before {
    font-size: 1rem;
    right: 1rem;
  }

  // ToolBox Sort
  .toolbox-sort {
    .form-control {
      max-width: 15rem;
      padding-left: 1.4rem;
    }

    &::before {
      right: 1.4rem;
    }
  }

  // .left-sidebar-toggle {
  //     padding: .8em 1.04em;
  //     i {
  //         margin-bottom: .1rem;
  //         &::before {
  //             font-weight: 600;
  //         }
  //     }
  // }
}

.toolbox-item {
  flex-wrap: nowrap;
  margin-bottom: 1rem;

  label {
    color: $dark-color;
  }
}

.toolbox-item-full {
  flex-basis: 100%;
}

// Layout Button
.btn-layout {
  color: #dadada;
  font-size: 22px;
  padding: 0 2px;

  &:last-child {
    padding-right: 0;
  }

  &:hover,
  &.active {
    color: #333;
  }

  &+& {
    margin-left: 2px;
  }
}

// Select Menu
.select-menu {
  color: #222;

  &::before {
    right: 1.25em;
    font-size: 1.2rem;
    margin-top: 1px;
  }

  ul,
  ul::before {
    content: '';
    position: absolute;
    border: 1px solid $border-color-light;
    width: 11px;
  }

  ul {
    top: 100%;
    z-index: 100;
    width: 18rem;
    margin-top: 1rem;
    padding: 1rem 1.4rem 1rem;
    background: #fff;
    opacity: 0;
    visibility: hidden;

    &::before {
      top: -6px;
      left: 22px;
      height: 11px;
      border-width: 1px 0 0 1px;
      background: #fff;
      transform: rotate(45deg);
    }
  }

  &.opened>ul {
    opacity: 1;
    visibility: visible;
  }
}

.select-menu select,
.select-menu-toggle {
  position: relative;
  border: 1px solid #ccc;
  padding: .8rem 4rem .8rem 1.4rem;
  font-size: 1.2rem;
  line-height: 1.5;
  letter-spacing: -.03em;
}

.select-menu-toggle {
  border-radius: 2px;
}

.select-menu {
  select {
    max-width: 17.2rem;
    padding: .85rem 2.6rem .85rem .9rem;
  }
}

// Select Items
.select-item {
  padding: 6px .7rem 4px 1.2rem;
  color: #222;
  background: #eee;
  letter-spacing: -.01em;

  i {
    padding: .5rem .6rem .7rem;
    margin-left: 5px;
    font-size: 9px;
    vertical-align: middle;
  }
}

.select-items {
  // display: none;
  display: block;
  visibility: hidden;
  opacity: 0;
  height: 0;
  transition: .4s;

  .select-item {
    transition: opacity .4s;
  }

  .toolbox+& {
    font-size: 1.2rem;
    margin: 0;
  }

  >* {
    display: inline-block;
    margin: 0 .8rem .5rem 0;
  }

  .filter-clean {
    margin-left: 1.2rem;
  }
}

.sticky-content-wrapper+.select-items {
  font-size: 1.2rem;
  margin: 2px 0 1.8rem;
}

// Toolbox Pagination
.toolbox-pagination {
  position: relative;
  margin-bottom: 1rem;
  padding: 2.5rem .2rem;
  border-top: 1px solid #e1e1e1;
}

// Toolbox Horizontal
.toolbox-horizontal {
  .show-info {
    color: #999;
    font-size: inherit;
  }

  &.fixed {

    .sidebar-overlay,
    .sidebar-content {
      min-height: 100vh;
    }
  }

  @include mq(lg) {
    .toolbox-left {
      padding-right: 1px;
      margin-right: -1px;
    }
  }
}

//Left Sidebar Toggle
.toolbox .toolbox-left .left-sidebar-toggle,
.sidebar-content .filter-actions .sidebar-toggle-btn,
.toolbox .top-sidebar-toggle,
.right-sidebar-toggle.btn-sm {
  padding: .7em .92em;
  margin-right: 2rem;
  font-weight: 700;
  line-height: 1.2;

  .d-icon-filter-2 {
    font-size: 1.4rem;

    &::before {
      font-weight: 600;
    }
  }
}

.toolbox .toolbox-left .left-sidebar-toggle {
  font-weight: 600;
}

.toolbox-item.right-sidebar-toggle {
  margin-right: 0;
}

// Navigation Style
.toolbox-wrap {
  display: flex;
  flex-direction: column-reverse;
  line-height: 1.3;

  // Toolbox
  .toolbox>* {
    line-height: 37px;
  }

  .toolbox-layout {
    display: flex;
  }

  // select {
  //     max-width: 16rem;
  //     padding-left: 3px;
  //     padding-right: 2.4em;
  //     text-transform: uppercase;
  //     font-weight: 600;
  // }

  option {
    text-transform: none;
  }

  .toolbox-layout {
    display: flex;
  }

  //Sidebar Content
  .sidebar-content {
    .widget-title {
      padding-bottom: 1rem;
    }
  }

  // Sidebar Content
  // .widget-title {
  //     padding-bottom: 1.3rem;
  //     border-top: 0;
  // }
  // .sidebar-fixed .sidebar-content {
  //     padding-bottom: 0;
  // }
}

.sticky-toolbox.fixed {
  position: fixed;
  background-color: #fff;
  padding: 1rem 2rem 0;
}

// Filter Action, Price Slider, Filter Clean
.widget .widget-body .filter-actions {
  display: block;
  padding: 3.2rem 0 1.5rem .2rem;
}

.filter-price-text {
  font-size: 1.3rem;
  color: $dark-color;

}

.filter-items a::before {
  content: '';
  position: absolute;
  border: 1px solid $grey-color;
  width: 18px;
  height: 18px;
  border-radius: 2px;
  top: 50%;
  transform: translateY(-50%);
  left: -2.7rem;
  font-size: .7em;
  font-weight: 900;
  font-family: 'Font Awesome 5 Free';
  text-align: center;
  text-indent: 1px; // issue
  line-height: 1.7em;

  @include only-for-retina(1.5) {
    text-indent: 0;
  }
}

// .sticky-sidebar-wrapper .widget:first-child {
// 	border-top: 3px solid $border-color-light;
// }

.toolbox .btn-link {
  padding: 0;
}

.filter-clean {
  font-size: 1.4rem;
  margin-left: auto;
  color: $dark-color;
  transition: .3s;

  &:hover {
    color: $primary-color;
  }
}

.toolbox-wrap .card-body .filter-clean {
  display: inline;
  opacity: 0;
}

.toolbox-wrap .expanded .filter-clean {
  opacity: 1;
}

.card.navigation-card {
  &.expanded {
    overflow: visible;
  }

  &:not(.collapsed)+.toolbox .toolbox-right {
    opacity: 0;
    transform: opacity .3s;
  }
}

// Shop Sidebar
.shop-sidebar {
  position: relative;

  // .sidebar-content {
  // 	padding: 3rem;
  // }

  // .filter-actions {
  // 	padding-top: 0;
  // }
  // issue : need?
  .btn-outline:not(:hover) {
    color: $primary-color;
    background: #fff;
  }

  .sidebar-toggle-btn,
  .left-sidebar-toggle {
    padding: .8em 1.04em;
  }

  //Filter action
  .filter-actions {
    display: flex;
    align-items: center;
    padding-right: .6rem;
    margin-bottom: 2rem;
  }
}

.btn-icon-right i {
  margin-left: .8rem;
  font-size: 1.8rem;
}

// Products Widget
// .widget-products {
// 	.widget-title {
// 		margin-bottom: 2rem;
// 		border-top: 0;
// 		border-bottom: 1px solid $border-color;
// 	}
// 	.product-price {
// 		color: $primary-color;
// 	}
// 	.owl-nav i {
// 		font-size: 1.7rem;
// 	}
// 	.owl-nav {
// 		top: -5.5rem;
// 	}	
// }


// Responsive
@include mq(lg) {

  // .shop-sidebar .sidebar-content {
  // 	padding-top: 3rem;
  // }
  .sidebar:not(.closed) .sticky-sidebar-fixed>.filter-actions {
    visibility: hidden;
  }

  .sidebar-fixed {
    .filter-actions:first-child {
      padding-top: 3rem;
    }

    // &.shop-sidebar .sidebar-content {
    // 	padding: 0 0 2rem;
    // }

    // 5. Toggle Fixed Sidebar
    transition: margin .4s;

    +.main-content {
      transition: flex-basis .4s, max-width .4s;
    }
  }

  .sticky-sidebar-wrapper.closed {
    height: 0; // to solve issue occurs by closed sticky sidebar for fixed sidebar
  }

  .closed.sidebar {
    margin-left: -25%;
  }

  .closed.right-sidebar {
    margin-right: -25%;
  }

  .closed+.main-content {
    max-width: 100%;
    flex-basis: 100%;
  }

  // .main-content-wrap {
  // 	overflow: hidden;
  // }

  // Remain Toggle
  .sidebar-toggle-remain {
    & .toggle-remain {
      position: absolute;
      transition: .4s;
      left: 0;

      @include only-for-ie() {
        top: 2.7rem;
      }
    }

    .filter-clean {
      line-height: 3.7rem;
    }

    // left sidebar
    &.sidebar {
      .toggle-remain i {
        transition: transform .3s;
      }

      &.closed {
        .toggle-remain {
          left: calc(100% + 30px);

          i {
            transform: rotateY(180deg);
          }
        }

        +*>.toolbox:first-child {
          margin-left: 10.8rem;
        }
      }

      // for only left sidebar to hide "clean all"'s movement
      .toggle-remain::before {
        content: '';
        position: absolute;
        background-color: #fff;
        right: calc(100% + 2px);
        width: 20px;
        bottom: -2px;
        top: -2px;
      }
    }

    // right sidebar
    &.right-sidebar.closed {
      .toggle-remain {
        left: -3rem;
        transform: translateX(-100%);

        i::before {
          content: "\e97e";
        }
      }

      +*>.toolbox:first-child {
        padding-right: 11rem;
      }
    }
  }

  .toolbox-wrap .sidebar-content {
    border-top: 3px solid $border-color-light;
    transition: border .3s;

    &>div {
      border-bottom: 3px solid $border-color-light;
    }

    .widget {
      border: none;
    }
  }

  .shop-sidebar .filter-actions {
    margin-bottom: 0;
  }
}

@include mq(xs, max) {
  .sticky-toolbox.fixed {
    padding: 1rem 1.5rem 0;
  }

  .toolbox {
    padding-right: 0;
    padding-left: 0;
  }
}

@include mq(sm, max) {
  .toolbox-item.select-box {
    margin-right: 0;
  }

  .toolbox-item.select-box~.toolbox-item.right-sidebar-toggle {
    margin-left: 1rem;
  }

  .toolbox-item.toolbox-layout {
    display: none;
  }

  .toolbox .toolbox-left .left-sidebar-toggle,
  .sticky-sidebar .filter-actions .sidebar-toggle-btn {
    margin-right: 1rem;
  }

  .toolbox {
    .toolbox-left {
      margin: 0;
    }

    .left-sidebar-toggle {
      margin-right: 0.5rem;
    }

    .toolbox-sort .form-control {
      max-width: 12.8rem;
    }
  }

  .toolbox-horizontal {
    justify-content: flex-start;
    flex-direction: row;

    .toolbox-left {
      margin-right: auto;
    }

    .toolbox-right {
      margin-left: auto;
    }

    .toolbox-item.toolbox-show {
      margin-right: 0;
    }
  }

  .toolbox-pagination {
    flex-direction: column;

    >.show-info {
      margin-right: 0;
    }

    >*:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}

@include mq(sm) {
  .toolbox-horizontal {
    .toolbox-left {
      margin-right: auto;
    }
  }

  // issue
  .mr-sm-auto {
    margin-right: auto !important;
  }
}

@include mq(md, max) {
  .toolbox-item {

    label,
    >span,
    &.show-info {
      display: none;
    }
  }
}

@include mq(lg, max) {

  // Shop Sidebar : Mobile
  .toolbox {
    .sidebar-fixed {
      margin-right: 0;
    }

    .sidebar-content {
      display: block;
    }
  }

  // Shop Sidebar's Select Menu : Mobile
  .sidebar-content {
    .select-menu {
      display: block;
      margin: 0;
      font-size: 1.4rem;

      ul {
        position: static;
        width: 100%;
        padding: 0;
        border: 0;
        color: #666;
        margin: 0 0 1.1rem;
        opacity: 1;
        visibility: visible;

        a::before {
          width: 18px;
          height: 18px;
          margin-top: 0;
        }
      }

      &::before,
      ul::before,
      >a::before {
        content: none;
      }
    }

    .select-menu-toggle {
      display: block;
      border-width: 3px 0 0;
      padding: 2rem 2px 1.6rem;
      font-size: 1.5rem;
      font-weight: 600;
      text-transform: capitalize;
    }
  }

  .toolbox-item:first-child a {
    border: none;
  }

  // Navigation Style
  // .toolbox-wrap {
  //     .widget-title {
  //         border-top: 3px solid $border-color-light;
  //     }
  // }

  .card.navigation-card.collapsed .card-header+div {
    display: block !important;
  }

  .card.navigation-card.expanded+.toolbox .toolbox-right {
    opacity: 1 !important;
  }
}

@include mq(lg) {
  .toolbox .sidebar-content {
    max-width: none;
  }

  .toolbox-horizontal {
    .sidebar-fixed {
      .sidebar-content {
        padding-bottom: 0;
      }
    }
  }

  // Navigation Style
  .toolbox-wrap {
    // .sidebar-content {
    //     border: solid #ebebeb;
    //     border-width: 3px 0;
    //     margin-bottom: 2rem;
    //     transition: border-color .3s;
    // }

    .filter-actions:first-child {
      padding: 0;
    }

    .sidebar-toggle-btn {
      display: none;
    }

    .sidebar.closed {
      margin: 0;

      .sidebar-content {
        display: none;

        // issue for toggle animation
        border-color: transparent;
        background: transparent;
      }
    }

    .filter-clean {
      display: none;
      position: absolute;
      right: 0;
      top: -40px;
    }
  }
}

@include mq(xl) {
  .select-menu:not(:last-child):not(.toolbox-show) {
    margin-right: 2rem;
  }

  .toolbox .shop-sidebar {
    margin-right: 2rem
  }
}

.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  z-index: 1
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0)
}

.owl-carousel .owl-item,
.owl-carousel .owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0)
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%
}

.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
  display: none
}

.owl-carousel .owl-dot,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.owl-carousel .owl-nav [class*=owl-],
.owl-carousel button.owl-dot {
  background: 0 0;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit
}

.owl-carousel.owl-loaded {
  display: block
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block
}

.owl-carousel.owl-hidden {
  opacity: 0
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden
}

.owl-carousel.owl-drag .owl-item {
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab
}

.owl-carousel.owl-rtl {
  direction: rtl
}

.owl-carousel.owl-rtl .owl-item {
  float: right
}

.no-js .owl-carousel {
  display: block
}

.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both
}

.owl-carousel .owl-animated-in {
  z-index: 0
}

.owl-carousel .owl-animated-out {
  z-index: 1
}

.owl-carousel .fadeOut {
  animation-name: fadeOut
}

@keyframes fadeOut {
  0% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}

.owl-height {
  transition: height .5s ease-in-out
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity .4s ease
}

.owl-carousel .owl-item .owl-lazy:not([src]),
.owl-carousel .owl-item .owl-lazy[src^=""] {
  max-height: 0
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d
}

.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  // background: url(owl.video.play.png) no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform .1s ease
}

.owl-carousel .owl-video-play-icon:hover {
  transform: scale(1.3, 1.3)
}

.owl-carousel .owl-video-playing .owl-video-play-icon,
.owl-carousel .owl-video-playing .owl-video-tn {
  display: none
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity .4s ease
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%
}

.owl-theme .owl-nav {
  margin-top: 0;
  text-align: center;
  -webkit-tap-highlight-color: transparent
}

.owl-theme .owl-nav [class*=owl-] {
  position: absolute;
  top: 50%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin: 0;
  margin-top: -15px;
  color: #2b579a;
  font-size: 16px;
  line-height: 1;
  padding: 0;
  border: .1rem solid #ebebeb;
  background: 0 0;
  cursor: pointer;
  border-radius: 50px;
  transition: all .3s ease
}

.owl-theme .owl-nav [class*=owl-]:not(.disabled):hover {
  color: #fff;
  border-color: #2b579a;
  background: #2b579a;
  text-decoration: none
}

.owl-theme .owl-nav .disabled {
  opacity: .8;
  cursor: default
}

.owl-theme .owl-nav .owl-prev {
  left: -50px
}

.owl-theme .owl-nav .owl-next {
  right: -50px
}

.owl-theme .owl-nav.disabled+.owl-dots {
  margin-top: 10px
}

.owl-theme .owl-dots {
  line-height: 0;
  text-align: center;
  -webkit-tap-highlight-color: transparent
}

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1
}

.owl-theme .owl-dots .owl-dot span {
  width: 8px;
  height: 8px;
  margin: 5px 6px;
  border: .1rem solid #d7d7d7;
  background: 0 0;
  display: block;
  -webkit-backface-visibility: visible;
  transition: all .3s ease;
  border-radius: 30px
}

.owl-theme .owl-dots .owl-dot:hover span {
  border-color: #ccc;
  background: #ccc
}

.owl-theme .owl-dots .owl-dot.active span {
  width: 18px;
  border-color: #ccc;
  background: #ccc
}

.owl-theme.owl-light .owl-nav [class*=owl-] {
  border-color: #b7b7b7
}

.owl-theme.owl-light .owl-nav [class*=owl-]:not(.disabled):hover {
  border-color: #2b579a
}

.owl-theme.owl-light .owl-dots .owl-dot:hover span {
  border-color: #2b579a;
  background: #2b579a
}

.owl-theme.owl-light .owl-dots .owl-dot.active span {
  border-color: #2b579a;
  background: #2b579a
}

.owl-theme.owl-nav-inside .owl-nav .owl-prev {
  left: 30px
}

.owl-theme.owl-nav-inside .owl-nav .owl-next {
  right: 30px
}

.owl-theme.owl-nav-inside .owl-dots {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 25px
}

.owl-simple .owl-nav {
  margin-top: 0;
  text-align: center;
  -webkit-tap-highlight-color: transparent
}

.owl-simple .owl-nav [class*=owl-] {
  position: absolute;
  top: 50%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  margin: 0;
  margin-top: -21px;
  color: #ccc;
  font-size: 30px;
  line-height: 1;
  padding: 0;
  border: none;
  background: 0 0;
  cursor: pointer;
  border-radius: 50px;
  transition: all .3s ease
}

.owl-simple .owl-nav [class*=owl-] i {
  margin-top: .1rem
}

.owl-simple .owl-nav [class*=owl-]:not(.disabled):hover {
  color: #2b579a;
  background: 0 0;
  text-decoration: none
}

.owl-simple .owl-nav .disabled {
  opacity: .7;
  cursor: default
}

.owl-simple .owl-nav .owl-prev {
  left: -50px
}

.owl-simple .owl-nav .owl-next {
  right: -50px
}

.owl-simple .owl-nav.disabled+.owl-dots {
  margin-top: 10px
}

.owl-simple .owl-dots {
  line-height: 0;
  text-align: center;
  -webkit-tap-highlight-color: transparent
}

.owl-simple .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1
}

.owl-simple .owl-dots .owl-dot span {
  width: 8px;
  height: 8px;
  margin: 5px 6px;
  border: .1rem solid #d7d7d7;
  background: 0 0;
  display: block;
  -webkit-backface-visibility: visible;
  transition: all .3s ease;
  border-radius: 30px
}

.owl-simple .owl-dots .owl-dot:hover span {
  border-color: #ccc;
  background: #ccc
}

.owl-simple .owl-dots .owl-dot.active span {
  width: 18px;
  border-color: #ccc;
  background: #ccc
}

.owl-simple.owl-light .owl-nav [class*=owl-] {
  color: #fff
}

.owl-simple.owl-light .owl-dots .owl-dot:hover span {
  border-color: #2b579a;
  background: #2b579a
}

.owl-simple.owl-light .owl-dots .owl-dot.active span {
  border-color: #2b579a;
  background: #2b579a
}

.owl-simple.owl-light.owl-nav-inside .owl-dots .owl-dot.active span {
  border-color: #f8f8f8;
  background: #f8f8f8
}

.owl-simple.owl-dark .owl-nav [class*=owl-] {
  color: #666
}

.owl-simple.owl-dark .owl-dots .owl-dot span {
  border-color: #666
}

.owl-simple.owl-dark .owl-dots .owl-dot.active span,
.owl-simple.owl-dark .owl-dots .owl-dot:hover span {
  border-color: #666;
  background: #666
}

.owl-simple.owl-nav-inside .owl-nav .owl-prev {
  left: 18px
}

.owl-simple.owl-nav-inside .owl-nav .owl-next {
  right: 18px
}

.owl-simple.owl-nav-inside .owl-dots {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 25px
}

.owl-full .owl-nav {
  margin-top: 0;
  text-align: center;
  -webkit-tap-highlight-color: transparent
}

.owl-full .owl-nav [class*=owl-] {
  position: absolute;
  top: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 100%;
  margin: 0;
  color: #2b579a;
  font-size: 20px;
  line-height: 1;
  padding: 0;
  text-align: center;
  border: none;
  background: rgba(255, 255, 255, .75);
  cursor: pointer;
  border-radius: 0;
  transition: all .3s ease
}

.owl-full .owl-nav [class*=owl-] i {
  margin-top: .1rem
}

.owl-full .owl-nav [class*=owl-]:focus,
.owl-full .owl-nav [class*=owl-]:hover {
  color: #2b579a;
  background: #fff;
  text-decoration: none;
  outline: 0 !important
}

.owl-full .owl-nav [class*=owl-]:before {
  content: '';
  display: block;
  width: 10px;
  height: 100%;
  position: absolute;
  left: 100%;
  top: 0;
  border-radius: 20px 0 0 20px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  // background-image: url(../../../images/nav-prev-shadow.png)
}

.owl-full .owl-nav .disabled {
  opacity: 0;
  pointer-events: none;
  cursor: default
}

.owl-full .owl-nav .owl-prev {
  left: 0
}

.owl-full .owl-nav .owl-next {
  right: 0
}

.owl-full .owl-nav .owl-next:before {
  left: auto;
  right: 100%;
  // background-image: url(../../../images/nav-next-shadow.png)
}

.owl-full.carousel-with-shadow .owl-stage-outer {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0
}

.owl-full.carousel-with-shadow .owl-nav [class*=owl-] {
  height: calc(100% - 20px)
}

.owl-full .owl-nav.disabled+.owl-dots {
  margin-top: 0
}

.owl-full .owl-dots {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -4rem;
  line-height: 0;
  text-align: center;
  -webkit-tap-highlight-color: transparent
}

.owl-full .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1
}

.owl-full .owl-dots .owl-dot span {
  width: 8px;
  height: 8px;
  margin: 5px 6px;
  border: .1rem solid #2b579a;
  background: 0 0;
  display: block;
  -webkit-backface-visibility: visible;
  transition: all .3s ease;
  border-radius: 30px
}

.owl-full .owl-dots .owl-dot:hover span {
  border-color: #2b579a;
  background: #2b579a
}

.owl-full .owl-dots .owl-dot.active span {
  width: 18px;
  border-color: #2b579a;
  background: #2b579a
}

.owl-full.owl-nav-dark .owl-nav [class*=owl-] {
  color: #333
}

.owl-full.owl-nav-dark .owl-nav [class*=owl-]:focus,
.owl-full.owl-nav-dark .owl-nav [class*=owl-]:hover {
  color: #2b579a;
  background: #fff
}

.bg-light .owl-full .owl-nav [class*=owl-]:focus,
.bg-light .owl-full .owl-nav [class*=owl-]:hover {
  background: #f8f8f8
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: hsla(0, 0%, 100%, 0.7);
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(90deg, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error)
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translateZ(var(--toastify-z-index));
  position: fixed;
  padding: 4px;
  width: var(--toastify-toast-width);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: #fff
}

.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em
}

.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%)
}

.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em
}

.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em
}

.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%)
}

.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0
  }

  .Toastify__toast-container--top-center,
  .Toastify__toast-container--top-left,
  .Toastify__toast-container--top-right {
    top: 0;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    transform: translateX(0)
  }

  .Toastify__toast-container--bottom-center,
  .Toastify__toast-container--bottom-left,
  .Toastify__toast-container--bottom-right {
    bottom: 0;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    transform: translateX(0)
  }

  .Toastify__toast-container--rtl {
    right: 0;
    left: auto
  }
}

.Toastify__toast {
  position: relative;
  min-height: var(--toastify-toast-min-height);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 10px 0 rgba(0, 0, 0, .1), 0 2px 15px 0 rgba(0, 0, 0, .05);
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, .1), 0 2px 15px 0 rgba(0, 0, 0, .05);
  display: -moz-box;
  display: flex;
  -moz-box-pack: justify;
  justify-content: space-between;
  max-height: var(--toastify-toast-max-height);
  overflow: hidden;
  font-family: var(--toastify-font-family);
  cursor: pointer;
  direction: ltr
}

.Toastify__toast--rtl {
  direction: rtl
}

.Toastify__toast-body {
  margin: auto 0;
  -moz-box-flex: 1;
  flex: 1 1 auto;
  padding: 6px;
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center
}

.Toastify__toast-body>div:last-child {
  -moz-box-flex: 1;
  flex: 1 1
}

.Toastify__toast-icon {
  -webkit-margin-end: 10px;
  -moz-margin-end: 10px;
  margin-inline-end: 10px;
  width: 20px;
  flex-shrink: 0;
  display: -moz-box;
  display: flex
}

.Toastify--animate {
  animation-fill-mode: both;
  -webkit-animation-duration: .7s;
  -moz-animation-duration: .7s;
  animation-duration: .7s
}

.Toastify--animate,
.Toastify--animate-icon {
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both
}

.Toastify--animate-icon {
  animation-fill-mode: both;
  -webkit-animation-duration: .3s;
  -moz-animation-duration: .3s;
  animation-duration: .3s
}

@media only screen and (max-width: 480px) {
  .Toastify__toast {
    margin-bottom: 0;
    border-radius: 0
  }
}

.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark)
}

.Toastify__toast-theme--colored.Toastify__toast--default,
.Toastify__toast-theme--light {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light)
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info)
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success)
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning)
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error)
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light)
}

.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark)
}

.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info)
}

.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success)
}

.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning)
}

.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error)
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--error,
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info,
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--success,
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning {
  background: var(--toastify-color-transparent)
}

.Toastify__close-button {
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: .7;
  -webkit-transition: .3s ease;
  -moz-transition: .3s ease;
  transition: .3s ease;
  align-self: flex-start
}

.Toastify__close-button--light {
  color: #000;
  opacity: .3
}

.Toastify__close-button>svg {
  fill: currentColor;
  height: 16px;
  width: 14px
}

.Toastify__close-button:focus,
.Toastify__close-button:hover {
  opacity: 1
}

@-webkit-keyframes Toastify__trackProgress {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }

  to {
    -webkit-transform: scaleX(0);
    transform: scaleX(0)
  }
}

@-moz-keyframes Toastify__trackProgress {
  0% {
    -moz-transform: scaleX(1);
    transform: scaleX(1)
  }

  to {
    -moz-transform: scaleX(0);
    transform: scaleX(0)
  }
}

@keyframes Toastify__trackProgress {
  0% {
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    transform: scaleX(1)
  }

  to {
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    transform: scaleX(0)
  }
}

.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: var(--toastify-z-index);
  opacity: .7;
  -webkit-transform-origin: left;
  -moz-transform-origin: left;
  transform-origin: left
}

.Toastify__progress-bar--animated {
  -webkit-animation: Toastify__trackProgress linear 1 forwards;
  -moz-animation: Toastify__trackProgress linear 1 forwards;
  animation: Toastify__trackProgress linear 1 forwards
}

.Toastify__progress-bar--controlled {
  -webkit-transition: -webkit-transform .2s;
  transition: -webkit-transform .2s;
  -moz-transition: transform .2s, -moz-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s, -moz-transform .2s
}

.Toastify__progress-bar--rtl {
  right: 0;
  left: auto;
  -webkit-transform-origin: right;
  -moz-transform-origin: right;
  transform-origin: right
}

.Toastify__spinner {
  width: 20px;
  height: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 100%;
  border: 2px solid;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right: 2px solid var(--toastify-spinner-color);
  -webkit-animation: Toastify__spin .65s linear infinite;
  -moz-animation: Toastify__spin .65s linear infinite;
  animation: Toastify__spin .65s linear infinite
}

@-webkit-keyframes Toastify__bounceInRight {

  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1)
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0)
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0)
  }

  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0)
  }

  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0)
  }

  to {
    -webkit-transform: none;
    transform: none
  }
}

@-moz-keyframes Toastify__bounceInRight {

  0%,
  60%,
  75%,
  90%,
  to {
    -moz-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1)
  }

  0% {
    opacity: 0;
    -moz-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0)
  }

  60% {
    opacity: 1;
    -moz-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0)
  }

  75% {
    -moz-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0)
  }

  90% {
    -moz-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0)
  }

  to {
    -moz-transform: none;
    transform: none
  }
}

@keyframes Toastify__bounceInRight {

  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    -moz-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1)
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    -moz-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0)
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    -moz-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0)
  }

  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    -moz-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0)
  }

  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    -moz-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0)
  }

  to {
    -webkit-transform: none;
    -moz-transform: none;
    transform: none
  }
}

@-webkit-keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0)
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0)
  }
}

@-moz-keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    -moz-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0)
  }

  to {
    opacity: 0;
    -moz-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0)
  }
}

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    -moz-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0)
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    -moz-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0)
  }
}

@-webkit-keyframes Toastify__bounceInLeft {

  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1)
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0)
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0)
  }

  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0)
  }

  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0)
  }

  to {
    -webkit-transform: none;
    transform: none
  }
}

@-moz-keyframes Toastify__bounceInLeft {

  0%,
  60%,
  75%,
  90%,
  to {
    -moz-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1)
  }

  0% {
    opacity: 0;
    -moz-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0)
  }

  60% {
    opacity: 1;
    -moz-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0)
  }

  75% {
    -moz-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0)
  }

  90% {
    -moz-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0)
  }

  to {
    -moz-transform: none;
    transform: none
  }
}

@keyframes Toastify__bounceInLeft {

  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    -moz-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1)
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    -moz-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0)
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    -moz-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0)
  }

  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    -moz-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0)
  }

  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    -moz-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0)
  }

  to {
    -webkit-transform: none;
    -moz-transform: none;
    transform: none
  }
}

@-webkit-keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0)
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0)
  }
}

@-moz-keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    -moz-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0)
  }

  to {
    opacity: 0;
    -moz-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0)
  }
}

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    -moz-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0)
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    -moz-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0)
  }
}

@-webkit-keyframes Toastify__bounceInUp {

  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1)
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0)
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0)
  }

  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0)
  }

  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0)
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@-moz-keyframes Toastify__bounceInUp {

  0%,
  60%,
  75%,
  90%,
  to {
    -moz-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1)
  }

  0% {
    opacity: 0;
    -moz-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0)
  }

  60% {
    opacity: 1;
    -moz-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0)
  }

  75% {
    -moz-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0)
  }

  90% {
    -moz-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0)
  }

  to {
    -moz-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes Toastify__bounceInUp {

  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    -moz-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1)
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    -moz-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0)
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    -moz-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0)
  }

  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    -moz-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0)
  }

  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    -moz-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0)
  }

  to {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@-webkit-keyframes Toastify__bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0)
  }

  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0)
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0)
  }
}

@-moz-keyframes Toastify__bounceOutUp {
  20% {
    -moz-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0)
  }

  40%,
  45% {
    opacity: 1;
    -moz-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0)
  }

  to {
    opacity: 0;
    -moz-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0)
  }
}

@keyframes Toastify__bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    -moz-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0)
  }

  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    -moz-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0)
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    -moz-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0)
  }
}

@-webkit-keyframes Toastify__bounceInDown {

  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1)
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0)
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0)
  }

  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0)
  }

  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0)
  }

  to {
    -webkit-transform: none;
    transform: none
  }
}

@-moz-keyframes Toastify__bounceInDown {

  0%,
  60%,
  75%,
  90%,
  to {
    -moz-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1)
  }

  0% {
    opacity: 0;
    -moz-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0)
  }

  60% {
    opacity: 1;
    -moz-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0)
  }

  75% {
    -moz-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0)
  }

  90% {
    -moz-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0)
  }

  to {
    -moz-transform: none;
    transform: none
  }
}

@keyframes Toastify__bounceInDown {

  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    -moz-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1)
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    -moz-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0)
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    -moz-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0)
  }

  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    -moz-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0)
  }

  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    -moz-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0)
  }

  to {
    -webkit-transform: none;
    -moz-transform: none;
    transform: none
  }
}

@-webkit-keyframes Toastify__bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0)
  }

  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0)
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0)
  }
}

@-moz-keyframes Toastify__bounceOutDown {
  20% {
    -moz-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0)
  }

  40%,
  45% {
    opacity: 1;
    -moz-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0)
  }

  to {
    opacity: 0;
    -moz-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0)
  }
}

@keyframes Toastify__bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    -moz-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0)
  }

  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    -moz-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0)
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    -moz-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0)
  }
}

.Toastify__bounce-enter--bottom-left,
.Toastify__bounce-enter--top-left {
  -webkit-animation-name: Toastify__bounceInLeft;
  -moz-animation-name: Toastify__bounceInLeft;
  animation-name: Toastify__bounceInLeft
}

.Toastify__bounce-enter--bottom-right,
.Toastify__bounce-enter--top-right {
  -webkit-animation-name: Toastify__bounceInRight;
  -moz-animation-name: Toastify__bounceInRight;
  animation-name: Toastify__bounceInRight
}

.Toastify__bounce-enter--top-center {
  -webkit-animation-name: Toastify__bounceInDown;
  -moz-animation-name: Toastify__bounceInDown;
  animation-name: Toastify__bounceInDown
}

.Toastify__bounce-enter--bottom-center {
  -webkit-animation-name: Toastify__bounceInUp;
  -moz-animation-name: Toastify__bounceInUp;
  animation-name: Toastify__bounceInUp
}

.Toastify__bounce-exit--bottom-left,
.Toastify__bounce-exit--top-left {
  -webkit-animation-name: Toastify__bounceOutLeft;
  -moz-animation-name: Toastify__bounceOutLeft;
  animation-name: Toastify__bounceOutLeft
}

.Toastify__bounce-exit--bottom-right,
.Toastify__bounce-exit--top-right {
  -webkit-animation-name: Toastify__bounceOutRight;
  -moz-animation-name: Toastify__bounceOutRight;
  animation-name: Toastify__bounceOutRight
}

.Toastify__bounce-exit--top-center {
  -webkit-animation-name: Toastify__bounceOutUp;
  -moz-animation-name: Toastify__bounceOutUp;
  animation-name: Toastify__bounceOutUp
}

.Toastify__bounce-exit--bottom-center {
  -webkit-animation-name: Toastify__bounceOutDown;
  -moz-animation-name: Toastify__bounceOutDown;
  animation-name: Toastify__bounceOutDown
}

@-webkit-keyframes Toastify__zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3)
  }

  50% {
    opacity: 1
  }
}

@-moz-keyframes Toastify__zoomIn {
  0% {
    opacity: 0;
    -moz-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3)
  }

  50% {
    opacity: 1
  }
}

@keyframes Toastify__zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    -moz-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3)
  }

  50% {
    opacity: 1
  }
}

@-webkit-keyframes Toastify__zoomOut {
  0% {
    opacity: 1
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3)
  }

  to {
    opacity: 0
  }
}

@-moz-keyframes Toastify__zoomOut {
  0% {
    opacity: 1
  }

  50% {
    opacity: 0;
    -moz-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3)
  }

  to {
    opacity: 0
  }
}

@keyframes Toastify__zoomOut {
  0% {
    opacity: 1
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    -moz-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3)
  }

  to {
    opacity: 0
  }
}

.Toastify__zoom-enter {
  -webkit-animation-name: Toastify__zoomIn;
  -moz-animation-name: Toastify__zoomIn;
  animation-name: Toastify__zoomIn
}

.Toastify__zoom-exit {
  -webkit-animation-name: Toastify__zoomOut;
  -moz-animation-name: Toastify__zoomOut;
  animation-name: Toastify__zoomOut
}

@-webkit-keyframes Toastify__flipIn {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0
  }

  40% {
    -webkit-transform: perspective(400px) rotateX(-20deg);
    transform: perspective(400px) rotateX(-20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in
  }

  60% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg);
    opacity: 1
  }

  80% {
    -webkit-transform: perspective(400px) rotateX(-5deg);
    transform: perspective(400px) rotateX(-5deg)
  }

  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px)
  }
}

@-moz-keyframes Toastify__flipIn {
  0% {
    -moz-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    -moz-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0
  }

  40% {
    -moz-transform: perspective(400px) rotateX(-20deg);
    transform: perspective(400px) rotateX(-20deg);
    -moz-animation-timing-function: ease-in;
    animation-timing-function: ease-in
  }

  60% {
    -moz-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg);
    opacity: 1
  }

  80% {
    -moz-transform: perspective(400px) rotateX(-5deg);
    transform: perspective(400px) rotateX(-5deg)
  }

  to {
    -moz-transform: perspective(400px);
    transform: perspective(400px)
  }
}

@keyframes Toastify__flipIn {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    -moz-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0
  }

  40% {
    -webkit-transform: perspective(400px) rotateX(-20deg);
    -moz-transform: perspective(400px) rotateX(-20deg);
    transform: perspective(400px) rotateX(-20deg);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    animation-timing-function: ease-in
  }

  60% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    -moz-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg);
    opacity: 1
  }

  80% {
    -webkit-transform: perspective(400px) rotateX(-5deg);
    -moz-transform: perspective(400px) rotateX(-5deg);
    transform: perspective(400px) rotateX(-5deg)
  }

  to {
    -webkit-transform: perspective(400px);
    -moz-transform: perspective(400px);
    transform: perspective(400px)
  }
}

@-webkit-keyframes Toastify__flipOut {
  0% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px)
  }

  30% {
    -webkit-transform: perspective(400px) rotateX(-20deg);
    transform: perspective(400px) rotateX(-20deg);
    opacity: 1
  }

  to {
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    opacity: 0
  }
}

@-moz-keyframes Toastify__flipOut {
  0% {
    -moz-transform: perspective(400px);
    transform: perspective(400px)
  }

  30% {
    -moz-transform: perspective(400px) rotateX(-20deg);
    transform: perspective(400px) rotateX(-20deg);
    opacity: 1
  }

  to {
    -moz-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    opacity: 0
  }
}

@keyframes Toastify__flipOut {
  0% {
    -webkit-transform: perspective(400px);
    -moz-transform: perspective(400px);
    transform: perspective(400px)
  }

  30% {
    -webkit-transform: perspective(400px) rotateX(-20deg);
    -moz-transform: perspective(400px) rotateX(-20deg);
    transform: perspective(400px) rotateX(-20deg);
    opacity: 1
  }

  to {
    -webkit-transform: perspective(400px) rotateX(90deg);
    -moz-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    opacity: 0
  }
}

.Toastify__flip-enter {
  -webkit-animation-name: Toastify__flipIn;
  -moz-animation-name: Toastify__flipIn;
  animation-name: Toastify__flipIn
}

.Toastify__flip-exit {
  -webkit-animation-name: Toastify__flipOut;
  -moz-animation-name: Toastify__flipOut;
  animation-name: Toastify__flipOut
}

@-webkit-keyframes Toastify__slideInRight {
  0% {
    -webkit-transform: translate3d(110%, 0, 0);
    transform: translate3d(110%, 0, 0);
    visibility: visible
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@-moz-keyframes Toastify__slideInRight {
  0% {
    -moz-transform: translate3d(110%, 0, 0);
    transform: translate3d(110%, 0, 0);
    visibility: visible
  }

  to {
    -moz-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes Toastify__slideInRight {
  0% {
    -webkit-transform: translate3d(110%, 0, 0);
    -moz-transform: translate3d(110%, 0, 0);
    transform: translate3d(110%, 0, 0);
    visibility: visible
  }

  to {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@-webkit-keyframes Toastify__slideInLeft {
  0% {
    -webkit-transform: translate3d(-110%, 0, 0);
    transform: translate3d(-110%, 0, 0);
    visibility: visible
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@-moz-keyframes Toastify__slideInLeft {
  0% {
    -moz-transform: translate3d(-110%, 0, 0);
    transform: translate3d(-110%, 0, 0);
    visibility: visible
  }

  to {
    -moz-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes Toastify__slideInLeft {
  0% {
    -webkit-transform: translate3d(-110%, 0, 0);
    -moz-transform: translate3d(-110%, 0, 0);
    transform: translate3d(-110%, 0, 0);
    visibility: visible
  }

  to {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@-webkit-keyframes Toastify__slideInUp {
  0% {
    -webkit-transform: translate3d(0, 110%, 0);
    transform: translate3d(0, 110%, 0);
    visibility: visible
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@-moz-keyframes Toastify__slideInUp {
  0% {
    -moz-transform: translate3d(0, 110%, 0);
    transform: translate3d(0, 110%, 0);
    visibility: visible
  }

  to {
    -moz-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes Toastify__slideInUp {
  0% {
    -webkit-transform: translate3d(0, 110%, 0);
    -moz-transform: translate3d(0, 110%, 0);
    transform: translate3d(0, 110%, 0);
    visibility: visible
  }

  to {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@-webkit-keyframes Toastify__slideInDown {
  0% {
    -webkit-transform: translate3d(0, -110%, 0);
    transform: translate3d(0, -110%, 0);
    visibility: visible
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@-moz-keyframes Toastify__slideInDown {
  0% {
    -moz-transform: translate3d(0, -110%, 0);
    transform: translate3d(0, -110%, 0);
    visibility: visible
  }

  to {
    -moz-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes Toastify__slideInDown {
  0% {
    -webkit-transform: translate3d(0, -110%, 0);
    -moz-transform: translate3d(0, -110%, 0);
    transform: translate3d(0, -110%, 0);
    visibility: visible
  }

  to {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@-webkit-keyframes Toastify__slideOutRight {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(110%, 0, 0);
    transform: translate3d(110%, 0, 0)
  }
}

@-moz-keyframes Toastify__slideOutRight {
  0% {
    -moz-transform: translateZ(0);
    transform: translateZ(0)
  }

  to {
    visibility: hidden;
    -moz-transform: translate3d(110%, 0, 0);
    transform: translate3d(110%, 0, 0)
  }
}

@keyframes Toastify__slideOutRight {
  0% {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0)
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(110%, 0, 0);
    -moz-transform: translate3d(110%, 0, 0);
    transform: translate3d(110%, 0, 0)
  }
}

@-webkit-keyframes Toastify__slideOutLeft {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(-110%, 0, 0);
    transform: translate3d(-110%, 0, 0)
  }
}

@-moz-keyframes Toastify__slideOutLeft {
  0% {
    -moz-transform: translateZ(0);
    transform: translateZ(0)
  }

  to {
    visibility: hidden;
    -moz-transform: translate3d(-110%, 0, 0);
    transform: translate3d(-110%, 0, 0)
  }
}

@keyframes Toastify__slideOutLeft {
  0% {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0)
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(-110%, 0, 0);
    -moz-transform: translate3d(-110%, 0, 0);
    transform: translate3d(-110%, 0, 0)
  }
}

@-webkit-keyframes Toastify__slideOutDown {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 500px, 0);
    transform: translate3d(0, 500px, 0)
  }
}

@-moz-keyframes Toastify__slideOutDown {
  0% {
    -moz-transform: translateZ(0);
    transform: translateZ(0)
  }

  to {
    visibility: hidden;
    -moz-transform: translate3d(0, 500px, 0);
    transform: translate3d(0, 500px, 0)
  }
}

@keyframes Toastify__slideOutDown {
  0% {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0)
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 500px, 0);
    -moz-transform: translate3d(0, 500px, 0);
    transform: translate3d(0, 500px, 0)
  }
}

@-webkit-keyframes Toastify__slideOutUp {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -500px, 0);
    transform: translate3d(0, -500px, 0)
  }
}

@-moz-keyframes Toastify__slideOutUp {
  0% {
    -moz-transform: translateZ(0);
    transform: translateZ(0)
  }

  to {
    visibility: hidden;
    -moz-transform: translate3d(0, -500px, 0);
    transform: translate3d(0, -500px, 0)
  }
}

@keyframes Toastify__slideOutUp {
  0% {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0)
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -500px, 0);
    -moz-transform: translate3d(0, -500px, 0);
    transform: translate3d(0, -500px, 0)
  }
}

.Toastify__slide-enter--bottom-left,
.Toastify__slide-enter--top-left {
  -webkit-animation-name: Toastify__slideInLeft;
  -moz-animation-name: Toastify__slideInLeft;
  animation-name: Toastify__slideInLeft
}

.Toastify__slide-enter--bottom-right,
.Toastify__slide-enter--top-right {
  -webkit-animation-name: Toastify__slideInRight;
  -moz-animation-name: Toastify__slideInRight;
  animation-name: Toastify__slideInRight
}

.Toastify__slide-enter--top-center {
  -webkit-animation-name: Toastify__slideInDown;
  -moz-animation-name: Toastify__slideInDown;
  animation-name: Toastify__slideInDown
}

.Toastify__slide-enter--bottom-center {
  -webkit-animation-name: Toastify__slideInUp;
  -moz-animation-name: Toastify__slideInUp;
  animation-name: Toastify__slideInUp
}

.Toastify__slide-exit--bottom-left,
.Toastify__slide-exit--top-left {
  -webkit-animation-name: Toastify__slideOutLeft;
  -moz-animation-name: Toastify__slideOutLeft;
  animation-name: Toastify__slideOutLeft
}

.Toastify__slide-exit--bottom-right,
.Toastify__slide-exit--top-right {
  -webkit-animation-name: Toastify__slideOutRight;
  -moz-animation-name: Toastify__slideOutRight;
  animation-name: Toastify__slideOutRight
}

.Toastify__slide-exit--top-center {
  -webkit-animation-name: Toastify__slideOutUp;
  -moz-animation-name: Toastify__slideOutUp;
  animation-name: Toastify__slideOutUp
}

.Toastify__slide-exit--bottom-center {
  -webkit-animation-name: Toastify__slideOutDown;
  -moz-animation-name: Toastify__slideOutDown;
  animation-name: Toastify__slideOutDown
}

@-webkit-keyframes Toastify__spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn)
  }
}

@-moz-keyframes Toastify__spin {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  to {
    -moz-transform: rotate(1turn);
    transform: rotate(1turn)
  }
}

@keyframes Toastify__spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  to {
    -webkit-transform: rotate(1turn);
    -moz-transform: rotate(1turn);
    transform: rotate(1turn)
  }
}

@-webkit-keyframes closeWindow {
  0% {
    opacity: 1
  }

  to {
    opacity: 0
  }
}

@-moz-keyframes closeWindow {
  0% {
    opacity: 1
  }

  to {
    opacity: 0
  }
}

@keyframes closeWindow {
  0% {
    opacity: 1
  }

  to {
    opacity: 0
  }
}

.ril__outer {
  background-color: rgba(0, 0, 0, .85);
  outline: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -ms-touch-select: none;
  touch-action: none
}

.ril__outerClosing {
  opacity: 0
}

.ril__image,
.ril__imageNext,
.ril__imagePrev,
.ril__inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0
}

.ril__image,
.ril__imageNext,
.ril__imagePrev {
  margin: auto;
  max-width: none;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -ms-touch-select: none;
  touch-action: none
}

.ril__imageDiscourager {
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain
}

.ril__navButtons {
  border: none;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 20px;
  height: 34px;
  padding: 40px 30px;
  margin: auto;
  cursor: pointer;
  opacity: .7
}

.ril__navButtons:hover {
  opacity: 1
}

.ril__navButtons:active {
  opacity: .7
}

.ril__navButtonPrev {
  left: 0;
  background: rgba(0, 0, 0, .2) url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDE5LDMgLTIsLTIgLTE2LDE2IDE2LDE2IDEsLTEgLTE1LC0xNSAxNSwtMTUgeiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==") no-repeat 50%
}

.ril__navButtonNext {
  right: 0;
  background: rgba(0, 0, 0, .2) url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDEsMyAyLC0yIDE2LDE2IC0xNiwxNiAtMSwtMSAxNSwtMTUgLTE1LC0xNSB6IiBmaWxsPSIjRkZGIi8+PC9zdmc+") no-repeat 50%
}

.ril__downloadBlocker {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7");
  background-size: cover
}

.ril__caption,
.ril__toolbar {
  background-color: rgba(0, 0, 0, .5);
  position: absolute;
  left: 0;
  right: 0;
  display: -moz-box;
  display: flex;
  -moz-box-pack: justify;
  justify-content: space-between
}

.ril__caption {
  bottom: 0;
  max-height: 150px;
  overflow: auto
}

.ril__captionContent {
  padding: 10px 20px;
  color: #fff
}

.ril__toolbar {
  top: 0;
  height: 50px
}

.ril__toolbarSide {
  height: 50px;
  margin: 0
}

.ril__toolbarLeftSide {
  padding-left: 20px;
  padding-right: 0;
  -moz-box-flex: 0;
  flex: 0 1 auto;
  overflow: hidden;
  text-overflow: ellipsis
}

.ril__toolbarRightSide {
  padding-left: 0;
  padding-right: 20px;
  -moz-box-flex: 0;
  flex: 0 0 auto
}

.ril__toolbarItem {
  display: inline-block;
  line-height: 50px;
  padding: 0;
  color: #fff;
  font-size: 120%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.ril__toolbarItemChild {
  vertical-align: middle
}

.ril__builtinButton {
  width: 40px;
  height: 35px;
  cursor: pointer;
  border: none;
  opacity: .7
}

.ril__builtinButton:hover {
  opacity: 1
}

.ril__builtinButton:active {
  outline: none
}

.ril__builtinButtonDisabled {
  cursor: default;
  opacity: .5
}

.ril__builtinButtonDisabled:hover {
  opacity: .5
}

.ril__closeButton {
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj48cGF0aCBkPSJtIDEsMyAxLjI1LC0xLjI1IDcuNSw3LjUgNy41LC03LjUgMS4yNSwxLjI1IC03LjUsNy41IDcuNSw3LjUgLTEuMjUsMS4yNSAtNy41LC03LjUgLTcuNSw3LjUgLTEuMjUsLTEuMjUgNy41LC03LjUgLTcuNSwtNy41IHoiIGZpbGw9IiNGRkYiLz48L3N2Zz4=") no-repeat 50%
}

.ril__zoomInButton {
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PHBhdGggZD0iTTEyIDV2NiIvPjwvZz48Y2lyY2xlIGN4PSIxMiIgY3k9IjgiIHI9IjciIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+") no-repeat 50%
}

.ril__zoomOutButton {
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PC9nPjxjaXJjbGUgY3g9IjEyIiBjeT0iOCIgcj0iNyIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiLz48L3N2Zz4=") no-repeat 50%
}

.ril__outerAnimating {
  -webkit-animation-name: closeWindow;
  -moz-animation-name: closeWindow;
  animation-name: closeWindow
}

@-webkit-keyframes pointFade {

  0%,
  19.999%,
  to {
    opacity: 0
  }

  20% {
    opacity: 1
  }
}

@-moz-keyframes pointFade {

  0%,
  19.999%,
  to {
    opacity: 0
  }

  20% {
    opacity: 1
  }
}

@keyframes pointFade {

  0%,
  19.999%,
  to {
    opacity: 0
  }

  20% {
    opacity: 1
  }
}

.ril__loadingCircle {
  width: 60px;
  height: 60px;
  position: relative
}

.ril__loadingCirclePoint {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0
}

.ril__loadingCirclePoint:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 11%;
  height: 30%;
  background-color: #fff;
  border-radius: 30%;
  -webkit-animation: pointFade .8s ease-in-out infinite both;
  -moz-animation: pointFade .8s ease-in-out infinite both;
  animation: pointFade .8s ease-in-out infinite both
}

.ril__loadingCirclePoint:first-of-type {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  transform: rotate(0deg)
}

.ril__loadingCirclePoint:first-of-type:before,
.ril__loadingCirclePoint:nth-of-type(7):before {
  -webkit-animation-delay: -.8s;
  -moz-animation-delay: -.8s;
  animation-delay: -.8s
}

.ril__loadingCirclePoint:nth-of-type(2) {
  -webkit-transform: rotate(30deg);
  -moz-transform: rotate(30deg);
  transform: rotate(30deg)
}

.ril__loadingCirclePoint:nth-of-type(8) {
  -webkit-transform: rotate(210deg);
  -moz-transform: rotate(210deg);
  transform: rotate(210deg)
}

.ril__loadingCirclePoint:nth-of-type(2):before,
.ril__loadingCirclePoint:nth-of-type(8):before {
  -webkit-animation-delay: -666ms;
  -moz-animation-delay: -666ms;
  animation-delay: -666ms
}

.ril__loadingCirclePoint:nth-of-type(3) {
  -webkit-transform: rotate(60deg);
  -moz-transform: rotate(60deg);
  transform: rotate(60deg)
}

.ril__loadingCirclePoint:nth-of-type(9) {
  -webkit-transform: rotate(240deg);
  -moz-transform: rotate(240deg);
  transform: rotate(240deg)
}

.ril__loadingCirclePoint:nth-of-type(3):before,
.ril__loadingCirclePoint:nth-of-type(9):before {
  -webkit-animation-delay: -533ms;
  -moz-animation-delay: -533ms;
  animation-delay: -533ms
}

.ril__loadingCirclePoint:nth-of-type(4) {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  transform: rotate(90deg)
}

.ril__loadingCirclePoint:nth-of-type(10) {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  transform: rotate(270deg)
}

.ril__loadingCirclePoint:nth-of-type(4):before,
.ril__loadingCirclePoint:nth-of-type(10):before {
  -webkit-animation-delay: -.4s;
  -moz-animation-delay: -.4s;
  animation-delay: -.4s
}

.ril__loadingCirclePoint:nth-of-type(5) {
  -webkit-transform: rotate(120deg);
  -moz-transform: rotate(120deg);
  transform: rotate(120deg)
}

.ril__loadingCirclePoint:nth-of-type(11) {
  -webkit-transform: rotate(300deg);
  -moz-transform: rotate(300deg);
  transform: rotate(300deg)
}

.ril__loadingCirclePoint:nth-of-type(5):before,
.ril__loadingCirclePoint:nth-of-type(11):before {
  -webkit-animation-delay: -266ms;
  -moz-animation-delay: -266ms;
  animation-delay: -266ms
}

.ril__loadingCirclePoint:nth-of-type(6) {
  -webkit-transform: rotate(150deg);
  -moz-transform: rotate(150deg);
  transform: rotate(150deg)
}

.ril__loadingCirclePoint:nth-of-type(12) {
  -webkit-transform: rotate(330deg);
  -moz-transform: rotate(330deg);
  transform: rotate(330deg)
}

.ril__loadingCirclePoint:nth-of-type(6):before,
.ril__loadingCirclePoint:nth-of-type(12):before {
  -webkit-animation-delay: -133ms;
  -moz-animation-delay: -133ms;
  animation-delay: -133ms
}

.ril__loadingCirclePoint:nth-of-type(7) {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg)
}

.ril__loadingCirclePoint:nth-of-type(13) {
  -webkit-transform: rotate(1turn);
  -moz-transform: rotate(1turn);
  transform: rotate(1turn)
}

.ril__loadingCirclePoint:nth-of-type(7):before,
.ril__loadingCirclePoint:nth-of-type(13):before {
  -webkit-animation-delay: 0ms;
  -moz-animation-delay: 0ms;
  animation-delay: 0ms
}

.ril__loadingContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0
}

.ril__imageNext .ril__loadingContainer,
.ril__imagePrev .ril__loadingContainer {
  display: none
}

.ril__errorContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  color: #fff
}

.ril__imageNext .ril__errorContainer,
.ril__imagePrev .ril__errorContainer {
  display: none
}

.ril__loadingContainer__icon {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%)
}

.input-range__slider {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #3f51b5;
  border: 1px solid #3f51b5;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: 1rem;
  margin-left: -.5rem;
  margin-top: -.65rem;
  outline: none;
  position: absolute;
  top: 50%;
  -webkit-transition: -webkit-transform .3s ease-out, -webkit-box-shadow .3s ease-out;
  transition: -webkit-transform .3s ease-out, -webkit-box-shadow .3s ease-out;
  -moz-transition: transform .3s ease-out, box-shadow .3s ease-out, -moz-transform .3s ease-out;
  transition: transform .3s ease-out, box-shadow .3s ease-out;
  transition: transform .3s ease-out, box-shadow .3s ease-out, -webkit-transform .3s ease-out, -moz-transform .3s ease-out, -webkit-box-shadow .3s ease-out;
  width: 1rem
}

.input-range__slider:active {
  -webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  transform: scale(1.3)
}

.input-range__slider:focus {
  -webkit-box-shadow: 0 0 0 5px rgba(63, 81, 181, .2);
  box-shadow: 0 0 0 5px rgba(63, 81, 181, .2)
}

.input-range--disabled .input-range__slider {
  background: #ccc;
  border: 1px solid #ccc;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transform: none;
  -moz-transform: none;
  transform: none
}

.input-range__slider-container {
  -webkit-transition: left .3s ease-out;
  -moz-transition: left .3s ease-out;
  transition: left .3s ease-out
}

.input-range__label {
  color: #aaa;
  font-family: Helvetica Neue, san-serif;
  font-size: .8rem;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
  white-space: nowrap
}

.input-range__label--max,
.input-range__label--min {
  bottom: -1.4rem;
  position: absolute
}

.input-range__label--min {
  left: 0
}

.input-range__label--max {
  right: 0
}

.input-range__label--value {
  position: absolute;
  top: -1.8rem
}

.input-range__label-container {
  left: -50%;
  position: relative
}

.input-range__label--max .input-range__label-container {
  left: 50%
}

.input-range__track {
  background: #eee;
  border-radius: .3rem;
  cursor: pointer;
  display: block;
  height: .3rem;
  position: relative;
  -webkit-transition: left .3s ease-out, width .3s ease-out;
  -moz-transition: left .3s ease-out, width .3s ease-out;
  transition: left .3s ease-out, width .3s ease-out
}

.input-range--disabled .input-range__track {
  background: #eee
}

.input-range__track--background {
  left: 0;
  margin-top: -.15rem;
  position: absolute;
  right: 0;
  top: 50%
}

.input-range__track--active {
  background: #3f51b5
}

.input-range {
  height: 1rem;
  position: relative;
  width: 100%
}

.owl-carousel .owl-item img {
  height: auto
}

.owl-loaded .owl-stage:after {
  content: ""
}

.owl-carousel:not(.owl-loaded) {
  flex-wrap: nowrap;
  overflow: hidden
}

.owl-carousel:not(.owl-loaded)[class*=cols-]:not(.gutter-no) {
  margin-left: -10px !important;
  margin-right: -10px !important;
  width: auto
}

.owl-theme .owl-nav.disabled+.owl-dots {
  margin-top: 1.5rem
}

.owl-theme.owl-shadow-carousel .owl-nav.disabled+.owl-dots {
  margin-top: 4rem
}

.owl-theme .owl-nav .owl-next,
.owl-theme .owl-nav .owl-prev {
  margin: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 20px;
  font-weight: 400;
  color: #222
}

.owl-theme .owl-nav .owl-next i:before,
.owl-theme .owl-nav .owl-prev i:before {
  margin: 0
}

.owl-theme .owl-nav .owl-next:not(.disabled):active .owl-theme .owl-nav .owl-next:not(.disabled):focus,
.owl-theme .owl-nav .owl-next:not(.disabled):active .owl-theme .owl-nav .owl-prev:not(.disabled):focus,
.owl-theme .owl-nav .owl-next:not(.disabled):hover,
.owl-theme .owl-nav .owl-prev:not(.disabled):active .owl-theme .owl-nav .owl-next:not(.disabled):focus,
.owl-theme .owl-nav .owl-prev:not(.disabled):active .owl-theme .owl-nav .owl-prev:not(.disabled):focus,
.owl-theme .owl-nav .owl-prev:not(.disabled):hover {
  background-color: #222;
  border-color: #222;
  color: #fff
}

.owl-theme .owl-nav .owl-next.disabled,
.owl-theme .owl-nav .owl-prev.disabled {
  color: #ccc
}

.owl-theme .owl-nav .owl-prev {
  left: -40px
}

.owl-theme .owl-nav .owl-next {
  right: -40px
}

.owl-theme .owl-dots .owl-dot.active span {
  background-color: #222;
  border-color: #222
}

.owl-theme .owl-dots .owl-dot span {
  margin: 5px
}

@media(max-width: 1300px) {
  .owl-theme .owl-nav .owl-prev {
    left: 20px
  }

  .owl-theme .owl-nav .owl-next {
    right: 20px
  }
}

.owl-nav-full .owl-nav {
  opacity: 0;
  -webkit-transition: opacity .4s;
  -moz-transition: opacity .4s;
  transition: opacity .4s
}

.owl-nav-full .owl-nav .owl-next,
.owl-nav-full .owl-nav .owl-prev {
  margin: 0;
  top: 0;
  height: 100%;
  -webkit-transform: none;
  -moz-transform: none;
  transform: none;
  border: 0;
  font-size: 3rem;
  font-weight: 400;
  color: #333;
  border-radius: 0;
  background-color: hsla(0, 0%, 100%, .8);
  -webkit-transition: .4s;
  -moz-transition: .4s;
  transition: .4s
}

.owl-nav-full .owl-nav .owl-next:not(.disabled):hover,
.owl-nav-full .owl-nav .owl-prev:not(.disabled):hover {
  color: #222;
  background-color: hsla(0, 0%, 100%, .8)
}

.owl-nav-full .owl-nav .owl-next.disabled,
.owl-nav-full .owl-nav .owl-prev.disabled {
  visibility: hidden;
  opacity: 0
}

.owl-nav-full .owl-nav .owl-prev {
  left: 0;
  -webkit-box-shadow: 4px 0 7px -5px rgba(0, 0, 0, .2);
  box-shadow: 4px 0 7px -5px rgba(0, 0, 0, .2)
}

.owl-nav-full .owl-nav .owl-next {
  right: 0;
  -webkit-box-shadow: -4px 0 7px -5px rgba(0, 0, 0, .2);
  box-shadow: -4px 0 7px -5px rgba(0, 0, 0, .2)
}

.owl-nav-full:hover .owl-nav {
  opacity: 1
}

.owl-nav-full:hover .owl-prev {
  left: 0
}

.owl-nav-full:hover .owl-next {
  right: 0
}

.owl-nav-arrow .owl-nav .owl-next,
.owl-nav-arrow .owl-nav .owl-prev {
  margin: 0;
  color: #666;
  font-weight: 400;
  width: 1em;
  height: 1em;
  font-size: 4.5rem;
  border: none
}

.owl-nav-arrow .owl-nav .owl-next i,
.owl-nav-arrow .owl-nav .owl-prev i {
  display: none
}

.owl-nav-arrow .owl-nav .owl-next:before,
.owl-nav-arrow .owl-nav .owl-prev:before {
  font-family: riode
}

.owl-nav-arrow .owl-nav .owl-next:not(.disabled):active,
.owl-nav-arrow .owl-nav .owl-next:not(.disabled):focus,
.owl-nav-arrow .owl-nav .owl-next:not(.disabled):hover,
.owl-nav-arrow .owl-nav .owl-prev:not(.disabled):active,
.owl-nav-arrow .owl-nav .owl-prev:not(.disabled):focus,
.owl-nav-arrow .owl-nav .owl-prev:not(.disabled):hover {
  background-color: transparent;
  color: #222
}

.owl-nav-arrow .owl-nav .owl-next.disabled,
.owl-nav-arrow .owl-nav .owl-prev.disabled {
  color: #aaa
}

.owl-nav-arrow .owl-nav .owl-next {
  right: 5%
}

.owl-nav-arrow .owl-nav .owl-next:before {
  content: ""
}

.owl-nav-arrow .owl-nav .owl-prev {
  left: 5%
}

.owl-nav-arrow .owl-nav .owl-prev:before {
  content: ""
}

.owl-nav-inner .owl-nav .owl-next,
.owl-nav-inner .owl-nav .owl-prev {
  opacity: 0;
  visibility: hidden
}

.owl-nav-inner .owl-nav .owl-prev {
  left: 0
}

.owl-nav-inner .owl-nav .owl-next {
  right: 0
}

.owl-nav-inner:hover .owl-next,
.owl-nav-inner:hover .owl-prev {
  opacity: 1;
  visibility: visible
}

.owl-nav-inner:hover .owl-prev {
  left: 2rem
}

.owl-nav-inner:hover .owl-next {
  right: 2rem
}

.owl-nav-top .owl-nav {
  position: absolute;
  top: -5.7rem;
  right: -.4rem;
  color: #ccc
}

.owl-nav-top .owl-nav .owl-next:not(.disabled),
.owl-nav-top .owl-nav .owl-prev:not(.disabled) {
  color: #777
}

.owl-nav-top .owl-nav i {
  padding: .1rem .5rem;
  font-size: 1.8rem;
  font-weight: 700
}

.owl-nav-top .owl-nav i:before {
  margin: 0
}

.owl-nav-bottom .owl-nav {
  position: absolute;
  bottom: 1.7rem;
  right: -.4rem;
  color: #999
}

.owl-nav-bottom .owl-nav i {
  font-size: 2rem;
  font-weight: 700
}

.owl-nav-bottom .owl-nav i:before {
  margin: 0
}

.owl-nav-bottom .owl-prev {
  margin-right: .8rem
}

.owl-dot-white .owl-dots .owl-dot span {
  background-color: #fff;
  border-color: #fff;
  opacity: .8
}

.owl-dot-white .owl-dots .owl-dot.active span {
  background-color: #fff;
  border-color: #fff;
  opacity: 1
}

.owl-dot-grey .owl-dots .owl-dot span {
  background-color: #efefef;
  border-color: #efefef
}

.owl-dot-grey .owl-dots .owl-dot.active span {
  background-color: #999;
  border-color: #999
}

.owl-dot-dark .owl-dots .owl-dot span {
  background-color: #a1a1a1;
  border-color: #a1a1a1
}

.owl-dot-dark .owl-dots .owl-dot.active span {
  background-color: #333;
  border-color: #333
}

.owl-dot-inner .owl-dots {
  position: absolute;
  bottom: 4.5rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%)
}

.brand-carousel .owl-stage {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center
}

.brand-carousel .owl-item img {
  width: auto;
  margin: auto
}

.brand-carousel:not(.loaded) {
  text-align: center;
  -moz-box-align: center;
  align-items: center
}

.owl-carousel .slide-animate {
  -webkit-transform: translateZ(0) scale(1);
  -moz-transform: translateZ(0) scale(1);
  transform: translateZ(0) scale(1);
  will-change: filter, transform, opacity;
  visibility: hidden;
  opacity: 0
}

.owl-carousel .slide-animate.show-content {
  visibility: visible;
  opacity: 1
}

.owl-full-height,
.owl-full-height .owl-item,
.owl-full-height .owl-stage,
.owl-full-height .owl-stage-outer,
.owl-same-height .owl-item>* {
  height: 100%
}

.owl-same-height .owl-stage {
  display: -moz-box;
  display: flex
}

.owl-full-height .owl-item img {
  height: 100%;
  width: 100%;
  object-fit: cover
}

.owl-shadow-carousel .owl-stage-outer {
  margin: -20px;
  padding: 20px
}

.owl-nav-bg .owl-nav .owl-prev {
  left: 15.4%
}

.owl-nav-bg .owl-nav .owl-next {
  right: 15.4%
}

.owl-nav-bg .owl-nav [class*=owl-] {
  width: 4.8rem;
  height: 4.8rem;
  border-width: 2px;
  font-size: 3.2rem;
  color: #666;
  border-color: #666
}

.owl-nav-bg .owl-nav [class*=owl-].disabled {
  border-color: #acabab
}

.owl-nav-bg .owl-nav i {
  line-height: 1;
  margin-bottom: 2px
}

.owl-nav-fade .owl-nav .owl-prev {
  opacity: 0;
  margin-left: 4rem
}

.owl-nav-fade .owl-nav .owl-next {
  opacity: 0;
  margin-right: 4rem
}

.owl-nav-fade:hover .owl-nav .owl-prev {
  opacity: 1;
  margin-left: 0
}

.owl-nav-fade:hover .owl-nav .owl-next {
  opacity: 1;
  margin-right: 0
}

.owl-split .owl-item:not(:last-child) {
  position: relative
}

.owl-split .owl-item:not(:last-child):after {
  content: "";
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #ebebeb
}

.owl-middle .owl-stage {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center
}

.rotate-slider {
  padding-left: 17.9%
}

.rotate-slider .owl-stage-outer {
  padding: 20px 20px 20px 23%;
  margin: -20px -20px -20px -23%
}

.rotate-slider .owl-item {
  -webkit-transition: 1s;
  -moz-transition: 1s;
  transition: 1s;
  -webkit-box-shadow: 0 0 20px 5px rgba(0, 0, 0, .1);
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, .1)
}

.rotate-slider .owl-item:not(.active) {
  -webkit-transform: scale(.8) translateX(100%);
  -moz-transform: scale(.8) translateX(100%);
  transform: scale(.8) translateX(100%);
  -webkit-transform-origin: left;
  -moz-transform-origin: left;
  transform-origin: left
}

.rotate-slider .owl-nav .owl-next {
  right: auto;
  left: 40px;
  font-size: 36px
}

.rotate-slider .owl-nav .owl-next:before {
  content: ""
}

.rotate-slider .owl-nav .owl-prev {
  display: none
}

@media(max-width: 479px) {
  .rotate-slider .owl-nav .owl-next {
    right: auto;
    left: 15px;
    font-size: 24px
  }
}

.noUi-target {
  background: #eee;
  margin: 4px 16px 3rem 9px
}

.noUi-connects {
  padding: 11.5px 0;
  top: -10px
}

.noUi-connect {
  background: #999;
  margin-top: 11px;
  height: 3px
}

.noUi-horizontal .noUi-handle {
  top: -.6rem;
  width: 1.2rem;
  height: 1.7rem;
  background: #444;
  border-radius: 3px
}

*,
:after,
:before {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit
}

html {
  font-size: 62.5%;
  font-size-adjust: 100%;
  font-weight: 400;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

body {
  margin: 0;
  font-family: Poppins, sans-serif;
  font-size: 1.4rem;
  line-height: 1.6;
  color: #666;
  overflow-x: hidden !important;
  overflow-y: scroll !important
}

main {
  display: block;
  position: relative
}

body:not(.loaded) * {
  -webkit-transition: all 0s !important;
  -moz-transition: all 0s !important;
  transition: all 0s !important
}

.loaded .page-wrapper {
  opacity: 1
}

.page-wrapper {
  opacity: 0;
  position: relative;
  -webkit-transition: margin .4s, opacity .5s;
  -moz-transition: margin .4s, opacity .5s;
  transition: margin .4s, opacity .5s
}

table {
  width: 100%;
  border-collapse: collapse
}

table td,
table th {
  padding: 0
}

.section {
  padding: 7rem 0
}

.grey-section {
  background: #f6f7f9
}

.background-section {
  background-repeat: no-repeat
}

.parallax {
  background-color: #3c3f41
}

ul {
  padding-left: 1.5em
}

.breadcrumb,
.category ul,
.comments ul,
.dropdown-box,
.filter-items,
.list,
.menu,
.menu ul,
.mobile-menu,
.mobile-menu ul,
.nav,
.nav-filters,
.nav ul,
.pagination,
.product-nav,
.product-tabs>div ul,
.select-menu>ul,
.widget-body,
.widget-body ul {
  list-style-type: none;
  margin: 0;
  padding: 0
}

a {
  text-decoration: none;
  color: inherit;
  -webkit-transition: color .3s;
  -moz-transition: color .3s;
  transition: color .3s
}

a:hover {
  color: #222
}

:focus {
  outline: 0
}

figure {
  margin: 0
}

img {
  max-width: 100%;
  height: auto
}

hr {
  margin-bottom: 2rem;
  border: 0;
  border-top: 1px solid #eee
}

input {
  -webkit-appearance: none;
  border: 0;
  padding: 0;
  font-family: Poppins, sans-serif
}

input:focus {
  outline: 0
}

i {
  font-style: normal
}

button:focus {
  outline: none
}

.scrollable,
.sidebar-content {
  -webkit-overflow-scrolling: touch
}

.scrollable::-webkit-scrollbar,
.sidebar-content::-webkit-scrollbar {
  height: 7px;
  width: 4px
}

.scrollable::-webkit-scrollbar-thumb,
.sidebar-content::-webkit-scrollbar-thumb {
  margin-right: 2px;
  background: rgba(0, 0, 0, .25);
  border-radius: 5px;
  cursor: pointer
}

.scrollable-light::-webkit-scrollbar-thumb {
  background: hsla(0, 0%, 100%, .2)
}

@-webkit-keyframes bouncedelay {

  0%,
  80%,
  to {
    -webkit-transform: scale(1);
    transform: scale(1)
  }

  40% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2)
  }
}

@-moz-keyframes bouncedelay {

  0%,
  80%,
  to {
    -moz-transform: scale(1);
    transform: scale(1)
  }

  40% {
    -moz-transform: scale(1.2);
    transform: scale(1.2)
  }
}

@keyframes bouncedelay {

  0%,
  80%,
  to {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    transform: scale(1)
  }

  40% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    transform: scale(1.2)
  }
}

@-webkit-keyframes rotatedelay {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn)
  }
}

@-moz-keyframes rotatedelay {
  0% {
    -moz-transform: rotate(0);
    transform: rotate(0)
  }

  to {
    -moz-transform: rotate(1turn);
    transform: rotate(1turn)
  }
}

@keyframes rotatedelay {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    transform: rotate(0)
  }

  to {
    -webkit-transform: rotate(1turn);
    -moz-transform: rotate(1turn);
    transform: rotate(1turn)
  }
}

@-webkit-keyframes reveal-1 {
  0% {
    -webkit-transform: rotate(135deg) translateZ(0);
    transform: rotate(135deg) translateZ(0);
    border-radius: 50%
  }

  20% {
    -webkit-transform: rotate(135deg) translate3d(55%, 55%, 0);
    transform: rotate(135deg) translate3d(55%, 55%, 0);
    border-radius: 0
  }

  80% {
    -webkit-transform: rotate(495deg) translate3d(55%, 55%, 0);
    transform: rotate(495deg) translate3d(55%, 55%, 0);
    border-radius: 0
  }

  to {
    -webkit-transform: rotate(495deg) translateZ(0);
    transform: rotate(495deg) translateZ(0);
    border-radius: 50%
  }
}

@-moz-keyframes reveal-1 {
  0% {
    -moz-transform: rotate(135deg) translateZ(0);
    transform: rotate(135deg) translateZ(0);
    border-radius: 50%
  }

  20% {
    -moz-transform: rotate(135deg) translate3d(55%, 55%, 0);
    transform: rotate(135deg) translate3d(55%, 55%, 0);
    border-radius: 0
  }

  80% {
    -moz-transform: rotate(495deg) translate3d(55%, 55%, 0);
    transform: rotate(495deg) translate3d(55%, 55%, 0);
    border-radius: 0
  }

  to {
    -moz-transform: rotate(495deg) translateZ(0);
    transform: rotate(495deg) translateZ(0);
    border-radius: 50%
  }
}

@keyframes reveal-1 {
  0% {
    -webkit-transform: rotate(135deg) translateZ(0);
    -moz-transform: rotate(135deg) translateZ(0);
    transform: rotate(135deg) translateZ(0);
    border-radius: 50%
  }

  20% {
    -webkit-transform: rotate(135deg) translate3d(55%, 55%, 0);
    -moz-transform: rotate(135deg) translate3d(55%, 55%, 0);
    transform: rotate(135deg) translate3d(55%, 55%, 0);
    border-radius: 0
  }

  80% {
    -webkit-transform: rotate(495deg) translate3d(55%, 55%, 0);
    -moz-transform: rotate(495deg) translate3d(55%, 55%, 0);
    transform: rotate(495deg) translate3d(55%, 55%, 0);
    border-radius: 0
  }

  to {
    -webkit-transform: rotate(495deg) translateZ(0);
    -moz-transform: rotate(495deg) translateZ(0);
    transform: rotate(495deg) translateZ(0);
    border-radius: 50%
  }
}

@-webkit-keyframes reveal-2 {
  0% {
    -webkit-transform: rotate(135deg) translateZ(0);
    transform: rotate(135deg) translateZ(0);
    border-radius: 50%
  }

  20% {
    -webkit-transform: rotate(135deg) translate3d(55%, -55%, 0);
    transform: rotate(135deg) translate3d(55%, -55%, 0);
    border-radius: 0
  }

  80% {
    -webkit-transform: rotate(495deg) translate3d(55%, -55%, 0);
    transform: rotate(495deg) translate3d(55%, -55%, 0);
    border-radius: 0
  }

  to {
    -webkit-transform: rotate(495deg) translateZ(0);
    transform: rotate(495deg) translateZ(0);
    border-radius: 50%
  }
}

@-moz-keyframes reveal-2 {
  0% {
    -moz-transform: rotate(135deg) translateZ(0);
    transform: rotate(135deg) translateZ(0);
    border-radius: 50%
  }

  20% {
    -moz-transform: rotate(135deg) translate3d(55%, -55%, 0);
    transform: rotate(135deg) translate3d(55%, -55%, 0);
    border-radius: 0
  }

  80% {
    -moz-transform: rotate(495deg) translate3d(55%, -55%, 0);
    transform: rotate(495deg) translate3d(55%, -55%, 0);
    border-radius: 0
  }

  to {
    -moz-transform: rotate(495deg) translateZ(0);
    transform: rotate(495deg) translateZ(0);
    border-radius: 50%
  }
}

@keyframes reveal-2 {
  0% {
    -webkit-transform: rotate(135deg) translateZ(0);
    -moz-transform: rotate(135deg) translateZ(0);
    transform: rotate(135deg) translateZ(0);
    border-radius: 50%
  }

  20% {
    -webkit-transform: rotate(135deg) translate3d(55%, -55%, 0);
    -moz-transform: rotate(135deg) translate3d(55%, -55%, 0);
    transform: rotate(135deg) translate3d(55%, -55%, 0);
    border-radius: 0
  }

  80% {
    -webkit-transform: rotate(495deg) translate3d(55%, -55%, 0);
    -moz-transform: rotate(495deg) translate3d(55%, -55%, 0);
    transform: rotate(495deg) translate3d(55%, -55%, 0);
    border-radius: 0
  }

  to {
    -webkit-transform: rotate(495deg) translateZ(0);
    -moz-transform: rotate(495deg) translateZ(0);
    transform: rotate(495deg) translateZ(0);
    border-radius: 50%
  }
}

@-webkit-keyframes reveal-3 {
  0% {
    -webkit-transform: rotate(135deg) translateZ(0);
    transform: rotate(135deg) translateZ(0);
    border-radius: 50%
  }

  20% {
    -webkit-transform: rotate(135deg) translate3d(-55%, -55%, 0);
    transform: rotate(135deg) translate3d(-55%, -55%, 0);
    border-radius: 0
  }

  80% {
    -webkit-transform: rotate(495deg) translate3d(-55%, -55%, 0);
    transform: rotate(495deg) translate3d(-55%, -55%, 0);
    border-radius: 0
  }

  to {
    -webkit-transform: rotate(495deg) translateZ(0);
    transform: rotate(495deg) translateZ(0);
    border-radius: 50%
  }
}

@-moz-keyframes reveal-3 {
  0% {
    -moz-transform: rotate(135deg) translateZ(0);
    transform: rotate(135deg) translateZ(0);
    border-radius: 50%
  }

  20% {
    -moz-transform: rotate(135deg) translate3d(-55%, -55%, 0);
    transform: rotate(135deg) translate3d(-55%, -55%, 0);
    border-radius: 0
  }

  80% {
    -moz-transform: rotate(495deg) translate3d(-55%, -55%, 0);
    transform: rotate(495deg) translate3d(-55%, -55%, 0);
    border-radius: 0
  }

  to {
    -moz-transform: rotate(495deg) translateZ(0);
    transform: rotate(495deg) translateZ(0);
    border-radius: 50%
  }
}

@keyframes reveal-3 {
  0% {
    -webkit-transform: rotate(135deg) translateZ(0);
    -moz-transform: rotate(135deg) translateZ(0);
    transform: rotate(135deg) translateZ(0);
    border-radius: 50%
  }

  20% {
    -webkit-transform: rotate(135deg) translate3d(-55%, -55%, 0);
    -moz-transform: rotate(135deg) translate3d(-55%, -55%, 0);
    transform: rotate(135deg) translate3d(-55%, -55%, 0);
    border-radius: 0
  }

  80% {
    -webkit-transform: rotate(495deg) translate3d(-55%, -55%, 0);
    -moz-transform: rotate(495deg) translate3d(-55%, -55%, 0);
    transform: rotate(495deg) translate3d(-55%, -55%, 0);
    border-radius: 0
  }

  to {
    -webkit-transform: rotate(495deg) translateZ(0);
    -moz-transform: rotate(495deg) translateZ(0);
    transform: rotate(495deg) translateZ(0);
    border-radius: 50%
  }
}

@-webkit-keyframes reveal-4 {
  0% {
    -webkit-transform: rotate(135deg) translateZ(0);
    transform: rotate(135deg) translateZ(0);
    border-radius: 50%
  }

  20% {
    -webkit-transform: rotate(135deg) translate3d(-55%, 55%, 0);
    transform: rotate(135deg) translate3d(-55%, 55%, 0);
    border-radius: 0
  }

  80% {
    -webkit-transform: rotate(495deg) translate3d(-55%, 55%, 0);
    transform: rotate(495deg) translate3d(-55%, 55%, 0);
    border-radius: 0
  }

  to {
    -webkit-transform: rotate(495deg) translateZ(0);
    transform: rotate(495deg) translateZ(0);
    border-radius: 50%
  }
}

@-moz-keyframes reveal-4 {
  0% {
    -moz-transform: rotate(135deg) translateZ(0);
    transform: rotate(135deg) translateZ(0);
    border-radius: 50%
  }

  20% {
    -moz-transform: rotate(135deg) translate3d(-55%, 55%, 0);
    transform: rotate(135deg) translate3d(-55%, 55%, 0);
    border-radius: 0
  }

  80% {
    -moz-transform: rotate(495deg) translate3d(-55%, 55%, 0);
    transform: rotate(495deg) translate3d(-55%, 55%, 0);
    border-radius: 0
  }

  to {
    -moz-transform: rotate(495deg) translateZ(0);
    transform: rotate(495deg) translateZ(0);
    border-radius: 50%
  }
}

@keyframes reveal-4 {
  0% {
    -webkit-transform: rotate(135deg) translateZ(0);
    -moz-transform: rotate(135deg) translateZ(0);
    transform: rotate(135deg) translateZ(0);
    border-radius: 50%
  }

  20% {
    -webkit-transform: rotate(135deg) translate3d(-55%, 55%, 0);
    -moz-transform: rotate(135deg) translate3d(-55%, 55%, 0);
    transform: rotate(135deg) translate3d(-55%, 55%, 0);
    border-radius: 0
  }

  80% {
    -webkit-transform: rotate(495deg) translate3d(-55%, 55%, 0);
    -moz-transform: rotate(495deg) translate3d(-55%, 55%, 0);
    transform: rotate(495deg) translate3d(-55%, 55%, 0);
    border-radius: 0
  }

  to {
    -webkit-transform: rotate(495deg) translateZ(0);
    -moz-transform: rotate(495deg) translateZ(0);
    transform: rotate(495deg) translateZ(0);
    border-radius: 50%
  }
}

.bounce-loader {
  position: absolute;
  display: -moz-box;
  display: flex;
  flex-wrap: wrap;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: justify;
  justify-content: space-between;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  -webkit-transition: all .2s;
  -moz-transition: all .2s;
  transition: all .2s;
  text-align: center;
  z-index: 10000
}

.bounce-loader .bounce1,
.bounce-loader .bounce2,
.bounce-loader .bounce3,
.bounce-loader .bounce4 {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  margin-bottom: 5px;
  background-color: #222
}

.bounce-loader .bounce1 {
  -webkit-animation: reveal-1 2s infinite;
  -moz-animation: reveal-1 2s infinite;
  animation: reveal-1 2s infinite
}

.bounce-loader .bounce2 {
  -webkit-animation: reveal-2 2s infinite;
  -moz-animation: reveal-2 2s infinite;
  animation: reveal-2 2s infinite
}

.bounce-loader .bounce3 {
  -webkit-animation: reveal-3 2s infinite;
  -moz-animation: reveal-3 2s infinite;
  animation: reveal-3 2s infinite
}

.bounce-loader .bounce4 {
  -webkit-animation: reveal-4 2s infinite;
  -moz-animation: reveal-4 2s infinite;
  animation: reveal-4 2s infinite
}

.bounce-loader .bounce3 {
  border: 3px solid #222;
  background-color: transparent
}

.appear-animate {
  -webkit-transform: translateZ(0) scale(1);
  -moz-transform: translateZ(0) scale(1);
  transform: translateZ(0) scale(1);
  will-change: transform, filter, opacity
}

.fade {
  opacity: 0;
  -webkit-transition: opacity .5s;
  -moz-transition: opacity .5s;
  transition: opacity .5s
}

.fade.in {
  opacity: 1
}

.scroll-top {
  position: fixed;
  text-align: center;
  bottom: 30px;
  left: auto;
  right: 30px;
  width: 60px;
  height: 60px;
  font-size: 27px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: visibility .3s, opacity .3s, -webkit-transform .3s;
  transition: visibility .3s, opacity .3s, -webkit-transform .3s;
  -moz-transition: transform .3s, visibility .3s, opacity .3s, -moz-transform .3s;
  transition: transform .3s, visibility .3s, opacity .3s;
  transition: transform .3s, visibility .3s, opacity .3s, -webkit-transform .3s, -moz-transform .3s;
  color: #222;
  -webkit-transform: translateY(40px);
  -moz-transform: translateY(40px);
  transform: translateY(40px);
  border-radius: 3px;
  z-index: 999;
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, .1);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .1);
  line-height: 60px;
  background-color: #fff
}

.scroll-top:hover {
  color: #222
}

.scroll-top i {
  font-weight: 900;
  line-height: inherit
}

.infinite-scroll-component>.d-loading {
  display: block;
  position: absolute;
  top: 100%;
  left: -moz-calc(50% - 17px);
  left: calc(50% - 17px);
  width: 34px;
  height: 34px;
  border: 2px solid transparent;
  border-top-color: #26b;
  border-radius: 50%;
  -webkit-animation: spin .75s linear infinite;
  -moz-animation: spin .75s linear infinite;
  animation: spin .75s linear infinite
}

.infinite-scroll-component>.d-loading:before {
  content: "";
  top: -2px;
  left: -2px;
  position: absolute;
  width: inherit;
  height: inherit;
  border: inherit;
  border-radius: inherit;
  -webkit-animation: spin 1.5s ease infinite;
  -moz-animation: spin 1.5s ease infinite;
  animation: spin 1.5s ease infinite
}

@media(max-width: 991px) {
  #__next {
    overflow: hidden
  }
}

@media(min-width: 768px) {
  .scroll-top.show {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    transform: translateY(0)
  }
}

@-webkit-keyframes fixedTop {
  0% {
    margin-top: -60px
  }

  to {
    margin-top: 0
  }
}

@-moz-keyframes fixedTop {
  0% {
    margin-top: -60px
  }

  to {
    margin-top: 0
  }
}

@-webkit-keyframes fixedBottom {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transform-origin: center top 0;
    transform-origin: center top 0
  }

  to {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
}

@-moz-keyframes fixedBottom {
  0% {
    -moz-transform: translateY(100%);
    transform: translateY(100%);
    -moz-transform-origin: center top 0;
    transform-origin: center top 0
  }

  to {
    -moz-transform: translateY(0);
    transform: translateY(0)
  }
}

.sticky-content.fix-top {
  top: 0
}

.sticky-content.fix-bottom {
  bottom: 0
}

.sticky-content.fixed {
  position: fixed;
  left: 0;
  right: 0;
  opacity: 1;
  background: #fff;
  z-index: 1051;
  -webkit-box-shadow: 0 0 10px 1px rgba(0, 0, 0, .1);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, .1)
}

.sticky-content.fixed.fix-top {
  -webkit-animation: fixedTop .4s;
  -moz-animation: fixedTop .4s;
  animation: fixedTop .4s
}

.sticky-content.fixed.fix-bottom {
  -webkit-animation: fixedBottom .4s;
  -moz-animation: fixedBottom .4s;
  animation: fixedBottom .4s;
  -webkit-transition: margin-bottom .4s;
  -moz-transition: margin-bottom .4s;
  transition: margin-bottom .4s
}

.load-more-overlay.loading:after,
.loading:not(.load-more-overlay) {
  -webkit-animation: spin .65s linear infinite;
  -moz-animation: spin .65s linear infinite;
  animation: spin .65s linear infinite;
  border: 2px solid transparent;
  border-radius: 32px;
  border-top: 2px solid rgba(0, 0, 0, .4) !important;
  border-right: 2px solid rgba(0, 0, 0, .4) !important;
  border-bottom: 2px solid rgba(0, 0, 0, .4) !important;
  content: "";
  display: block;
  height: 21px;
  top: 50%;
  margin-top: -11px;
  left: 50%;
  margin-left: -10px;
  right: auto;
  position: absolute;
  width: 21px
}

.load-more-overlay {
  position: relative
}

.load-more-overlay.loading:after {
  content: ""
}

.load-more-overlay:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  opacity: .8
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg)
  }
}

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  to {
    -moz-transform: rotate(359deg);
    transform: rotate(359deg)
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  to {
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    transform: rotate(359deg)
  }
}

.riode-rounded-skin .btn,
.riode-rounded-skin .minipopup-box,
.riode-rounded-skin .post-calendar,
.riode-rounded-skin .post-framed,
.riode-rounded-skin .post-media,
.riode-rounded-skin .post-single .post-author-detail,
.riode-rounded-skin .post-single>.post-wrap img,
.riode-rounded-skin .product-category,
.riode-rounded-skin .product-category .category-content,
.riode-rounded-skin .product-hide-details .btn-product,
.riode-rounded-skin .product-hide-details .btn-product-icon,
.riode-rounded-skin .product-with-qty .quantity button,
.riode-rounded-skin .product-wrapper .banner,
.riode-rounded-skin .vendor-widget .vendor-banner,
.riode-rounded-skin .vendor-widget .vendor-logo,
.riode-rounded-skin .vendor-widget .vendor-product>figure {
  border-radius: 3px;
  overflow: hidden
}

.riode-rounded-skin .form-row [type=email],
.riode-rounded-skin .form-row [type=password],
.riode-rounded-skin .form-row [type=tel],
.riode-rounded-skin .form-row [type=text],
.riode-rounded-skin .form-row textarea,
.riode-rounded-skin .login-popup {
  border-radius: 3px
}

.riode-rounded-skin .btn-link,
.riode-rounded-skin .form-coupon .input-text {
  border-radius: 0;
  overflow: visible
}

.riode-rounded-skin .post.post-mask.gradient:before {
  border-radius: 0 0 3px 3px;
  overflow: hidden
}

.riode-rounded-skin .mfp-product .product,
.riode-rounded-skin .product-category.cat-type-default {
  border-radius: 10px
}

.w-100 {
  width: 100% !important
}

.h-100 {
  height: 100% !important
}

.p-sticky {
  position: sticky !important
}

.p-relative {
  position: relative !important
}

.x-50 {
  left: 50% !important;
  -webkit-transform: translateX(-50%) !important;
  -moz-transform: translateX(-50%) !important;
  transform: translateX(-50%) !important
}

.x-50.y-50 {
  -webkit-transform: translate(-50%, -50%) !important;
  -moz-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important
}

.y-50 {
  top: 50% !important;
  -webkit-transform: translateY(-50%) !important;
  -moz-transform: translateY(-50%) !important;
  transform: translateY(-50%) !important
}

.d-none {
  display: none !important
}

.d-block {
  display: block !important
}

.d-inline-block {
  display: inline-block !important
}

.d-flex {
  display: -moz-box !important;
  display: flex !important
}

.d-inline-flex {
  display: -moz-inline-box !important;
  display: inline-flex !important
}

.justify-content-center {
  -moz-box-pack: center !important;
  justify-content: center !important
}

.justify-content-start {
  -moz-box-pack: start !important;
  justify-content: flex-start !important
}

.justify-content-end {
  -moz-box-pack: end !important;
  justify-content: flex-end !important
}

.justify-content-between {
  -moz-box-pack: justify !important;
  justify-content: space-between !important
}

.align-items-start {
  -moz-box-align: start !important;
  align-items: flex-start !important
}

.align-items-center {
  -moz-box-align: center !important;
  align-items: center !important
}

.align-items-end {
  -moz-box-align: end !important;
  align-items: flex-end !important
}

.flex-column {
  -moz-box-orient: vertical !important;
  -moz-box-direction: normal !important;
  flex-direction: column !important
}

.flex-wrap {
  flex-wrap: wrap !important
}

.flex-1 {
  -moz-box-flex: 1 !important;
  flex: 1 1 !important
}

.overflow-hidden {
  overflow: hidden !important
}

@media(min-width: 1200px) {
  .d-xl-none {
    display: none !important
  }

  .d-xl-block {
    display: block !important
  }

  .d-xl-flex {
    display: -moz-box !important;
    display: flex !important
  }

  .d-xl-inline-flex {
    display: -moz-inline-box !important;
    display: inline-flex !important
  }
}

@media(min-width: 992px) {
  .d-lg-none {
    display: none !important
  }

  .d-lg-block {
    display: block !important
  }

  .d-lg-flex {
    display: -moz-box !important;
    display: flex !important
  }

  .d-lg-inline-flex {
    display: -moz-inline-box !important;
    display: inline-flex !important
  }
}

@media(max-width: 1199px) {
  .d-xl-show {
    display: none !important
  }
}

@media(max-width: 991px) {
  .d-lg-show {
    display: none !important
  }
}

.font-primary,
.font-secondary {
  font-family: Poppins, sans-serif !important
}

.font-tertiary {
  font-family: Open Sans, sans-serif !important
}

.font-weight-bolder {
  font-weight: 800 !important
}

.font-weight-bold {
  font-weight: 700 !important
}

.font-weight-semi-bold {
  font-weight: 600 !important
}

.font-weight-normal {
  font-weight: 400 !important
}

.text-uppercase {
  text-transform: uppercase !important
}

.text-capitalize {
  text-transform: capitalize !important
}

.text-normal {
  text-transform: none !important
}

.font-italic {
  font-style: italic !important
}

.font-normal {
  font-stretch: normal !important
}

.text-left {
  text-align: left !important
}

.text-center {
  text-align: center !important
}

.text-right {
  text-align: right !important
}

.text-white {
  color: #fff !important
}

.text-light {
  color: #ccc !important
}

.text-grey {
  color: #999 !important
}

.text-body {
  color: #666 !important
}

.text-dark {
  color: #222 !important
}

.text-black {
  color: #000 !important
}

.text-primary {
  color: #222 !important
}

.text-secondary {
  color: #ff675d !important
}

.ls-md {
  letter-spacing: -.03em !important
}

.ls-s {
  letter-spacing: -.01em !important
}

.ls-m {
  letter-spacing: -.025em !important
}

.ls-l {
  letter-spacing: -.05em !important
}

.ls-normal {
  letter-spacing: 0 !important
}

.lh-2 {
  line-height: 2 !important
}

.lh-1 {
  line-height: 1 !important
}

.lh-0 {
  line-height: 0 !important
}

.bg-white {
  background-color: #fff !important
}

.bg-dark {
  background-color: #222 !important
}

.bg-grey {
  background-color: #999 !important
}

.bg-light {
  background-color: #ccc !important
}

.bg-black {
  background-color: #000 !important
}

.bg-primary {
  background-color: #222 !important
}

.bg-secondary {
  background-color: #ff675d !important
}

.border-no {
  border: none !important
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: 400;
  margin: 0 0 2rem
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Poppins, sans-serif;
  font-weight: 600;
  line-height: 1.4;
  color: #222
}

h1 {
  font-size: 4rem
}

h2 {
  font-size: 3.4rem
}

h3 {
  font-size: 3rem
}

h4 {
  font-size: 2.4rem
}

h5 {
  font-size: 1.8rem
}

h6 {
  font-size: 1.5rem
}

p {
  font-family: Poppins, sans-serif;
  font-size: 1.4rem;
  line-height: 1.86
}

blockquote {
  margin: 0
}

.list {
  font-size: 1.4rem;
  line-height: 1.86;
  color: #666
}

.list-style-none {
  list-style: none
}

.list-type-number {
  list-style-type: decimal
}

.list-type-check li {
  position: relative;
  padding-left: 2.4rem
}

.list-type-check li:before {
  display: block;
  position: absolute;
  left: 3px;
  top: 2px;
  font-family: riode;
  content: "";
  font-weight: 700;
  font-size: 1.2rem;
  color: #222
}

.list-circle li {
  padding-left: 1rem;
  margin-bottom: 1rem
}

.list-circle i {
  margin-right: 1.2rem;
  width: 4rem;
  height: 4rem;
  border: 1px solid #e1e1e1;
  border-radius: 50%;
  text-align: center;
  line-height: 3.8rem;
  font-size: 1.1rem
}

.list-arrow li {
  position: relative;
  -webkit-transition: color .3s, padding .3s;
  -moz-transition: color .3s, padding .3s;
  transition: color .3s, padding .3s
}

.list-arrow li:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  font-family: riode;
  font-size: 1.4rem;
  -webkit-transition: opacity .3s, visibility .3s;
  -moz-transition: opacity .3s, visibility .3s;
  transition: opacity .3s, visibility .3s
}

.list-arrow li.show,
.list-arrow li:hover {
  padding-left: 2.2rem;
  color: #222
}

.list-arrow li.show:before,
.list-arrow li:hover:before {
  visibility: visible;
  opacity: 1
}

.text-bd-left {
  position: relative;
  padding-left: 2rem
}

.text-bd-left:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  width: 2px;
  top: 5px;
  bottom: 5px;
  background-color: #dae1e5
}

@media(max-width: 767px) {
  .list-type-number {
    margin-left: 2.3rem
  }
}

.container,
.container-fluid {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto
}

@media(max-width: 479px) {

  .container,
  .container-fluid {
    padding-left: 15px;
    padding-right: 15px
  }
}

.container-fluid {
  max-width: 1820px
}

.container {
  max-width: 1220px
}

.divider {
  display: inline-block;
  vertical-align: middle;
  margin-right: 1.5rem;
  height: 2.5rem;
  width: 1px;
  background-color: #e1e1e1
}

.row {
  display: -moz-box;
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px
}

.row>* {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px
}

.gutter-lg {
  margin-left: -15px;
  margin-right: -15px
}

.gutter-lg>* {
  padding-right: 15px;
  padding-left: 15px
}

.gutter-sm {
  margin-left: -5px;
  margin-right: -5px
}

.gutter-sm>* {
  padding-right: 5px;
  padding-left: 5px
}

.gutter-xs {
  margin-left: -1px;
  margin-right: -1px
}

.gutter-xs>* {
  padding-right: 1px;
  padding-left: 1px
}

.gutter-no {
  margin-left: 0;
  margin-right: 0
}

.gutter-no>* {
  padding-right: 0;
  padding-left: 0
}

.cols-1>* {
  max-width: 100%;
  -moz-box-flex: 0;
  flex: 0 0 100%
}

.cols-2>* {
  max-width: 50%;
  -moz-box-flex: 0;
  flex: 0 0 50%
}

.cols-3>* {
  max-width: 33.3333%;
  -moz-box-flex: 0;
  flex: 0 0 33.3333%
}

.cols-4>* {
  max-width: 25%;
  -moz-box-flex: 0;
  flex: 0 0 25%
}

.cols-5>* {
  max-width: 20%;
  -moz-box-flex: 0;
  flex: 0 0 20%
}

.cols-6>* {
  max-width: 16.6667%;
  -moz-box-flex: 0;
  flex: 0 0 16.6667%
}

.cols-7>* {
  max-width: 14.2857%;
  -moz-box-flex: 0;
  flex: 0 0 14.2857%
}

.cols-8>* {
  max-width: 12.5%;
  -moz-box-flex: 0;
  flex: 0 0 12.5%
}

@media(min-width: 480px) {
  .cols-xs-1>* {
    max-width: 100%;
    -moz-box-flex: 0;
    flex: 0 0 100%
  }

  .cols-xs-2>* {
    max-width: 50%;
    -moz-box-flex: 0;
    flex: 0 0 50%
  }

  .cols-xs-3>* {
    max-width: 33.3333%;
    -moz-box-flex: 0;
    flex: 0 0 33.3333%
  }

  .cols-xs-4>* {
    max-width: 25%;
    -moz-box-flex: 0;
    flex: 0 0 25%
  }

  .cols-xs-5>* {
    max-width: 20%;
    -moz-box-flex: 0;
    flex: 0 0 20%
  }

  .cols-xs-6>* {
    max-width: 16.6667%;
    -moz-box-flex: 0;
    flex: 0 0 16.6667%
  }

  .cols-xs-7>* {
    max-width: 14.2857%;
    -moz-box-flex: 0;
    flex: 0 0 14.2857%
  }

  .cols-xs-8>* {
    max-width: 12.5%;
    -moz-box-flex: 0;
    flex: 0 0 12.5%
  }
}

@media(min-width: 576px) {
  .cols-sm-1>* {
    max-width: 100%;
    -moz-box-flex: 0;
    flex: 0 0 100%
  }

  .cols-sm-2>* {
    max-width: 50%;
    -moz-box-flex: 0;
    flex: 0 0 50%
  }

  .cols-sm-3>* {
    max-width: 33.3333%;
    -moz-box-flex: 0;
    flex: 0 0 33.3333%
  }

  .cols-sm-4>* {
    max-width: 25%;
    -moz-box-flex: 0;
    flex: 0 0 25%
  }

  .cols-sm-5>* {
    max-width: 20%;
    -moz-box-flex: 0;
    flex: 0 0 20%
  }

  .cols-sm-6>* {
    max-width: 16.6667%;
    -moz-box-flex: 0;
    flex: 0 0 16.6667%
  }

  .cols-sm-7>* {
    max-width: 14.2857%;
    -moz-box-flex: 0;
    flex: 0 0 14.2857%
  }

  .cols-sm-8>* {
    max-width: 12.5%;
    -moz-box-flex: 0;
    flex: 0 0 12.5%
  }
}

@media(min-width: 768px) {
  .cols-md-1>* {
    max-width: 100%;
    -moz-box-flex: 0;
    flex: 0 0 100%
  }

  .cols-md-2>* {
    max-width: 50%;
    -moz-box-flex: 0;
    flex: 0 0 50%
  }

  .cols-md-3>* {
    max-width: 33.3333%;
    -moz-box-flex: 0;
    flex: 0 0 33.3333%
  }

  .cols-md-4>* {
    max-width: 25%;
    -moz-box-flex: 0;
    flex: 0 0 25%
  }

  .cols-md-5>* {
    max-width: 20%;
    -moz-box-flex: 0;
    flex: 0 0 20%
  }

  .cols-md-6>* {
    max-width: 16.6667%;
    -moz-box-flex: 0;
    flex: 0 0 16.6667%
  }

  .cols-md-7>* {
    max-width: 14.2857%;
    -moz-box-flex: 0;
    flex: 0 0 14.2857%
  }

  .cols-md-8>* {
    max-width: 12.5%;
    -moz-box-flex: 0;
    flex: 0 0 12.5%
  }
}

.overflow-ellipse-for-productName {
  width: auto !important;
}

@media(min-width: 992px) {
  .cols-lg-1>* {
    max-width: 100%;
    -moz-box-flex: 0;
    flex: 0 0 100%
  }

  .cols-lg-2>* {
    max-width: 50%;
    -moz-box-flex: 0;
    flex: 0 0 50%
  }

  .cols-lg-3>* {
    max-width: 33.3333%;
    -moz-box-flex: 0;
    flex: 0 0 33.3333%
  }

  .cols-lg-4>* {
    max-width: 25%;
    -moz-box-flex: 0;
    flex: 0 0 25%
  }

  .cols-lg-5>* {
    max-width: 20%;
    -moz-box-flex: 0;
    flex: 0 0 20%
  }

  .cols-lg-6>* {
    max-width: 16.6667%;
    -moz-box-flex: 0;
    flex: 0 0 16.6667%
  }

  .cols-lg-7>* {
    max-width: 14.2857%;
    -moz-box-flex: 0;
    flex: 0 0 14.2857%
  }

  .cols-lg-8>* {
    max-width: 12.5%;
    -moz-box-flex: 0;
    flex: 0 0 12.5%
  }
}

@media(min-width: 1200px) {
  .cols-xl-1>* {
    max-width: 100%;
    -moz-box-flex: 0;
    flex: 0 0 100%
  }

  .cols-xl-2>* {
    max-width: 50%;
    -moz-box-flex: 0;
    flex: 0 0 50%
  }

  .cols-xl-3>* {
    max-width: 33.3333%;
    -moz-box-flex: 0;
    flex: 0 0 33.3333%
  }

  .cols-xl-4>* {
    max-width: 25%;
    -moz-box-flex: 0;
    flex: 0 0 25%
  }

  .cols-xl-5>* {
    max-width: 20%;
    -moz-box-flex: 0;
    flex: 0 0 20%
  }

  .cols-xl-6>* {
    max-width: 16.6667%;
    -moz-box-flex: 0;
    flex: 0 0 16.6667%
  }

  .cols-xl-7>* {
    max-width: 14.2857%;
    -moz-box-flex: 0;
    flex: 0 0 14.2857%
  }

  .cols-xl-8>* {
    max-width: 12.5%;
    -moz-box-flex: 0;
    flex: 0 0 12.5%
  }
}

.col-1 {
  max-width: 8.3333%;
  flex: 0 0 8.3333%
}

.col-1,
.col-2 {
  -moz-box-flex: 0
}

.col-2 {
  max-width: 16.6667%;
  flex: 0 0 16.6667%
}

.col-3 {
  max-width: 25%;
  flex: 0 0 25%
}

.col-3,
.col-4 {
  -moz-box-flex: 0
}

.col-4 {
  max-width: 33.3333%;
  flex: 0 0 33.3333%
}

.col-5 {
  max-width: 41.6667%;
  flex: 0 0 41.6667%
}

.col-5,
.col-6 {
  -moz-box-flex: 0
}

.col-6 {
  max-width: 50%;
  flex: 0 0 50%
}

.col-7 {
  max-width: 58.3333%;
  flex: 0 0 58.3333%
}

.col-7,
.col-8 {
  -moz-box-flex: 0
}

.col-8 {
  max-width: 66.6667%;
  flex: 0 0 66.6667%
}

.col-9 {
  max-width: 75%;
  flex: 0 0 75%
}

.col-9,
.col-10 {
  -moz-box-flex: 0
}

.col-10 {
  max-width: 83.3333%;
  flex: 0 0 83.3333%
}

.col-11 {
  max-width: 91.6667%;
  -moz-box-flex: 0;
  flex: 0 0 91.6667%
}

.col-12 {
  max-width: 100%;
  -moz-box-flex: 0;
  flex: 0 0 100%
}

@media(min-width: 480px) {
  .col-xs-1 {
    max-width: 8.3333%;
    -moz-box-flex: 0;
    flex: 0 0 8.3333%
  }

  .col-xs-2 {
    max-width: 16.6667%;
    -moz-box-flex: 0;
    flex: 0 0 16.6667%
  }

  .col-xs-3 {
    max-width: 25%;
    -moz-box-flex: 0;
    flex: 0 0 25%
  }

  .col-xs-4 {
    max-width: 33.3333%;
    -moz-box-flex: 0;
    flex: 0 0 33.3333%
  }

  .col-xs-5 {
    max-width: 41.6667%;
    -moz-box-flex: 0;
    flex: 0 0 41.6667%
  }

  .col-xs-6 {
    max-width: 50%;
    -moz-box-flex: 0;
    flex: 0 0 50%
  }

  .col-xs-7 {
    max-width: 58.3333%;
    -moz-box-flex: 0;
    flex: 0 0 58.3333%
  }

  .col-xs-8 {
    max-width: 66.6667%;
    -moz-box-flex: 0;
    flex: 0 0 66.6667%
  }

  .col-xs-9 {
    max-width: 75%;
    -moz-box-flex: 0;
    flex: 0 0 75%
  }

  .col-xs-10 {
    max-width: 83.3333%;
    -moz-box-flex: 0;
    flex: 0 0 83.3333%
  }

  .col-xs-11 {
    max-width: 91.6667%;
    -moz-box-flex: 0;
    flex: 0 0 91.6667%
  }

  .col-xs-12 {
    max-width: 100%;
    -moz-box-flex: 0;
    flex: 0 0 100%
  }
}

@media(min-width: 576px) {
  .col-sm-1 {
    max-width: 8.3333%;
    -moz-box-flex: 0;
    flex: 0 0 8.3333%
  }

  .col-sm-2 {
    max-width: 16.6667%;
    -moz-box-flex: 0;
    flex: 0 0 16.6667%
  }

  .col-sm-3 {
    max-width: 25%;
    -moz-box-flex: 0;
    flex: 0 0 25%
  }

  .col-sm-4 {
    max-width: 33.3333%;
    -moz-box-flex: 0;
    flex: 0 0 33.3333%
  }

  .col-sm-5 {
    max-width: 41.6667%;
    -moz-box-flex: 0;
    flex: 0 0 41.6667%
  }

  .col-sm-6 {
    max-width: 50%;
    -moz-box-flex: 0;
    flex: 0 0 50%
  }

  .col-sm-7 {
    max-width: 58.3333%;
    -moz-box-flex: 0;
    flex: 0 0 58.3333%
  }

  .col-sm-8 {
    max-width: 66.6667%;
    -moz-box-flex: 0;
    flex: 0 0 66.6667%
  }

  .col-sm-9 {
    max-width: 75%;
    -moz-box-flex: 0;
    flex: 0 0 75%
  }

  .col-sm-10 {
    max-width: 83.3333%;
    -moz-box-flex: 0;
    flex: 0 0 83.3333%
  }

  .col-sm-11 {
    max-width: 91.6667%;
    -moz-box-flex: 0;
    flex: 0 0 91.6667%
  }

  .col-sm-12 {
    max-width: 100%;
    -moz-box-flex: 0;
    flex: 0 0 100%
  }
}

@media(min-width: 768px) {
  .col-md-1 {
    max-width: 8.3333%;
    -moz-box-flex: 0;
    flex: 0 0 8.3333%
  }

  .col-md-2 {
    max-width: 16.6667%;
    -moz-box-flex: 0;
    flex: 0 0 16.6667%
  }

  .col-md-3 {
    max-width: 25%;
    -moz-box-flex: 0;
    flex: 0 0 25%
  }

  .col-md-4 {
    max-width: 33.3333%;
    -moz-box-flex: 0;
    flex: 0 0 33.3333%
  }

  .col-md-5 {
    max-width: 41.6667%;
    -moz-box-flex: 0;
    flex: 0 0 41.6667%
  }

  .col-md-6 {
    max-width: 50%;
    -moz-box-flex: 0;
    flex: 0 0 50%
  }

  .col-md-7 {
    max-width: 58.3333%;
    -moz-box-flex: 0;
    flex: 0 0 58.3333%
  }

  .col-md-8 {
    max-width: 66.6667%;
    -moz-box-flex: 0;
    flex: 0 0 66.6667%
  }

  .col-md-9 {
    max-width: 75%;
    -moz-box-flex: 0;
    flex: 0 0 75%
  }

  .col-md-10 {
    max-width: 83.3333%;
    -moz-box-flex: 0;
    flex: 0 0 83.3333%
  }

  .col-md-11 {
    max-width: 91.6667%;
    -moz-box-flex: 0;
    flex: 0 0 91.6667%
  }

  .col-md-12 {
    max-width: 100%;
    -moz-box-flex: 0;
    flex: 0 0 100%
  }
}

@media(min-width: 992px) {
  .col-lg-1 {
    max-width: 8.3333%;
    -moz-box-flex: 0;
    flex: 0 0 8.3333%
  }

  .col-lg-2 {
    max-width: 16.6667%;
    -moz-box-flex: 0;
    flex: 0 0 16.6667%
  }

  .col-lg-3 {
    max-width: 25%;
    -moz-box-flex: 0;
    flex: 0 0 25%
  }

  .col-lg-4 {
    max-width: 33.3333%;
    -moz-box-flex: 0;
    flex: 0 0 33.3333%
  }

  .col-lg-5 {
    max-width: 41.6667%;
    -moz-box-flex: 0;
    flex: 0 0 41.6667%
  }

  .col-lg-6 {
    max-width: 50%;
    -moz-box-flex: 0;
    flex: 0 0 50%
  }

  .col-lg-7 {
    max-width: 58.3333%;
    -moz-box-flex: 0;
    flex: 0 0 58.3333%
  }

  .col-lg-8 {
    max-width: 66.6667%;
    -moz-box-flex: 0;
    flex: 0 0 66.6667%
  }

  .col-lg-9 {
    max-width: 75%;
    -moz-box-flex: 0;
    flex: 0 0 75%
  }

  .col-lg-10 {
    max-width: 83.3333%;
    -moz-box-flex: 0;
    flex: 0 0 83.3333%
  }

  .col-lg-11 {
    max-width: 91.6667%;
    -moz-box-flex: 0;
    flex: 0 0 91.6667%
  }

  .col-lg-12 {
    max-width: 100%;
    -moz-box-flex: 0;
    flex: 0 0 100%
  }
}

@media(min-width: 1200px) {
  .col-xl-1 {
    max-width: 8.3333%;
    -moz-box-flex: 0;
    flex: 0 0 8.3333%
  }

  .col-xl-2 {
    max-width: 16.6667%;
    -moz-box-flex: 0;
    flex: 0 0 16.6667%
  }

  .col-xl-3 {
    max-width: 25%;
    -moz-box-flex: 0;
    flex: 0 0 25%
  }

  .col-xl-4 {
    max-width: 33.3333%;
    -moz-box-flex: 0;
    flex: 0 0 33.3333%
  }

  .col-xl-5 {
    max-width: 41.6667%;
    -moz-box-flex: 0;
    flex: 0 0 41.6667%
  }

  .col-xl-6 {
    max-width: 50%;
    -moz-box-flex: 0;
    flex: 0 0 50%
  }

  .col-xl-7 {
    max-width: 58.3333%;
    -moz-box-flex: 0;
    flex: 0 0 58.3333%
  }

  .col-xl-8 {
    max-width: 66.6667%;
    -moz-box-flex: 0;
    flex: 0 0 66.6667%
  }

  .col-xl-9 {
    max-width: 75%;
    -moz-box-flex: 0;
    flex: 0 0 75%
  }

  .col-xl-10 {
    max-width: 83.3333%;
    -moz-box-flex: 0;
    flex: 0 0 83.3333%
  }

  .col-xl-11 {
    max-width: 91.6667%;
    -moz-box-flex: 0;
    flex: 0 0 91.6667%
  }

  .col-xl-12 {
    max-width: 100%;
    -moz-box-flex: 0;
    flex: 0 0 100%
  }
}

@media(min-width: 1600px) {
  .col-xxl-10 {
    -moz-box-flex: 0;
    flex: 0 0 83.3333%;
    max-width: 83.3333%
  }

  .col-xxl-3 {
    -moz-box-flex: 0;
    flex: 0 0 25%;
    max-width: 25%
  }

  .col-xxl-2 {
    -moz-box-flex: 0;
    flex: 0 0 16.6666%;
    max-width: 16.6666%
  }
}

@media(min-width: 1200px) {
  .col-xl-5col {
    flex: 0 0 20%;
    max-width: 20%
  }

  .col-xl-5col,
  .col-xl-5col2 {
    position: relative;
    -moz-box-flex: 0;
    padding-right: 10px;
    padding-left: 10px
  }

  .col-xl-5col2 {
    flex: 0 0 40%;
    max-width: 40%
  }

  .col-xl-5col4 {
    position: relative;
    -moz-box-flex: 0;
    flex: 0 0 80%;
    max-width: 80%;
    padding-right: 10px;
    padding-left: 10px
  }
}

.order-first {
  -moz-box-ordinal-group: 0;
  order: -1
}

.order-last {
  -moz-box-ordinal-group: 10;
  order: 9
}

@media(min-width: 576px) {
  .order-sm-auto {
    -moz-box-ordinal-group: 1;
    order: 0
  }

  .order-sm-first {
    -moz-box-ordinal-group: 0;
    order: -1
  }

  .order-sm-last {
    -moz-box-ordinal-group: 10;
    order: 9
  }
}

@media(min-width: 768px) {
  .order-md-auto {
    -moz-box-ordinal-group: 1;
    order: 0
  }

  .order-md-first {
    -moz-box-ordinal-group: 0;
    order: -1
  }

  .order-md-last {
    -moz-box-ordinal-group: 10;
    order: 9
  }
}

@media(min-width: 992px) {
  .order-lg-auto {
    -moz-box-ordinal-group: 1;
    order: 0
  }

  .order-lg-first {
    -moz-box-ordinal-group: 0;
    order: -1
  }

  .order-lg-last {
    -moz-box-ordinal-group: 10;
    order: 9
  }
}

.grid {
  margin: -1rem
}

.grid .banner,
.grid .banner img,
.grid .category,
.grid .category>a,
.grid .category img,
.grid figure {
  height: 100%
}

.grid img {
  object-fit: cover
}

.grid .grid-item {
  padding: 1rem;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0)
}

.grid.gutter-sm {
  margin: -.5rem
}

.grid.gutter-sm .grid-item {
  padding: .5rem
}

.grid.gutter-no {
  margin: 0
}

.grid.gutter-no .grid-item {
  padding: 0
}

.grid:after {
  display: block;
  clear: both
}

.grid.grid-float {
  display: block !important
}

.grid.grid-float .grid-item {
  float: left
}

.split-line>* {
  margin-bottom: 3rem;
  padding-bottom: 3rem;
  border-bottom: 1px solid #ebebeb
}

.split-line>:after {
  content: "";
  display: block;
  width: 1px;
  height: -moz-calc(100% - 3rem);
  height: calc(100% - 3rem);
  position: absolute;
  right: 0;
  top: 0;
  background-color: #ebebeb
}

.split-line.cols-1>:nth-child(1n):after,
.split-line.cols-2>:nth-child(2n):after,
.split-line.cols-3>:nth-child(3n):after,
.split-line.cols-4>:nth-child(4n):after,
.split-line.cols-5>:nth-child(5n):after,
.split-line.cols-6>:nth-child(6n):after,
.split-line.cols-7>:nth-child(7n):after,
.split-line.cols-8>:nth-child(8n):after,
.split-line>.col-1:nth-child(12n):after,
.split-line>.col-2:nth-child(6n):after,
.split-line>.col-3:nth-child(4n):after,
.split-line>.col-4:nth-child(3n):after,
.split-line>.col-5:nth-child(2n):after,
.split-line>.col-6:nth-child(2n):after,
.split-line>.col-7:nth-child(1n):after,
.split-line>.col-8:nth-child(1n):after,
.split-line>.col-9:nth-child(1n):after,
.split-line>.col-10:nth-child(1n):after,
.split-line>.col-11:nth-child(1n):after {
  content: none
}

@media(min-width: 576px) {

  .split-line.cols-sm-1>:nth-child(n):after,
  .split-line.cols-sm-2>:nth-child(n):after,
  .split-line.cols-sm-3>:nth-child(n):after,
  .split-line.cols-sm-4>:nth-child(n):after,
  .split-line.cols-sm-5>:nth-child(n):after,
  .split-line.cols-sm-6>:nth-child(n):after,
  .split-line.cols-sm-7>:nth-child(n):after,
  .split-line.cols-sm-8>:nth-child(n):after,
  .split-line>.col-sm-1:nth-child(n):after,
  .split-line>.col-sm-2:nth-child(n):after,
  .split-line>.col-sm-3:nth-child(n):after,
  .split-line>.col-sm-4:nth-child(n):after,
  .split-line>.col-sm-5:nth-child(n):after,
  .split-line>.col-sm-6:nth-child(n):after,
  .split-line>.col-sm-7:nth-child(n):after,
  .split-line>.col-sm-8:nth-child(n):after,
  .split-line>.col-sm-9:nth-child(n):after,
  .split-line>.col-sm-10:nth-child(n):after,
  .split-line>.col-sm-11:nth-child(n):after {
    content: ""
  }

  .split-line.cols-sm-1>:nth-child(1n):after,
  .split-line.cols-sm-2>:nth-child(2n):after,
  .split-line.cols-sm-3>:nth-child(3n):after,
  .split-line.cols-sm-4>:nth-child(4n):after,
  .split-line.cols-sm-5>:nth-child(5n):after,
  .split-line.cols-sm-6>:nth-child(6n):after,
  .split-line.cols-sm-7>:nth-child(7n):after,
  .split-line.cols-sm-8>:nth-child(8n):after,
  .split-line>.col-sm-1:nth-child(12n):after,
  .split-line>.col-sm-2:nth-child(6n):after,
  .split-line>.col-sm-3:nth-child(4n):after,
  .split-line>.col-sm-4:nth-child(3n):after,
  .split-line>.col-sm-5:nth-child(2n):after,
  .split-line>.col-sm-6:nth-child(2n):after,
  .split-line>.col-sm-7:nth-child(1n):after,
  .split-line>.col-sm-8:nth-child(1n):after,
  .split-line>.col-sm-9:nth-child(1n):after,
  .split-line>.col-sm-10:nth-child(1n):after,
  .split-line>.col-sm-11:nth-child(1n):after {
    content: none
  }
}

@media(min-width: 768px) {

  .split-line.cols-md-1>:nth-child(n):after,
  .split-line.cols-md-2>:nth-child(n):after,
  .split-line.cols-md-3>:nth-child(n):after,
  .split-line.cols-md-4>:nth-child(n):after,
  .split-line.cols-md-5>:nth-child(n):after,
  .split-line.cols-md-6>:nth-child(n):after,
  .split-line.cols-md-7>:nth-child(n):after,
  .split-line.cols-md-8>:nth-child(n):after,
  .split-line>.col-md-1:nth-child(n):after,
  .split-line>.col-md-2:nth-child(n):after,
  .split-line>.col-md-3:nth-child(n):after,
  .split-line>.col-md-4:nth-child(n):after,
  .split-line>.col-md-5:nth-child(n):after,
  .split-line>.col-md-6:nth-child(n):after,
  .split-line>.col-md-7:nth-child(n):after,
  .split-line>.col-md-8:nth-child(n):after,
  .split-line>.col-md-9:nth-child(n):after,
  .split-line>.col-md-10:nth-child(n):after,
  .split-line>.col-md-11:nth-child(n):after {
    content: ""
  }

  .split-line.cols-md-1>:nth-child(1n):after,
  .split-line.cols-md-2>:nth-child(2n):after,
  .split-line.cols-md-3>:nth-child(3n):after,
  .split-line.cols-md-4>:nth-child(4n):after,
  .split-line.cols-md-5>:nth-child(5n):after,
  .split-line.cols-md-6>:nth-child(6n):after,
  .split-line.cols-md-7>:nth-child(7n):after,
  .split-line.cols-md-8>:nth-child(8n):after,
  .split-line>.col-md-1:nth-child(12n):after,
  .split-line>.col-md-2:nth-child(6n):after,
  .split-line>.col-md-3:nth-child(4n):after,
  .split-line>.col-md-4:nth-child(3n):after,
  .split-line>.col-md-5:nth-child(2n):after,
  .split-line>.col-md-6:nth-child(2n):after,
  .split-line>.col-md-7:nth-child(1n):after,
  .split-line>.col-md-8:nth-child(1n):after,
  .split-line>.col-md-9:nth-child(1n):after,
  .split-line>.col-md-10:nth-child(1n):after,
  .split-line>.col-md-11:nth-child(1n):after {
    content: none
  }
}

@media(min-width: 992px) {

  .split-line.cols-lg-1>:nth-child(n):after,
  .split-line.cols-lg-2>:nth-child(n):after,
  .split-line.cols-lg-3>:nth-child(n):after,
  .split-line.cols-lg-4>:nth-child(n):after,
  .split-line.cols-lg-5>:nth-child(n):after,
  .split-line.cols-lg-6>:nth-child(n):after,
  .split-line.cols-lg-7>:nth-child(n):after,
  .split-line.cols-lg-8>:nth-child(n):after,
  .split-line>.col-lg-1:nth-child(n):after,
  .split-line>.col-lg-2:nth-child(n):after,
  .split-line>.col-lg-3:nth-child(n):after,
  .split-line>.col-lg-4:nth-child(n):after,
  .split-line>.col-lg-5:nth-child(n):after,
  .split-line>.col-lg-6:nth-child(n):after,
  .split-line>.col-lg-7:nth-child(n):after,
  .split-line>.col-lg-8:nth-child(n):after,
  .split-line>.col-lg-9:nth-child(n):after,
  .split-line>.col-lg-10:nth-child(n):after,
  .split-line>.col-lg-11:nth-child(n):after {
    content: ""
  }

  .split-line.cols-lg-1>:nth-child(1n):after,
  .split-line.cols-lg-2>:nth-child(2n):after,
  .split-line.cols-lg-3>:nth-child(3n):after,
  .split-line.cols-lg-4>:nth-child(4n):after,
  .split-line.cols-lg-5>:nth-child(5n):after,
  .split-line.cols-lg-6>:nth-child(6n):after,
  .split-line.cols-lg-7>:nth-child(7n):after,
  .split-line.cols-lg-8>:nth-child(8n):after,
  .split-line>.col-lg-1:nth-child(12n):after,
  .split-line>.col-lg-2:nth-child(6n):after,
  .split-line>.col-lg-3:nth-child(4n):after,
  .split-line>.col-lg-4:nth-child(3n):after,
  .split-line>.col-lg-5:nth-child(2n):after,
  .split-line>.col-lg-6:nth-child(2n):after,
  .split-line>.col-lg-7:nth-child(1n):after,
  .split-line>.col-lg-8:nth-child(1n):after,
  .split-line>.col-lg-9:nth-child(1n):after,
  .split-line>.col-lg-10:nth-child(1n):after,
  .split-line>.col-lg-11:nth-child(1n):after {
    content: none
  }
}

@media(min-width: 1200px) {

  .split-line.cols-xl-1>:nth-child(n):after,
  .split-line.cols-xl-2>:nth-child(n):after,
  .split-line.cols-xl-3>:nth-child(n):after,
  .split-line.cols-xl-4>:nth-child(n):after,
  .split-line.cols-xl-5>:nth-child(n):after,
  .split-line.cols-xl-6>:nth-child(n):after,
  .split-line.cols-xl-7>:nth-child(n):after,
  .split-line.cols-xl-8>:nth-child(n):after,
  .split-line>.col-xl-1:nth-child(n):after,
  .split-line>.col-xl-2:nth-child(n):after,
  .split-line>.col-xl-3:nth-child(n):after,
  .split-line>.col-xl-4:nth-child(n):after,
  .split-line>.col-xl-5:nth-child(n):after,
  .split-line>.col-xl-5col:nth-child(n):after,
  .split-line>.col-xl-6:nth-child(n):after,
  .split-line>.col-xl-7:nth-child(n):after,
  .split-line>.col-xl-7col:nth-child(n):after,
  .split-line>.col-xl-8:nth-child(n):after,
  .split-line>.col-xl-8col:nth-child(n):after,
  .split-line>.col-xl-9:nth-child(n):after,
  .split-line>.col-xl-10:nth-child(n):after,
  .split-line>.col-xl-11:nth-child(n):after {
    content: ""
  }

  .split-line.cols-xl-1>:nth-child(1n):after,
  .split-line.cols-xl-2>:nth-child(2n):after,
  .split-line.cols-xl-3>:nth-child(3n):after,
  .split-line.cols-xl-4>:nth-child(4n):after,
  .split-line.cols-xl-5>:nth-child(5n):after,
  .split-line.cols-xl-6>:nth-child(6n):after,
  .split-line.cols-xl-7>:nth-child(7n):after,
  .split-line.cols-xl-8>:nth-child(8n):after,
  .split-line>.col-xl-1:nth-child(12n):after,
  .split-line>.col-xl-2:nth-child(6n):after,
  .split-line>.col-xl-3:nth-child(4n):after,
  .split-line>.col-xl-4:nth-child(3n):after,
  .split-line>.col-xl-5:nth-child(2n):after,
  .split-line>.col-xl-5col:nth-child(5n):after,
  .split-line>.col-xl-6:nth-child(2n):after,
  .split-line>.col-xl-7:nth-child(1n):after,
  .split-line>.col-xl-7col:nth-child(7n):after,
  .split-line>.col-xl-8:nth-child(1n):after,
  .split-line>.col-xl-8col:nth-child(8n):after,
  .split-line>.col-xl-9:nth-child(1n):after,
  .split-line>.col-xl-10:nth-child(1n):after,
  .split-line>.col-xl-11:nth-child(1n):after {
    content: none
  }
}

.mt-0 {
  margin-top: 0 !important
}

.mb-0 {
  margin-bottom: 0 !important
}

.pt-0 {
  padding-top: 0 !important
}

.pb-0 {
  padding-bottom: 0 !important
}

.mt-1 {
  margin-top: .5rem !important
}

.mb-1 {
  margin-bottom: .5rem !important
}

.pt-1 {
  padding-top: .5rem !important
}

.pb-1 {
  padding-bottom: .5rem !important
}

.mt-2 {
  margin-top: 1rem !important
}

.mb-2 {
  margin-bottom: 1rem !important
}

.pt-2 {
  padding-top: 1rem !important
}

.pb-2 {
  padding-bottom: 1rem !important
}

.mt-3 {
  margin-top: 1.5rem !important
}

.mb-3 {
  margin-bottom: 1.5rem !important
}

.pt-3 {
  padding-top: 1.5rem !important
}

.pb-3 {
  padding-bottom: 1.5rem !important
}

.mt-4 {
  margin-top: 2rem !important
}

.mb-4 {
  margin-bottom: 2rem !important
}

.pt-4 {
  padding-top: 2rem !important
}

.pb-4 {
  padding-bottom: 2rem !important
}

.mt-5 {
  margin-top: 2.5rem !important
}

.mb-5 {
  margin-bottom: 2.5rem !important
}

.pt-5 {
  padding-top: 2.5rem !important
}

.pb-5 {
  padding-bottom: 2.5rem !important
}

.mt-6 {
  margin-top: 3rem !important
}

.mb-6 {
  margin-bottom: 3rem !important
}

.pt-6 {
  padding-top: 3rem !important
}

.pb-6 {
  padding-bottom: 3rem !important
}

.mt-7 {
  margin-top: 3.5rem !important
}

.mb-7 {
  margin-bottom: 3.5rem !important
}

.pt-7 {
  padding-top: 3.5rem !important
}

.pb-7 {
  padding-bottom: 3.5rem !important
}

.mt-8 {
  margin-top: 4rem !important
}

.mb-8 {
  margin-bottom: 4rem !important
}

.pt-8 {
  padding-top: 4rem !important
}

.pb-8 {
  padding-bottom: 4rem !important
}

.mt-9 {
  margin-top: 4.5rem !important
}

.mb-9 {
  margin-bottom: 4.5rem !important
}

.pt-9 {
  padding-top: 4.5rem !important
}

.pb-9 {
  padding-bottom: 4.5rem !important
}

.mt-10 {
  margin-top: 5rem !important
}

.mb-10 {
  margin-bottom: 5rem !important
}

.pt-10 {
  padding-top: 5rem !important
}

.pb-10 {
  padding-bottom: 5rem !important
}

.ml-0 {
  margin-left: 0 !important
}

.mr-0 {
  margin-right: 0 !important
}

.pl-0 {
  padding-left: 0 !important
}

.pr-0 {
  padding-right: 0 !important
}

.ml-1 {
  margin-left: .5rem !important
}

.mr-1 {
  margin-right: .5rem !important
}

.pl-1 {
  padding-left: .5rem !important
}

.pr-1 {
  padding-right: .5rem !important
}

.ml-2 {
  margin-left: 1rem !important
}

.mr-2 {
  margin-right: 1rem !important
}

.pl-2 {
  padding-left: 1rem !important
}

.pr-2 {
  padding-right: 1rem !important
}

.ml-3 {
  margin-left: 1.5rem !important
}

.mr-3 {
  margin-right: 1.5rem !important
}

.pl-3 {
  padding-left: 1.5rem !important
}

.pr-3 {
  padding-right: 1.5rem !important
}

.ml-4 {
  margin-left: 2rem !important
}

.mr-4 {
  margin-right: 2rem !important
}

.pl-4 {
  padding-left: 2rem !important
}

.pr-4 {
  padding-right: 2rem !important
}

.ml-5 {
  margin-left: 2.5rem !important
}

.mr-5 {
  margin-right: 2.5rem !important
}

.pl-5 {
  padding-left: 2.5rem !important
}

.pr-5 {
  padding-right: 2.5rem !important
}

.ml-6 {
  margin-left: 3rem !important
}

.mr-6 {
  margin-right: 3rem !important
}

.pl-6 {
  padding-left: 3rem !important
}

.pr-6 {
  padding-right: 3rem !important
}

.ml-7 {
  margin-left: 3.5rem !important
}

.mr-7 {
  margin-right: 3.5rem !important
}

.pl-7 {
  padding-left: 3.5rem !important
}

.pr-7 {
  padding-right: 3.5rem !important
}

.ml-8 {
  margin-left: 4rem !important
}

.mr-8 {
  margin-right: 4rem !important
}

.pl-8 {
  padding-left: 4rem !important
}

.pr-8 {
  padding-right: 4rem !important
}

.ml-9 {
  margin-left: 4.5rem !important
}

.mr-9 {
  margin-right: 4.5rem !important
}

.pl-9 {
  padding-left: 4.5rem !important
}

.pr-9 {
  padding-right: 4.5rem !important
}

.ml-10 {
  margin-left: 5rem !important
}

.mr-10 {
  margin-right: 5rem !important
}

.pl-10 {
  padding-left: 5rem !important
}

.pr-10 {
  padding-right: 5rem !important
}

.p-0 {
  padding: 0 !important
}

.m-0 {
  margin: 0 !important
}

.ml-auto {
  margin-left: auto !important
}

.mr-auto,
.mx-auto {
  margin-right: auto !important
}

.mx-auto {
  margin-left: auto !important
}

@media(min-width: 576px) {
  .mt-sm-0 {
    margin-top: 0 !important
  }

  .mt-sm-1 {
    margin-top: .5rem !important
  }

  .mt-sm-2 {
    margin-top: 1rem !important
  }

  .mt-sm-3 {
    margin-top: 1.5rem !important
  }

  .mt-sm-4 {
    margin-top: 2rem !important
  }

  .mt-sm-5 {
    margin-top: 2.5rem !important
  }

  .mt-sm-6 {
    margin-top: 3rem !important
  }

  .mt-sm-7 {
    margin-top: 3.5rem !important
  }

  .mt-sm-8 {
    margin-top: 4rem !important
  }

  .mt-sm-9 {
    margin-top: 4.5rem !important
  }

  .mt-sm-10 {
    margin-top: 5rem !important
  }

  .mb-sm-0 {
    margin-bottom: 0 !important
  }

  .mb-sm-1 {
    margin-bottom: .5rem !important
  }

  .mb-sm-2 {
    margin-bottom: 1rem !important
  }

  .mb-sm-3 {
    margin-bottom: 1.5rem !important
  }

  .mb-sm-4 {
    margin-bottom: 2rem !important
  }

  .mb-sm-5 {
    margin-bottom: 2.5rem !important
  }

  .mb-sm-6 {
    margin-bottom: 3rem !important
  }

  .mb-sm-7 {
    margin-bottom: 3.5rem !important
  }

  .mb-sm-8 {
    margin-bottom: 4rem !important
  }

  .mb-sm-9 {
    margin-bottom: 4.5rem !important
  }

  .mb-sm-10 {
    margin-bottom: 5rem !important
  }

  .mr-sm-0 {
    margin-right: 0 !important
  }

  .mr-sm-1 {
    margin-right: .5rem !important
  }

  .mr-sm-2 {
    margin-right: 1rem !important
  }

  .mr-sm-3 {
    margin-right: 1.5rem !important
  }

  .mr-sm-4 {
    margin-right: 2rem !important
  }

  .mr-sm-5 {
    margin-right: 2.5rem !important
  }

  .mr-sm-6 {
    margin-right: 3rem !important
  }

  .mr-sm-7 {
    margin-right: 3.5rem !important
  }

  .mr-sm-8 {
    margin-right: 4rem !important
  }

  .mr-sm-9 {
    margin-right: 4.5rem !important
  }

  .mr-sm-10 {
    margin-right: 5rem !important
  }

  .ml-sm-0 {
    margin-left: 0 !important
  }

  .ml-sm-1 {
    margin-left: .5rem !important
  }

  .ml-sm-2 {
    margin-left: 1rem !important
  }

  .ml-sm-3 {
    margin-left: 1.5rem !important
  }

  .ml-sm-4 {
    margin-left: 2rem !important
  }

  .ml-sm-5 {
    margin-left: 2.5rem !important
  }

  .ml-sm-6 {
    margin-left: 3rem !important
  }

  .ml-sm-7 {
    margin-left: 3.5rem !important
  }

  .ml-sm-8 {
    margin-left: 4rem !important
  }

  .ml-sm-9 {
    margin-left: 4.5rem !important
  }

  .ml-sm-10 {
    margin-left: 5rem !important
  }

  .pt-sm-0 {
    padding-top: 0 !important
  }

  .pt-sm-1 {
    padding-top: .5rem !important
  }

  .pt-sm-2 {
    padding-top: 1rem !important
  }

  .pt-sm-3 {
    padding-top: 1.5rem !important
  }

  .pt-sm-4 {
    padding-top: 2rem !important
  }

  .pt-sm-5 {
    padding-top: 2.5rem !important
  }

  .pt-sm-6 {
    padding-top: 3rem !important
  }

  .pt-sm-7 {
    padding-top: 3.5rem !important
  }

  .pt-sm-8 {
    padding-top: 4rem !important
  }

  .pt-sm-9 {
    padding-top: 4.5rem !important
  }

  .pt-sm-10 {
    padding-top: 5rem !important
  }

  .pr-sm-0 {
    padding-right: 0 !important
  }

  .pr-sm-1 {
    padding-right: .5rem !important
  }

  .pr-sm-2 {
    padding-right: 1rem !important
  }

  .pr-sm-3 {
    padding-right: 1.5rem !important
  }

  .pr-sm-4 {
    padding-right: 2rem !important
  }

  .pr-sm-5 {
    padding-right: 2.5rem !important
  }

  .pr-sm-6 {
    padding-right: 3rem !important
  }

  .pr-sm-7 {
    padding-right: 3.5rem !important
  }

  .pr-sm-8 {
    padding-right: 4rem !important
  }

  .pr-sm-9 {
    padding-right: 4.5rem !important
  }

  .pr-sm-10 {
    padding-right: 5rem !important
  }

  .pl-sm-0 {
    padding-left: 0 !important
  }

  .pl-sm-1 {
    padding-left: .5rem !important
  }

  .pl-sm-2 {
    padding-left: 1rem !important
  }

  .pl-sm-3 {
    padding-left: 1.5rem !important
  }

  .pl-sm-4 {
    padding-left: 2rem !important
  }

  .pl-sm-5 {
    padding-left: 2.5rem !important
  }

  .pl-sm-6 {
    padding-left: 3rem !important
  }

  .pl-sm-7 {
    padding-left: 3.5rem !important
  }

  .pl-sm-8 {
    padding-left: 4rem !important
  }

  .pl-sm-9 {
    padding-left: 4.5rem !important
  }

  .pl-sm-10 {
    padding-left: 5rem !important
  }

  .pb-sm-0 {
    padding-bottom: 0 !important
  }

  .pb-sm-1 {
    padding-bottom: .5rem !important
  }

  .pb-sm-2 {
    padding-bottom: 1rem !important
  }

  .pb-sm-3 {
    padding-bottom: 1.5rem !important
  }

  .pb-sm-4 {
    padding-bottom: 2rem !important
  }

  .pb-sm-5 {
    padding-bottom: 2.5rem !important
  }

  .pb-sm-6 {
    padding-bottom: 3rem !important
  }

  .pb-sm-7 {
    padding-bottom: 3.5rem !important
  }

  .pb-sm-8 {
    padding-bottom: 4rem !important
  }

  .pb-sm-9 {
    padding-bottom: 4.5rem !important
  }

  .pb-sm-10 {
    padding-bottom: 5rem !important
  }
}

@media(min-width: 768px) {
  .mt-md-0 {
    margin-top: 0 !important
  }

  .mt-md-1 {
    margin-top: .5rem !important
  }

  .mt-md-2 {
    margin-top: 1rem !important
  }

  .mt-md-3 {
    margin-top: 1.5rem !important
  }

  .mt-md-4 {
    margin-top: 2rem !important
  }

  .mt-md-5 {
    margin-top: 2.5rem !important
  }

  .mt-md-6 {
    margin-top: 3rem !important
  }

  .mt-md-7 {
    margin-top: 3.5rem !important
  }

  .mt-md-8 {
    margin-top: 4rem !important
  }

  .mt-md-9 {
    margin-top: 4.5rem !important
  }

  .mt-md-10 {
    margin-top: 5rem !important
  }

  .mb-md-0 {
    margin-bottom: 0 !important
  }

  .mb-md-1 {
    margin-bottom: .5rem !important
  }

  .mb-md-2 {
    margin-bottom: 1rem !important
  }

  .mb-md-3 {
    margin-bottom: 1.5rem !important
  }

  .mb-md-4 {
    margin-bottom: 2rem !important
  }

  .mb-md-5 {
    margin-bottom: 2.5rem !important
  }

  .mb-md-6 {
    margin-bottom: 3rem !important
  }

  .mb-md-7 {
    margin-bottom: 3.5rem !important
  }

  .mb-md-8 {
    margin-bottom: 4rem !important
  }

  .mb-md-9 {
    margin-bottom: 4.5rem !important
  }

  .mb-md-10 {
    margin-bottom: 5rem !important
  }

  .pt-md-0 {
    padding-top: 0 !important
  }

  .pt-md-1 {
    padding-top: .5rem !important
  }

  .pt-md-2 {
    padding-top: 1rem !important
  }

  .pt-md-3 {
    padding-top: 1.5rem !important
  }

  .pt-md-4 {
    padding-top: 2rem !important
  }

  .pt-md-5 {
    padding-top: 2.5rem !important
  }

  .pt-md-6 {
    padding-top: 3rem !important
  }

  .pt-md-7 {
    padding-top: 3.5rem !important
  }

  .pt-md-8 {
    padding-top: 4rem !important
  }

  .pt-md-9 {
    padding-top: 4.5rem !important
  }

  .pt-md-10 {
    padding-top: 5rem !important
  }

  .pb-md-0 {
    padding-bottom: 0 !important
  }

  .pb-md-1 {
    padding-bottom: .5rem !important
  }

  .pb-md-2 {
    padding-bottom: 1rem !important
  }

  .pb-md-3 {
    padding-bottom: 1.5rem !important
  }

  .pb-md-4 {
    padding-bottom: 2rem !important
  }

  .pb-md-5 {
    padding-bottom: 2.5rem !important
  }

  .pb-md-6 {
    padding-bottom: 3rem !important
  }

  .pb-md-7 {
    padding-bottom: 3.5rem !important
  }

  .pb-md-8 {
    padding-bottom: 4rem !important
  }

  .pb-md-9 {
    padding-bottom: 4.5rem !important
  }

  .pb-md-10 {
    padding-bottom: 5rem !important
  }
}

@media(min-width: 992px) {
  .mt-lg-0 {
    margin-top: 0 !important
  }

  .mt-lg-1 {
    margin-top: .5rem !important
  }

  .mt-lg-2 {
    margin-top: 1rem !important
  }

  .mt-lg-3 {
    margin-top: 1.5rem !important
  }

  .mt-lg-4 {
    margin-top: 2rem !important
  }

  .mt-lg-5 {
    margin-top: 2.5rem !important
  }

  .mt-lg-6 {
    margin-top: 3rem !important
  }

  .mt-lg-7 {
    margin-top: 3.5rem !important
  }

  .mt-lg-8 {
    margin-top: 4rem !important
  }

  .mt-lg-9 {
    margin-top: 4.5rem !important
  }

  .mt-lg-10 {
    margin-top: 5rem !important
  }

  .mb-lg-0 {
    margin-bottom: 0 !important
  }

  .mb-lg-1 {
    margin-bottom: .5rem !important
  }

  .mb-lg-2 {
    margin-bottom: 1rem !important
  }

  .mb-lg-3 {
    margin-bottom: 1.5rem !important
  }

  .mb-lg-4 {
    margin-bottom: 2rem !important
  }

  .mb-lg-5 {
    margin-bottom: 2.5rem !important
  }

  .mb-lg-6 {
    margin-bottom: 3rem !important
  }

  .mb-lg-7 {
    margin-bottom: 3.5rem !important
  }

  .mb-lg-8 {
    margin-bottom: 4rem !important
  }

  .mb-lg-9 {
    margin-bottom: 4.5rem !important
  }

  .mb-lg-10 {
    margin-bottom: 5rem !important
  }

  .pt-lg-0 {
    padding-top: 0 !important
  }

  .pt-lg-1 {
    padding-top: .5rem !important
  }

  .pt-lg-2 {
    padding-top: 1rem !important
  }

  .pt-lg-3 {
    padding-top: 1.5rem !important
  }

  .pt-lg-4 {
    padding-top: 2rem !important
  }

  .pt-lg-5 {
    padding-top: 2.5rem !important
  }

  .pt-lg-6 {
    padding-top: 3rem !important
  }

  .pt-lg-7 {
    padding-top: 3.5rem !important
  }

  .pt-lg-8 {
    padding-top: 4rem !important
  }

  .pt-lg-9 {
    padding-top: 4.5rem !important
  }

  .pt-lg-10 {
    padding-top: 5rem !important
  }

  .pb-lg-0 {
    padding-bottom: 0 !important
  }

  .pb-lg-1 {
    padding-bottom: .5rem !important
  }

  .pb-lg-2 {
    padding-bottom: 1rem !important
  }

  .pb-lg-3 {
    padding-bottom: 1.5rem !important
  }

  .pb-lg-4 {
    padding-bottom: 2rem !important
  }

  .pb-lg-5 {
    padding-bottom: 2.5rem !important
  }

  .pb-lg-6 {
    padding-bottom: 3rem !important
  }

  .pb-lg-7 {
    padding-bottom: 3.5rem !important
  }

  .pb-lg-8 {
    padding-bottom: 4rem !important
  }

  .pb-lg-9 {
    padding-bottom: 4.5rem !important
  }

  .pb-lg-10 {
    padding-bottom: 5rem !important
  }

  .ml-lg-0 {
    margin-left: 0 !important
  }

  .mr-lg-0 {
    margin-right: 0 !important
  }

  .pl-lg-0 {
    padding-left: 0 !important
  }

  .pr-lg-0 {
    padding-right: 0 !important
  }

  .ml-lg-1 {
    margin-left: .5rem !important
  }

  .mr-lg-1 {
    margin-right: .5rem !important
  }

  .pl-lg-1 {
    padding-left: .5rem !important
  }

  .pr-lg-1 {
    padding-right: .5rem !important
  }

  .ml-lg-2 {
    margin-left: 1rem !important
  }

  .mr-lg-2 {
    margin-right: 1rem !important
  }

  .pl-lg-2 {
    padding-left: 1rem !important
  }

  .pr-lg-2 {
    padding-right: 1rem !important
  }

  .ml-lg-3 {
    margin-left: 1.5rem !important
  }

  .mr-lg-3 {
    margin-right: 1.5rem !important
  }

  .pl-lg-3 {
    padding-left: 1.5rem !important
  }

  .pr-lg-3 {
    padding-right: 1.5rem !important
  }

  .ml-lg-4 {
    margin-left: 2rem !important
  }

  .mr-lg-4 {
    margin-right: 2rem !important
  }

  .pl-lg-4 {
    padding-left: 2rem !important
  }

  .pr-lg-4 {
    padding-right: 2rem !important
  }

  .ml-lg-5 {
    margin-left: 2.5rem !important
  }

  .mr-lg-5 {
    margin-right: 2.5rem !important
  }

  .pl-lg-5 {
    padding-left: 2.5rem !important
  }

  .pr-lg-5 {
    padding-right: 2.5rem !important
  }

  .ml-lg-6 {
    margin-left: 3rem !important
  }

  .mr-lg-6 {
    margin-right: 3rem !important
  }

  .pl-lg-6 {
    padding-left: 3rem !important
  }

  .pr-lg-6 {
    padding-right: 3rem !important
  }

  .ml-lg-7 {
    margin-left: 3.5rem !important
  }

  .mr-lg-7 {
    margin-right: 3.5rem !important
  }

  .pl-lg-7 {
    padding-left: 3.5rem !important
  }

  .pr-lg-7 {
    padding-right: 3.5rem !important
  }

  .ml-lg-8 {
    margin-left: 4rem !important
  }

  .mr-lg-8 {
    margin-right: 4rem !important
  }

  .pl-lg-8 {
    padding-left: 4rem !important
  }

  .pr-lg-8 {
    padding-right: 4rem !important
  }

  .ml-lg-9 {
    margin-left: 4.5rem !important
  }

  .mr-lg-9 {
    margin-right: 4.5rem !important
  }

  .pl-lg-9 {
    padding-left: 4.5rem !important
  }

  .pr-lg-9 {
    padding-right: 4.5rem !important
  }

  .ml-lg-10 {
    margin-left: 5rem !important
  }

  .mr-lg-10 {
    margin-right: 5rem !important
  }

  .pl-lg-10 {
    padding-left: 5rem !important
  }

  .pr-lg-10 {
    padding-right: 5rem !important
  }
}

@media(min-width: 1200px) {
  .mt-xl-0 {
    margin-top: 0 !important
  }

  .mt-xl-1 {
    margin-top: .5rem !important
  }

  .mt-xl-2 {
    margin-top: 1rem !important
  }

  .mt-xl-3 {
    margin-top: 1.5rem !important
  }

  .mt-xl-4 {
    margin-top: 2rem !important
  }

  .mt-xl-5 {
    margin-top: 2.5rem !important
  }

  .mt-xl-6 {
    margin-top: 3rem !important
  }

  .mt-xl-7 {
    margin-top: 3.5rem !important
  }

  .mt-xl-8 {
    margin-top: 4rem !important
  }

  .mt-xl-9 {
    margin-top: 4.5rem !important
  }

  .mt-xl-10 {
    margin-top: 5rem !important
  }

  .mb-xl-0 {
    margin-bottom: 0 !important
  }

  .mb-xl-1 {
    margin-bottom: .5rem !important
  }

  .mb-xl-2 {
    margin-bottom: 1rem !important
  }

  .mb-xl-3 {
    margin-bottom: 1.5rem !important
  }

  .mb-xl-4 {
    margin-bottom: 2rem !important
  }

  .mb-xl-5 {
    margin-bottom: 2.5rem !important
  }

  .mb-xl-6 {
    margin-bottom: 3rem !important
  }

  .mb-xl-7 {
    margin-bottom: 3.5rem !important
  }

  .mb-xl-8 {
    margin-bottom: 4rem !important
  }

  .mb-xl-9 {
    margin-bottom: 4.5rem !important
  }

  .mb-xl-10 {
    margin-bottom: 5rem !important
  }

  .ml-xl-0 {
    margin-left: 0 !important
  }

  .ml-xl-1 {
    margin-left: .5rem !important
  }

  .ml-xl-2 {
    margin-left: 1rem !important
  }

  .ml-xl-3 {
    margin-left: 1.5rem !important
  }

  .ml-xl-4 {
    margin-left: 2rem !important
  }

  .ml-xl-5 {
    margin-left: 2.5rem !important
  }

  .ml-xl-6 {
    margin-left: 3rem !important
  }

  .ml-xl-7 {
    margin-left: 3.5rem !important
  }

  .ml-xl-8 {
    margin-left: 4rem !important
  }

  .ml-xl-9 {
    margin-left: 4.5rem !important
  }

  .ml-xl-10 {
    margin-left: 5rem !important
  }

  .mr-xl-0 {
    margin-right: 0 !important
  }

  .mr-xl-1 {
    margin-right: .5rem !important
  }

  .mr-xl-2 {
    margin-right: 1rem !important
  }

  .mr-xl-3 {
    margin-right: 1.5rem !important
  }

  .mr-xl-4 {
    margin-right: 2rem !important
  }

  .mr-xl-5 {
    margin-right: 2.5rem !important
  }

  .mr-xl-6 {
    margin-right: 3rem !important
  }

  .mr-xl-7 {
    margin-right: 3.5rem !important
  }

  .mr-xl-8 {
    margin-right: 4rem !important
  }

  .mr-xl-9 {
    margin-right: 4.5rem !important
  }

  .mr-xl-10 {
    margin-right: 5rem !important
  }
}

@media(min-width: 992px) {
  .ml-lg-auto {
    margin-left: auto !important
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important
  }

  .mx-lg-auto {
    margin-left: auto !important
  }
}

.accordion {
  overflow: hidden
}

.card-header {
  text-transform: capitalize;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1;
  color: #222
}

.card-header a {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  position: relative;
  padding: 1.8rem .5rem
}

.card-header a:hover,
.card-header a:hover:after {
  color: #222
}

.card-header a:after {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  right: .5rem;
  font-family: riode;
  font-size: 1.8rem;
  font-weight: 400;
  letter-spacing: -.01em;
  color: #222
}

.card-header .expanded,
.card-header .expanded:after {
  color: #222
}

.card-body {
  padding: 1.3rem .5rem
}

.card-wrapper {
  overflow: hidden
}

.card-wrapper.collapse:not(.show) {
  display: none
}

.card-wrapper.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height .35s ease;
  -moz-transition: height .35s ease;
  transition: height .35s ease
}

.card {
  background-color: #fff;
  overflow: hidden
}

.accordion-simple .card {
  border-top: 1px solid #e1e1e1
}

.accordion-gutter-md .card:not(:last-child) {
  margin-bottom: 10px
}

.accordion-gutter-sm .card:not(:last-child) {
  margin-bottom: 2px
}

.accordion-plus .toggle-button.collapsed:after,
.accordion-plus .toggle-button.collapsing:after {
  content: ""
}

.accordion-plus .toggle-button.expanded:after,
.accordion-plus .toggle-button.expanding:after {
  content: ""
}

.accordion-boxed .card-body,
.accordion-boxed .card-header a {
  padding-left: 2rem;
  padding-right: 2rem
}

.accordion-boxed .card-header a:after {
  right: 2.3rem
}

.toggle-button.collapsed:after,
.toggle-button.collapsing:after {
  content: ""
}

.toggle-button.expanded:after,
.toggle-button.expanding:after {
  content: ""
}

.accordion-border .card {
  border: solid #e1e1e1;
  border-width: 1px 1px 0
}

.accordion-border .card:last-child {
  border-bottom: 1px solid #e1e1e1
}

.accordion-background .card {
  background-color: #fff;
  border-color: #fff
}

.accordion-dropshadow {
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, .12);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, .12)
}

.accordion-dropshadow .card {
  background-color: #fff;
  border-color: #fff
}

.accordion-icon .card-header i {
  margin-right: 1.5rem;
  font-size: 1.8rem;
  font-weight: 400;
  letter-spacing: -.01em;
  line-height: 0
}

.accordion-card-bg .card {
  border: 1px solid #f2f3f5
}

.accordion-card-bg .card-header a {
  padding-top: 1.7rem;
  padding-bottom: 1.7rem;
  background-color: #f2f3f5
}

.accordion-card-bg.accordion-primary .card {
  border: 0;
  background-color: #f2f3f5
}

.accordion-card-bg.accordion-primary .card-header a {
  padding-top: 1.8rem;
  padding-bottom: 1.8rem;
  background-color: #37c;
  color: #fff
}

.accordion-card-bg.accordion-primary .card-header a:after {
  color: #fff
}

.accordion-color .collapse {
  color: #222
}

.accordion-card-border .card {
  border: 1px solid #dae1e5
}

.alert-round {
  border-radius: 3px
}

.alert {
  position: relative;
  padding: 1.6rem 3.8rem 1.6rem 1.8rem;
  border: 1px solid #222;
  font-size: 1.4rem;
  line-height: 1.75
}

.alert .btn-close {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 2.1rem;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-top: -.1rem;
  font-size: 1.5rem;
  line-height: 0
}

.alert-title {
  text-transform: uppercase;
  font-size: 1.4rem;
  line-height: inherit;
  color: inherit
}

.btn-close {
  -webkit-transition: color .3s;
  -moz-transition: color .3s;
  transition: color .3s
}

.btn-close:hover {
  color: #000
}

.alert-primary {
  background-color: #222;
  border-color: #222;
  color: #222
}

.alert-success {
  background-color: #a8c26e;
  border-color: #a8c26e;
  color: #a8c26e
}

.alert-warning {
  background-color: #ff675d;
  border-color: #ff675d;
  color: #ff675d
}

.alert-danger {
  background-color: #b10001;
  border-color: #b10001;
  color: #b10001
}

.alert-black {
  background-color: #222;
  border-color: #222
}

.alert-black .btn-close:hover {
  color: #666
}

.alert-simple {
  background-color: transparent;
  border-style: dashed;
  border-color: #cacbcc
}

.alert-dark,
.alert-dark .btn-close {
  color: #fff
}

.alert-icon {
  padding: 1.5rem 3.9rem 1.5rem 1.9rem
}

.alert-icon>i {
  vertical-align: middle;
  margin-right: 1.2rem;
  line-height: 1;
  font-size: 2.4rem;
  font-weight: 600
}

.alert-light.alert-primary {
  background-color: #edf4f9;
  border-color: #edf4f9
}

.alert-light.alert-danger {
  background-color: #f6d6d5;
  border-color: #f6d6d5;
  color: #6d1a17
}

.alert-light.alert-danger .btn-close {
  color: #6d1a17
}

.alert-light.alert-warning {
  background-color: #fff5eb;
  border-color: #fff5eb
}

.alert-message {
  display: block;
  padding: 1.3rem 3.9rem 1.9rem 1.9rem
}

.alert-message .btn-close {
  position: absolute;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  transform: translateY(0);
  top: 2.7rem;
  right: 2.1rem;
  font-size: 1.4rem
}

.alert-message .alert-title {
  margin-bottom: .5rem;
  font-weight: 700;
  line-height: 1.7
}

.alert-message p {
  margin: 0;
  padding-right: 2rem;
  line-height: 1.7
}

.alert-message.alert-link p {
  color: #666
}

.alert-message.alert-link a {
  color: #222
}

.alert-message.alert-link a:hover {
  text-decoration: underline
}

.alert.alert-inline {
  padding-right: 4rem
}

.alert.alert-inline .alert-title {
  display: inline-block;
  margin: 0 .8rem 0 0
}

.alert.alert-inline p {
  display: inline
}

.alert-btn:not(.alert-message) {
  padding: .9rem 3.9rem .9rem .9rem
}

.alert-btn .btn:not(.btn-close) {
  margin-right: 1rem;
  padding: .73em 1.61em
}

.alert-btn.alert-message p {
  margin-bottom: 1rem
}

.alert-btn.alert-dark.alert-warning .btn:not(.btn-close) {
  background-color: transparent;
  padding: .7em 1.43em;
  border-width: 3px;
  color: #fff
}

.alert-btn.alert-dark.alert-warning .btn:not(.btn-close):hover {
  border-color: #df7f50;
  background: #df7f50
}

.alert-btn.alert-light.alert-warning .btn:not(.btn-close) {
  color: #ff675d;
  background-color: #fff;
  border-color: #fff
}

.alert-btn.alert-light.alert-warning .btn:not(.btn-close):hover {
  color: #fff;
  background-color: #ff675d;
  border-color: #ff675d
}

.alert-summary>i {
  margin-right: .9rem;
  font-size: 1.6rem;
  line-height: 1.25
}

.alert-summary ul {
  margin: 1.7rem 0 0 2.7rem;
  list-style-type: disc
}

@-webkit-keyframes maskUp {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%)
  }

  to {
    -webkit-transform: translate(0);
    transform: translate(0)
  }
}

@-moz-keyframes maskUp {
  0% {
    -moz-transform: translateY(100%);
    transform: translateY(100%)
  }

  to {
    -moz-transform: translate(0);
    transform: translate(0)
  }
}

@keyframes maskUp {
  0% {
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    transform: translateY(100%)
  }

  to {
    -webkit-transform: translate(0);
    -moz-transform: translate(0);
    transform: translate(0)
  }
}

@-webkit-keyframes maskRight {
  0% {
    -webkit-transform: translate(-100%);
    transform: translate(-100%)
  }

  to {
    -webkit-transform: translate(0);
    transform: translate(0)
  }
}

@-moz-keyframes maskRight {
  0% {
    -moz-transform: translate(-100%);
    transform: translate(-100%)
  }

  to {
    -moz-transform: translate(0);
    transform: translate(0)
  }
}

@keyframes maskRight {
  0% {
    -webkit-transform: translate(-100%);
    -moz-transform: translate(-100%);
    transform: translate(-100%)
  }

  to {
    -webkit-transform: translate(0);
    -moz-transform: translate(0);
    transform: translate(0)
  }
}

@-webkit-keyframes maskDown {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%)
  }

  to {
    -webkit-transform: translate(0);
    transform: translate(0)
  }
}

@-moz-keyframes maskDown {
  0% {
    -moz-transform: translateY(-100%);
    transform: translateY(-100%)
  }

  to {
    -moz-transform: translate(0);
    transform: translate(0)
  }
}

@keyframes maskDown {
  0% {
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    transform: translateY(-100%)
  }

  to {
    -webkit-transform: translate(0);
    -moz-transform: translate(0);
    transform: translate(0)
  }
}

@-webkit-keyframes maskLeft {
  0% {
    -webkit-transform: translate(100%);
    transform: translate(100%)
  }

  to {
    -webkit-transform: translate(0);
    transform: translate(0)
  }
}

@-moz-keyframes maskLeft {
  0% {
    -moz-transform: translate(100%);
    transform: translate(100%)
  }

  to {
    -moz-transform: translate(0);
    transform: translate(0)
  }
}

@keyframes maskLeft {
  0% {
    -webkit-transform: translate(100%);
    -moz-transform: translate(100%);
    transform: translate(100%)
  }

  to {
    -webkit-transform: translate(0);
    -moz-transform: translate(0);
    transform: translate(0)
  }
}

.maskUp {
  -webkit-animation-name: maskUp;
  -moz-animation-name: maskUp;
  animation-name: maskUp
}

.maskRight {
  -webkit-animation-name: maskRight;
  -moz-animation-name: maskRight;
  animation-name: maskRight
}

.maskDown {
  -webkit-animation-name: maskDown;
  -moz-animation-name: maskDown;
  animation-name: maskDown
}

.maskLeft {
  -webkit-animation-name: maskLeft;
  -moz-animation-name: maskLeft;
  animation-name: maskLeft
}

@-webkit-keyframes fadeInUpShorter {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none
  }
}

@-moz-keyframes fadeInUpShorter {
  0% {
    opacity: 0;
    -moz-transform: translateY(50px);
    transform: translateY(50px);
    -moz-transform-origin: 0 0;
    transform-origin: 0 0
  }

  to {
    opacity: 1;
    -moz-transform: none;
    transform: none
  }
}

@keyframes fadeInUpShorter {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
    -moz-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    transform-origin: 0 0
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    transform: none
  }
}

.fadeInUpShorter {
  -webkit-animation-name: fadeInUpShorter;
  -moz-animation-name: fadeInUpShorter;
  animation-name: fadeInUpShorter
}

@-webkit-keyframes fadeInLeftShorter {
  0% {
    opacity: 0;
    -webkit-transform: translate(50px);
    transform: translate(50px);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none
  }
}

@-moz-keyframes fadeInLeftShorter {
  0% {
    opacity: 0;
    -moz-transform: translate(50px);
    transform: translate(50px);
    -moz-transform-origin: 0 0;
    transform-origin: 0 0
  }

  to {
    opacity: 1;
    -moz-transform: none;
    transform: none
  }
}

@keyframes fadeInLeftShorter {
  0% {
    opacity: 0;
    -webkit-transform: translate(50px);
    -moz-transform: translate(50px);
    transform: translate(50px);
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    transform-origin: 0 0
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    transform: none
  }
}

.fadeInLeftShorter {
  -webkit-animation-name: fadeInLeftShorter;
  -moz-animation-name: fadeInLeftShorter;
  animation-name: fadeInLeftShorter
}

@-webkit-keyframes fadeInRightShorter {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50px);
    transform: translate(-50px);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none
  }
}

@-moz-keyframes fadeInRightShorter {
  0% {
    opacity: 0;
    -moz-transform: translate(-50px);
    transform: translate(-50px);
    -moz-transform-origin: 0 0;
    transform-origin: 0 0
  }

  to {
    opacity: 1;
    -moz-transform: none;
    transform: none
  }
}

@keyframes fadeInRightShorter {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50px);
    -moz-transform: translate(-50px);
    transform: translate(-50px);
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    transform-origin: 0 0
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    transform: none
  }
}

.fadeInRightShorter {
  -webkit-animation-name: fadeInRightShorter;
  -moz-animation-name: fadeInRightShorter;
  animation-name: fadeInRightShorter
}

@-webkit-keyframes fadeInDownShorter {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none
  }
}

@-moz-keyframes fadeInDownShorter {
  0% {
    opacity: 0;
    -moz-transform: translateY(-50px);
    transform: translateY(-50px);
    -moz-transform-origin: 0 0;
    transform-origin: 0 0
  }

  to {
    opacity: 1;
    -moz-transform: none;
    transform: none
  }
}

@keyframes fadeInDownShorter {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-50px);
    -moz-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    transform-origin: 0 0
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    transform: none
  }
}

.fadeInDownShorter {
  -webkit-animation-name: fadeInDownShorter;
  -moz-animation-name: fadeInDownShorter;
  animation-name: fadeInDownShorter
}

@-webkit-keyframes blurIn {
  0% {
    opacity: 0;
    -webkit-filter: blur(20px);
    filter: blur(20px);
    -webkit-transform: scale(1.2);
    transform: scale(1.2)
  }

  to {
    opacity: 1;
    -webkit-filter: blur(0);
    filter: blur(0);
    -webkit-transform: none;
    transform: none
  }
}

@-moz-keyframes blurIn {
  0% {
    opacity: 0;
    filter: blur(20px);
    -moz-transform: scale(1.2);
    transform: scale(1.2)
  }

  to {
    opacity: 1;
    filter: blur(0);
    -moz-transform: none;
    transform: none
  }
}

@keyframes blurIn {
  0% {
    opacity: 0;
    -webkit-filter: blur(20px);
    filter: blur(20px);
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    transform: scale(1.2)
  }

  to {
    opacity: 1;
    -webkit-filter: blur(0);
    filter: blur(0);
    -webkit-transform: none;
    -moz-transform: none;
    transform: none
  }
}

.blurIn {
  -webkit-animation-name: blurIn;
  -moz-animation-name: blurIn;
  animation-name: blurIn
}

@-webkit-keyframes grayOut {
  0% {
    opacity: 1;
    -webkit-filter: grayscale(0);
    filter: grayscale(0)
  }

  15% {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%)
  }

  to {
    opacity: 0;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%)
  }
}

@-moz-keyframes grayOut {
  0% {
    opacity: 1;
    filter: grayscale(0)
  }

  15% {
    filter: grayscale(100%)
  }

  to {
    opacity: 0;
    filter: grayscale(100%)
  }
}

@keyframes grayOut {
  0% {
    opacity: 1;
    -webkit-filter: grayscale(0);
    filter: grayscale(0)
  }

  15% {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%)
  }

  to {
    opacity: 0;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%)
  }
}

.grayOut {
  -webkit-animation-name: grayOut;
  -moz-animation-name: grayOut;
  animation-name: grayOut
}

@-webkit-keyframes dotPulse {
  0% {
    opacity: 1;
    -webkit-transform: scale(.2);
    transform: scale(.2)
  }

  to {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

@-moz-keyframes dotPulse {
  0% {
    opacity: 1;
    -moz-transform: scale(.2);
    transform: scale(.2)
  }

  to {
    opacity: 0;
    -moz-transform: scale(1);
    transform: scale(1)
  }
}

@keyframes dotPulse {
  0% {
    opacity: 1;
    -webkit-transform: scale(.2);
    -moz-transform: scale(.2);
    transform: scale(.2)
  }

  to {
    opacity: 0;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    transform: scale(1)
  }
}

.dotPulse {
  -webkit-animation-name: dotPulse;
  -moz-animation-name: dotPulse;
  animation-name: dotPulse;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-duration: 4s;
  -moz-animation-duration: 4s;
  animation-duration: 4s
}

@-webkit-keyframes slideInUp {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@-moz-keyframes slideInUp {
  0% {
    -moz-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible
  }

  to {
    -moz-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes slideInUp {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    -moz-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible
  }

  to {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@-webkit-keyframes slideInDown {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@-moz-keyframes slideInDown {
  0% {
    -moz-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible
  }

  to {
    -moz-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes slideInDown {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
    -moz-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible
  }

  to {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@-webkit-keyframes slideInLeft {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@-moz-keyframes slideInLeft {
  0% {
    -moz-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible
  }

  to {
    -moz-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes slideInLeft {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0);
    -moz-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible
  }

  to {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@-webkit-keyframes slideInRight {
  0% {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@-moz-keyframes slideInRight {
  0% {
    -moz-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible
  }

  to {
    -moz-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes slideInRight {
  0% {
    -webkit-transform: translate3d(100%, 0, 0);
    -moz-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible
  }

  to {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@-webkit-keyframes flipInX {
  0% {
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg)
  }

  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px)
  }
}

@-moz-keyframes flipInX {
  0% {
    -moz-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
    -moz-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg)
  }

  to {
    -moz-transform: perspective(400px);
    transform: perspective(400px)
  }
}

@keyframes flipInX {
  0% {
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
    -webkit-transform: perspective(400px) rotateX(90deg);
    -moz-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg)
  }

  to {
    -webkit-transform: perspective(400px);
    -moz-transform: perspective(400px);
    transform: perspective(400px)
  }
}

@-webkit-keyframes flipInY {
  0% {
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg)
  }

  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px)
  }
}

@-moz-keyframes flipInY {
  0% {
    -moz-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
    -moz-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg)
  }

  to {
    -moz-transform: perspective(400px);
    transform: perspective(400px)
  }
}

@keyframes flipInY {
  0% {
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
    -webkit-transform: perspective(400px) rotateY(90deg);
    -moz-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg)
  }

  to {
    -webkit-transform: perspective(400px);
    -moz-transform: perspective(400px);
    transform: perspective(400px)
  }
}

@-webkit-keyframes flipOutY {
  0% {
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-transform: perspective(400px);
    transform: perspective(400px)
  }

  to {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg)
  }
}

@-moz-keyframes flipOutY {
  0% {
    -moz-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -moz-transform: perspective(400px);
    transform: perspective(400px)
  }

  to {
    opacity: 0;
    -moz-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg)
  }
}

@keyframes flipOutY {
  0% {
    -webkit-animation-timing-function: ease-out;
    -moz-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-transform: perspective(400px);
    -moz-transform: perspective(400px);
    transform: perspective(400px)
  }

  to {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateY(90deg);
    -moz-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg)
  }
}

@-webkit-keyframes brightIn {
  0% {
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    -webkit-filter: brightness(0);
    filter: brightness(0)
  }

  to {
    -webkit-filter: brightness(100%);
    filter: brightness(100%)
  }
}

@-moz-keyframes brightIn {
  0% {
    -moz-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    filter: brightness(0)
  }

  to {
    filter: brightness(100%)
  }
}

@keyframes brightIn {
  0% {
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    -webkit-filter: brightness(0);
    filter: brightness(0)
  }

  to {
    -webkit-filter: brightness(100%);
    filter: brightness(100%)
  }
}

.brightIn {
  -webkit-animation-name: brightIn;
  -moz-animation-name: brightIn;
  animation-name: brightIn
}

.appear-animate {
  visibility: hidden;
  opacity: 0
}

.appear-animation-visible {
  visibility: visible;
  opacity: 1
}

@-webkit-keyframes zoomInShorter {
  0% {
    -webkit-transform: scale3d(.8, .8, .8);
    opacity: 0;
    transform: scale3d(.8, .8, .8)
  }

  50% {
    opacity: 1
  }
}

@-moz-keyframes zoomInShorter {
  0% {
    -webkit-transform: scale3d(.8, .8, .8);
    opacity: 0;
    -moz-transform: scale3d(.8, .8, .8);
    transform: scale3d(.8, .8, .8)
  }

  50% {
    opacity: 1
  }
}

@keyframes zoomInShorter {
  0% {
    -webkit-transform: scale3d(.8, .8, .8);
    opacity: 0;
    -moz-transform: scale3d(.8, .8, .8);
    transform: scale3d(.8, .8, .8)
  }

  50% {
    opacity: 1
  }
}

.zoomInShorter {
  -webkit-animation-name: zoomInShorter;
  -moz-animation-name: zoomInShorter;
  animation-name: zoomInShorter
}

@-webkit-keyframes bounceInUp {

  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1)
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0)
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0)
  }

  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0)
  }

  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0)
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@-moz-keyframes bounceInUp {

  0%,
  60%,
  75%,
  90%,
  to {
    -moz-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1)
  }

  0% {
    opacity: 0;
    -moz-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0)
  }

  60% {
    opacity: 1;
    -moz-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0)
  }

  75% {
    -moz-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0)
  }

  90% {
    -moz-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0)
  }

  to {
    -moz-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes bounceInUp {

  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    -moz-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1)
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    -moz-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0)
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    -moz-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0)
  }

  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    -moz-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0)
  }

  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    -moz-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0)
  }

  to {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@-webkit-keyframes slideZoomIn {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 1
  }

  to {
    -webkit-transform: scale3d(1.1, 1.1, 1);
    transform: scale3d(1.1, 1.1, 1);
    opacity: 1
  }
}

@-moz-keyframes slideZoomIn {
  0% {
    -moz-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 1
  }

  to {
    -moz-transform: scale3d(1.1, 1.1, 1);
    transform: scale3d(1.1, 1.1, 1);
    opacity: 1
  }
}

@keyframes slideZoomIn {
  0% {
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 1
  }

  to {
    -webkit-transform: scale3d(1.1, 1.1, 1);
    -moz-transform: scale3d(1.1, 1.1, 1);
    transform: scale3d(1.1, 1.1, 1);
    opacity: 1
  }
}

.slideZoomIn {
  -webkit-animation-name: slideZoomIn;
  -moz-animation-name: slideZoomIn;
  animation-name: slideZoomIn
}

@-webkit-keyframes rotateStar {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn)
  }
}

@-moz-keyframes rotateStar {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  to {
    -moz-transform: rotate(1turn);
    transform: rotate(1turn)
  }
}

@keyframes rotateStar {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  to {
    -webkit-transform: rotate(1turn);
    -moz-transform: rotate(1turn);
    transform: rotate(1turn)
  }
}

.banner {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  font-size: 1rem
}

.banner figure img {
  display: block;
  width: 100%;
  height: auto
}

.banner.inner-banner:before {
  content: "";
  position: absolute;
  left: 1rem;
  right: 1rem;
  top: 1rem;
  bottom: 1rem;
  border: 2px solid hsla(0, 0%, 88.2%, .3);
  z-index: 1
}

.banner.inner-banner .banner-content {
  z-index: 2
}

.banner .banner-content {
  position: relative
}

.banner-radius {
  border-radius: 1rem;
  overflow: hidden
}

.banner-subtitle {
  letter-spacing: -.025em;
  line-height: 1
}

.banner-price-info,
.banner-title {
  line-height: 1
}

.banner-fixed>.banner-content,
.banner-fixed>.container,
.banner-fixed>.container-fluid {
  position: absolute;
  z-index: 1
}

.banner-fixed>.container,
.banner-fixed>.container-fluid {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0
}

.banner-fixed.content-middle .banner-content {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%)
}

.banner-fixed.content-center .banner-content {
  text-align: center;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%)
}

.banner-fixed.content-center.content-middle .banner-content {
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
  .intro-slider .banner img {
    height: 100%;
    width: 100%
  }
}

.video-banner {
  position: relative
}

.video-banner .btn-play {
  display: inline-block;
  line-height: 49px;
  width: 51px;
  height: 51px;
  font-size: 22px;
  border-radius: 50%;
  border: 1px solid;
  -webkit-transition: background-color .3s, border-color .3s;
  -moz-transition: background-color .3s, border-color .3s;
  transition: background-color .3s, border-color .3s
}

.video-banner .btn-play i {
  margin-left: 4px
}

.video-banner .btn-play:hover {
  background-color: #222;
  border-color: #222
}

.banner-button-hide .banner-content {
  -webkit-transition: padding .3s;
  -moz-transition: padding .3s;
  transition: padding .3s
}

.banner-button-hide .btn {
  position: absolute;
  bottom: 0;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  left: 50%;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  transition: .3s
}

.banner-button-hide:not(:hover) .btn {
  margin: 0;
  visibility: hidden;
  opacity: 0
}

.banner-button-hide:hover .banner-content {
  padding-bottom: 6.2rem
}

@media(max-width: 991px) {
  .blog-sidebar-wrapper {
    z-index: 1200
  }
}

.post {
  font-family: Poppins, sans-serif
}

.post .btn i {
  margin-left: 8px
}

.post .btn i:before {
  margin: 0
}

.post .post-details>:last-child {
  margin-bottom: 0
}

.post-calendar {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  z-index: 2;
  width: 4.5rem;
  height: 4.7rem;
  background: hsla(0, 0%, 100%, .8);
  color: #222;
  font-weight: 600;
  line-height: 1;
  border-radius: .3rem
}

.post-calendar .post-day {
  display: block;
  margin-bottom: 1px;
  font-size: 1.6rem
}

.post-calendar .post-month {
  display: block;
  margin-left: 2px;
  font-size: 1rem
}

.post-media {
  position: relative;
  width: 100%;
  margin-bottom: 0;
  overflow: hidden;
  border-radius: 3px
}

.post-media img {
  display: block;
  width: 100%;
  height: auto;
  -webkit-transition: -webkit-transform .3s;
  transition: -webkit-transform .3s;
  -moz-transition: transform .3s, -moz-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s, -moz-transform .3s
}

.post-media .post-calendar {
  position: absolute;
  left: 2rem;
  top: 2rem
}

.post-media .owl-dots {
  bottom: 2.5rem
}

.post-media .owl-dots .owl-dot span {
  border-color: #fff;
  background-color: transparent
}

.post-media .owl-dots .owl-dot:hover span {
  border-color: #fff;
  background-color: #fff
}

.post-details {
  padding: 2.3rem 0 2rem
}

.post-meta {
  margin-bottom: .6rem;
  font-family: inherit;
  font-size: 1.3rem;
  font-weight: inherit;
  line-height: 1.2;
  color: #999
}

.post-meta a,
.post-meta span {
  font-weight: 600;
  color: #222
}

.post-meta a {
  text-transform: capitalize
}

.post-meta a:hover {
  color: #222
}

.post-meta span {
  -webkit-transition: color .3s;
  -moz-transition: color .3s;
  transition: color .3s
}

.post-meta .post-author {
  text-transform: uppercase
}

.post-meta .post-comment {
  margin-bottom: .6rem;
  font-family: inherit;
  font-size: 1.3rem;
  font-weight: inherit;
  line-height: 1.2;
  color: #999
}

.post-meta .post-comment:hover span {
  color: inherit
}

.post-title {
  margin-bottom: .5rem;
  text-transform: capitalize;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.5;
  -webkit-line-clamp: 1;
  word-break: break-all
}

.post-content,
.post-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden
}

.post-content {
  -webkit-line-clamp: 3;
  margin-bottom: 1.8rem;
  line-height: 1.72;
  letter-spacing: 0;
  color: #666
}

@media(min-width: 576px) {
  .post-classic .post-title {
    font-size: 2.4rem
  }
}

.post-sm .post-details {
  padding: 2.2rem .3rem 2rem
}

.post-video .post-media {
  position: relative
}

.post-video .video-play {
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: color .3s, opacity .3s;
  -moz-transition: color .3s, opacity .3s;
  transition: color .3s, opacity .3s;
  font-family: Font Awesome\ 5 Free;
  font-size: 6rem;
  color: #fff;
  cursor: pointer;
  z-index: 10
}

.post-video .video-play:before {
  content: ""
}

.post-video video {
  display: none;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
  object-fit: cover
}

.post-video.paused video,
.post-video.playing video {
  display: block
}

.post-video.paused .video-play,
.post-video.playing .video-play {
  opacity: 0
}

.post-video.paused .video-play:before,
.post-video.playing .video-play:before {
  content: ""
}

.post-video.paused .post-media,
.post-video.playing .post-media {
  background-color: #000
}

.post-video.paused .post-media:hover .video-play,
.post-video.playing .post-media:hover .video-play {
  opacity: 1
}

.post-video.paused .post-media img,
.post-video.playing .post-media img {
  visibility: hidden
}

.post-video.paused .video-play {
  opacity: 1
}

.post-video.paused .video-play:before {
  content: ""
}

.post-video.paused.playing .video-play:before {
  content: ""
}

.post-list {
  margin-bottom: 2rem
}

.post-list .post-details {
  padding: 2rem 0
}

.post-list img {
  min-height: 20rem;
  object-fit: cover
}

@media(min-width: 576px) {
  .post-list {
    display: -moz-box;
    display: flex;
    -moz-box-align: center;
    align-items: center
  }

  .post-list .post-media {
    margin-right: 2rem
  }

  .post-list .post-details,
  .post-list .post-media {
    width: -moz-calc(50% - 1rem);
    width: calc(50% - 1rem)
  }
}

.post-list-xs {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  margin-bottom: 2rem;
  margin-left: 2px
}

.post-list-xs .post-calendar {
  color: #ccc;
  border-color: #666;
  background-color: transparent
}

.post-list-xs .post-details {
  -moz-box-flex: 1;
  flex: 1 1;
  margin: 0 0 0 2rem;
  padding: 0
}

.post-list-xs .post-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 0;
  text-transform: none;
  font-size: inherit;
  font-weight: 400;
  line-height: 1.69;
  color: inherit;
  white-space: normal
}

.post-list-xs .post-title:hover a {
  color: #fff
}

.post-list-sm {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  margin-bottom: 2rem
}

.post-list-sm .post-media {
  max-width: 9rem;
  -moz-box-flex: 0;
  flex: 0 0 9rem;
  height: 9rem
}

.post-list-sm .post-media img {
  height: 100%;
  object-fit: cover
}

.post-list-sm .post-details {
  padding: 0;
  margin-left: 1.6rem
}

.post-list-sm .post-meta {
  margin-bottom: .4rem
}

.post-list-sm .post-meta a {
  font-size: 1.3rem;
  font-weight: 400;
  color: #999
}

.post-list-sm .post-title {
  margin-bottom: 0;
  white-space: normal;
  text-transform: none;
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical
}

.post-col .post {
  margin: 0;
  padding: 1rem .3rem
}

.post-image-gap {
  padding: 2rem;
  background-color: #fff;
  -webkit-transition: -webkit-box-shadow .3s;
  transition: -webkit-box-shadow .3s;
  -moz-transition: box-shadow .3s;
  transition: box-shadow .3s;
  transition: box-shadow .3s, -webkit-box-shadow .3s
}

.post-image-gap .post-details {
  padding-bottom: 1rem
}

.post-image-gap:hover {
  -webkit-box-shadow: 0 0 7px 0 rgba(0, 0, 0, .1);
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, .1)
}

.post-mask {
  position: relative
}

.post-mask:before {
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: .75;
  background: rgba(51, 51, 51, .5);
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all .3s;
  pointer-events: none;
  z-index: 1
}

.post-mask img {
  min-height: 19rem
}

.post-mask.gradient:before {
  height: 50%;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(125, 185, 232, 0)), to(#000));
  background: -moz-linear-gradient(top, rgba(125, 185, 232, 0) 0, #000 100%);
  background: linear-gradient(180deg, rgba(125, 185, 232, 0) 0, #000)
}

.post-mask .post-details {
  position: absolute;
  padding: 0;
  left: 3rem;
  right: 3rem;
  bottom: 2.2rem;
  z-index: 2;
  -webkit-transform: translateY(4rem);
  -moz-transform: translateY(4rem);
  transform: translateY(4rem);
  -webkit-transition: -webkit-transform .4s;
  transition: -webkit-transform .4s;
  -moz-transition: transform .4s, -moz-transform .4s;
  transition: transform .4s;
  transition: transform .4s, -webkit-transform .4s, -moz-transform .4s
}

.post-mask .post-meta {
  color: #fff
}

.post-mask .post-meta a,
.post-mask .post-meta span {
  color: inherit
}

.post-mask .post-meta a {
  font-weight: 400
}

.post-mask .post-title {
  margin: 0;
  text-transform: none;
  line-height: 1.32;
  color: #fff
}

.post-mask .post-title a:hover {
  color: #fff
}

.post-mask .btn-link {
  margin-top: 1.5rem;
  opacity: 0;
  -webkit-transition: opacity .4s;
  -moz-transition: opacity .4s;
  transition: opacity .4s
}

.post-mask:hover .post-media a:before {
  opacity: .1
}

.post-mask:hover .post-details {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  transform: translateY(0)
}

.post-mask:hover .btn-link {
  opacity: 1
}

.post-frame {
  padding: 2rem 2rem 0;
  -webkit-box-shadow: 0 5px 20px 3px rgba(0, 0, 0, .05);
  box-shadow: 0 5px 20px 3px rgba(0, 0, 0, .05)
}

.post-frame .post-details {
  padding: 2.2rem 0 2rem
}

.post-frame .post-content {
  margin-bottom: 1.8rem;
  line-height: 1.72
}

ul.blog-filters {
  padding-top: 4rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
  font-weight: 700;
  color: #333;
  border-top: 1px solid #e1e1e1
}

ul.blog-filters li {
  margin-right: 2rem;
  margin-left: 2rem
}

ul.blog-filters .nav-filter {
  letter-spacing: 0
}

.blog-filters span {
  margin-left: .8rem;
  color: #aaa
}

.blog-filters li {
  padding: 0;
  margin-right: .8rem;
  line-height: 2.143;
  border-bottom: 2px solid transparent
}

.blog-filters a.nav-filter {
  border-width: 2px
}

.btn {
  display: inline-block;
  border: 2px solid #e4eaec;
  background-color: #e4eaec;
  color: #222;
  outline: 0;
  padding: 1.22em 2.78em;
  font-weight: 700;
  font-size: 1.4rem;
  font-family: Poppins, sans-serif;
  letter-spacing: 0;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: center;
  -webkit-transition: color .3s, border-color .3s, background-color .3s, -webkit-box-shadow .3s;
  transition: color .3s, border-color .3s, background-color .3s, -webkit-box-shadow .3s;
  -moz-transition: color .3s, border-color .3s, background-color .3s, box-shadow .3s;
  transition: color .3s, border-color .3s, background-color .3s, box-shadow .3s;
  transition: color .3s, border-color .3s, background-color .3s, box-shadow .3s, -webkit-box-shadow .3s;
  white-space: nowrap;
  cursor: pointer
}

.btn:active,
.btn:focus,
.btn:hover {
  color: #222;
  border-color: #cfdadd;
  background-color: #cfdadd
}

.btn i {
  display: inline-block;
  vertical-align: middle;
  margin-left: .4rem;
  line-height: 0;
  font-size: 1.9rem
}

.btn i:before {
  margin: 0
}

.btn-solid {
  background-color: #fff;
  border-color: #fff;
  color: #222
}

.btn-solid:active,
.btn-solid:focus,
.btn-solid:hover {
  border-color: #222;
  background-color: #222;
  color: #fff
}

.btn-outline {
  border: 2px solid #e4eaec;
  color: #666;
  background-color: transparent
}

.btn-outline:active,
.btn-outline:focus,
.btn-outline:hover {
  border-color: #666;
  background-color: #666;
  color: #fff
}

.btn-outline.btn-outline-light {
  padding: 1.07em 2.21em;
  border-width: 1px
}

.btn-outline.btn-bg {
  color: #fff
}

.btn-icon-left i,
.btn-icon-right i,
.btn-reveal-left i,
.btn-reveal-right i {
  line-height: 0
}

.btn-icon-right {
  display: -moz-inline-box;
  display: inline-flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center
}

.btn-icon-right i {
  margin-left: .7rem
}

.btn-icon-left {
  display: -moz-inline-box;
  display: inline-flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center
}

.btn-icon-left i {
  margin-right: .7rem;
  margin-left: 0
}

.btn-reveal-left i {
  opacity: 0;
  margin-left: -1em;
  -webkit-transform: translateX(.5em);
  -moz-transform: translateX(.5em);
  transform: translateX(.5em);
  margin-right: 0;
  -webkit-transition: opacity .3s, margin .3s, -webkit-transform .3s;
  transition: opacity .3s, margin .3s, -webkit-transform .3s;
  -moz-transition: transform .3s, opacity .3s, margin .3s, -moz-transform .3s;
  transition: transform .3s, opacity .3s, margin .3s;
  transition: transform .3s, opacity .3s, margin .3s, -webkit-transform .3s, -moz-transform .3s
}

.btn-reveal-left:active i,
.btn-reveal-left:focus i,
.btn-reveal-left:hover i {
  opacity: 1;
  margin-left: 0;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  transform: translateX(0);
  margin-right: .7rem
}

.btn-reveal-right i {
  opacity: 0;
  margin-right: -1em;
  -webkit-transform: translateX(-.5em);
  -moz-transform: translateX(-.5em);
  transform: translateX(-.5em);
  margin-left: 0;
  -webkit-transition: opacity .3s, margin .3s, -webkit-transform .3s;
  transition: opacity .3s, margin .3s, -webkit-transform .3s;
  -moz-transition: transform .3s, opacity .3s, margin .3s, -moz-transform .3s;
  transition: transform .3s, opacity .3s, margin .3s;
  transition: transform .3s, opacity .3s, margin .3s, -webkit-transform .3s, -moz-transform .3s
}

.btn-reveal-right:active i,
.btn-reveal-right:focus i,
.btn-reveal-right:hover i {
  opacity: 1;
  margin-left: .7rem;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  transform: translateX(0);
  margin-right: 0
}

.btn-slide-left:active i,
.btn-slide-left:focus i,
.btn-slide-left:hover i {
  -webkit-animation: slideLeft .5s ease;
  -moz-animation: slideLeft .5s ease;
  animation: slideLeft .5s ease
}

.btn-slide-right:active i,
.btn-slide-right:focus i,
.btn-slide-right:hover i {
  -webkit-animation: slideRight .5s ease;
  -moz-animation: slideRight .5s ease;
  animation: slideRight .5s ease
}

.btn-slide-up:active i,
.btn-slide-up:focus i,
.btn-slide-up:hover i {
  -webkit-animation: slideUp .5s ease;
  -moz-animation: slideUp .5s ease;
  animation: slideUp .5s ease
}

.btn-slide-down:active i,
.btn-slide-down:focus i,
.btn-slide-down:hover i {
  -webkit-animation: slideDown .5s ease;
  -moz-animation: slideDown .5s ease;
  animation: slideDown .5s ease
}

.btn-infinite:hover i {
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite
}

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }

  50% {
    -webkit-transform: translateX(-.5em);
    transform: translateX(-.5em)
  }

  to {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }
}

@-moz-keyframes slideLeft {
  0% {
    -moz-transform: translateX(0);
    transform: translateX(0)
  }

  50% {
    -moz-transform: translateX(-.5em);
    transform: translateX(-.5em)
  }

  to {
    -moz-transform: translateX(0);
    transform: translateX(0)
  }
}

@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    transform: translateX(0)
  }

  50% {
    -webkit-transform: translateX(-.5em);
    -moz-transform: translateX(-.5em);
    transform: translateX(-.5em)
  }

  to {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    transform: translateX(0)
  }
}

@-webkit-keyframes slideRight {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }

  50% {
    -webkit-transform: translateX(.5em);
    transform: translateX(.5em)
  }

  to {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }
}

@-moz-keyframes slideRight {
  0% {
    -moz-transform: translateX(0);
    transform: translateX(0)
  }

  50% {
    -moz-transform: translateX(.5em);
    transform: translateX(.5em)
  }

  to {
    -moz-transform: translateX(0);
    transform: translateX(0)
  }
}

@keyframes slideRight {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    transform: translateX(0)
  }

  50% {
    -webkit-transform: translateX(.5em);
    -moz-transform: translateX(.5em);
    transform: translateX(.5em)
  }

  to {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    transform: translateX(0)
  }
}

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }

  50% {
    -webkit-transform: translateY(-.5em);
    transform: translateY(-.5em)
  }

  to {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
}

@-moz-keyframes slideUp {
  0% {
    -moz-transform: translateY(0);
    transform: translateY(0)
  }

  50% {
    -moz-transform: translateY(-.5em);
    transform: translateY(-.5em)
  }

  to {
    -moz-transform: translateY(0);
    transform: translateY(0)
  }
}

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    transform: translateY(0)
  }

  50% {
    -webkit-transform: translateY(-.5em);
    -moz-transform: translateY(-.5em);
    transform: translateY(-.5em)
  }

  to {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    transform: translateY(0)
  }
}

@-webkit-keyframes slideDown {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }

  50% {
    -webkit-transform: translateY(.5em);
    transform: translateY(.5em)
  }

  to {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
}

@-moz-keyframes slideDown {
  0% {
    -moz-transform: translateY(0);
    transform: translateY(0)
  }

  50% {
    -moz-transform: translateY(.5em);
    transform: translateY(.5em)
  }

  to {
    -moz-transform: translateY(0);
    transform: translateY(0)
  }
}

@keyframes slideDown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    transform: translateY(0)
  }

  50% {
    -webkit-transform: translateY(.5em);
    -moz-transform: translateY(.5em);
    transform: translateY(.5em)
  }

  to {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    transform: translateY(0)
  }
}

.btn-shadow-lg {
  -webkit-box-shadow: 0 13px 20px -10px rgba(0, 0, 0, .15);
  box-shadow: 0 13px 20px -10px rgba(0, 0, 0, .15)
}

.btn-shadow-lg:active,
.btn-shadow-lg:focus,
.btn-shadow-lg:hover {
  -webkit-box-shadow: 0 13px 20px -10px rgba(0, 0, 0, .2);
  box-shadow: 0 13px 20px -10px rgba(0, 0, 0, .2)
}

.btn-shadow {
  -webkit-box-shadow: 0 15px 20px -15px rgba(0, 0, 0, .15);
  box-shadow: 0 15px 20px -15px rgba(0, 0, 0, .15)
}

.btn-shadow:active,
.btn-shadow:focus,
.btn-shadow:hover {
  -webkit-box-shadow: 0 15px 20px -15px rgba(0, 0, 0, .2);
  box-shadow: 0 15px 20px -15px rgba(0, 0, 0, .2)
}

.btn-shadow-sm {
  -webkit-box-shadow: 0 17px 20px -20px rgba(0, 0, 0, .15);
  box-shadow: 0 17px 20px -20px rgba(0, 0, 0, .15)
}

.btn-shadow-sm:active,
.btn-shadow-sm:focus,
.btn-shadow-sm:hover {
  -webkit-box-shadow: 0 17px 20px -20px rgba(0, 0, 0, .2);
  box-shadow: 0 17px 20px -20px rgba(0, 0, 0, .2)
}

.btn-underline:active:after,
.btn-underline:focus:after,
.btn-underline:hover:after {
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  transform: scaleX(1)
}

.btn-underline:after {
  display: block;
  margin-top: 1px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 2px;
  border: 1px solid;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: -webkit-transform .3s;
  transition: -webkit-transform .3s;
  -moz-transition: transform .3s, -moz-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s, -moz-transform .3s;
  content: ""
}

.btn-underline.btn-underline-visible:after {
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  transform: scaleX(1)
}

.btn-underline.btn-underline-width-sm:after {
  height: 1px;
  border-bottom: 0
}

.btn-underline.sm:after {
  width: 46%
}

.btn-underline.lg:after {
  margin-left: -16.5%;
  margin-right: -16.5%;
  width: 133%
}

.btn-link {
  padding: 0;
  color: #222;
  border: 0
}

.btn-link,
.btn-link:active,
.btn-link:focus,
.btn-link:hover {
  background-color: transparent
}

.btn-disabled {
  cursor: not-allowed;
  pointer-events: none;
  background-color: #e4eaec !important;
  color: #999 !important;
  border-color: #e4eaec !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important
}

.btn-disabled:after,
.btn-disabled:before {
  content: none !important
}

.btn.btn-rounded,
.btn.btn-rounded:after,
.btn.btn-rounded:before {
  border-radius: 3px
}

.btn.btn-block {
  display: block;
  padding-left: 0;
  padding-right: 0;
  width: 100%
}

.btn.btn-lg {
  padding: 1.5em 3.42em
}

.btn.btn-md {
  padding: 1.07em 2.15em;
  font-size: 1.3rem
}

.btn.btn-sm {
  padding: .92em 1.61em;
  font-size: 1.3rem
}

.btn.btn-link {
  padding: 0
}

@media(-ms-high-contrast:active), (-ms-high-contrast:none) {
  .btn.btn-link {
    overflow: visible
  }
}

.btn-primary {
  color: #fff;
  border-color: #222;
  background-color: #222
}

.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover {
  color: #fff;
  border-color: #343434;
  background-color: #343434
}

.btn-primary.btn-solid {
  color: #222;
  border-color: #fff;
  background-color: #fff
}

.btn-primary.btn-solid:active,
.btn-primary.btn-solid:focus,
.btn-primary.btn-solid:hover {
  border-color: #222;
  background-color: #222;
  color: #fff
}

.btn-primary.btn-outline {
  color: #222;
  border-color: #222;
  background-color: transparent
}

.btn-primary.btn-outline:active,
.btn-primary.btn-outline:focus,
.btn-primary.btn-outline:hover {
  background-color: #222;
  color: #fff
}

.btn-primary.btn-link {
  background-color: transparent;
  color: #222
}

.btn-primary.btn-link:active,
.btn-primary.btn-link:focus,
.btn-primary.btn-link:hover,
.btn-primary.btn-underline:active,
.btn-primary.btn-underline:focus,
.btn-primary.btn-underline:hover {
  color: #222
}

.btn-secondary {
  color: #fff;
  border-color: #ff675d;
  background-color: #ff675d
}

.btn-secondary:active,
.btn-secondary:focus,
.btn-secondary:hover {
  color: #fff;
  border-color: #ff8881;
  background-color: #ff8881
}

.btn-secondary.btn-solid {
  color: #ff675d;
  border-color: #fff;
  background-color: #fff
}

.btn-secondary.btn-solid:active,
.btn-secondary.btn-solid:focus,
.btn-secondary.btn-solid:hover {
  border-color: #ff675d;
  background-color: #ff675d;
  color: #fff
}

.btn-secondary.btn-outline {
  color: #ff675d;
  border-color: #ff675d;
  background-color: transparent
}

.btn-secondary.btn-outline:active,
.btn-secondary.btn-outline:focus,
.btn-secondary.btn-outline:hover {
  background-color: #ff675d;
  color: #fff
}

.btn-secondary.btn-link {
  background-color: transparent;
  color: #ff675d
}

.btn-secondary.btn-link:active,
.btn-secondary.btn-link:focus,
.btn-secondary.btn-link:hover {
  color: #222
}

.btn-secondary.btn-underline:active,
.btn-secondary.btn-underline:focus,
.btn-secondary.btn-underline:hover {
  color: #ff675d
}

.btn-alert {
  color: #fff;
  border-color: #b10001;
  background-color: #b10001
}

.btn-alert:active,
.btn-alert:focus,
.btn-alert:hover {
  color: #fff;
  border-color: #d50001;
  background-color: #d50001
}

.btn-alert.btn-solid {
  color: #b10001;
  border-color: #fff;
  background-color: #fff
}

.btn-alert.btn-solid:active,
.btn-alert.btn-solid:focus,
.btn-alert.btn-solid:hover {
  border-color: #b10001;
  background-color: #b10001;
  color: #fff
}

.btn-alert.btn-outline {
  color: #b10001;
  border-color: #b10001;
  background-color: transparent
}

.btn-alert.btn-outline:active,
.btn-alert.btn-outline:focus,
.btn-alert.btn-outline:hover {
  background-color: #b10001;
  color: #fff
}

.btn-alert.btn-link {
  background-color: transparent;
  color: #b10001
}

.btn-alert.btn-link:active,
.btn-alert.btn-link:focus,
.btn-alert.btn-link:hover {
  color: #222
}

.btn-alert.btn-underline:active,
.btn-alert.btn-underline:focus,
.btn-alert.btn-underline:hover {
  color: #b10001
}

.btn-success {
  color: #fff;
  border-color: #a8c26e;
  background-color: #a8c26e
}

.btn-success:active,
.btn-success:focus,
.btn-success:hover {
  color: #fff;
  border-color: #b7cd87;
  background-color: #b7cd87
}

.btn-success.btn-solid {
  color: #a8c26e;
  border-color: #fff;
  background-color: #fff
}

.btn-success.btn-solid:active,
.btn-success.btn-solid:focus,
.btn-success.btn-solid:hover {
  border-color: #a8c26e;
  background-color: #a8c26e;
  color: #fff
}

.btn-success.btn-outline {
  color: #a8c26e;
  border-color: #a8c26e;
  background-color: transparent
}

.btn-success.btn-outline:active,
.btn-success.btn-outline:focus,
.btn-success.btn-outline:hover {
  background-color: #a8c26e;
  color: #fff
}

.btn-success.btn-link {
  background-color: transparent;
  color: #a8c26e
}

.btn-success.btn-link:active,
.btn-success.btn-link:focus,
.btn-success.btn-link:hover {
  color: #222
}

.btn-success.btn-underline:active,
.btn-success.btn-underline:focus,
.btn-success.btn-underline:hover {
  color: #a8c26e
}

.btn-dark {
  color: #fff;
  border-color: #222;
  background-color: #222
}

.btn-dark:active,
.btn-dark:focus,
.btn-dark:hover {
  color: #fff;
  border-color: #343434;
  background-color: #343434
}

.btn-dark.btn-solid {
  color: #222;
  border-color: #fff;
  background-color: #fff
}

.btn-dark.btn-solid:active,
.btn-dark.btn-solid:focus,
.btn-dark.btn-solid:hover {
  border-color: #222;
  background-color: #222;
  color: #fff
}

.btn-dark.btn-outline {
  color: #222;
  border-color: #222;
  background-color: transparent
}

.btn-dark.btn-outline:active,
.btn-dark.btn-outline:focus,
.btn-dark.btn-outline:hover {
  background-color: #222;
  color: #fff
}

.btn-dark.btn-link {
  background-color: transparent;
  color: #222
}

.btn-dark.btn-link:active,
.btn-dark.btn-link:focus,
.btn-dark.btn-link:hover,
.btn-dark.btn-underline:active,
.btn-dark.btn-underline:focus,
.btn-dark.btn-underline:hover {
  color: #222
}

.btn-white {
  color: #222;
  background-color: #fff;
  border-color: #fff
}

.btn-white:active,
.btn-white:focus,
.btn-white:hover {
  color: #fff;
  border-color: #222;
  background-color: #222
}

.btn-white.btn-outline {
  color: #fff;
  background-color: transparent;
  border-color: #fff
}

.btn-white.btn-outline:active,
.btn-white.btn-outline:focus,
.btn-white.btn-outline:hover {
  color: #222;
  background-color: #fff
}

.btn-white.btn-link,
.btn-white.btn-link:active,
.btn-white.btn-link:focus,
.btn-white.btn-link:hover {
  color: #fff;
  background-color: transparent
}

.category {
  position: relative;
  font-size: 1rem
}

.category img {
  display: block;
  width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  border-radius: .3rem
}

.category .category-name {
  margin-bottom: 0;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.2;
  color: inherit;
  text-transform: capitalize
}

.category .category-name a {
  color: inherit
}

.category .category-count {
  font-size: 1.2rem;
  line-height: 1.2
}

.category-content {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  -moz-box-pack: center;
  justify-content: center;
  text-align: center;
  -webkit-transition: background .3s;
  -moz-transition: background .3s;
  transition: background .3s
}

.category-absolute .category-content {
  cursor: pointer;
  position: absolute;
  left: 1rem;
  right: 1rem;
  bottom: 1rem;
  height: 5.2rem;
  border-radius: .3rem;
  overflow: hidden
}

.category-absolute .btn {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  transform: translateY(100%);
  opacity: 0;
  -webkit-transition: opacity .3s, -webkit-transform .3s;
  transition: opacity .3s, -webkit-transform .3s;
  -moz-transition: transform .3s, opacity .3s, -moz-transform .3s;
  transition: transform .3s, opacity .3s;
  transition: transform .3s, opacity .3s, -webkit-transform .3s, -moz-transform .3s;
  line-height: 1
}

.category-absolute .category-content:hover .btn {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  transform: translateY(0);
  opacity: 1
}

.category-default .category-content {
  background-color: #fff
}

.category-default .category-name,
.category-light .category-name {
  margin-bottom: 0;
  color: #222;
  letter-spacing: -.025em
}

.category-default1 .category-content {
  background-color: #fff;
  color: #222
}

.category-default1 .category-name {
  margin: 0
}

.category-default1:hover .category-content {
  background-color: #222;
  color: #fff
}

.category-light .category-name {
  padding: 1.8rem 3.9rem;
  background-color: #fff;
  border-radius: .3rem;
  -webkit-transition: background-color .3s, padding .3s;
  -moz-transition: background-color .3s, padding .3s;
  transition: background-color .3s, padding .3s
}

.category-light .category-name a {
  position: relative;
  -webkit-transition: padding .3s;
  -moz-transition: padding .3s;
  transition: padding .3s
}

.category-light .category-name a:after {
  content: "";
  display: inline-block;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: riode;
  font-size: 1.6rem;
  font-weight: 400;
  opacity: 0;
  -webkit-transition: opacity .3s;
  -moz-transition: opacity .3s;
  transition: opacity .3s
}

.category-light:hover .category-name {
  padding-left: 2.7rem;
  padding-right: 2.7rem;
  background-color: #222;
  color: #fff
}

.category-light:hover .category-name a {
  padding-right: 2.3rem
}

.category-light:hover .category-name a:after {
  opacity: 1
}

.category-icon {
  padding: 2.6rem .5rem;
  border: 1px solid #e1e1e1;
  color: #222;
  text-align: center;
  -webkit-transition: border .3s;
  -moz-transition: border .3s;
  transition: border .3s;
  border-radius: .3rem;
  height: 100%
}

.category-icon i {
  margin-bottom: 1.3rem;
  font-size: 4.2rem;
  color: #666;
  -webkit-transition: -webkit-transform .3s;
  transition: -webkit-transform .3s;
  -moz-transition: transform .3s, -moz-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s, -moz-transform .3s
}

.category-icon .category-name {
  margin: 0;
  color: #222
}

.category-icon:hover i {
  -webkit-transform: translateY(-4px);
  -moz-transform: translateY(-4px);
  transform: translateY(-4px)
}

.category-icon:hover .category-content {
  background: transparent
}

.category-icon-inline {
  display: -moz-inline-box;
  display: inline-flex;
  -moz-box-align: center;
  align-items: center
}

.category-icon-inline .category-media {
  font-size: 3.2rem;
  line-height: 1;
  color: #444;
  -webkit-transition: color .3s;
  -moz-transition: color .3s;
  transition: color .3s
}

.category-icon-inline .category-media i {
  margin-right: .8rem
}

.category-icon-inline .category-media svg {
  display: block;
  margin-right: 1rem;
  width: 3.5rem;
  height: 3.9rem;
  stroke: #444;
  fill: #444;
  -webkit-transition: stroke .3s;
  -moz-transition: stroke .3s;
  transition: stroke .3s
}

.category-icon-inline .category-name {
  margin: 0;
  text-transform: none;
  text-align: left;
  font-size: 1.3rem;
  font-weight: inherit;
  font-family: inherit;
  line-height: 1.08;
  color: inherit;
  -webkit-transition: color .3s;
  -moz-transition: color .3s;
  transition: color .3s
}

.category-icon-inline:hover .category-media,
.category-icon-inline:hover .category-name {
  color: #222
}

.category-icon-inline:hover svg {
  stroke: #222;
  fill: #222
}

.category-icon-inline:hover i,
.category-icon-inline:hover svg {
  -webkit-animation: slideUpShorter .6s;
  -moz-animation: slideUpShorter .6s;
  animation: slideUpShorter .6s
}

@-webkit-keyframes slideUpShorter {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }

  50% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px)
  }

  to {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
}

@-moz-keyframes slideUpShorter {
  0% {
    -moz-transform: translateY(0);
    transform: translateY(0)
  }

  50% {
    -moz-transform: translateY(-4px);
    transform: translateY(-4px)
  }

  to {
    -moz-transform: translateY(0);
    transform: translateY(0)
  }
}

@keyframes slideUpShorter {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    transform: translateY(0)
  }

  50% {
    -webkit-transform: translateY(-4px);
    -moz-transform: translateY(-4px);
    transform: translateY(-4px)
  }

  to {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    transform: translateY(0)
  }
}

.ellipse-section .owl-stage-outer {
  margin: -2rem;
  padding: 2rem
}

.category-ellipse .category-media {
  border-radius: 50%;
  overflow: hidden;
  padding: 1.2rem;
  background: #fff;
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, .1);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .1);
  text-align: center
}

.category-ellipse img {
  -webkit-transform: rotate(-10deg) translateX(-14px);
  -moz-transform: rotate(-10deg) translateX(-14px);
  transform: rotate(-10deg) translateX(-14px);
  -webkit-transition: -webkit-transform .3s;
  transition: -webkit-transform .3s;
  -moz-transition: transform .3s, -moz-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s, -moz-transform .3s
}

.category-ellipse .category-content {
  padding-top: 2rem
}

.category-ellipse .category-name {
  letter-spacing: -.025em;
  color: #222
}

.category-ellipse .category-name:hover {
  color: #222
}

.category-ellipse .category-count {
  text-transform: uppercase
}

.category-ellipse:hover img {
  -webkit-transform: rotate(0) translateX(0);
  -moz-transform: rotate(0) translateX(0);
  transform: rotate(0) translateX(0)
}

.category-classic .category-content {
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(38, 38, 38, .9);
  height: 25%;
  color: #fff;
  border-radius: 0
}

.category-classic .category-name {
  margin-bottom: 0;
  text-transform: uppercase
}

.category-classic .category-count {
  display: block;
  opacity: 0;
  line-height: 0;
  visibility: hidden;
  -webkit-transition: opacity .3s, line-height .3s, visibility .3s, padding .3s;
  -moz-transition: opacity .3s, line-height .3s, visibility .3s, padding .3s;
  transition: opacity .3s, line-height .3s, visibility .3s, padding .3s
}

.category-classic:hover .category-count {
  visibility: visible;
  line-height: 1.2;
  opacity: 1;
  padding-top: 4px
}

.category-group-icon,
.category-group-image {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  border: 1px solid #e1e1e1;
  border-radius: .3rem
}

.category-group-icon>*,
.category-group-image>* {
  -moz-box-flex: 1;
  flex: 1 1
}

.category-group-icon li:not(:last-child),
.category-group-image li:not(:last-child) {
  margin-bottom: .9rem
}

.category-group-image {
  color: #666
}

.category-group-image .category-content {
  position: static;
  padding: 2.3rem 1rem 2.3rem 0;
  background: transparent;
  text-align: left;
  -moz-box-align: start;
  align-items: flex-start
}

.category-group-image .category-name {
  margin-bottom: 1.5rem;
  text-transform: none;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
  font-size: 1.6rem;
  font-weight: 700;
  color: #222;
  letter-spacing: -.01em
}

.category-group-image .category-list {
  font-size: 1.3rem;
  line-height: 1.2
}

.category-group-image .category-list a {
  position: relative;
  display: inline-block;
  -webkit-transition: -webkit-text-decoration .3s;
  transition: -webkit-text-decoration .3s;
  -moz-transition: text-decoration .3s, -moz-text-decoration .3s;
  transition: text-decoration .3s;
  transition: text-decoration .3s, -webkit-text-decoration .3s, -moz-text-decoration .3s
}

.category-group-image .category-list a:hover {
  color: #222;
  text-decoration: underline
}

.category-group-image .category-list a:active {
  color: #222
}

.category-group-image:hover .category-name {
  margin-bottom: 1.5rem
}

.category-group-image:hover .category-content {
  background: transparent
}

.category-group-icon {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  border: 0;
  color: #fff
}

.category-group-icon>* {
  -moz-box-flex: 1;
  flex: 1 1
}

.category-group-icon .category-media {
  text-align: center
}

.category-group-icon .category-media i {
  display: inline-block;
  font-size: 6.8rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.8rem;
  line-height: 1;
  -webkit-transition: -webkit-transform .3s;
  transition: -webkit-transform .3s;
  -moz-transition: transform .3s, -moz-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s, -moz-transform .3s;
  color: #fff
}

.category-group-icon>a {
  padding-right: 1rem
}

.category-group-icon>a:hover i {
  -webkit-transform: translateY(-.5rem);
  -moz-transform: translateY(-.5rem);
  transform: translateY(-.5rem)
}

.category-group-icon .category-content {
  padding: 28px 0 28px 10px;
  background: transparent;
  text-align: left;
  -moz-box-align: start;
  align-items: flex-start
}

.category-group-icon .category-name {
  margin-bottom: 0;
  padding: 0 .8rem;
  text-align: center;
  letter-spacing: -.025em;
  text-transform: uppercase;
  color: #fff
}

.category-group-icon .category-list {
  font-size: 1.3rem;
  line-height: 1.2
}

.category-group-icon .category-list li {
  white-space: nowrap
}

.category-group-icon .category-list li:before {
  content: "";
  padding-right: .8rem;
  font-family: Font Awesome\ 5 Free;
  font-size: 1.3rem;
  font-weight: 600;
  color: #fff
}

.category-group-icon .category-list a {
  position: relative;
  display: inline-block;
  -webkit-transition: -webkit-text-decoration .3s;
  transition: -webkit-text-decoration .3s;
  -moz-transition: text-decoration .3s, -moz-text-decoration .3s;
  transition: text-decoration .3s;
  transition: text-decoration .3s, -webkit-text-decoration .3s, -moz-text-decoration .3s
}

.category-group-icon .category-list a:hover {
  color: inherit;
  text-decoration: underline
}

.category-group-icon:hover .category-name {
  margin-bottom: 0
}

.category-group-icon:hover .category-content {
  background: transparent
}

.category-banner {
  overflow: hidden;
  color: #222
}

.category-banner.text-white,
.category-banner.text-white .btn {
  color: #fff
}

.category-banner .category-content {
  top: 4.5rem;
  left: 5rem;
  bottom: auto;
  width: auto;
  height: auto;
  -moz-box-align: start;
  align-items: flex-start;
  z-index: 1;
  background-color: transparent;
  -webkit-transition: top .3s, padding .3s;
  -moz-transition: top .3s, padding .3s;
  transition: top .3s, padding .3s
}

.category-banner .category-name {
  text-transform: none;
  text-align: left;
  font-size: 2rem;
  font-weight: 700;
  font-family: inherit;
  letter-spacing: -.025em
}

.category-banner .category-count {
  visibility: visible;
  opacity: 1;
  font-size: 1.4rem;
  line-height: 1.2;
  -webkit-transition: color .3s;
  -moz-transition: color .3s;
  transition: color .3s;
  z-index: 1
}

.category-banner .btn {
  top: auto;
  right: auto;
  -webkit-transform: none;
  -moz-transform: none;
  transform: none
}

.category-banner:hover .category-content {
  top: 3rem;
  padding-bottom: 3rem;
  background-color: transparent
}

.category-banner:hover .btn {
  opacity: 1
}

.category-badge .category-content {
  padding: 1.4rem 4rem;
  left: 2rem;
  right: auto;
  bottom: 2rem;
  background-color: #fff
}

.category-badge .category-name {
  font-weight: 700;
  letter-spacing: .007em;
  color: #222
}

.category-overlay .category-content {
  -moz-box-align: center;
  align-items: center;
  width: 100%
}

.category-overlay .category-count {
  padding: 0;
  line-height: 0;
  opacity: 0;
  -webkit-transform: translateY(-1rem);
  -moz-transform: translateY(-1rem);
  transform: translateY(-1rem);
  -webkit-transition: opacity .3s, line-height .3s, padding .3s, -webkit-transform .3s;
  transition: opacity .3s, line-height .3s, padding .3s, -webkit-transform .3s;
  -moz-transition: transform .3s, opacity .3s, line-height .3s, padding .3s, -moz-transform .3s;
  transition: transform .3s, opacity .3s, line-height .3s, padding .3s;
  transition: transform .3s, opacity .3s, line-height .3s, padding .3s, -webkit-transform .3s, -moz-transform .3s
}

.category-overlay .category-name {
  margin: 0
}

.category-overlay:hover .category-content {
  padding: 0;
  top: 50%
}

.category-overlay:hover .category-count {
  padding-top: 1rem;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  transform: translateY(0);
  opacity: 1
}

.category-block {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  height: 10.7rem;
  padding: 1.5rem;
  color: #fff;
  background-color: #333;
  -webkit-transition: background-color .3s;
  -moz-transition: background-color .3s;
  transition: background-color .3s
}

.category-block .category-name {
  margin: 0;
  text-align: center;
  font-weight: 400;
  line-height: 1.7rem;
  text-transform: none
}

.category-block:hover {
  background-color: #222
}

.category-block:hover .category-name {
  color: #fff
}

.border-container .owl-stage-outer {
  padding: 1px;
  margin: -1px
}

@media(max-width: 479px) {
  .category-absolute .btn {
    line-height: 50px;
    padding: 0
  }
}

.comments {
  padding: 6rem 0;
  border-top: 1px solid #ebebeb
}

.comments ul ul {
  margin-left: 6rem;
  padding-top: 4.5rem
}

.comments li {
  margin-bottom: 4.5rem
}

.comments li:last-child {
  border-bottom-width: 0;
  margin-bottom: 0;
  padding-bottom: 0
}

@media(max-width: 575px) {
  .comments ul ul {
    margin-left: 3rem
  }
}

.comment {
  position: relative;
  display: -moz-box;
  display: flex;
  -moz-box-align: start;
  align-items: flex-start
}

.comment p:last-child {
  margin-bottom: 0
}

.comment-body {
  position: relative;
  -moz-box-flex: 1;
  flex: 1 1 auto;
  padding: .8rem 0 0 2.5rem
}

.comment-reply {
  color: #777;
  position: absolute;
  right: 0;
  top: 1.7rem;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1;
  letter-spacing: -.01em;
  padding-bottom: .5rem;
  -webkit-transition: color .3s, -webkit-box-shadow .3s;
  transition: color .3s, -webkit-box-shadow .3s;
  -moz-transition: color .3s, box-shadow .3s;
  transition: color .3s, box-shadow .3s;
  transition: color .3s, box-shadow .3s, -webkit-box-shadow .3s
}

.comment-reply:focus,
.comment-reply:hover {
  color: #222;
  -webkit-box-shadow: 0 2px 0 #222;
  box-shadow: 0 2px 0 #222
}

.comment-media {
  max-width: 6rem;
  -moz-box-flex: 0;
  flex: 0 0 6rem;
  height: 6rem;
  overflow: hidden
}

.comment-media img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover
}

.comment-user,
.comment-user h4 {
  margin-bottom: 1rem
}

.comment-user h4 {
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 1;
  letter-spacing: 0
}

.comment-user h4 a:focus,
.comment-user h4 a:hover {
  color: #222
}

.comment-user h4 span {
  display: block
}

.comment-date {
  display: block;
  margin-bottom: .5rem;
  font-size: 1.3rem;
  color: #999;
  line-height: 1
}

.reply .form-control {
  max-width: 100%;
  color: #999;
  border-color: #e1e1e1;
  background: #fff;
  border-radius: .3rem
}

.reply p {
  font-size: 1.3rem
}

.count-to {
  display: inline-block;
  margin-bottom: 1.6rem;
  font-size: 5rem;
  font-weight: 700;
  letter-spacing: -.064em;
  line-height: 1
}

.count-to:after {
  content: "+"
}

.count-title {
  margin-bottom: .7rem;
  font-size: 2rem
}

.element-section {
  padding: 10.5rem 0 13.5rem;
  background-color: #f3f6f9
}

.element-section p {
  font-size: 1.6rem;
  line-height: 1.4
}

.element-section .title {
  font-size: 4rem
}

.elements>div {
  margin-bottom: 3rem;
  -webkit-transition: -webkit-transform .3s;
  transition: -webkit-transform .3s;
  -moz-transition: transform .3s, -moz-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s, -moz-transform .3s
}

.elements>div>a {
  display: block
}

.elements>div .element-accordian .element-img {
  background-position: -moz-calc(-180px*(1 - 1)) 0;
  background-position: calc(-180px*(1 - 1)) 0
}

.elements>div .element-accordian .element-hover-img {
  background-position: -moz-calc(-180px*1 + 90px) 0;
  background-position: calc(-180px*1 + 90px) 0
}

.elements>div .element-banner .element-img {
  background-position: -moz-calc(-180px*(2 - 1)) 0;
  background-position: calc(-180px*(2 - 1)) 0
}

.elements>div .element-banner .element-hover-img {
  background-position: -moz-calc(-180px*2 + 90px) 0;
  background-position: calc(-180px*2 + 90px) 0
}

.elements>div .element-blog .element-img {
  background-position: -moz-calc(-180px*(3 - 1)) 0;
  background-position: calc(-180px*(3 - 1)) 0
}

.elements>div .element-blog .element-hover-img {
  background-position: -moz-calc(-180px*3 + 90px) 0;
  background-position: calc(-180px*3 + 90px) 0
}

.elements>div .element-button .element-img {
  background-position: -moz-calc(-180px*(4 - 1)) 0;
  background-position: calc(-180px*(4 - 1)) 0
}

.elements>div .element-button .element-hover-img {
  background-position: -moz-calc(-180px*4 + 90px) 0;
  background-position: calc(-180px*4 + 90px) 0
}

.elements>div .element-cta .element-img {
  background-position: -moz-calc(-180px*(5 - 1)) 0;
  background-position: calc(-180px*(5 - 1)) 0
}

.elements>div .element-cta .element-hover-img {
  background-position: -moz-calc(-180px*5 + 90px) 0;
  background-position: calc(-180px*5 + 90px) 0
}

.elements>div .element-icon-box .element-img {
  background-position: -moz-calc(-180px*(6 - 1)) 0;
  background-position: calc(-180px*(6 - 1)) 0
}

.elements>div .element-icon-box .element-hover-img {
  background-position: -moz-calc(-180px*6 + 90px) 0;
  background-position: calc(-180px*6 + 90px) 0
}

.elements>div .element-portfolio .element-img {
  background-position: -moz-calc(-180px*(7 - 1)) 0;
  background-position: calc(-180px*(7 - 1)) 0
}

.elements>div .element-portfolio .element-hover-img {
  background-position: -moz-calc(-180px*7 + 90px) 0;
  background-position: calc(-180px*7 + 90px) 0
}

.elements>div .element-category .element-img {
  background-position: -moz-calc(-180px*(8 - 1)) 0;
  background-position: calc(-180px*(8 - 1)) 0
}

.elements>div .element-category .element-hover-img {
  background-position: -moz-calc(-180px*8 + 90px) 0;
  background-position: calc(-180px*8 + 90px) 0
}

.elements>div .element-product .element-img {
  background-position: -moz-calc(-180px*(9 - 1)) 0;
  background-position: calc(-180px*(9 - 1)) 0
}

.elements>div .element-product .element-hover-img {
  background-position: -moz-calc(-180px*9 + 90px) 0;
  background-position: calc(-180px*9 + 90px) 0
}

.elements>div .element-tab .element-img {
  background-position: -moz-calc(-180px*(10 - 1)) 0;
  background-position: calc(-180px*(10 - 1)) 0
}

.elements>div .element-tab .element-hover-img {
  background-position: -moz-calc(-180px*10 + 90px) 0;
  background-position: calc(-180px*10 + 90px) 0
}

.elements>div .element-testimonial .element-img {
  background-position: -moz-calc(-180px*(11 - 1)) 0;
  background-position: calc(-180px*(11 - 1)) 0
}

.elements>div .element-testimonial .element-hover-img {
  background-position: -moz-calc(-180px*11 + 90px) 0;
  background-position: calc(-180px*11 + 90px) 0
}

.elements>div .element-title .element-img {
  background-position: -moz-calc(-180px*(12 - 1)) 0;
  background-position: calc(-180px*(12 - 1)) 0
}

.elements>div .element-title .element-hover-img {
  background-position: -moz-calc(-180px*12 + 90px) 0;
  background-position: calc(-180px*12 + 90px) 0
}

.elements>div .element-typography .element-img {
  background-position: -moz-calc(-180px*(13 - 1)) 0;
  background-position: calc(-180px*(13 - 1)) 0
}

.elements>div .element-typography .element-hover-img {
  background-position: -moz-calc(-180px*13 + 90px) 0;
  background-position: calc(-180px*13 + 90px) 0
}

.elements>div .element-video .element-img {
  background-position: -moz-calc(-180px*(14 - 1)) 0;
  background-position: calc(-180px*(14 - 1)) 0
}

.elements>div .element-video .element-hover-img {
  background-position: -moz-calc(-180px*14 + 90px) 0;
  background-position: calc(-180px*14 + 90px) 0
}

.elements>div .element-icon .element-img {
  background-position: -moz-calc(-180px*(15 - 1)) 0;
  background-position: calc(-180px*(15 - 1)) 0
}

.elements>div .element-icon .element-hover-img {
  background-position: -moz-calc(-180px*15 + 90px) 0;
  background-position: calc(-180px*15 + 90px) 0
}

.elements>div:hover {
  -webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  transform: scale(1.3);
  z-index: 1
}

.element {
  display: -moz-box;
  display: flex;
  position: relative;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  padding: 3.4rem 0 2.6rem;
  background-color: #fff;
  border-radius: 1rem;
  -webkit-box-shadow: 0 2rem 3rem rgba(0, 0, 0, .05), 0 0 5px rgba(0, 0, 0, .05);
  box-shadow: 0 2rem 3rem rgba(0, 0, 0, .05), 0 0 5px rgba(0, 0, 0, .05);
  -webkit-transition: -webkit-transform .3s;
  transition: -webkit-transform .3s;
  -moz-transition: transform .3s, -moz-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s, -moz-transform .3s
}

.element svg {
  fill: #666
}

.element.element-icon svg {
  margin-top: -1.1rem
}

.element .element-img {
  background-repeat: no-repeat
}

.element .element-hover-img,
.element .element-img {
  width: 90px;
  height: 70px;
  // background-image: url(/react/riode/demo-2/_next/static/media/elements.2b796e595926374d1bfe63c92e06e9e8.png);
  -webkit-transition: opacity .3s, visibility .3s;
  -moz-transition: opacity .3s, visibility .3s;
  transition: opacity .3s, visibility .3s
}

.element .element-hover-img {
  position: absolute;
  left: 50%;
  top: 1.2rem;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0
}

.element p {
  margin: 1.4rem 0 0;
  text-align: center;
  color: #222;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.2;
  text-transform: uppercase
}

.element.active .element-img,
.element:hover .element-img {
  opacity: 0;
  visibility: hidden
}

.element.active .element-hover-img,
.element:hover .element-hover-img {
  opacity: 1;
  visibility: visible
}

@media(max-width: 575px) {
  .element-section .element-type {
    max-width: 30rem;
    margin: 0 auto
  }

  .elements>div:hover {
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    transform: scale(1.05)
  }
}

.form .form-control {
  margin-bottom: 2rem
}

.form-control {
  display: block;
  width: 100%;
  min-height: 4.5rem;
  padding: .85rem 2rem;
  border: 1px solid #e3e3e3;
  font-size: 1.4rem;
  line-height: 1.5;
  font-weight: 400;
  color: #666;
  background-color: transparent;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0
}

.form-control:-ms-input-placeholder {
  color: inherit;
  -ms-transition: color .3s;
  transition: color .3s
}

.form-control::placeholder {
  color: inherit;
  -webkit-transition: color .3s;
  -moz-transition: color .3s;
  transition: color .3s
}

.form-control::-webkit-input-placeholder {
  color: inherit;
  -webkit-transition: color .3s;
  transition: color .3s
}

.form-control:focus:-ms-input-placeholder {
  color: transparent
}

.form-control:focus::placeholder {
  color: transparent
}

.form-control:focus::-webkit-input-placeholder {
  color: transparent
}

.form-control:focus::-moz-placeholder {
  color: transparent
}

.form-control.form-solid {
  background-color: #fff;
  border: 0;
  color: #8d8d8d
}

textarea {
  font-family: inherit;
  min-width: 100%;
  max-width: 100%
}

.custom-checkbox {
  position: absolute;
  opacity: 0
}

.custom-checkbox+.parse-content label,
.custom-checkbox+label {
  display: block;
  position: relative;
  padding-left: 2.4rem;
  cursor: pointer
}

.custom-checkbox+.parse-content label:before,
.custom-checkbox+label:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 18px;
  height: 18px;
  line-height: 17px;
  border: 1px solid #ccc;
  border-radius: 2px
}

.reply .custom-checkbox+.parse-content label:before,
.reply .custom-checkbox+label:before {
  top: 11px
}

@media(-ms-high-contrast:active), (-ms-high-contrast:none) {

  .custom-checkbox+.parse-content label:before,
  .custom-checkbox+label:before {
    line-height: 14px
  }
}

.custom-checkbox:checked+.parse-content label:before,
.custom-checkbox:checked+label:before {
  content: "";
  border-color: #222;
  background: #222;
  color: #fff;
  font-size: 9px;
  font-weight: 600;
  font-family: Font Awesome\ 5 Free;
  text-align: center
}

.select-box,
.select-menu {
  position: relative
}

.select-box select,
.select-menu select {
  position: relative;
  max-width: 14rem;
  min-height: auto;
  width: auto;
  height: 100%;
  padding-left: 9px;
  padding-right: 25px;
  border: 1px solid #d7d7d7;
  border-radius: 2px;
  color: inherit;
  background-color: transparent;
  font-size: 1.2rem;
  font-family: inherit;
  letter-spacing: inherit;
  z-index: 1;
  -moz-appearance: none;
  -webkit-appearance: none
}

.select-box select:focus,
.select-menu select:focus {
  outline: none
}

.select-box:before,
.select-menu:before {
  content: "";
  font-family: Font Awesome\ 5 Free;
  position: absolute;
  font-weight: 900;
  font-size: 9px;
  right: 8px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%)
}

.select-box option {
  font-size: 1.2rem
}

@media(-ms-high-contrast:active), (-ms-high-contrast:none) {
  select::-ms-expand {
    display: none
  }

  select:focus::-ms-value {
    background: transparent;
    color: currentColor
  }
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none
}

.quantity {
  -moz-appearance: textfield;
  max-width: 48px
}

.input-group {
  display: -moz-inline-box;
  display: inline-flex;
  width: 11rem;
  height: 4.5rem
}

.input-group .form-control {
  -moz-box-flex: 1;
  flex: 1 1;
  padding: 0;
  height: inherit;
  font-size: 1.4rem;
  border: solid #ccc;
  border-width: 1px 0;
  color: #222;
  text-align: center
}

.input-group button {
  padding: 0;
  width: 3.1rem;
  border: 1px solid #ccc;
  font-size: 1.2rem;
  color: #222;
  background-color: transparent;
  cursor: pointer;
  -webkit-appearance: none
}

.custom-radio {
  position: relative
}

.custom-radio input {
  display: none
}

input[type=radio]~label {
  padding-left: 2.5rem
}

input[type=radio]~label:after,
input[type=radio]~label:before {
  position: absolute;
  content: "";
  display: inline-block;
  border-radius: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%)
}

input[type=radio]~label:before {
  left: 0;
  width: 1.8rem;
  height: 1.8rem;
  background: #fff;
  border: 1px solid #cdcdcd
}

input[type=radio]~label:after {
  width: .8rem;
  height: .8rem;
  left: .5rem;
  background: #666;
  opacity: 0
}

input[type=radio]:checked~label:after {
  opacity: 1
}

.input-wrapper input.form-control {
  -moz-box-flex: 1;
  flex: 1 1;
  min-width: 40px;
  font-size: 1.2rem
}

.input-wrapper-inline {
  display: -moz-box;
  display: flex;
  position: relative;
  max-width: 61.3rem;
  width: 100%;
  height: 45px
}

.input-wrapper-inline .btn {
  line-height: 1
}

.input-wrapper-round .form-control {
  position: relative;
  border-radius: 2.4rem
}

.input-wrapper-round .btn {
  border-radius: 2.4rem;
  line-height: 1
}

.input-wrapper-round.input-wrapper-inline .form-control {
  border-radius: 2.4rem 0 0 2.4rem
}

.input-wrapper-round.input-wrapper-inline .btn {
  border-radius: 0 2.4rem 2.4rem 0
}

.form-wrapper.form-wrapper-inline {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: justify;
  justify-content: space-between
}

.form-wrapper.form-wrapper-inline form {
  -moz-box-flex: 1;
  flex: 1 1
}

@media(max-width: 991px) {
  .form-wrapper.form-wrapper-inline {
    display: block;
    text-align: center
  }

  .form-wrapper.form-wrapper-inline form {
    margin-left: auto;
    margin-right: auto
  }
}

.btn-absolute input.form-control {
  width: 100%;
  padding: 1rem 4rem 1rem 1.5rem;
  color: #666;
  font-size: 1.3rem
}

.btn-absolute .btn {
  position: absolute;
  width: auto;
  height: 100%;
  min-width: 4.3rem;
  right: 0;
  font-size: 1.7rem
}

.btn-absolute .btn:hover {
  color: #222
}

.btn-absolute .btn i {
  margin-left: 0
}

.category-section.masonry-section .grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 50%);
  grid-template-rows: repeat(auto-fill, 33.3333333333%);
  grid-auto-rows: auto
}

.category-section.masonry-section .grid .height-x2 {
  grid-column-end: span 1;
  grid-row-end: span 2
}

.category-section.badge-section .grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 50%);
  grid-template-rows: repeat(auto-fill, 50%);
  grid-auto-rows: auto
}

.category-section.badge-section .grid .height-x2 {
  grid-column-end: span 1;
  grid-row-end: span 2
}

.posts.grid:not(.masonry) {
  display: grid
}

.posts.grid:not(.masonry) .info-box {
  grid-column-end: span 3
}

.posts.grid:not(.masonry).grid-2cols {
  grid-template-columns: repeat(auto-fill, 50%)
}

.posts.grid:not(.masonry).grid-3cols {
  grid-template-columns: repeat(auto-fill, 33.3333333333%)
}

.grid.instagram-masonry,
.posts.grid:not(.masonry).grid-4cols {
  grid-template-columns: repeat(auto-fill, 25%)
}

.grid.instagram-masonry {
  display: grid;
  grid-template-rows: repeat(auto-fill, 12.5%);
  grid-auto-rows: auto
}

.grid.instagram-masonry .height-x2:first-child {
  grid-column-end: span 2;
  grid-row-end: span 4
}

.grid.instagram-masonry .height-x15 {
  grid-column-end: span 1;
  grid-row-end: span 3
}

.grid.instagram-masonry .height-x1:nth-child(3),
.grid.instagram-masonry .height-x1:nth-child(7),
.grid.instagram-masonry .height-x1:nth-child(8) {
  grid-column-end: span 1;
  grid-row-end: span 2
}

.grid.instagram-masonry .height-x2:nth-child(4),
.grid.instagram-masonry .height-x2:nth-child(6) {
  grid-column-end: span 1;
  grid-row-end: span 4
}

.grid.instagram-masonry .height-x25 {
  grid-column-end: span 1;
  grid-row-end: span 5
}

@media(max-width: 1199px) {
  .posts.grid:not(.masonry).grid-4cols {
    grid-template-columns: repeat(auto-fill, 33.3333333333%)
  }
}

@media(max-width: 991px) {

  .posts.grid:not(.masonry).grid-3cols,
  .posts.grid:not(.masonry).grid-4cols {
    grid-template-columns: repeat(auto-fill, 50%)
  }
}

@media(max-width: 767px) {
  .category-section.masonry-section .grid {
    grid-template-rows: repeat(auto-fill, 16.6666666667%)
  }

  .category-section.masonry-section .grid .height-x2 {
    grid-column-end: span 2;
    grid-row-end: span 2
  }

  .category-section.masonry-section .grid .height-x1 {
    grid-column-end: span 2
  }

  .category-section.badge-section .grid {
    grid-template-rows: repeat(auto-fill, 25%)
  }

  .category-section.badge-section .grid .height-x2 {
    grid-column-end: span 2;
    grid-row-end: span 2
  }

  .category-section.badge-section .grid .height-x1 {
    grid-column-end: span 2
  }

  .grid.instagram-masonry {
    grid-template-columns: repeat(auto-fill, 50%);
    grid-template-rows: repeat(auto-fill, 5.5555555556%);
    grid-auto-rows: auto
  }
}

@media(max-width: 575px) {

  .posts.grid:not(.masonry).grid-2cols,
  .posts.grid:not(.masonry).grid-3cols,
  .posts.grid:not(.masonry).grid-4cols {
    grid-template-columns: repeat(auto-fill, 100%)
  }
}

.icon-box .icon-box-title {
  margin: 0 0 .7rem;
  text-transform: uppercase;
  font-size: 1.5rem;
  letter-spacing: -.025em;
  line-height: 1.6
}

.icon-box p {
  margin: 0;
  font-size: 1.4rem;
  line-height: 1.72;
  letter-spacing: 0;
  color: #666
}

.icon-box i:before {
  margin: 0
}

.icon-box .icon-box-content {
  text-align: center
}

.icon-box-icon {
  display: inline-block;
  margin-bottom: 2.6rem;
  font-size: 3.4rem;
  font-weight: 600;
  line-height: 0;
  color: #222
}

.icon-box-icon svg {
  height: 3.4rem;
  width: 4.8rem
}

.icon-box-side {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center
}

.icon-box-side .icon-box-content {
  text-align: left
}

.icon-box-side .icon-box-icon {
  margin-right: 1.5rem;
  margin-bottom: 0
}

.icon-box-side .icon-box-title {
  margin: 0
}

.icon-border .icon-box-icon {
  display: -moz-inline-box;
  display: inline-flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  width: 5.2rem;
  height: 5.2rem;
  border-radius: 50%;
  border: 1px solid #222;
  font-size: 2.5rem
}

.icon-border svg {
  height: 2.4rem
}

.icon-inversed .icon-box-icon {
  display: -moz-inline-box;
  display: inline-flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-color: #222;
  color: #fff;
  font-size: 2.5rem
}

.icon-inversed svg {
  height: 2.4rem;
  fill: #fff
}

.icon-solid .icon-box-icon {
  font-size: 4.8rem;
  color: #222
}

.icon-solid svg {
  width: 5.4rem;
  height: 5.4rem;
  fill: #222
}

.icon-solid.icon-box-side {
  -moz-box-align: start;
  align-items: flex-start
}

.icon-solid.icon-box-side svg {
  width: 4.8rem;
  height: 4.8rem
}

.icon-box-tiny .icon-box-icon {
  margin: 0 1rem 0 0;
  font-size: 2.8rem
}

.icon-box-tiny svg {
  height: 2.8rem;
  width: 2.8rem
}

.icon-box-tiny .icon-box-title {
  margin-bottom: 0;
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center
}

@media(max-width: 991px) {
  .icon-box-side {
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column
  }

  .icon-box-side .icon-box-icon {
    display: inline-block;
    margin: 0 0 2rem;
    padding: 2rem
  }

  .icon-box-side .icon-box-content {
    text-align: center
  }
}

.menu-icon {
  display: inline-block;
  color: #fff;
  width: 1.7rem
}

.menu-icon:after,
.menu-icon:before {
  display: block;
  content: "";
  width: 100%
}

.menu-icon:before {
  height: 6px;
  border-top: 2px solid;
  border-bottom: 2px solid
}

.menu-icon:after {
  height: 4px;
  border-bottom: 2px solid
}

.minicart-icon {
  display: inline-block;
  position: relative;
  width: 2.5em;
  height: 2.5em;
  margin-top: 1px;
  border: 2px solid;
  border-radius: 2px;
  text-align: center;
  -webkit-transition: background .4s, border-color .4s;
  -moz-transition: background .4s, border-color .4s;
  transition: background .4s, border-color .4s
}

.minicart-icon:before {
  content: "";
  position: absolute;
  top: -.75em;
  left: .585em;
  width: 1em;
  height: .5em;
  border-radius: 10px 10px 0 0;
  border: inherit;
  border-bottom: none;
  -webkit-transition: -webkit-transform .4s;
  transition: -webkit-transform .4s;
  -moz-transition: transform .4s, -moz-transform .4s;
  transition: transform .4s;
  transition: transform .4s, -webkit-transform .4s, -moz-transform .4s
}

.shop-input-range.noUi-target {
  margin: 4px 16px 1.6rem 9px
}

.shop-input-range.noUi-target .input-range {
  height: 0
}

.shop-input-range.noUi-target .input-range__track--active {
  margin-top: 1px;
  background-color: #999
}

.shop-input-range.noUi-target .input-range__slider {
  top: -.3rem;
  width: 1.2rem;
  height: 1.7rem;
  background: #444;
  border-radius: 3px
}

.shop-input-range.noUi-target .input-range__label-container {
  display: none
}

.instagram {
  position: relative;
  overflow: hidden;
  border-radius: .3rem
}

.instagram a {
  display: block;
  position: relative;
  height: 100%
}

.instagram a:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #000;
  opacity: 0;
  -webkit-transition: opacity .3s;
  -moz-transition: opacity .3s;
  transition: opacity .3s;
  z-index: 1
}

.instagram a:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(90deg) scale(2);
  -moz-transform: translate(-50%, -50%) rotate(90deg) scale(2);
  transform: translate(-50%, -50%) rotate(90deg) scale(2);
  font-size: 3rem;
  font-family: riode;
  color: #fff;
  opacity: 0;
  -webkit-transition: opacity .3s, -webkit-transform .3s;
  transition: opacity .3s, -webkit-transform .3s;
  -moz-transition: opacity .3s, transform .3s, -moz-transform .3s;
  transition: opacity .3s, transform .3s;
  transition: opacity .3s, transform .3s, -webkit-transform .3s, -moz-transform .3s;
  z-index: 1
}

.instagram img {
  display: block;
  -webkit-transition: -webkit-transform .3s;
  transition: -webkit-transform .3s;
  -moz-transition: transform .3s, -moz-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s, -moz-transform .3s;
  width: 100%;
  height: auto
}

.instagram:hover a:before {
  opacity: .5
}

.instagram:hover a:after {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%) rotate(0deg) scale(1);
  -moz-transform: translate(-50%, -50%) rotate(0deg) scale(1);
  transform: translate(-50%, -50%) rotate(0deg) scale(1)
}

.instagram:hover img {
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  transform: scale(1.15)
}

.instagram-info .instagram-content {
  position: absolute;
  left: 3rem;
  bottom: 3rem;
  font-size: 1.4rem;
  letter-spacing: 0;
  color: #fff;
  opacity: 0;
  z-index: 2;
  -webkit-transition: opacity .3s;
  -moz-transition: opacity .3s;
  transition: opacity .3s
}

.instagram-info .instagram-content i {
  display: inline-block;
  margin-right: .8rem;
  vertical-align: middle;
  font-size: 1.6rem
}

.instagram-info .instagram-content .d-icon-comments:before {
  font-size: 1em
}

.instagram-info .instagram-content a {
  display: inline-block;
  color: #fff
}

.instagram-info .instagram-content a:not(:last-child) {
  margin-right: 2rem
}

.instagram-info .instagram-content a:before,
.instagram-info a:after {
  content: none
}

.instagram-info:hover .instagram-content {
  opacity: 1
}

@media(max-width: 359px) {
  .instagram-info .instagram-content {
    left: 1rem;
    bottom: 1rem
  }
}

.lazy-load-image-background {
  display: block !important
}

.lazy-load-image-background.lazy-load-image-loaded {
  display: inline !important
}

.lazy-load-image-background.opacity:not(.lazy-load-image-loaded) {
  background-color: #f4f4f4
}

.lazy-load-image-background.opacity:not(.lazy-load-image-loaded) img {
  opacity: 0
}

.lazy-load-image-background.opacity.lazy-load-image-loaded img {
  opacity: 1;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all .3s
}

.member {
  text-align: center;
  margin-bottom: 2rem
}

.member img {
  display: block;
  width: 100%
}

.member figure {
  position: relative;
  margin-bottom: 1.5rem
}

.member-job,
.member-name {
  text-transform: uppercase;
  font-size: 1.4rem;
  margin-bottom: 0
}

.member-job {
  font-size: 1.2rem;
  font-weight: 400;
  color: inherit;
  text-transform: none
}

.minipopup-area {
  left: 30px;
  bottom: 30px;
  z-index: 9999
}

.minipopup-box {
  left: -8rem;
  width: 303px;
  padding: 2rem;
  background-color: #fff;
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, .1);
  box-shadow: 0 5px 20px rgba(0, 0, 0, .1);
  opacity: 0;
  -webkit-transition: opacity .3s, left .3s ease-out, -webkit-transform .3s;
  transition: opacity .3s, left .3s ease-out, -webkit-transform .3s;
  -moz-transition: opacity .3s, left .3s ease-out, transform .3s, -moz-transform .3s;
  transition: opacity .3s, left .3s ease-out, transform .3s;
  transition: opacity .3s, left .3s ease-out, transform .3s, -webkit-transform .3s, -moz-transform .3s;
  z-index: 9999
}

.minipopup-box.show {
  opacity: 1;
  left: 0
}

.minipopup-box.focus {
  -webkit-transform: scale(.98) translateY(-100%);
  -moz-transform: scale(.98) translateY(-100%);
  transform: scale(.98) translateY(-100%)
}

.minipopup-box .product.product-cart .product-name {
  display: block;
  margin-bottom: 3px
}

.minipopup-box .product.product-cart {
  margin-top: 0
}

.minipopup-box .product.product-cart .product-media {
  margin-right: 1.7rem;
  height: 9rem
}

.minipopup-box .product.product-cart .product-detail {
  margin-top: .3rem
}

.minipopup-box .product.product-cart .product-name {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
  font-size: 1.4rem;
  margin-bottom: 1.1rem;
  color: #000;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: -.025em
}

.minipopup-box .product.product-cart .product-name:hover {
  color: #222
}

.minipopup-box .product.product-cart .product-price {
  margin-bottom: -.2rem;
  font-size: 1.3rem
}

.minipopup-box .product.product-cart .product-quantity {
  margin-right: .7rem;
  font-size: 1.4rem;
  font-weight: 600;
  color: #222
}

.minipopup-box .product.product-cart .product-quantity:after {
  margin: 0 0 -.2rem .7rem;
  font-size: 1.2rem
}

.minipopup-box .product.product-cart.product-purchased .purchased-title {
  margin-bottom: 1.9rem
}

.minipopup-box .product.product-cart.product-purchased .product-detail {
  margin-top: .1rem
}

.minipopup-box .product.product-cart.product-purchased .product-name {
  margin-bottom: 1.2rem
}

.minipopup-box img {
  max-width: 9rem;
  max-height: 9rem
}

.minipopup-box .ratings-container {
  margin-bottom: 0
}

.minipopup-box .product-price {
  color: #222;
  margin-bottom: 3px
}

.minipopup-box .action-group {
  margin-top: 2rem
}

.minipopup-box .btn.btn-sm {
  padding: .92em 2.07em;
  font-weight: 600;
  letter-spacing: -.025em;
  border-width: 1px
}

.minipopup-box .btn.btn-outline {
  margin-right: .7rem;
  padding: .92em 2.2em
}

.minipopup-title {
  margin-bottom: 1.5rem;
  color: #222;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  letter-spacing: -.035em;
  line-height: 1
}

.minipopup-title .btn {
  margin-left: 1rem
}

.purchased-time {
  color: #222;
  font-size: 1.2rem
}

@media(max-width: 479px) {
  .minipopup-area {
    left: 9px
  }
}

.overlay,
.overlay-visible {
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  -moz-box-pack: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  -webkit-transition: padding .3s ease-out, background-color .3s, opacity .3s;
  -moz-transition: padding .3s ease-out, background-color .3s, opacity .3s;
  transition: padding .3s ease-out, background-color .3s, opacity .3s
}

.overlay {
  padding-top: 10rem;
  background: rgba(0, 0, 0, .3);
  opacity: 0
}

a>.overlay {
  cursor: pointer
}

figure:hover .overlay {
  padding-top: 0;
  opacity: 1
}

.overlay.social-links {
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  flex-direction: row
}

.overlay .social-links {
  -moz-box-pack: center;
  justify-content: center
}

.overlay a:hover {
  color: #fff;
  text-decoration: underline
}

.overlay a.social-link {
  border-color: #fff
}

.overlay .social-link:hover {
  color: #fff;
  text-decoration: none
}

figure:hover .overlay-visible {
  background: rgba(34, 34, 34, .9);
  padding-bottom: 9rem
}

.overlay-transparent {
  background: transparent
}

.overlay-dark .banner,
.overlay-dark figure,
.overlay-light .banner,
.overlay-light figure {
  position: relative
}

.overlay-dark.banner:after,
.overlay-dark figure:after,
.overlay-dark figure>a:after,
.overlay-light.banner:after,
.overlay-light figure:after,
.overlay-light figure>a:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #222;
  opacity: 0;
  -webkit-transition: opacity .3s, background .3s, -webkit-transform .3s;
  transition: opacity .3s, background .3s, -webkit-transform .3s;
  -moz-transition: opacity .3s, background .3s, transform .3s, -moz-transform .3s;
  transition: opacity .3s, background .3s, transform .3s;
  transition: opacity .3s, background .3s, transform .3s, -webkit-transform .3s, -moz-transform .3s
}

.overlay-dark.banner:hover:after,
.overlay-dark:hover figure:after,
.overlay-dark:hover figure>a:after,
.overlay-light.banner:hover:after,
.overlay-light:hover figure:after,
.overlay-light:hover figure>a:after {
  opacity: .1
}

.overlay-dark.banner-fixed:after,
.overlay-dark.post>figure:after,
.overlay-light.banner-fixed:after,
.overlay-light.post>figure:after {
  content: none
}

.overlay-dark.post>figure>a:after,
.overlay-light.post>figure>a:after {
  z-index: 1
}

.banner.overlay-light:after,
.overlay-light figure:after {
  background: #ccc
}

.overlay-zoom {
  overflow: hidden
}

.overlay-zoom img {
  -webkit-transition: -webkit-transform .3s;
  transition: -webkit-transform .3s;
  -moz-transition: transform .3s, -moz-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s, -moz-transform .3s
}

.overlay-zoom:hover img {
  -webkit-transform: scale(1.08);
  -moz-transform: scale(1.08);
  transform: scale(1.08)
}

.overlay-effect-one:after,
.overlay-effect-one:before,
.overlay-effect-one figure:after,
.overlay-effect-one figure:before {
  content: "";
  position: absolute;
  opacity: 1
}

.overlay-effect-one:before,
.overlay-effect-one figure:before {
  background: hsla(0, 0%, 100%, .3);
  bottom: 0;
  left: 50%;
  right: 51%;
  top: 0
}

.overlay-effect-one:after,
.overlay-effect-one figure:after {
  bottom: 50%;
  left: 0;
  right: 0;
  top: 50%
}

.overlay-effect-one:hover:after,
.overlay-effect-one:hover:before,
.overlay-effect-one:hover figure:after,
.overlay-effect-one:hover figure:before {
  left: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: .5s linear;
  -moz-transition: .5s linear;
  transition: .5s linear
}

.overlay-effect-three,
.overlay-effect-three figure,
.overlay-effect-two,
.overlay-effect-two figure {
  overflow: hidden;
  position: relative
}

.overlay-effect-two:after,
.overlay-effect-two:before,
.overlay-effect-two figure:after,
.overlay-effect-two figure:before {
  content: "";
  position: absolute;
  background: hsla(0, 0%, 100%, .6);
  height: 100%;
  left: 0;
  opacity: .4;
  top: 0;
  -webkit-transition: .9s;
  -moz-transition: .9s;
  transition: .9s;
  width: 100%
}

.overlay-effect-two:before,
.overlay-effect-two figure:before {
  -webkit-transform: translate(105%);
  -moz-transform: translate(105%);
  transform: translate(105%)
}

.overlay-effect-two:after,
.overlay-effect-two figure:after {
  -webkit-transform: translate(-105%);
  -moz-transform: translate(-105%);
  transform: translate(-105%)
}

.overlay-effect-two:hover:after,
.overlay-effect-two:hover:before,
.overlay-effect-two:hover figure:after,
.overlay-effect-two:hover figure:before {
  opacity: 0;
  -webkit-transform: none;
  -moz-transform: none;
  transform: none
}

.overlay-effect-three:before,
.overlay-effect-three figure:before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 120%;
  height: 60px;
  background: hsla(0, 0%, 100%, .1);
  -webkit-transform: scale3d(1.9, 1.4, 1) rotate(45deg) translate3d(0, -150%, 0);
  -moz-transform: scale3d(1.9, 1.4, 1) rotate(45deg) translate3d(0, -150%, 0);
  transform: scale3d(1.9, 1.4, 1) rotate(45deg) translate3d(0, -150%, 0);
  -webkit-transition: .5s linear;
  -moz-transition: .5s linear;
  transition: .5s linear;
  backface-visibility: hidden
}

.overlay-effect-three:hover:before,
.overlay-effect-three:hover figure:before {
  top: 100%;
  left: -100%;
  -webkit-transform: scale3d(1.9, 1.4, 1) rotate(45deg) translate3d(0, 200%, 0);
  -moz-transform: scale3d(1.9, 1.4, 1) rotate(45deg) translate3d(0, 200%, 0);
  transform: scale3d(1.9, 1.4, 1) rotate(45deg) translate3d(0, 200%, 0)
}

.overlay-effect-four:after,
.overlay-effect-four:before,
.overlay-effect-four figure:after,
.overlay-effect-four figure:before {
  content: "";
  position: absolute;
  background: hsla(0, 0%, 100%, .1);
  opacity: 1;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s
}

.overlay-effect-four:before,
.overlay-effect-four figure:before {
  bottom: 0;
  left: 50%;
  right: 51%;
  top: 0
}

.overlay-effect-four:after,
.overlay-effect-four figure:after {
  bottom: 50%;
  left: 0;
  right: 0;
  top: 50%
}

.overlay-effect-four:hover:after,
.overlay-effect-four:hover:before,
.overlay-effect-four:hover figure:after,
.overlay-effect-four:hover figure:before {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: .5
}

.page-subtitle {
  margin-bottom: .5rem;
  text-transform: uppercase;
  font-size: 2rem;
  line-height: 1.2
}

.page-title {
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 4rem;
  line-height: 1.125;
  color: #222
}

.page-title+.breadcrumb {
  padding: 1em 0
}

.breadcrumb-nav .breadcrumb {
  padding: 1.4rem 0
}

.breadcrumb-nav .breadcrumb li:not(:last-child):after {
  margin-left: .8rem;
  opacity: .5
}

.breadcrumb {
  display: -moz-box;
  display: flex;
  flex-wrap: wrap;
  -moz-box-align: center;
  align-items: center;
  color: #666;
  font-family: Poppins, sans-serif;
  font-size: 1.4rem
}

.breadcrumb a {
  color: inherit
}

.breadcrumb a:hover {
  color: #222
}

.breadcrumb li:not(:last-child) a {
  color: #999;
  -webkit-transition: color .3s;
  -moz-transition: color .3s;
  transition: color .3s
}

.breadcrumb li:hover:not(:last-child) a {
  color: #222
}

.breadcrumb .delimiter {
  margin: 0 .7em;
  font-size: 1.19rem;
  opacity: .5
}

.breadcrumb li:not(:last-child):after {
  content: "";
  position: relative;
  margin: 0 .6em;
  font-size: 1.2rem;
  font-weight: 600;
  font-family: Font Awesome\ 5 Free;
  color: #999
}

.breadcrumb li:last-child {
  color: #222
}

.breadcrumb-sm {
  padding: 1.1rem 0;
  color: inherit
}

.breadcrumb-sm li:last-child {
  color: inherit
}

.page-header {
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  -moz-box-align: center;
  align-items: center;
  height: 250px;
  background-color: #c8c3bf;
  background-position: 50%;
  background-size: cover;
  text-align: center;
  color: #222
}

.page-header,
.page-header .breadcrumb {
  -moz-box-pack: center;
  justify-content: center
}

.page-header .breadcrumb li:not(:last-child):after {
  content: none
}

.page-header>.breadcrumb li,
.page-header>.breadcrumb li a,
.page-header>.page-subtitle,
.page-header>.page-title {
  color: #fff
}

.page-header>.breadcrumb li:not(:last-child) a {
  opacity: .5;
  -webkit-transition: opacity .3s;
  -moz-transition: opacity .3s;
  transition: opacity .3s
}

.page-header>.breadcrumb li:hover a {
  color: #fff;
  opacity: 1
}

@media(max-width: 767px) {
  .page-title {
    font-size: 3.6rem
  }
}

.pagination {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  flex-wrap: wrap;
  font-size: 1.4rem;
  letter-spacing: .01em;
  -moz-box-pack: center;
  justify-content: center;
  color: #222
}

.page-link-next i,
.page-link-prev i {
  font-size: 1.9rem
}

.page-link-prev i {
  margin-right: .5rem
}

.page-link-next i {
  margin-left: .5rem
}

.page-link {
  display: -moz-inline-box;
  display: inline-flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  min-width: 3rem;
  height: 4rem;
  padding: 0 .3rem;
  line-height: 1;
  background-color: transparent;
  border: .1rem solid transparent;
  -webkit-transition: border .3s;
  -moz-transition: border .3s;
  transition: border .3s
}

.active>.page-link,
:hover:not(.disabled)>.page-link {
  border-color: #dadada;
  color: #222
}

.disabled>.page-link {
  color: #aaa
}

.page-link-next,
.page-link-prev {
  min-width: 8rem;
  padding: 1.2rem
}

.page-item:not(:last-child) {
  margin-right: .5rem
}

.page-item-dots {
  white-space: nowrap
}

.page-item-dots:before {
  content: "...";
  margin-top: -.9em;
  margin-right: .5rem;
  display: inline-block;
  vertical-align: middle
}

.show-info {
  margin: 0;
  color: #222
}

.show-info span {
  margin: 0 .5rem;
  color: #222
}

.page-item-total {
  margin-right: .5rem
}

@media(min-width: 576px) {

  .page-item-dots,
  .page-item-total {
    margin-right: 2rem
  }
}

.mfp-bg,
.mfp-wrap {
  z-index: 3000
}

.mfp-bg {
  background: #000;
  opacity: 0;
  -webkit-transition: opacity .3s ease-out;
  -moz-transition: opacity .3s ease-out;
  transition: opacity .3s ease-out
}

.mfp-bg.mfp-ready {
  opacity: .7
}

.mfp-bg.mfp-removing {
  opacity: 0
}

.mfp-container {
  padding: 4rem 2rem
}

.mfp-container video {
  width: 100%;
  height: 100%
}

.mfp-wrap .btn-close {
  top: 20px;
  right: 20px
}

.mfp-content .btn-close {
  top: -25px;
  right: 0
}

.mfp-iframe-holder .btn-close,
.mfp-image-holder .btn-close {
  top: 15px;
  right: 0
}

.mfp-arrow,
.pswp__button--arrow--left,
.pswp__button--arrow--right {
  width: 4.8rem;
  height: 4.8rem;
  color: #fff;
  border-radius: 50%;
  font-size: 2.4rem;
  font-weight: 900;
  font-family: riode;
  text-align: center;
  opacity: .5;
  right: 10%;
  -webkit-transition: opacity .3s;
  -moz-transition: opacity .3s;
  transition: opacity .3s;
  border: 2px solid
}

.pswp__ui>button:before {
  background: transparent;
  position: static;
  line-height: 44px
}

.pswp__ui>button.pswp__button--arrow--left:before {
  margin-right: 3px
}

.pswp__ui>button.pswp__button--arrow--right:before {
  margin-left: 3px
}

.pswp__ui>button:hover {
  opacity: 1
}

.pswp__button--arrow--right:before {
  content: "";
  font-family: riode
}

.mfp-arrow:after,
.mfp-arrow:before {
  content: "";
  position: static;
  display: inline;
  margin: 0;
  border: 0
}

.mfp-arrow:after {
  content: none
}

button.mfp-arrow {
  border: 2px solid
}

button.mfp-close {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  z-index: 1046;
  -webkit-box-shadow: none;
  box-shadow: none;
  touch-action: manipulation;
  position: absolute;
  top: 0;
  right: 0;
  text-decoration: none;
  text-align: center;
  padding: 0 0 18px 10px;
  color: #fff;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: -webkit-transform .3s;
  transition: -webkit-transform .3s;
  -moz-transition: transform .3s, -moz-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s, -moz-transform .3s;
  width: 18px;
  height: 18px;
  opacity: 1
}

button.mfp-close:focus,
button.mfp-close:hover {
  -webkit-transform: rotate(135deg) scale(1.5);
  -moz-transform: rotate(135deg) scale(1.5);
  transform: rotate(135deg) scale(1.5)
}

button.mfp-close span {
  display: none
}

.login-popup button.mfp-close,
.newsletter-popup button.mfp-close,
.quickview-modal button.mfp-close,
.video-modal button.mfp-close {
  top: -25px;
  right: 0
}

button.mfp-close:before {
  width: 100%;
  height: 2px;
  top: -moz-calc(50% - 1px);
  top: calc(50% - 1px);
  left: 0
}

button.mfp-close:after,
button.mfp-close:before {
  content: "";
  display: block;
  position: absolute;
  background: #fff
}

button.mfp-close:after {
  width: 2px;
  height: 100%;
  top: 0;
  left: -moz-calc(50% - 1px);
  left: calc(50% - 1px)
}

.mfp-arrow-left,
.pswp__button--arrow--left {
  left: 10%
}

.mfp-arrow-left:before,
.pswp__button--arrow--left:before {
  content: "";
  font-family: riode
}

.mfp-content>* {
  position: relative;
  margin: auto;
  background: #fff;
  -webkit-box-shadow: 5px 5px 20px rgba(0, 0, 0, .1);
  box-shadow: 5px 5px 20px rgba(0, 0, 0, .1);
  opacity: 0
}

.mfp-ready .mfp-content>* {
  opacity: 1
}

.mfp-removing .mfp-content>* {
  opacity: 0
}

.ReactModal__Content {
  position: relative;
  margin: auto;
  background: #fff;
  -webkit-box-shadow: 5px 5px 20px rgba(0, 0, 0, .1);
  box-shadow: 5px 5px 20px rgba(0, 0, 0, .1)
}

.ReactModal__Overlay {
  -webkit-transition: opacity .4s;
  -moz-transition: opacity .4s;
  transition: opacity .4s;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0
}

.ReactModal__Overlay.newsletter-modal-overlay {
  z-index: 10000 !important
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  opacity: 1
}

.ReactModal__Overlay.opened {
  opacity: 1 !important
}

.ReactModal__Content {
  -webkit-transition: .3s;
  -moz-transition: .3s;
  transition: .3s;
  -webkit-transition-delay: .03s;
  -moz-transition-delay: .03s;
  transition-delay: .03s
}

.ReactModal__Content.newsletter-popup {
  -webkit-transform: translateY(-20px) perspective(600px) rotateX(20deg);
  -moz-transform: translateY(-20px) perspective(600px) rotateX(20deg);
  transform: translateY(-20px) perspective(600px) rotateX(20deg)
}

.ReactModal__Content.newsletter-popup.ReactModal__Content--after-open {
  -webkit-transform: translateY(0) perspective(600px) rotateX(0);
  -moz-transform: translateY(0) perspective(600px) rotateX(0);
  transform: translateY(0) perspective(600px) rotateX(0)
}

.ReactModal__Content.login-popup,
.ReactModal__Content.quickview-modal,
.ReactModal__Content.video-modal {
  opacity: 0;
  -webkit-transform: translateY(30px);
  -moz-transform: translateY(30px);
  transform: translateY(30px)
}

.ReactModal__Content.login-popup.ReactModal__Content--after-open,
.ReactModal__Content.quickview-modal.ReactModal__Content--after-open,
.ReactModal__Content.video-modal.ReactModal__Content--after-open {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  transform: translateY(0);
  opacity: 1
}

.ReactModal__Content.quickview-modal.removed,
.ReactModal__Content.video-modal.removed {
  -webkit-transform: translateY(30px);
  -moz-transform: translateY(30px);
  transform: translateY(30px);
  opacity: 0
}

.ReactModal__Content.video-modal {
  background: transparent
}

.ReactModal__Overlay.removed {
  opacity: 0;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  transition: .3s
}

.login-popup {
  max-width: 490px;
  padding: 4rem;
  border-radius: .3rem;
  width: 100%
}

.login-popup .tab {
  font-size: 1.8rem;
  color: #ccc
}

.login-popup .tab .nav-item {
  -moz-box-flex: 0;
  flex: none
}

.login-popup .nav-item .nav-link {
  padding: 0 0 1px;
  color: #999;
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: -.025em;
  line-height: 2.43;
  -webkit-transition: font-size .3s, color .3s;
  -moz-transition: font-size .3s, color .3s;
  transition: font-size .3s, color .3s
}

.login-popup .nav-item .nav-link.active {
  font-size: 3rem
}

.login-popup .nav-item.active .nav-link {
  font-size: 3rem;
  color: #222
}

.login-popup .delimiter {
  margin: 0 .8rem
}

.login-popup .tab-pane {
  padding: 0
}

.login-popup form {
  margin-bottom: 2.2rem
}

.login-popup .form-group {
  margin-bottom: 1.8rem
}

.login-popup .form-group label {
  display: inline-block;
  margin-bottom: 8px;
  font-size: 1.3rem;
  line-height: 1
}

.login-popup .form-group .form-control {
  padding: 1.2rem 1.5rem 1.1rem;
  height: 3.7rem;
  font-size: 1.3rem;
  color: #999;
  border-radius: .3rem;
  border-color: #e1e1e1
}

.login-popup .form-footer {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: justify;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 2.3rem;
  font-size: 1.2rem
}

.login-popup .custom-checkbox+label {
  padding-left: 2.6rem
}

.login-popup .btn {
  height: 4.7rem;
  padding: 0;
  font-size: 1.3rem
}

.login-popup .lost-link {
  text-align: right;
  color: #999;
  font-size: 1.2rem
}

.login-popup .lost-link:hover {
  color: #222
}

.login-popup .form-choice label {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  margin-bottom: 1.3rem;
  font-size: 1.2rem;
  color: #666
}

.login-popup .form-choice label:after,
.login-popup .form-choice label:before {
  content: "";
  height: 1px;
  -moz-box-flex: 1;
  flex: 1 1;
  background: #e1e1e1
}

.login-popup .form-choice label:after {
  margin-left: 3rem
}

.login-popup .form-choice label:before {
  margin-right: 3rem
}

.login-popup .social-link {
  display: -moz-box;
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
  -moz-box-align: center;
  align-items: center;
  width: 3.6rem;
  height: 3.6rem;
  font-size: 1.4rem;
  border-radius: .3rem;
  color: #fff
}

.login-popup .social-link.social-google {
  background-color: #db402c
}

.login-popup .social-link.social-facebook {
  background-color: #3b5998
}

.login-popup .social-link.social-twitter {
  background-color: #1ab2e8
}

.container>.login-popup {
  margin: 0 auto;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  box-shadow: 0 0 10px rgba(0, 0, 0, .1)
}

.newsletter-popup {
  display: -moz-box;
  display: flex;
  max-width: 72rem;
  text-align: center;
  background-position: 60%;
  background-size: cover;
  border-radius: 1rem;
  display: inline-block;
  width: 100%
}

.newsletter-popup h4 {
  margin-bottom: .6rem;
  font-size: 2rem;
  font-weight: 800;
  line-height: 1.2
}

.newsletter-popup h2 {
  margin-bottom: .7rem;
  font-size: 3.2rem;
  line-height: 1.2;
  letter-spacing: -.04375em
}

.newsletter-popup h2 span {
  font-weight: 800
}

.newsletter-popup b {
  display: block;
  margin-top: .3rem;
  color: #222;
  font-size: 3rem;
  letter-spacing: .025em
}

.newsletter-popup .btn {
  min-width: auto;
  padding: .5em 1.5em;
  font-size: 1.3rem
}

.newsletter-popup p {
  max-width: 100%;
  margin: 0 .2rem 2.5rem;
  font-size: 1.4rem;
  line-height: 1.7
}

.newsletter-popup label {
  display: inline;
  font-size: 1.2rem
}

.newsletter-popup label:before {
  border-color: #666
}

.newsletter-popup .email {
  border: 0;
  color: #999;
  background: #f4f4f4
}

.newsletter-popup .form-control {
  background: #fff;
  border: 1px solid #222;
  border-right: none
}

.newsletter-popup .input-wrapper-inline {
  margin-bottom: 2.6rem;
  height: 4.6rem
}

.newsletter-popup .input-wrapper-inline .form-control {
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  border-radius: .3rem 0 0 .3rem
}

.newsletter-popup .input-wrapper-inline .btn {
  border-radius: 0 .3rem .3rem 0
}

.newsletter-popup .custom-checkbox+label {
  padding-left: 2.6rem
}

.newsletter-content {
  margin-left: auto;
  max-width: 41.4rem;
  padding: 7.1rem 4.2rem 5rem
}

.quickview-modal.product.product-single .mfp-container {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  padding: 4rem 2rem
}

.quickview-modal.product.product-single .mfp-content {
  max-width: 98.8rem;
  margin: auto
}

.quickview-modal.product.product-single .product {
  background-color: #fff;
  margin-bottom: 2rem;
  padding: 3rem 1.5rem;
  border-radius: 1rem
}

.quickview-modal.product.product-single .product-details {
  padding: 0
}

.quickview-modal.product.product-single .btn-close {
  position: absolute;
  top: -2.7rem;
  right: 0;
  background: transparent;
  border: none
}

.quickview-modal.product.product-single .owl-nav .owl-next,
.quickview-modal.product.product-single .owl-nav .owl-prev {
  width: 3.6rem;
  height: 3.6rem;
  font-size: 3rem
}

.quickview-modal.product.product-single .owl-nav .owl-next {
  right: 1rem
}

.quickview-modal.product.product-single .owl-nav .owl-prev {
  left: 1rem
}

.pswp__bg {
  background-color: rgba(0, 0, 0, .7)
}

.pswp__img--placeholder--blank {
  background-color: #f2f3f5
}

.pswp__ui--fit .pswp__caption,
.pswp__ui--fit .pswp__top-bar {
  background-color: transparent
}

.pswp__caption__center {
  text-align: center
}

.mfp-ready.mfp-zoom-popup .mfp-content {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1)
}

.mfp-removing.mfp-zoom-popup .mfp-content,
.mfp-zoom-popup .mfp-content {
  -webkit-transition: .2s ease-in-out;
  -moz-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
  -webkit-transform: scale(.8);
  -moz-transform: scale(.8);
  transform: scale(.8)
}

.mfp-ready.mfp-flip-popup .mfp-content {
  -webkit-transform: translateY(0) perspective(600px) rotateX(0);
  -moz-transform: translateY(0) perspective(600px) rotateX(0);
  transform: translateY(0) perspective(600px) rotateX(0)
}

.mfp-flip-popup .mfp-content,
.mfp-removing.mfp-flip-popup .mfp-content {
  -webkit-transition: .3s ease-out;
  -moz-transition: .3s ease-out;
  transition: .3s ease-out;
  -webkit-transform: translateY(-20px) perspective(600px) rotateX(20deg);
  -moz-transform: translateY(-20px) perspective(600px) rotateX(20deg);
  transform: translateY(-20px) perspective(600px) rotateX(20deg)
}

.mfp-content video {
  width: 100%;
  height: 100%
}

.video-modal {
  padding: 0;
  border-radius: .3rem;
  width: 100%
}

.mfp-video-popup .mfp-content,
.video-modal {
  max-width: 1000px
}

.mfp-video-popup {
  z-index: 10000
}

.ReactModal__Overlay {
  z-index: 2000 !important
}

.ril__image,
.ril__imageNext,
.ril__imagePrev {
  -webkit-transition: .3s;
  -moz-transition: .3s;
  transition: .3s
}

@media(max-width: 767px) {
  .quickview-modal .product {
    padding: 2rem 1rem
  }

  .mfp-arrow {
    color: #444
  }

  .quickview-modal .product-details {
    position: relative
  }
}

@media(min-width: 768px) {
  .quickview-modal .row {
    margin: 0 -1.5rem
  }

  .quickview-modal .row>.col-md-6 {
    padding-right: 1.5rem
  }

  .quickview-modal .product-gallery {
    margin-bottom: 0
  }

  .quickview-modal .pr-md-3 {
    padding-right: 1.5rem !important
  }

  .quickview-modal.product.product-single .product-details {
    position: absolute;
    overflow-y: auto;
    height: 100%;
    top: 0
  }
}

@media(max-width: 767px) {
  .newsletter-content {
    max-width: 36rem;
    padding-left: 2rem;
    padding-right: 2rem
  }

  .login-popup {
    max-width: 500px;
    padding: 3.5rem 2rem
  }
}

@media(max-width: 575px) {
  .newsletter-popup {
    background-image: none !important
  }

  .newsletter-popup .newsletter-content {
    margin-left: auto;
    margin-right: auto
  }
}

.equal-height .product {
  height: 100%
}

.product-shadow {
  -webkit-transition: -webkit-box-shadow .3s;
  transition: -webkit-box-shadow .3s;
  -moz-transition: box-shadow .3s;
  transition: box-shadow .3s;
  transition: box-shadow .3s, -webkit-box-shadow .3s
}

.product-shadow:hover {
  -webkit-box-shadow: 0 15px 25px -20px rgba(0, 0, 0, .5);
  box-shadow: 0 15px 25px -20px rgba(0, 0, 0, .5)
}

.product-label-round .product-label {
  padding: .5rem 1.1rem;
  border-radius: 1rem
}

.text-center .product-details {
  padding-left: .5rem;
  padding-right: .5rem
}

.product-wrap {
  margin-bottom: 2rem
}

.product {
  position: relative;
  font-family: Poppins, sans-serif;
  font-size: 1.4rem;
  color: #999;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  transition: .3s
}

.product:hover .product-action,
.product:hover .product-action-vertical {
  visibility: visible;
  opacity: 1
}

.product-media:hover span:first-child {
  opacity: 0
}

.product-media:hover span:last-child {
  opacity: 1
}

.product-media {
  position: relative;
  margin-bottom: 0;
  -webkit-transition: -webkit-box-shadow .3s;
  transition: -webkit-box-shadow .3s;
  -moz-transition: box-shadow .3s;
  transition: box-shadow .3s;
  transition: box-shadow .3s, -webkit-box-shadow .3s
}

.product-media:not(.pure-media):before {
  content: "";
  display: block;
  padding-top: 112.5%;
}

.product-media img {
  display: block;
  width: 100%;
  height: auto
}

.product-media img,
.product-media span {
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  transition: all .5s
}

.product-media span {
  position: absolute;
  right: 0;
  top: 0;
  left: 0
}

.product-media span:last-child {
  opacity: 0
}

.product-media span:first-child {
  opacity: 1
}

.product-loading-overlay:after,
.product-loading-overlay:before {
  content: "";
  display: block
}

.product-loading-overlay:before {
  padding-top: 155%;
  background-repeat: no-repeat;
  background-image: -moz-linear-gradient(#f4f4f4 105%, transparent 0), -moz-linear-gradient(#f4f4f4 12px, transparent 0), -moz-linear-gradient(#f4f4f4 16px, transparent 0), -moz-linear-gradient(#f4f4f4 12px, transparent 0), -moz-linear-gradient(#f4f4f4 16px, transparent 0);
  background-image: linear-gradient(#f4f4f4 105%, transparent 0), linear-gradient(#f4f4f4 12px, transparent 0), linear-gradient(#f4f4f4 16px, transparent 0), linear-gradient(#f4f4f4 12px, transparent 0), linear-gradient(#f4f4f4 16px, transparent 0);
  background-size: 100% 73%, 50% 12px, 73% 16px, 50% 12px, 40% 16px;
  background-position: left 0, 0 -moz-calc(73% + 20px), 0 -moz-calc(73% + 44px), 0 -moz-calc(73% + 66px), 0 -moz-calc(73% + 92px);
  background-position: left 0, left calc(73% + 20px), left calc(73% + 44px), left calc(73% + 66px), left calc(73% + 92px)
}

.product-loading-overlay:after {
  content: "";
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  top: -moz-calc(35% - 20px);
  top: calc(35% - 20px);
  left: -moz-calc(50% - 20px);
  left: calc(50% - 20px);
  -webkit-animation: spin .65s linear infinite;
  -moz-animation: spin .65s linear infinite;
  animation: spin .65s linear infinite;
  border-radius: 75%;
  border: 2px solid !important;
  border-color: #222 #222 transparent !important;
  z-index: 3
}

.product-label-group {
  position: absolute;
  left: 2rem;
  top: 1.8rem;
  max-width: 10rem
}

.product-label {
  display: block;
  text-align: center;
  margin-bottom: .5rem;
  padding: .5rem 1.1rem;
  font-size: 1.1rem;
  border-radius: 2px;
  font-weight: 600;
  line-height: 1;
  color: #fff;
  text-transform: uppercase
}

.product-label.label-new {
  background: #222
}

.product-label.label-sale {
  background: #ff675d
}

.product-label.label-top {
  background: #222
}

.product-label.label-stock {
  background: #999
}

.btn-product-icon {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  width: 3.6rem;
  height: 3.6rem;
  margin-bottom: .5rem;
  border: 1px solid #e1e1e1;
  border-radius: 50%;
  background-color: #fff;
  color: #999;
  font-size: 1.6rem;
  font-weight: 700;
  -webkit-transition: border-color .3s, color .3s, background-color .3s;
  -moz-transition: border-color .3s, color .3s, background-color .3s;
  transition: border-color .3s, color .3s, background-color .3s
}

.btn-product-icon.btn-wishlist i {
  margin-top: .3rem;
  margin-left: .1rem
}

.btn-product-icon:hover {
  border-color: #222;
  color: #fff;
  background-color: #222
}

.btn-product-icon i {
  margin-bottom: 2px
}

.btn-product-icon.btn-quickview i {
  font-size: inherit
}

.btn-product-icon.btn-compare i {
  font-size: 1.8rem
}

.product-list .btn-product-icon i {
  margin-bottom: 1px
}

.btn-product {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  -moz-box-flex: 1;
  flex: 1 1;
  padding: 1.14em 0;
  background-color: #222;
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: -.025em;
  text-transform: uppercase;
  -webkit-transition: opacity .3s;
  -moz-transition: opacity .3s;
  transition: opacity .3s
}

.btn-product:hover {
  color: #fff
}

.btn-quickview {
  opacity: .8;
  -webkit-transition: opacity .3s;
  -moz-transition: opacity .3s;
  transition: opacity .3s
}

.btn-quickview:hover {
  opacity: 1
}

.btn-quickview i {
  font-size: 1.4rem
}

.btn-wishlist {
  line-height: 1
}

.btn-wishlist.loading:after,
.btn-wishlist.loading:before {
  z-index: 1
}

.product-action-vertical {
  position: absolute;
  top: 15px;
  right: 15px;
  -webkit-transition: opacity .3s, visibility .3s;
  -moz-transition: opacity .3s, visibility .3s;
  transition: opacity .3s, visibility .3s;
  opacity: 0;
  visibility: hidden
}

.product-action-vertical .btn-wishlist.loading {
  overflow: hidden
}

.product-action {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  background-color: transparent;
  z-index: 10;
  -webkit-transition: opacity .3s, visibility .3s;
  -moz-transition: opacity .3s, visibility .3s;
  transition: opacity .3s, visibility .3s;
  opacity: 0
}

.product-details {
  position: relative;
  padding-top: 1.4rem;
  padding-bottom: 2rem
}

.product-details>.btn-wishlist {
  position: absolute;
  top: 1.2rem;
  right: 0;
  color: #999;
  z-index: 1;
  font-size: 1.6rem
}

.product-details>.btn-wishlist i {
  display: inline-block;
  margin: 0;
  -webkit-transition: -webkit-transform .3s;
  transition: -webkit-transform .3s;
  -moz-transition: transform .3s, -moz-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s, -moz-transform .3s
}

.product-details>.btn-wishlist:hover {
  color: #222
}

.product-details>.btn-wishlist:hover i {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  transform: rotateY(180deg)
}

.product-details>:last-child {
  margin-bottom: 0
}

.product-details .product-action {
  position: relative;
  opacity: 1;
  visibility: visible
}

.product-cat {
  margin-bottom: .5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden
}

.product-cat a:hover {
  color: #222
}

.product-name {
  margin-bottom: .3rem;
  font-family: inherit;
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: -.01em;
  color: #666;
  padding-right: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-name a:hover {
  color: #222
}

.product-price {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  -moz-box-align: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: .3rem;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.86;
  color: #222
}

.product-price .old-price {
  color: #aaa
}

.product-price .new-price {
  margin-right: 1rem;
  text-decoration: none
}

.ratings-container {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  margin-left: 1px;
  line-height: 1.1;
  margin-bottom: 1.2rem;
  font-size: 11px;
  letter-spacing: .2em
}

.ratings-full {
  cursor: pointer
}

.ratings,
.ratings-full {
  position: relative;
  font-family: riode
}

.ratings-full+.rating-reviews {
  margin-left: 1rem
}

.ratings-full:before {
  content: "" "" "" "" "";
  color: rgba(0, 0, 0, .16);
  font-family: riode
}

.ratings-full:hover .tooltiptext {
  visibility: visible;
  opacity: 1
}

.ratings {
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  overflow: hidden
}

.ratings:before {
  content: "" "" "" "" "";
  color: #ff675d;
  font-family: riode
}

.rating-reviews {
  margin: 2px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.1rem;
  color: #aaa;
  letter-spacing: 0;
  text-transform: capitalize
}

.rating-reviews:hover {
  color: #222
}

.product-variations {
  display: -moz-box;
  display: flex
}

.ratings-container+.product-variations {
  margin-top: -5px
}

.product-variations>a:not(.size-guide) {
  display: inline-block;
  position: relative;
  margin: 3px 9px 3px 0;
  padding: 0 5px;
  border-radius: 2px;
  background-color: transparent;
  background-size: cover;
  font-size: 1.2rem;
  font-family: inherit;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  width: 2.6rem;
  height: 2.6rem;
  color: #222;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  transition: .3s;
  border: 1px solid #e1e1e1
}

.product-variations>a:not(.size-guide):last-child {
  margin-right: 0
}

.product-variations .color:not(.active) {
  border: none
}

.product-variations>a.active,
.product-variations>a:not(.size-guide):hover {
  border: 1px solid;
  -webkit-box-shadow: inset 0 0 0 2px #fff;
  box-shadow: inset 0 0 0 2px #fff
}

.product-details .product-action,
.product-hide-details .product-action {
  position: relative;
  opacity: 1
}

.product-details .product-action .btn-wishlist,
.product-hide-details .product-action .btn-wishlist {
  position: static
}

.product-details .btn-cart,
.product-hide-details .btn-cart {
  display: block;
  max-width: 14rem;
  line-height: 2.9;
  padding: 0 .6em;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #222;
  background-color: transparent;
  border: 2px solid #ccc;
  margin-right: 5px;
  border-radius: .3rem
}

.product-details .btn-cart i,
.product-hide-details .btn-cart i {
  margin-right: .8rem;
  font-size: 1.8rem
}

.product-details .btn-cart,
.product-hide-details .btn-cart {
  -moz-box-flex: 1;
  flex: 1 1;
  height: 4.2rem;
  font-size: 1.3rem;
  -webkit-transition: color .3s, background-color .3s, border-color .3s;
  -moz-transition: color .3s, background-color .3s, border-color .3s;
  transition: color .3s, background-color .3s, border-color .3s;
  z-index: 1
}

.product-details .btn-quickview,
.product-hide-details .btn-quickview {
  margin: 0 0 0 1.6rem
}

.product-details .btn-product-icon,
.product-hide-details .btn-product-icon {
  width: auto;
  border: 0;
  background-color: transparent;
  color: #222;
  -webkit-transition: color .3s, background-color .3s, visibility .3s;
  -moz-transition: color .3s, background-color .3s, visibility .3s;
  transition: color .3s, background-color .3s, visibility .3s
}

.product-details .btn-product-icon i,
.product-hide-details .btn-product-icon i {
  font-size: 1.8rem
}

.product-details .btn-product-icon:hover,
.product-hide-details .btn-product-icon:hover {
  color: #222
}

.product-media .btn-product-icon.btn-cart i {
  margin-top: 2px;
  margin-right: 1px
}

.product:hover .product-action .btn-cart {
  color: #fff;
  background-color: #444;
  border-color: #444
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {

  .product-details .btn-cart,
  .product-hide-details .btn-cart {
    -moz-box-flex: 0;
    flex: 0 0 12.3rem
  }
}

@media (-ms-high-contrast:none)and (max-width: 575px),
screen and (-ms-high-contrast:active)and (max-width:575px) {

  .product-details .btn-cart,
  .product-hide-details .btn-cart {
    -moz-box-flex: 0;
    flex: 0 0 9.5rem
  }
}

.product-classic .btn-quickview {
  opacity: 1
}

.product-classic .btn-quickview i:before {
  content: ""
}

.product-classic .btn-wishlist {
  margin: 0 0 0 1.6rem
}

.product-classic .btn-quickview,
.product-classic .btn-wishlist {
  -webkit-transform: translateX(-200%);
  -moz-transform: translateX(-200%);
  transform: translateX(-200%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity .3s, visibility .3s, color .3s, background-color .3s, border-color .3s, -webkit-transform .3s;
  transition: opacity .3s, visibility .3s, color .3s, background-color .3s, border-color .3s, -webkit-transform .3s;
  -moz-transition: transform .3s, opacity .3s, visibility .3s, color .3s, background-color .3s, border-color .3s, -moz-transform .3s;
  transition: transform .3s, opacity .3s, visibility .3s, color .3s, background-color .3s, border-color .3s;
  transition: transform .3s, opacity .3s, visibility .3s, color .3s, background-color .3s, border-color .3s, -webkit-transform .3s, -moz-transform .3s
}

.product-classic .btn-quickview {
  -webkit-transition: opacity .5s, visibility .5s, color .3s, background-color .3s, border-color .3s, -webkit-transform .5s;
  transition: opacity .5s, visibility .5s, color .3s, background-color .3s, border-color .3s, -webkit-transform .5s;
  -moz-transition: transform .5s, opacity .5s, visibility .5s, color .3s, background-color .3s, border-color .3s, -moz-transform .5s;
  transition: transform .5s, opacity .5s, visibility .5s, color .3s, background-color .3s, border-color .3s;
  transition: transform .5s, opacity .5s, visibility .5s, color .3s, background-color .3s, border-color .3s, -webkit-transform .5s, -moz-transform .5s
}

.product-classic:hover .btn-quickview,
.product-classic:hover .btn-wishlist {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
  visibility: visible
}

.product-classic .product-details {
  padding-left: 0
}

.product-classic .ratings-container {
  margin-bottom: 1.5rem
}

.product-slideup-content {
  overflow: hidden
}

.product-slideup-content .product-hide-details {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%
}

.product-slideup-content .product-details,
.product-slideup-content .product-hide-details {
  -webkit-transition: -webkit-transform .3s;
  transition: -webkit-transform .3s;
  -moz-transition: transform .3s, -moz-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s, -moz-transform .3s;
  background-color: #fff
}

.product-slideup-content .product-details {
  padding-bottom: 1px
}

.product-slideup-content .btn-quickview,
.product-slideup-content .btn-wishlist {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity .3s, visibility .3s, color .3s, background-color .3s, -webkit-transform .4s;
  transition: opacity .3s, visibility .3s, color .3s, background-color .3s, -webkit-transform .4s;
  -moz-transition: transform .4s, opacity .3s, visibility .3s, color .3s, background-color .3s, -moz-transform .4s;
  transition: transform .4s, opacity .3s, visibility .3s, color .3s, background-color .3s;
  transition: transform .4s, opacity .3s, visibility .3s, color .3s, background-color .3s, -webkit-transform .4s, -moz-transform .4s
}

.product-slideup-content .btn-quickview {
  -webkit-transform: translateX(-200%);
  -moz-transform: translateX(-200%);
  transform: translateX(-200%)
}

.product-slideup-content .btn-wishlist {
  -webkit-transform: translateX(200%);
  -moz-transform: translateX(200%);
  transform: translateX(200%)
}

.product-slideup-content:hover .btn-quickview,
.product-slideup-content:hover .btn-wishlist {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
  visibility: visible
}

.product-slideup .product-details {
  padding-bottom: 0;
  background-color: #fff;
  -webkit-transition: -webkit-transform .3s;
  transition: -webkit-transform .3s;
  -moz-transition: transform .3s, -moz-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s, -moz-transform .3s
}

.product-slideup .ratings-container {
  margin-bottom: 0
}

.product-slideup .btn-quickview,
.product-slideup .btn-wishlist {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity .3s, visibility .3s, color .3s, background-color .3s, -webkit-transform .4s;
  transition: opacity .3s, visibility .3s, color .3s, background-color .3s, -webkit-transform .4s;
  -moz-transition: transform .4s, opacity .3s, visibility .3s, color .3s, background-color .3s, -moz-transform .4s;
  transition: transform .4s, opacity .3s, visibility .3s, color .3s, background-color .3s;
  transition: transform .4s, opacity .3s, visibility .3s, color .3s, background-color .3s, -webkit-transform .4s, -moz-transform .4s
}

.product-slideup .btn-quickview {
  -webkit-transform: translateX(-200%);
  -moz-transform: translateX(-200%);
  transform: translateX(-200%)
}

.product-slideup .btn-wishlist {
  -webkit-transform: translateX(200%);
  -moz-transform: translateX(200%);
  transform: translateX(200%)
}

.product-slideup .product-action {
  display: -moz-box;
  display: flex;
  position: absolute;
  padding-top: 1.5rem;
  top: 100%;
  bottom: auto;
  visibility: hidden;
  opacity: 0
}

.product-slideup:hover {
  -webkit-transform: translateY(-58px);
  -moz-transform: translateY(-58px);
  transform: translateY(-58px)
}

.product-slideup:hover .btn-quickview,
.product-slideup:hover .btn-wishlist {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
  visibility: visible
}

.product-slideup:hover .product-action {
  visibility: visible;
  opacity: 1
}

.product-slideup img {
  -webkit-filter: brightness(1.1);
  filter: brightness(1.1)
}

.product-slideup-content .btn-cart,
.product-slideup .btn-cart {
  margin-right: 0
}

.product-slideup-content .btn-wishlist,
.product-slideup .btn-wishlist {
  position: static;
  margin: 0 1.6rem 0 0
}

.split-line {
  overflow: hidden
}

.product-image-gap {
  padding: .9rem;
  border: 1px solid #e1e1e1;
  background-color: #fff
}

.product-image-gap .product-details {
  padding: 1.8rem 1rem 1rem
}

.product-image-gap .ratings-container {
  margin-bottom: 0
}

.product-image-gap:hover {
  -webkit-box-shadow: 0 5px 30px rgba(0, 0, 0, .05);
  box-shadow: 0 5px 30px rgba(0, 0, 0, .05)
}

@media(max-width: 479px) {
  .product-image-gap .product-details {
    padding: 1.8rem 0 1rem
  }
}

.product-list {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  margin-bottom: 2rem
}

.product-list .product-media {
  -moz-box-flex: 0;
  flex: 0 0 28rem;
  max-width: 28rem;
  margin: 0 2rem 0 0
}

.product-list .product-details {
  padding: 0;
  -moz-box-flex: 1;
  flex: 1 1
}

.product-list .product-label {
  font-size: 1.1rem
}

.product-list .btn-quickview {
  opacity: 1
}

.product-list .btn-wishlist {
  margin: 0 0 0 .8rem
}

.product-list .btn-quickview,
.product-list .btn-wishlist {
  -webkit-transform: translateX(-200%);
  -moz-transform: translateX(-200%);
  transform: translateX(-200%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity .3s, visibility .3s, color .3s, background-color .3s, -webkit-transform .3s;
  transition: opacity .3s, visibility .3s, color .3s, background-color .3s, -webkit-transform .3s;
  -moz-transition: transform .3s, opacity .3s, visibility .3s, color .3s, background-color .3s, -moz-transform .3s;
  transition: transform .3s, opacity .3s, visibility .3s, color .3s, background-color .3s;
  transition: transform .3s, opacity .3s, visibility .3s, color .3s, background-color .3s, -webkit-transform .3s, -moz-transform .3s
}

.product-list .btn-quickview {
  -webkit-transition: opacity .5s, visibility .5s, color .3s, background-color .3s, -webkit-transform .5s;
  transition: opacity .5s, visibility .5s, color .3s, background-color .3s, -webkit-transform .5s;
  -moz-transition: transform .5s, opacity .5s, visibility .5s, color .3s, background-color .3s, -moz-transform .5s;
  transition: transform .5s, opacity .5s, visibility .5s, color .3s, background-color .3s;
  transition: transform .5s, opacity .5s, visibility .5s, color .3s, background-color .3s, -webkit-transform .5s, -moz-transform .5s
}

.product-list .ratings-container {
  margin-bottom: .8rem
}

.product-list .product-name {
  letter-spacing: -.025em;
  color: #222
}

.product-list .product-name,
.product-list .product-price {
  font-size: 1.8rem;
  font-weight: 600
}

.product-list .product-short-desc {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 2rem;
  text-transform: none;
  font-size: 1.4rem;
  line-height: 1.78;
  color: #666
}

.product-list:hover .btn-quickview,
.product-list:hover .btn-wishlist {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
  visibility: visible
}

.product-list-sm {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  margin-bottom: 2rem
}

.product-list-sm .product-media {
  -moz-box-flex: 0;
  flex: 0 0 10rem;
  max-width: 10rem;
  margin: 0 1rem 0 0
}

.product-list-sm .product-details {
  padding: 0;
  overflow: hidden
}

.product-list-sm .product-name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: .7rem;
  white-space: normal;
  word-break: break-all;
  color: #222
}

.product-list-sm .product-name:hover {
  color: #222
}

.product-list-sm .ratings-container {
  margin: 0
}

.product-list-sm .product-price {
  font-size: 1.4rem
}

.product-list-sm+.product-list {
  margin-top: 2rem
}

.product-list-sm .btn-cart {
  margin-top: 2rem;
  height: 3.3rem;
  max-width: 11.7rem;
  border: 1px solid #d7d7d7;
  color: #ff675d;
  background: transparent;
  font-size: 1.2rem
}

.product-list-sm .btn-cart:hover {
  border-color: #ff675d;
  background: #ff675d
}

@media(max-width: 1220px)and (min-width:1200px) {
  .product-tabs .product-tab-description .divider {
    margin-left: -5px
  }
}

.product.text-center .product-cat {
  padding: 0
}

.product.text-center .product-name {
  padding-left: 2rem
}

.product.text-center .product-action,
.product.text-center .product-price,
.product.text-center .product-variations,
.product.text-center .ratings-container {
  -moz-box-pack: center;
  justify-content: center
}

.product.shadow-media:hover .product-media {
  -webkit-box-shadow: 0 20px 20px -16px rgba(0, 0, 0, .5);
  box-shadow: 0 20px 20px -16px rgba(0, 0, 0, .5)
}

.product.cart-full .product-price,
.product.product-with-qty .product-details .product-price {
  margin-bottom: 1rem
}

.product.cart-full .btn-cart,
.product.product-with-qty .product-details .btn-cart {
  padding: 0;
  border-color: #ccc;
  -webkit-transition: background-color .3s, border-color .3s, color .3s;
  -moz-transition: background-color .3s, border-color .3s, color .3s;
  transition: background-color .3s, border-color .3s, color .3s
}

.product.cart-full .btn-cart:active,
.product.cart-full .btn-cart:focus,
.product.cart-full .btn-cart:hover,
.product.product-with-qty .product-details .btn-cart:active,
.product.product-with-qty .product-details .btn-cart:focus,
.product.product-with-qty .product-details .btn-cart:hover {
  background-color: #222;
  border-color: #222;
  color: #fff
}

.product.cart-full .btn-cart {
  max-width: none;
  width: 100%
}

.product.product-with-qty .product-details {
  padding-left: 0;
  padding-right: 0
}

.product.product-with-qty .btn-cart {
  max-width: 12.3rem;
  margin-right: 0
}

.product.product-with-qty .btn-cart i {
  display: none
}

.product.product-with-qty .product-quantity {
  margin-right: 2rem
}

.product.product-with-qty .product-quantity,
.product.product-with-qty button {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center
}

.product.product-with-qty button {
  padding: 0;
  color: #333;
  font-size: 1rem;
  width: 24px;
  height: 24px;
  border-radius: .3rem;
  border: none;
  background: #f4f4f4;
  cursor: pointer;
  -webkit-transition: background-color .3s, color .3s;
  -moz-transition: background-color .3s, color .3s;
  transition: background-color .3s, color .3s
}

.product.product-with-qty button:hover {
  background-color: #222;
  color: #fff
}

.product.product-with-qty .quantity {
  min-height: auto;
  width: 3.2rem;
  padding: 0;
  font-weight: 700;
  font-size: 1.2rem;
  border: none;
  text-align: center;
  color: #222
}

.product.product-variable:not(.cart-full) .btn-cart:after {
  display: none;
  font-family: riode;
  content: "";
  font-size: 1.8rem;
  font-weight: 400
}

.product:hover.product-with-qty .btn-cart {
  background-color: transparent;
  color: #222
}

@media(max-width: 575px) {
  .product-list {
    display: block
  }

  .product-list .product-media {
    margin: 0;
    max-width: 100%
  }

  .product-list .product-details {
    padding: 1.8rem 0 2rem
  }

  .product-action-vertical {
    top: 10px;
    right: 10px
  }
}

@media(max-width: 479px) {

  .product-details .btn-cart,
  .product-hide-details .btn-cart {
    max-width: 4.2rem;
    display: -moz-box;
    display: flex
  }

  .product-details .btn-cart span,
  .product-hide-details .btn-cart span {
    display: none
  }

  .product-details .btn-cart i,
  .product-hide-details .btn-cart i {
    margin-right: 0
  }

  .product.product-with-qty .product-details .btn-cart {
    max-width: 4.2rem;
    padding: .78em 0
  }

  .product.product-with-qty .btn-cart i {
    display: block
  }

  .product.product-with-qty .product-quantity {
    margin-right: 1rem
  }

  .cart-full .product-details .btn-cart span {
    display: inline
  }

  .cart-full .btn-cart i {
    display: none
  }

  .product-label-group {
    left: 1.2rem;
    top: 1.2rem
  }

  .btn-quickview {
    font-size: 1.1rem
  }

  .btn-product-icon {
    width: 3.2rem;
    height: 3.2rem;
    font-size: 1.3rem
  }

  .product.product-variable:not(.cart-full) .btn-cart:after {
    display: block
  }

  .product.product-variable:not(.cart-full) .btn-cart i {
    display: none
  }
}

.product-filters {
  margin-right: 1.5rem
}

.product-single-carousel,
.product-thumbs-wrap {
  flex-basis: 100%;
  max-width: 100%
}

.product-single-carousel .owl-nav .owl-next,
.product-single-carousel .owl-nav .owl-prev {
  color: #222;
  border: 0
}

.product-image {
  position: relative;
  width: 100%
}

.product-thumbs-wrap {
  position: relative;
  margin-top: 1rem;
  overflow: hidden
}

.product-thumbs-wrap img {
  display: block
}

.product-thumbs-wrap button {
  display: -moz-box;
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
  -moz-box-align: center;
  align-items: center;
  width: 2.4rem;
  border: 0;
  -webkit-transition: opacity .3s, -webkit-transform .3s;
  transition: opacity .3s, -webkit-transform .3s;
  -moz-transition: opacity .3s, transform .3s, -moz-transform .3s;
  transition: opacity .3s, transform .3s;
  transition: opacity .3s, transform .3s, -webkit-transform .3s, -moz-transform .3s;
  z-index: 1;
  opacity: 0;
  -webkit-box-shadow: 0 0 1rem rgba(0, 0, 0, .1);
  box-shadow: 0 0 1rem rgba(0, 0, 0, .1);
  -webkit-appearance: none;
  cursor: pointer
}

.product-thumbs-wrap:hover button:not(.disabled) {
  opacity: .9;
  -webkit-transform: none;
  -moz-transform: none;
  transform: none
}

.product-thumbs-wrap button:not(.disabled):hover {
  opacity: 1
}

.product-thumbs-wrap>button {
  display: none
}

.product-thumbs-wrap button,
.product-thumbs .owl-nav .owl-next,
.product-thumbs .owl-nav .owl-prev {
  background-color: #fff;
  color: #666;
  font-size: 1.6rem
}

.product-thumb:before,
.product-thumbs-wrap button {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0
}

.product-thumbs {
  -webkit-transition: top .3s ease-out;
  -moz-transition: top .3s ease-out;
  transition: top .3s ease-out;
  display: -moz-box;
  display: flex
}

.product-thumbs.owl-carousel {
  margin: 0 -.5rem;
  width: -moz-calc(100% + 1rem);
  width: calc(100% + 1rem)
}

.product-thumbs .owl-stage {
  display: -moz-box;
  display: flex
}

.product-thumbs .owl-prev {
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  transform: translateX(-100%);
  left: .5rem
}

.product-thumbs .owl-next {
  left: auto;
  right: .5rem;
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  transform: translateX(100%)
}

.product-thumb {
  position: relative;
  margin: 0 .5rem;
  cursor: pointer
}

.product-thumb:before {
  content: "";
  -webkit-transition: border-color .3s;
  -moz-transition: border-color .3s;
  transition: border-color .3s;
  border: 2px solid transparent
}

.product-thumb.active:before {
  border-color: #222
}

.product-single {
  color: inherit
}

.product-single .product-details {
  padding: 0 0 3rem
}

.container-fluid .product-single .product-details {
  padding-top: 4px
}

aside+div .product-single .product-details {
  padding-top: 2px
}

.product-single .product-cat {
  margin-bottom: 1.3rem;
  color: #999;
  font-size: 1.3rem;
  font-weight: 400;
  letter-spacing: -.025em
}

.product-single .product-cat span {
  margin-right: .7rem
}

.product-single .product-name {
  margin-bottom: .6rem;
  font-size: 2.6rem;
  font-weight: 700;
  letter-spacing: -.025em;
  white-space: normal;
  color: #222;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical
}

.product-single .product-price {
  display: block;
  margin-bottom: 1.1rem;
  color: #ff675d;
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: -.025em;
  line-height: 1
}

.product-single .old-price {
  font-size: 2.4rem;
  font-weight: 600
}

.product-single .rating-reviews {
  font-size: 1.2rem
}

.product-single .rating-reviews:not(:hover) {
  color: #999
}

.product-single .ratings-container {
  margin-bottom: 1.7rem;
  font-size: 14px
}

.product-single .ratings-full {
  margin-top: -2px
}

.product-single .ratings:before {
  color: #666
}

.product-single label {
  color: #222;
  font-weight: 600;
  text-transform: uppercase
}

.product-single .product-action {
  display: inline-block
}

.product-single .product-action .btn-wishlist {
  position: relative
}

.product-single .divider {
  margin-left: -1rem;
  margin-right: 1.8rem
}

.product-single .social-links {
  color: #999
}

.product-single .social-link {
  border: none
}

.product-single .social-link.social-facebook:hover {
  background-color: transparent;
  color: #3b5998
}

.product-single .social-link.social-twitter:hover {
  background-color: transparent;
  color: #1da1f2
}

.product-single .social-link.social-pinterest:hover {
  background-color: transparent;
  color: #bd081c
}

.product-single .product-footer>* {
  margin-bottom: 1rem
}

.product-single .btn-compare,
.product-single .btn-wishlist {
  display: inline-block;
  padding: .5rem 0;
  font-weight: 400;
  font-size: 1.4rem;
  background-color: transparent;
  -webkit-transition: color .3s;
  -moz-transition: color .3s;
  transition: color .3s;
  text-transform: none;
  color: #666
}

.product-single .btn-compare i,
.product-single .btn-wishlist i {
  display: inline-block;
  margin: 0 .5rem .3rem 0;
  vertical-align: middle;
  font-size: 1.8rem;
  line-height: 0
}

.product-single .btn-compare:hover,
.product-single .btn-wishlist:hover {
  color: #222
}

.product-single .btn-compare i {
  margin-right: .8rem;
  font-size: 2.1rem
}

.product-single .quantity {
  font-weight: 700
}

.product-single .product-meta+.product-countdown-container {
  margin-top: .4rem
}

.product-single .product-countdown-container {
  display: -moz-inline-box;
  display: inline-flex;
  -moz-box-align: center;
  align-items: center;
  margin-bottom: 2.5rem;
  padding: 1rem 1.6rem .5rem;
  border: 1px solid #444;
  background-color: #444;
  text-transform: none;
  line-height: 1;
  color: #fff;
  border-radius: .3rem;
  flex-wrap: wrap
}

.product-single .product-countdown-container label {
  margin-right: .5rem;
  text-transform: none;
  color: #fff
}

.product-single .product-countdown-container .product-countdown,
.product-single .product-countdown-container .product-countdown-title {
  margin-bottom: .5rem
}

.product-single .product-countdown-container .product-sale-info {
  position: relative;
  padding-right: 1.5rem
}

.product-single .product-countdown-container .product-sale-info i {
  margin-right: .8rem
}

.product-single .product-countdown-container .product-sale-info:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 1px;
  height: 2.4rem;
  background: hsla(0, 0%, 100%, .2)
}

.product-single .product-countdown-container .product-sale-info+label {
  margin-left: 1.5rem
}

.product-single .product-label-group {
  top: 2rem;
  left: 2.5rem;
  z-index: 2
}

.product-single .product-label {
  color: #fff
}

.product-single .product-variations>a:not(.size-guide) {
  width: 3rem;
  height: 3rem
}

.product-single .product-variations>.disabled {
  cursor: not-allowed;
  opacity: .3
}

.product-single .color {
  border: 0
}

.product-single .size {
  width: 3.6rem
}

.product-single .accordion ul {
  list-style: none;
  padding-left: 0
}

.product-single .rating-form label,
.product-single form label {
  font-weight: 400;
  font-size: 1.3rem;
  text-transform: none;
  color: #666
}

.product-meta {
  margin-bottom: 1.8rem;
  color: #999;
  font-size: 1.2rem
}

.product-meta span {
  margin: 0 1.9rem 0 3px
}

.product-form {
  display: -moz-box;
  display: flex;
  -moz-box-align: start;
  align-items: flex-start;
  flex-wrap: nowrap;
  line-height: 3rem;
  margin: 0 0 1rem
}

.product-form>label {
  min-width: 7rem;
  margin-bottom: 1rem;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -.025em
}

.product-form.product-variations {
  line-height: 37px
}

.product-form .select-box:before {
  right: 1.4rem;
  font-size: 1.2rem;
  color: #222
}

.product-form select {
  max-width: none;
  padding: .8rem 3rem .8rem 1.4rem;
  color: #222;
  border-color: #ccc;
  font-size: 1.3rem
}

.product-form .product-variations {
  display: block;
  margin-top: -3px;
  margin-bottom: -3px
}

.product-form.product-qty {
  line-height: 4.5rem
}

.product-form .quantity-minus {
  border-radius: .3rem 0 0 .3rem
}

.product-form .quantity-plus {
  border-radius: 0 .3rem .3rem 0
}

.product-form .input-group {
  margin-right: 1rem
}

.product-form .btn-cart {
  border: 0;
  -moz-box-flex: 1;
  flex: 1 1;
  min-width: 13rem;
  font-size: 1.4rem;
  border-radius: .3rem;
  background-color: #222;
  color: #fff;
  cursor: pointer;
  max-width: 20.7rem;
  height: 4.5rem
}

.product-form .btn-cart.disabled {
  background-color: #e4eaec;
  cursor: not-allowed;
  color: #999
}

.product-form .btn-cart i {
  margin-right: .8rem;
  margin-top: -1px;
  font-size: 1.8rem;
  line-height: 0;
  vertical-align: middle
}

.product-form .btn-cart i:before {
  margin: 0
}

.product-form .btn-cart:hover:not(.disabled) {
  background-color: #101010
}

.product-form-group {
  position: relative;
  display: -moz-box;
  display: flex;
  -moz-box-align: start;
  align-items: flex-start;
  flex-wrap: wrap;
  -moz-box-flex: 1;
  flex: 1 1
}

.product-form-group>* {
  margin-bottom: 1rem
}

.product-form-group>:not(:last-child) {
  margin-right: 2rem
}

.product-form-group .product-variations {
  margin-bottom: 7px
}

.size-guide {
  display: -moz-inline-box;
  display: inline-flex;
  -moz-box-align: center;
  align-items: center;
  font-weight: 300
}

.size-guide i {
  margin-right: .8rem;
  font-size: 2.1rem;
  color: #666
}

.product-variation-price span {
  margin-bottom: 1rem;
  color: #222;
  font-size: 2.4rem;
  font-weight: 700;
  letter-spacing: -.025em
}

.product-variation-clean {
  display: block;
  max-width: 6.5rem;
  margin-top: 1rem;
  padding: .3em 1em;
  font-size: 1rem;
  line-height: 1.6;
  background: #f4f4f4;
  color: #000
}

.related-products {
  margin-top: 6.5rem
}

@media(min-width: 992px) {
  .pg-vertical .product-thumbs-wrap {
    -moz-box-ordinal-group: 0;
    order: -1;
    max-width: 109px;
    margin: 0 1rem 0 0
  }

  .pg-vertical .product-single-carousel {
    max-width: -moz-calc(100% - 119px);
    max-width: calc(100% - 119px)
  }

  .pg-vertical .product-thumbs {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0
  }

  .pg-vertical .product-thumb {
    margin: 0 0 .9rem
  }

  .pg-vertical .thumb-down,
  .pg-vertical .thumb-up {
    display: -moz-box;
    display: flex;
    width: 100%;
    height: 2.4rem
  }

  .pg-vertical .thumb-up {
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    transform: translateY(-100%)
  }

  .pg-vertical .thumb-up i:before {
    content: ""
  }

  .pg-vertical .thumb-down {
    top: auto;
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    transform: translateY(100%)
  }

  .pg-vertical .thumb-down i:before {
    content: ""
  }

  .pg-vertical .product-label-group {
    left: 14rem
  }
}

.product-single.product-sticky-both .p-sticky {
  top: 88px
}

.product-single.product-sticky-both .product-details {
  padding: 0
}

.product-tabs.tab-nav-simple .nav-link {
  font-size: 2rem;
  font-weight: 700;
  text-transform: capitalize;
  color: #222;
  letter-spacing: 0
}

.product-image.large-image img {
  max-width: none
}

.product-details.sticky {
  position: sticky;
  top: 88px
}

.large-image img {
  max-width: none
}

.quickview-modal .btn-close {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: -webkit-transform .3s;
  transition: -webkit-transform .3s;
  -moz-transition: transform .3s, -moz-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s, -moz-transform .3s;
  width: 18px;
  height: 18px;
  opacity: 1
}

.quickview-modal .btn-close:hover {
  -webkit-transform: rotate(135deg) scale(1.5);
  -moz-transform: rotate(135deg) scale(1.5);
  transform: rotate(135deg) scale(1.5)
}

.quickview-modal .btn-close:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  top: -moz-calc(50% - 1px);
  top: calc(50% - 1px);
  left: 0;
  background: #fff
}

.quickview-modal .btn-close:after {
  content: "";
  display: block;
  position: absolute;
  background: #fff;
  width: 2px;
  height: 100%;
  top: 0;
  left: -moz-calc(50% - 1px);
  left: calc(50% - 1px)
}

.product-gallery-details::-webkit-scrollbar {
  height: 7px;
  width: 4px
}

.product-gallery-details::-webkit-scrollbar-thumb {
  margin-right: 2px;
  background: rgba(0, 0, 0, .25);
  border-radius: 5px;
  cursor: pointer
}

.product-gallery-details::-webkit-scrollbar-track {
  background: #fff;
  -webkit-border-image: 10px;
  border-image: 10px;
  margin: 8px;
  width: 100%
}

.product-popup .product-details {
  padding: 0
}

.quickview-modal {
  max-width: 105.8rem;
  padding: 3rem 2rem;
  width: 100%;
  background: #fff;
  border-radius: 1rem
}

.ReactModal__Overlay {
  display: -moz-box;
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
  -moz-box-align: center;
  align-items: center;
  padding: 4rem 3rem 2rem
}

@media(min-width: 768px) {
  .quickview-modal .skeleton-body .col-md-6 {
    overflow: hidden
  }

  .quickview-modal .skeleton-body .col-md-6 .skel-pro-summary {
    position: absolute
  }
}

@media(max-width: 767px) {
  .quickview-modal .product-details {
    height: auto !important
  }

  .ReactModal__Overlay {
    display: block
  }
}

@media(max-width: 479px) {
  .ReactModal__Overlay {
    padding: 4rem 1rem 2rem
  }

  .ReactModal__Overlay .quickview-modal {
    padding: 2rem 1rem
  }

  .ReactModal__Overlay .quickview-modal .skeleton-body {
    padding: 0
  }
}

@supports(-ms-ime-align:auto) {
  .ReactModal__Overlay {
    display: -moz-box !important;
    display: flex !important
  }

  .product-popup {
    min-width: 20rem !important;
    top: auto !important;
    -webkit-transform: none !important;
    -moz-transform: none !important;
    transform: none !important
  }

  .newsletter-popup-container {
    top: 0 !important;
    margin: auto;
    -webkit-transform: translateZ(1000px) perspective(600px) rotateX(30deg);
    -moz-transform: translateZ(1000px) perspective(600px) rotateX(30deg);
    transform: translateZ(1000px) perspective(600px) rotateX(30deg)
  }

  .newsletter-popup-container.opened {
    -webkit-transform: translateZ(1000px) perspective(600px);
    -moz-transform: translateZ(1000px) perspective(600px);
    transform: translateZ(1000px) perspective(600px)
  }
}

.social-links {
  display: -moz-inline-box;
  display: inline-flex;
  flex-wrap: wrap;
  -moz-box-align: center;
  align-items: center
}

.social-link {
  margin: 2px 8px 2px 0;
  width: 30px;
  height: 30px;
  line-height: 28px;
  font-size: 1.5rem;
  border-radius: 50%;
  border: 2px solid #ccc;
  color: inherit;
  -webkit-transition: color .4s, border .4s, background .4s;
  -moz-transition: color .4s, border .4s, background .4s;
  transition: color .4s, border .4s, background .4s;
  text-align: center
}

.social-link:last-child {
  margin-right: 0
}

.social-link i {
  letter-spacing: -.001em;
  line-height: 1
}

.social-link:hover {
  background: #222;
  border-color: #222;
  color: #fff
}

.social-link.social-facebook:hover {
  background: #3b5998;
  border-color: #3b5998
}

.social-link.social-twitter:hover {
  background: #1da1f2;
  border-color: #1da1f2
}

.social-link.social-linkedin:hover {
  background: #0073b2;
  border-color: #0073b2
}

.social-link.social-email:hover,
.social-link.social-google:hover {
  background: #dd4b39;
  border-color: #dd4b39
}

.social-link.social-pinterest:hover {
  background: #bd081c;
  border-color: #bd081c
}

.social-link.social-reddit:hover {
  background: #ff4107;
  border-color: #ff4107
}

.social-link.social-tumblr:hover {
  background: #304e6c;
  border-color: #304e6c
}

.social-link.social-vk:hover {
  background: #6383a8;
  border-color: #6383a8
}

.social-link.social-whatsapp:hover {
  background: #3c8a38;
  border-color: #3c8a38
}

.social-link.social-xing:hover {
  background: #1a7576;
  border-color: #1a7576
}

.social-link.social-instagram:hover {
  background: #7c4a3a;
  border-color: #7c4a3a
}

.no-border .social-link {
  border: 0;
  line-height: 30px
}

.no-border .social-link:not(:hover) {
  background: transparent
}

.inline-links .social-link {
  display: inline-block;
  margin-right: 2rem;
  width: auto;
  height: auto;
  border: 0;
  color: inherit
}

.inline-links .social-link:not(:last-child) {
  margin-right: 2rem
}

.inline-links .social-link:hover {
  background: transparent
}

.inline-links .social-link.social-facebook:hover {
  color: #3b5998
}

.inline-links .social-link.social-twitter:hover {
  color: #1da1f2
}

.inline-links .social-link.social-linkedin:hover {
  color: #0073b2
}

.inline-links .social-link.social-email:hover,
.inline-links .social-link.social-google:hover {
  color: #dd4b39
}

.inline-links .social-link.social-pinterest:hover {
  color: #bd081c
}

.inline-links .social-link.social-reddit:hover {
  color: #ff4107
}

.inline-links .social-link.social-tumblr:hover {
  color: #304e6c
}

.inline-links .social-link.social-vk:hover {
  color: #6383a8
}

.inline-links .social-link.social-whatsapp:hover {
  color: #3c8a38
}

.inline-links .social-link.social-xing:hover {
  color: #1a7576
}

.inline-links .social-link.social-instagram:hover {
  color: #7c4a3a
}

.page-content.with-sidebar {
  padding-bottom: 10rem
}

.right-sidebar-active .right-sidebar .sidebar-close,
.right-sidebar-active .right-sidebar .sidebar-overlay,
.sidebar-active .sidebar .sidebar-close,
.sidebar-active .sidebar .sidebar-overlay,
.top-sidebar-active .top-sidebar .sidebar-close,
.top-sidebar-active .top-sidebar .sidebar-overlay {
  visibility: visible;
  opacity: 1
}

.right-sidebar-active .right-sidebar .sidebar-content,
.sidebar-active .sidebar .sidebar-content,
.top-sidebar-active .top-sidebar .sidebar-content {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  transform: translateX(0);
  opacity: 1
}

.top-sidebar-active .category-sidebar {
  display: none
}

.sidebar-active .page-wrapper,
.sidebar-active .toolbox.fixed {
  margin-left: 250px;
  margin-right: -250px
}

.right-sidebar-active .page-wrapper,
.right-sidebar-active .toolbox.fixed {
  margin-left: -250px;
  margin-right: 250px
}

@media(min-width: 992px) {

  .sidebar-fixed.right-sidebar .sidebar-content,
  .sidebar-fixed.sidebar .sidebar-content {
    top: 88px
  }
}

.sidebar-fixed.shop-sidebar .sidebar-content {
  top: 0
}

.sidebar-content,
.sidebar-overlay,
.sidebar-toggle {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1200
}

.sidebar-overlay {
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .8)
}

.sidebar-close,
.sidebar-overlay {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: visibility .4s, opacity .4s;
  -moz-transition: visibility .4s, opacity .4s;
  transition: visibility .4s, opacity .4s
}

.sidebar-toggle {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  top: 160px;
  width: 40px;
  height: 40px;
  font-size: 1.4rem;
  color: #fff;
  background-color: rgba(28, 26, 26, .8)
}

.sidebar-close {
  position: fixed;
  margin: 0;
  left: -moz-calc(100vw - 52px);
  left: calc(100vw - 52px);
  top: 12px;
  font-size: 3.5rem;
  color: #ccc;
  z-index: 1201
}

.sidebar-close:hover {
  color: #ccc
}

.sidebar-content {
  bottom: 0;
  width: 30rem;
  padding: 2rem;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  transform: translateX(-100%);
  overflow: auto;
  background-color: #fff;
  opacity: 0;
  line-height: 1.3;
  -webkit-transition: opacity .4s, -webkit-transform .4s;
  transition: opacity .4s, -webkit-transform .4s;
  -moz-transition: transform .4s, opacity .4s, -moz-transform .4s;
  transition: transform .4s, opacity .4s;
  transition: transform .4s, opacity .4s, -webkit-transform .4s, -moz-transform .4s
}

.sidebar-content .widget-body {
  margin-bottom: 1.8rem;
  opacity: 1;
  -webkit-transition: opacity .3s;
  -moz-transition: opacity .3s;
  transition: opacity .3s
}

.sidebar-content .sidebar-close {
  display: -moz-box;
  display: flex;
  position: static;
  -moz-box-align: center;
  align-items: center;
  margin-bottom: 2rem;
  text-transform: uppercase;
  letter-spacing: -.025em;
  line-height: 1
}

.sidebar-content .d-icon-times {
  width: 1.6rem;
  height: 1.6rem;
  margin-right: .6rem
}

.sidebar-content .widget.price-with-count .filter-items>li {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: justify;
  justify-content: space-between
}

.sidebar-content .btn-filter {
  padding: .86em 2em;
  border-radius: 2px
}

.sidebar-content .btn-filter:active,
.sidebar-content .btn-filter:focus,
.sidebar-content .btn-filter:hover {
  background-color: #222;
  border-color: #222
}

.right-sidebar {
  -moz-box-ordinal-group: 3;
  order: 2
}

.right-sidebar .sidebar-toggle {
  left: auto;
  right: 0
}

.right-sidebar .sidebar-close {
  left: 50px
}

.right-sidebar .sidebar-content {
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  transform: translateX(100%);
  left: auto;
  right: 0
}

@media(max-width: 991px) {
  .sidebar-content {
    width: 30rem
  }

  .right-sidebar .sidebar-close {
    left: 20px
  }
}

@media(min-width: 992px) {

  .sidebar-fixed .sidebar-close,
  .sidebar-fixed .sidebar-overlay,
  .sidebar-fixed .sidebar-toggle {
    display: none
  }

  .sidebar-fixed .sidebar-content {
    position: sticky;
    overflow: visible;
    padding: 0;
    opacity: 1;
    z-index: 1;
    width: auto;
    -webkit-transform: none;
    -moz-transform: none;
    transform: none
  }
}

@media(max-width: 991px) {
  aside .icon-box-side {
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    flex-direction: row
  }

  aside .icon-box-side .icon-box-icon {
    display: -moz-inline-box;
    display: inline-flex;
    margin: 0 1.5rem 0 0;
    padding: 0
  }

  aside .icon-box-side .icon-box-content {
    text-align: left
  }
}

.right-sidebar .sidebar-content::-webkit-scrollbar {
  width: 0
}

.tag {
  display: inline-block;
  padding: .6rem 1.3rem;
  margin: .5rem 1rem .5rem 0;
  border: 1px solid #ccc;
  font-size: 1.2rem;
  line-height: 1.35;
  letter-spacing: -.01em;
  -webkit-transition: color .3s, border-color .3s;
  -moz-transition: color .3s, border-color .3s;
  transition: color .3s, border-color .3s
}

.tag.active,
.tag:hover {
  color: #222;
  border-color: #222
}

.sidebar-content .form-control {
  border-color: #ccc;
  border-radius: .3rem
}

.widget-collapsible>.widget-title.collapsed {
  padding-bottom: 2.7rem
}

.widget-collapsible>.widget-title.collapsed .widget-collapsible>.widget-title {
  padding-bottom: 0
}

.widget-collapsible>.collapsed>.widget-title,
.widget-collapsible>.collapsing>.widget-title {
  padding-bottom: 2.7rem
}

.widget-collapsible>.collapsed>.widget-title .widget-title,
.widget-collapsible>.collapsing>.widget-title .widget-title {
  padding-bottom: 0
}

.widget-collapsible .toggle-btn {
  display: block;
  position: absolute;
  top: 36px;
  right: 12px;
  padding: 0;
  width: 10px;
  opacity: 1
}

.widget-collapsible .toggle-btn:after,
.widget-collapsible .toggle-btn:before {
  content: "";
  position: absolute;
  border-top: 2px solid #666;
  width: 10px;
  -webkit-transition: -webkit-transform .3s;
  transition: -webkit-transform .3s;
  -moz-transition: transform .3s, -moz-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s, -moz-transform .3s
}

.widget-collapsible>.collapsed .toggle-btn:before,
.widget-collapsible>.collapsing .toggle-btn:before {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  transform: rotate(90deg)
}

.widget-collapsible>.collapsed .toggle-btn:after,
.widget-collapsible>.collapsing .toggle-btn:after {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg)
}

.widget-collapsible .collapsed~.widget-body {
  opacity: .1
}

.widget-collapsible p {
  margin-bottom: 0;
  color: #aaa;
  line-height: 1.86
}

.toolbox-wrap .widget-title,
.widget-collapsible .widget-title {
  position: relative;
  cursor: pointer;
  margin: 0;
  padding: 2.6rem .3rem 1.8rem;
  font-size: 1.8rem;
  font-weight: 600;
  letter-spacing: -.03em;
  text-transform: none;
  -webkit-transition: padding .3s;
  -moz-transition: padding .3s;
  transition: padding .3s;
  border: none;
  border-top: 3px solid #eee
}

.navigation-filter .toolbox-wrap .widget-title {
  border-top: 0
}

.filter-items.search-ul li {
  padding: 13.5px 3px
}

.filter-items li {
  padding: 13px 3px 13px 30px;
  font-size: 1.3rem;
  color: #222
}

.filter-items>li:not(:last-child) {
  border-bottom: 1px solid #eee
}

.filter-items a {
  position: relative;
  display: block
}

.filter-items a:hover {
  color: #222
}

.filter-items .active>a:before {
  content: "";
  color: #fff;
  background-color: #222;
  border-color: #222
}

.filter-items.search-ul a:before {
  content: none
}

.filter-items .children li {
  padding: 5.5px 0
}

.filter-items li span {
  margin-left: .5rem;
  color: #aaa
}

.filter-items .with-ul>a>i {
  content: "";
  position: absolute;
  top: 50%;
  right: .7rem;
  line-height: 0;
  margin: -1.3rem -3px 0 0;
  padding: 1.2rem 5px;
  font-size: 1.2rem;
  font-family: Font Awesome\ 5 Free;
  font-weight: 600;
  -webkit-transition: -webkit-transform .3s;
  transition: -webkit-transform .3s;
  -moz-transition: transform .3s, -moz-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s, -moz-transform .3s
}

.filter-items .active a,
.filter-items .show>a {
  color: #222
}

.filter-items i.expanded,
.filter-items i.expanding {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  transform: rotate(-180deg)
}

.filter-items ul {
  display: none;
  position: relative;
  padding: 1rem 0 0 1.3rem
}

.filter-items ul:before {
  content: "";
  position: absolute;
  left: 2px;
  top: 4px;
  bottom: 3px;
  border-left: 1px solid #eee
}

.filter-items .color {
  top: 50%;
  display: inline-block;
  margin-right: .8rem;
  margin-top: -4.5px;
  width: .9rem;
  height: .9rem;
  border-radius: 50%
}

.widget-vendor-info li span {
  display: inline-block;
  margin-left: 0
}

.widget-vendor-info li span:first-child {
  min-width: 10.5rem;
  padding-right: 1rem;
  color: #333
}

.widget-vendor-info li span.ratings-container {
  margin-bottom: 0;
  padding-right: 0;
  font-size: 1.3rem
}

.widget-vendor-info .seller-name .details {
  color: #222
}

.widget-vendor-info .filter-items li {
  display: -moz-box;
  display: flex;
  padding: 14px 3px 13px 0
}

.widget-contact-vendor .form-control {
  min-height: 4.1rem;
  padding: 1.2rem 2rem;
  border: 1px solid #e1e1e1;
  line-height: 1.5;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 300;
  color: #999;
  -webkit-transition: color .3s, border-color .3s;
  -moz-transition: color .3s, border-color .3s;
  transition: color .3s, border-color .3s
}

.widget-contact-vendor .form-control:focus {
  border-color: #222
}

.widget-contact-vendor .form-control:not(:last-child) {
  margin-bottom: 1rem
}

.widget-contact-vendor .btn {
  margin: .8rem 0 1.2rem;
  padding: .8em 1.65em;
  letter-spacing: -.025em
}

.skel-megamenu,
.skel-menu,
.skeleton-body .skel-cat,
.skeleton-body .skel-post,
.skeleton-body .skel-post-list,
.skeleton-body .skel-post-mask,
.skeleton-body .skel-pro,
.skeleton-body .skel-pro-gallery,
.skeleton-body .skel-pro-list,
.skeleton-body .skel-pro-search,
.skeleton-body .skel-pro-summary,
.skeleton-body .skel-pro-tabs,
.skeleton-body .widget-2 {
  position: relative;
  overflow: hidden;
  width: 100%
}

.skel-megamenu:before,
.skel-menu:before,
.skeleton-body .skel-cat:before,
.skeleton-body .skel-post-list:before,
.skeleton-body .skel-post-mask:before,
.skeleton-body .skel-post:before,
.skeleton-body .skel-pro-gallery:before,
.skeleton-body .skel-pro-list:before,
.skeleton-body .skel-pro-search:before,
.skeleton-body .skel-pro-summary:before,
.skeleton-body .skel-pro-tabs:before,
.skeleton-body .skel-pro:before,
.skeleton-body .widget-2:before {
  content: "";
  display: block;
  width: 100%;
  background-repeat: no-repeat;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(100%, #f4f4f4), color-stop(0, transparent)), -webkit-gradient(linear, left top, left bottom, color-stop(100%, #f4f4f4), color-stop(0, transparent)), -webkit-gradient(linear, left top, left bottom, color-stop(100%, #f4f4f4), color-stop(0, transparent)), -webkit-gradient(linear, left top, left bottom, color-stop(100%, #f4f4f4), color-stop(0, transparent)), -webkit-gradient(linear, left top, left bottom, color-stop(100%, #f4f4f4), color-stop(0, transparent)), -webkit-gradient(linear, left top, left bottom, color-stop(100%, #f4f4f4), color-stop(0, transparent)), -webkit-gradient(linear, left top, left bottom, color-stop(100%, #f4f4f4), color-stop(0, transparent)), -webkit-gradient(linear, left top, left bottom, color-stop(100%, #f4f4f4), color-stop(0, transparent)), -webkit-gradient(linear, left top, left bottom, color-stop(100%, #f4f4f4), color-stop(0, transparent)), -webkit-gradient(linear, left top, left bottom, color-stop(100%, #f4f4f4), color-stop(0, transparent)), -webkit-gradient(linear, left top, left bottom, color-stop(100%, #f4f4f4), color-stop(0, transparent)), -webkit-gradient(linear, left top, left bottom, color-stop(100%, #f4f4f4), color-stop(0, transparent)), -webkit-gradient(linear, left top, left bottom, color-stop(100%, #f4f4f4), color-stop(0, transparent)), -webkit-gradient(linear, left top, left bottom, color-stop(100%, #f4f4f4), color-stop(0, transparent)), -webkit-gradient(linear, left top, left bottom, color-stop(100%, #f4f4f4), color-stop(0, transparent)), -webkit-gradient(linear, left top, left bottom, color-stop(100%, #f4f4f4), color-stop(0, transparent)), -webkit-gradient(linear, left top, left bottom, color-stop(100%, #f4f4f4), color-stop(0, transparent)), -webkit-gradient(linear, left top, left bottom, color-stop(100%, #f4f4f4), color-stop(0, transparent)), -webkit-gradient(linear, left top, left bottom, color-stop(100%, #f4f4f4), color-stop(0, transparent));
  background-image: -moz-linear-gradient(#f4f4f4 100%, transparent 0), -moz-linear-gradient(#f4f4f4 100%, transparent 0), -moz-linear-gradient(#f4f4f4 100%, transparent 0), -moz-linear-gradient(#f4f4f4 100%, transparent 0), -moz-linear-gradient(#f4f4f4 100%, transparent 0), -moz-linear-gradient(#f4f4f4 100%, transparent 0), -moz-linear-gradient(#f4f4f4 100%, transparent 0), -moz-linear-gradient(#f4f4f4 100%, transparent 0), -moz-linear-gradient(#f4f4f4 100%, transparent 0), -moz-linear-gradient(#f4f4f4 100%, transparent 0), -moz-linear-gradient(#f4f4f4 100%, transparent 0), -moz-linear-gradient(#f4f4f4 100%, transparent 0), -moz-linear-gradient(#f4f4f4 100%, transparent 0), -moz-linear-gradient(#f4f4f4 100%, transparent 0), -moz-linear-gradient(#f4f4f4 100%, transparent 0), -moz-linear-gradient(#f4f4f4 100%, transparent 0), -moz-linear-gradient(#f4f4f4 100%, transparent 0), -moz-linear-gradient(#f4f4f4 100%, transparent 0), -moz-linear-gradient(#f4f4f4 100%, transparent 0);
  background-image: linear-gradient(#f4f4f4 100%, transparent 0), linear-gradient(#f4f4f4 100%, transparent 0), linear-gradient(#f4f4f4 100%, transparent 0), linear-gradient(#f4f4f4 100%, transparent 0), linear-gradient(#f4f4f4 100%, transparent 0), linear-gradient(#f4f4f4 100%, transparent 0), linear-gradient(#f4f4f4 100%, transparent 0), linear-gradient(#f4f4f4 100%, transparent 0), linear-gradient(#f4f4f4 100%, transparent 0), linear-gradient(#f4f4f4 100%, transparent 0), linear-gradient(#f4f4f4 100%, transparent 0), linear-gradient(#f4f4f4 100%, transparent 0), linear-gradient(#f4f4f4 100%, transparent 0), linear-gradient(#f4f4f4 100%, transparent 0), linear-gradient(#f4f4f4 100%, transparent 0), linear-gradient(#f4f4f4 100%, transparent 0), linear-gradient(#f4f4f4 100%, transparent 0), linear-gradient(#f4f4f4 100%, transparent 0), linear-gradient(#f4f4f4 100%, transparent 0)
}

.skel-megamenu:after,
.skel-menu:after,
.skeleton-body .skel-cat:after,
.skeleton-body .skel-post-list:after,
.skeleton-body .skel-post-mask:after,
.skeleton-body .skel-post:after,
.skeleton-body .skel-pro-gallery:after,
.skeleton-body .skel-pro-list:after,
.skeleton-body .skel-pro-search:after,
.skeleton-body .skel-pro-summary:after,
.skeleton-body .skel-pro-tabs:after,
.skeleton-body .skel-pro:after,
.skeleton-body .widget-2:after {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(20%, hsla(0, 0%, 100%, 0)), color-stop(50%, hsla(0, 0%, 100%, .8)), color-stop(80%, hsla(0, 0%, 100%, 0)));
  background-image: -moz-linear-gradient(left, hsla(0, 0%, 100%, 0) 20%, hsla(0, 0%, 100%, .8) 50%, hsla(0, 0%, 100%, 0) 80%);
  background-image: linear-gradient(90deg, hsla(0, 0%, 100%, 0) 20%, hsla(0, 0%, 100%, .8) 50%, hsla(0, 0%, 100%, 0) 80%);
  -webkit-animation: skeletonloading 1.5s infinite;
  -moz-animation: skeletonloading 1.5s infinite;
  animation: skeletonloading 1.5s infinite
}

.skeleton-body .widget-2:first-of-type {
  padding-top: 0;
  margin-top: 0
}

.skeleton-body .widget-2:before {
  height: 630px;
  background-size: 100% 30px, 70% 20px, 50% 20px, 80% 20px, 40% 20px, 30% 20px, 50% 20px, 100% 30px, 70% 20px, 50% 20px, 80% 20px, 40% 20px, 30% 20px, 50% 20px;
  background-position: 0 10px, 0 65px, 0 105px, 0 145px, 0 185px, 0 225px, 0 265px, 0 325px, 0 380px, 0 420px, 0 460px, 0 500px, 0 540px, 0 580px
}

.skeleton-body .skel-pro:before {
  padding-top: calc(112.6666666667% + 119px);
  background-size: 100% -moz-calc(100% - 119px), 55% 12px, 85% 18px, 55% 20px, 40% 15px;
  background-size: 100% calc(100% - 119px), 55% 12px, 85% 18px, 55% 20px, 40% 15px;
  background-position: 0 0, 0 -moz-calc(100% - 119px + 22px), 0 -moz-calc(100% - 119px + 48px), 0 -moz-calc(100% - 119px + 75px), 0 -moz-calc(100% - 119px + 100px);
  background-position: 0 0, 0 calc(100% - 119px + 22px), 0 calc(100% - 119px + 48px), 0 calc(100% - 119px + 75px), 0 calc(100% - 119px + 100px)
}

.skeleton-body .skel-pro-search {
  padding: 1.5rem 0;
  border-bottom: 1px solid #ebebeb
}

.skeleton-body .skel-pro-search:before {
  padding-top: 60px;
  background-size: 60px 60px, 60% 30%, 30% 30%;
  background-position: 0 0, 75px 5px, 75px 80%
}

.skeleton-body .skel-pro-search:last-child {
  border-bottom: 0
}

.skeleton-body .skel-pro-summary:before {
  min-height: 500px;
  background-size: 55% 25px, 20% 25px, 70% 30px, 40% 18px, 30% 30px, 40% 15px, 100% 14px, 100% 14px, 100% 14px, 40% 14px, 66% 30px, 70% 30px, 100% 1px, 70% 45px, 100% 1px, 50% 30px;
  background-position: 0 0, 100% 0, 0 34px, 0 75px, 0 115px, 0 155px, 0 190px, 0 215px, 0 240px, 0 265px, 0 310px, 0 350px, 0 400px, 0 420px, 0 485px, 0 506px
}

.skel-quick.skeleton-body .skel-pro-gallery:before {
  background-size: 100% 100%;
  background-position: 0 0
}

.skel-quick.skeleton-body .skel-pro-summary:before {
  background-size: 70% 30px, 40% 18px, 30% 30px, 40% 15px, 100% 14px, 100% 14px, 100% 14px, 40% 14px, 100% 1px, 70% 45px, 100% 1px, 50% 30px;
  background-position: 0 20px, 0 60px, 0 100px, 0 140px, 0 175px, 0 200px, 0 225px, 0 250px, 0 285px, 0 305px, 0 370px, 0 390px;
  min-height: 435px
}

.skeleton-body .skel-pro-tabs:before {
  min-height: 200px;
  background-size: 120px 25px, 200px 25px, 100px 25px, 100% 1px, 100% 15px, 100% 15px, 100% 15px, 80% 15px;
  background-position: 0 0, 140px 0, 360px 0, 0 35px, 0 55px, 0 85px, 0 115px, 0 145px
}

.mfp-product.skeleton-body .skel-pro-gallery:before {
  padding-top: 112.5%;
  background-size: 100% 100%;
  background-position: 0 0
}

.mfp-product.skeleton-body .skel-pro-summary:before {
  padding-top: 112.5%;
  min-height: auto
}

.skeleton-body .skel-post-list:before,
.skeleton-body .skel-post:before {
  padding-top: calc(56.25% + 200px);
  background-size: 100% -moz-calc(100% - 200px), 40% 19px, 60% -moz-calc(3% + 10px), 100% 15px, 100% 15px, 67% 15px, 20% 20px;
  background-size: 100% calc(100% - 200px), 40% 19px, 60% calc(3% + 10px), 100% 15px, 100% 15px, 67% 15px, 20% 20px;
  background-position: 0 0, 0 -moz-calc(100% - 170px), 0 -moz-calc(100% - 143px), 0 -moz-calc(100% - 122px), 0 -moz-calc(100% - 102px), 0 -moz-calc(100% - 82px), 0 -moz-calc(100% - 54px);
  background-position: 0 0, 0 calc(100% - 170px), 0 calc(100% - 143px), 0 calc(100% - 122px), 0 calc(100% - 102px), 0 calc(100% - 82px), 0 calc(100% - 54px)
}

.skeleton-body .skel-cat:before,
.skeleton-body .skel-post-mask:before {
  padding-top: 100%;
  background-size: 100% 100%;
  background-position: 0 0
}

.skeleton-body .skel-pro-gallery:before {
  padding-top: 112.5%;
  background-size: 100% 80%, 23% 18.5%, 23% 18.5%, 23.5% 18.5%, 23.7% 18.5%;
  background-position: 0 0, 0 100%, 33% 100%, 66% 100%, 100% 100%
}

.skel-quick.skeleton-body .skel-pro-gallery:before {
  padding-top: 112.5%
}

.skeleton-body .cols-sm-2>.skel-pro-gallery {
  max-width: none;
  -moz-box-flex: 0;
  flex: 0 0 100%
}

.skeleton-body .cols-sm-2>.skel-pro-gallery:before {
  padding-top: 112.5%;
  background-size: -moz-calc(50% - 10px) -moz-calc(50% - 10px), -moz-calc(50% - 10px) -moz-calc(50% - 10px), -moz-calc(50% - 10px) -moz-calc(50% - 10px), -moz-calc(50% - 10px) -moz-calc(50% - 10px);
  background-size: calc(50% - 10px) calc(50% - 10px), calc(50% - 10px) calc(50% - 10px), calc(50% - 10px) calc(50% - 10px), calc(50% - 10px) calc(50% - 10px);
  background-position: 0 0, 100% 0, 0 100%, 100% 100%
}

.skeleton-body .product-masonry-type>.skel-pro-gallery:before {
  background-size: 100% -moz-calc(42.75% - 15px), -moz-calc(33.3333% - 15px) -moz-calc(13.6% - 15px), -moz-calc(66.6667% - 5px) -moz-calc(28.57% - 15px), -moz-calc(66.6667% - 10px) -moz-calc(28.57% - 15px), -moz-calc(33.3333% - 10px) -moz-calc(13.6% - 15px);
  background-size: 100% calc(42.75% - 15px), calc(33.3333% - 15px) calc(13.6% - 15px), calc(66.6667% - 5px) calc(28.57% - 15px), calc(66.6667% - 10px) calc(28.57% - 15px), calc(33.3333% - 10px) calc(13.6% - 15px);
  padding-top: calc(262.5% + 40px);
  background-position: 0 0, 0 57.8%, 100% 59.5%, 0 99.5%, 100% 90.2%
}

.skeleton-body.sticky-info .skel-pro-gallery:before {
  padding-top: calc(450% + 60px);
  background-size: 100% -moz-calc(25% - 15px), 100% -moz-calc(25% - 15px), 100% -moz-calc(25% - 15px), 100% -moz-calc(25% - 15px);
  background-size: 100% calc(25% - 15px), 100% calc(25% - 15px), 100% calc(25% - 15px), 100% calc(25% - 15px);
  background-position: 0 0, 0 33.333%, 0 66.667%, 0 100%
}

.skeleton-body .pg-gallery .skel-pro-gallery:before {
  padding-top: calc((100% - 40px)*9/24);
  background-size: 32% 100%, 32% 100%, 32% 100%;
  background-position: 0 0, 50% 0, 100% 0
}

.skeleton-body.sticky-both .skel-pro-gallery:before {
  padding-top: calc(450% + 60px);
  background-size: 100% -moz-calc(25% - 15px), 100% -moz-calc(25% - 15px), 100% -moz-calc(25% - 15px), 100% -moz-calc(25% - 15px);
  background-size: 100% calc(25% - 15px), 100% calc(25% - 15px), 100% calc(25% - 15px), 100% calc(25% - 15px);
  background-position: 0 0, 0 33.333%, 0 66.667%, 0 100%
}

.skeleton-body .skel-pro-list:not(.skel-pro):before {
  padding-top: max(25%, 11.3rem);
  background-size: 10rem 11.3rem, 100% 14px, -moz-calc(50% - 7.5rem) 14px, -moz-calc(80% - 11rem) 16px, -moz-calc(86% - 11rem) 12px;
  background-size: 10rem 11.3rem, 100% 14px, calc(50% - 7.5rem) 14px, calc(80% - 11rem) 16px, calc(86% - 11rem) 12px;
  background-position: 0 50%, 11rem -moz-calc(50% - 35px), 11rem -moz-calc(50% - 14px), 11rem -moz-calc(50% + 12px), 11rem -moz-calc(50% + 38px);
  background-position: 0 50%, 11rem calc(50% - 35px), 11rem calc(50% - 14px), 11rem calc(50% + 12px), 11rem calc(50% + 38px)
}

@-webkit-keyframes skeletonloading {
  0% {
    -webkit-transform: skewX(-45deg) translateX(-80%);
    transform: skewX(-45deg) translateX(-80%)
  }

  to {
    -webkit-transform: skewX(-45deg) translateX(80%);
    transform: skewX(-45deg) translateX(80%)
  }
}

@-moz-keyframes skeletonloading {
  0% {
    -moz-transform: skewX(-45deg) translateX(-80%);
    transform: skewX(-45deg) translateX(-80%)
  }

  to {
    -moz-transform: skewX(-45deg) translateX(80%);
    transform: skewX(-45deg) translateX(80%)
  }
}

@keyframes skeletonloading {
  0% {
    -webkit-transform: skewX(-45deg) translateX(-80%);
    -moz-transform: skewX(-45deg) translateX(-80%);
    transform: skewX(-45deg) translateX(-80%)
  }

  to {
    -webkit-transform: skewX(-45deg) translateX(80%);
    -moz-transform: skewX(-45deg) translateX(80%);
    transform: skewX(-45deg) translateX(80%)
  }
}

.mfp-container .mfp-content .quickView-content .skel-pro-single {
  margin-bottom: -2rem
}

.post-mask.skeleton-body:before {
  content: none
}

@media(max-width: 767px) {
  .skeleton-body .pg-gallery .skel-pro-gallery:before {
    padding-top: calc((100% - 20px)*9/16);
    background-size: -moz-calc(50% - 10px) 100%, -moz-calc(50% - 10px) 100%;
    background-size: calc(50% - 10px) 100%, calc(50% - 10px) 100%;
    background-position: 0 0, 100% 0
  }

  .skeleton-body .skel-pro-gallery {
    margin-bottom: 2rem
  }

  .skel-quick>:not(.mfp-s-error)>.mfp-preloader {
    position: relative;
    top: -100%;
    -webkit-transform: none;
    -moz-transform: none;
    transform: none;
    width: 100%;
    left: 0
  }
}

@media(min-width: 767px) {
  .skeleton-body .skel-post-list:before {
    padding-top: 0;
    min-height: 250px;
    background-size: 41% 100%, 20% 15px, 40% 25px, 56.2% 75px, 10% 22px;
    background-position: 0 0, 54.75% 40px, 73% 65px, 100% 105px, 48.6% 195px
  }
}

@media(max-width: 575px) {
  .skeleton-body .pg-gallery .skel-pro-gallery:before {
    padding-top: 112.5%;
    background-size: 100% 100%;
    background-position: 0 0
  }

  .skeleton-body .cols-sm-2>.skel-pro-gallery:before {
    padding-top: calc(450% + 60px);
    background-size: 100% -moz-calc(25% - 15px), 100% -moz-calc(25% - 15px), 100% -moz-calc(25% - 15px), 100% -moz-calc(25% - 15px);
    background-size: 100% calc(25% - 15px), 100% calc(25% - 15px), 100% calc(25% - 15px), 100% calc(25% - 15px);
    background-position: 0 0, 0 33.333%, 0 66.667%, 0 100%
  }
}

@media(min-width: 576px) {
  .skeleton-body .skel-pro-list:before {
    padding-top: max(25%, 315.4666666667px);
    background-size: 28rem 315.4666666667px, 20% 10px, 50% 18px, 25% 20px, 25% 12px, -moz-calc(100% - 28rem) 50px, 123px 50px;
    background-size: 28rem 315.4666666667px, 20% 10px, 50% 18px, 25% 20px, 25% 12px, calc(100% - 28rem) 50px, 123px 50px;
    background-position: 0 50%, 30rem -moz-calc(50% - 95px), 30rem -moz-calc(50% - 73px), 30rem -moz-calc(50% - 46px), 30rem -moz-calc(50% - 21px), 30rem -moz-calc(50% + 20px), 30rem -moz-calc(50% + 95px);
    background-position: 0 50%, 30rem calc(50% - 95px), 30rem calc(50% - 73px), 30rem calc(50% - 46px), 30rem calc(50% - 21px), 30rem calc(50% + 20px), 30rem calc(50% + 95px)
  }
}

@media(min-width: 576px)and (max-width:991px) {
  .skeleton-body.sticky-both>:not(.col-md-6) .skel-pro-gallery:before {
    padding-top: 112.5%;
    min-height: 0;
    background-size: -moz-calc(50% - 10px) -moz-calc(50% - 10px), -moz-calc(50% - 10px) -moz-calc(50% - 10px), -moz-calc(50% - 10px) -moz-calc(50% - 10px), -moz-calc(50% - 10px) -moz-calc(50% - 10px);
    background-size: calc(50% - 10px) calc(50% - 10px), calc(50% - 10px) calc(50% - 10px), calc(50% - 10px) calc(50% - 10px), calc(50% - 10px) calc(50% - 10px);
    background-position: 0 0, 100% 0, 0 100%, 100% 100%
  }
}

@media(min-width: 992px) {
  .skeleton-body .pg-vertical>.skel-pro-gallery:before {
    padding-top: calc((100% - 119px)*9/8);
    background-size: -moz-calc(100% - 119px) 100%, 109px 23.8%, 109px 23.8%, 109px 23.8%, 109px 23.8%;
    background-size: calc(100% - 119px) 100%, 109px 23.8%, 109px 23.8%, 109px 23.8%, 109px 23.8%;
    background-position: 119px 0, 0 0, 0 33%, 0 66%, 0 100%
  }

  .skeleton-body.product-single>:not(.col-md-6) .skel-pro-summary:before {
    min-height: 285px;
    background-size: 30% 30px, 25% 18px, 20% 15px, 48% 14px, 48% 14px, 48% 14px, 20% 14px, 30% 30px, 40% 30px, 40% 30px, 49.6% 1px, 40% 45px, 49.6% 1px, 30% 30px;
    background-position: 0 0, 0 40px, 0 65px, 0 100px, 0 125px, 0 150px, 0 175px, 72% 0, 84% 50px, 84% 100px, 100% 150px, 84% 170px, 100% 235px, 72% 255px
  }

  .skeleton-body.sticky-both>:not(.col-md-6) .skel-pro-summary:before {
    padding-top: calc(225% + 60px);
    background-size: 20% 30px, 24% 30px, 15% 18px, 19% 15px, 22% 36px, 24% 16px, 24% 14px, 24% 14px, 15% 14px, 24% 36px, 24% 36px, 24% 1px, 15% 45px, 24% 45px, 24% 1px, 24% 30px;
    background-position: 0 0, 0 40px, 0 80px, 0 105px, 0 140px, 0 195px, 0 220px, 0 245px, 0 270px, 100% 0, 100% 50px, 100% 100px, 89.4% 120px, 100% 185px, 100% 250px, 100% 270px
  }

  .skeleton-body.sticky-both .woocommerce-product-gallery {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    transform: translateX(-50%)
  }
}

@media(min-width: 768px) {
  .skel-quick.skeleton-body .skel-pro-summary {
    padding-left: 1rem
  }
}

.skel-menu:before {
  margin: 15px 0;
  background-size: 50% 20px, 60% 20px, 40% 20px, 50% 20px, 70% 20px, 60% 20px, 65% 20px;
  background-position: 30px 0, 30px 16%, 30px 33%, 30px 50%, 30px 66%, 30px 83%, 30px 100%
}

.skel-megamenu:before,
.skel-menu:before {
  height: -moz-calc(100% - 30px);
  height: calc(100% - 30px)
}

.skel-megamenu:before {
  width: -moz-calc(100% - 40px);
  width: calc(100% - 40px);
  margin: 15px 20px;
  background-size: 28% 20px, 23% 20px, 25% 20px, 20% 20px, 30% 20px, 25% 20px, 26% 20px, 28% 20px, 20% 20px, 28% 20px, 23% 20px, 25% 20px, 20% 20px, 30% 20px, 25% 20px, 26% 20px, 28% 20px, 20% 20px, 30% 100%;
  background-position: 0 0, 0 12.5%, 0 25%, 0 37.5%, 0 50%, 0 62.5%, 0 75%, 0 87.5%, 0 100%, 48.61% 0, 45.45% 12.5%, 46.66% 25%, 43.75% 37.5%, 50% 50%, 46.66% 62.5%, 47.3% 75%, 48.61% 87.5%, 43.75% 100%, 100% 0
}

.store {
  font-size: 1.3rem;
  line-height: 1.85;
  text-align: center
}

.store a.social-link {
  border-color: transparent
}

.store h4 {
  margin-bottom: 0;
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: -.025em;
  text-transform: uppercase
}

.store figure {
  position: relative
}

.store img {
  width: 100%;
  display: block
}

.tab-content {
  background-color: #fff
}

.nav-tabs {
  display: -moz-box;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #ebebeb
}

.nav-tabs .nav-item .nav-link.active,
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-item:hover .nav-link {
  color: #222
}

.nav-link {
  display: block;
  margin-bottom: -.1rem;
  padding: 1.6rem .2rem;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0;
  text-align: center;
  border-radius: 0;
  border: 0;
  -webkit-transition: border .35s, color .35s, background-color .35s;
  -moz-transition: border .35s, color .35s, background-color .35s;
  transition: border .35s, color .35s, background-color .35s;
  cursor: pointer
}

.nav-item:not(:last-child) {
  margin-right: 2.5rem
}

.nav-fill .nav-item {
  -moz-box-flex: 1;
  flex: 1 1
}

.tab-content {
  position: relative
}

.tab-content>.tab-pane {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 0 !important;
  opacity: 0;
  z-index: -1;
  -webkit-transition: visibility .5s, opacity .5s;
  -moz-transition: visibility .5s, opacity .5s;
  transition: visibility .5s, opacity .5s
}

.tab-content>.tab-pane:not(.active) {
  overflow: hidden;
  visibility: hidden
}

.tab-content>.active {
  position: relative;
  height: auto !important;
  opacity: 1;
  z-index: auto
}

.tab-pane {
  padding: 2.3rem 0;
  color: #666
}

.tab-pane,
.tab-pane p {
  line-height: 1.72
}

.tab-nav-simple .nav-link {
  border-bottom: 2px solid transparent;
  background-color: transparent;
  color: #666
}

.tab-nav-simple .nav-item .nav-link.active,
.tab-nav-simple .nav-item.show .nav-link,
.tab-nav-simple .nav-item:hover .nav-link {
  border-bottom-color: #222
}

.tab-nav-center .nav {
  -moz-box-pack: center;
  justify-content: center
}

.tab-nav-boxed .nav-item {
  margin-right: 0
}

.tab-nav-boxed .nav-link {
  padding-left: 2.4rem;
  padding-right: 2.4rem
}

.tab-nav-round .nav-item:not(:last-child) {
  margin-right: 2px
}

.tab-nav-round .nav-link {
  margin-bottom: 0;
  border-radius: 2.3rem;
  background-color: #f2f3f5
}

.tab-nav-round .nav-tabs {
  border-bottom: 0
}

.tab-boxed .tab-pane {
  padding-left: 2rem;
  padding-right: 2rem
}

.tab-outline .tab-content {
  border: 1px solid #ebebeb
}

.tab-outline .nav-tabs {
  border-bottom: 0
}

.tab-outline .nav-link {
  padding-top: 1.4rem;
  padding-bottom: 1.5rem;
  border: 1px solid transparent;
  border-top: 2px solid transparent
}

.tab-outline .nav-item .nav-link.active,
.tab-outline .nav-item.show .nav-link,
.tab-outline .nav-item:hover .nav-link {
  border-color: #222 #ebebeb #ebebeb
}

.tab-outline2 .nav {
  position: relative;
  z-index: 1
}

.tab-outline2 .tab-content {
  border: 1px solid #ebebeb
}

.tab-outline2 .nav-tabs {
  border-bottom: 0
}

.tab-outline2 .nav-link {
  padding: 1.5rem 2.4rem;
  border: 1px solid transparent
}

.tab-outline2 .nav-item .nav-link.active,
.tab-outline2 .nav-item.show .nav-link,
.tab-outline2 .nav-item:hover .nav-link {
  border-color: #ebebeb #ebebeb #fff
}

.tab-vertical {
  display: -moz-box;
  display: flex
}

.tab-vertical .nav-tabs {
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-flow: column nowrap;
  width: 27.8%;
  border: 0;
  border-right: 2px solid #ebebeb
}

.tab-vertical .tab-content {
  -moz-box-flex: 1;
  flex: 1 1
}

.tab-vertical .nav-item {
  margin-right: 0
}

.tab-vertical .nav-link {
  position: relative;
  margin-right: -2px;
  padding-left: 2rem;
  width: -moz-calc(100% + 2px);
  width: calc(100% + 2px);
  border: 0;
  text-align: left
}

.tab-vertical .nav-link:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  width: 2px;
  height: -moz-calc(100% - 6px);
  height: calc(100% - 6px);
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: transparent;
  z-index: 1
}

.tab-vertical .tab-pane {
  padding: 1.7rem 2rem
}

.tab-vertical.tab-simple .nav-link {
  margin-right: -2px
}

.tab-vertical.tab-simple .nav-link:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  width: 2px;
  height: -moz-calc(100% - 6px);
  height: calc(100% - 6px);
  top: 50%;
  -webkit-transform: translateY(-50%) scale(0);
  -moz-transform: translateY(-50%) scale(0);
  transform: translateY(-50%) scale(0);
  background-color: transparent;
  -webkit-transition: -webkit-transform .3s;
  transition: -webkit-transform .3s;
  -moz-transition: transform .3s, -moz-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s, -moz-transform .3s;
  z-index: 1
}

.tab-vertical.tab-simple .nav-item .nav-link.active,
.tab-vertical.tab-simple .nav-item.show .nav-link,
.tab-vertical.tab-simple .nav-item:hover .nav-link {
  color: #222
}

.tab-vertical.tab-simple .nav-item .nav-link.active:after,
.tab-vertical.tab-simple .nav-item.show .nav-link:after,
.tab-vertical.tab-simple .nav-item:hover .nav-link:after {
  background-color: #222;
  -webkit-transform: translateY(-50%) scale(1);
  -moz-transform: translateY(-50%) scale(1);
  transform: translateY(-50%) scale(1)
}

@media(max-width: 767px) {
  .tab-vertical {
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-flow: column nowrap
  }

  .tab-vertical .nav-tabs,
  .tab-vertical .tab-content {
    width: 100%
  }

  .tab-vertical .nav-tabs {
    border: 0
  }

  .tab-vertical .tab-content {
    padding-left: 0
  }

  .tab-vertical.tab-simple .nav-link:after {
    height: 2px;
    width: 100%;
    background-color: transparent;
    top: -moz-calc(100% - 2px);
    top: calc(100% - 2px);
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    transform: scale(0)
  }

  .tab-vertical.tab-simple .nav-item .nav-link.active:after,
  .tab-vertical.tab-simple .nav-item.show .nav-link:after,
  .tab-vertical.tab-simple .nav-item:hover .nav-link:after {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    transform: scale(1)
  }
}

.tab-inverse .tab-content {
  border-color: #fff
}

.tab-inverse .nav-tabs {
  border: 0
}

.tab-inverse .nav-link {
  margin: 0;
  width: 100%
}

.tab-inverse .nav-item.active .nav-link,
.tab-inverse .nav-item.show .nav-link,
.tab-inverse .nav-item:hover .nav-link {
  background-color: #fff;
  border-color: #fff
}

.tab-inverse.tab-simple .nav-link {
  padding-top: 1.4rem;
  border-top: 2px solid transparent
}

.tab-inverse.tab-simple .nav-item .nav-link.active,
.tab-inverse.tab-simple .nav-item.show .nav-link,
.tab-inverse.tab-simple .nav-item:hover .nav-link {
  border-top-color: #222
}

.tab-nav-solid .nav-link {
  padding-bottom: 1.6rem;
  border: 0
}

.tab-nav-solid .nav-item .nav-link.active,
.tab-nav-solid .nav-item.show .nav-link,
.tab-nav-solid .nav-item:hover .nav-link {
  color: #fff;
  background-color: #222;
  border-color: #222
}

@media(max-width: 767px) {
  .nav-link {
    font-size: 1.4rem
  }

  .tab-nav-boxed .nav-link {
    padding-left: 1rem;
    padding-right: 1rem
  }
}

@media(max-width: 479px) {
  .tab-nav-round .nav-link {
    margin-bottom: .5rem
  }
}

.nav-filters {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  flex-wrap: wrap;
  color: #666
}

.nav-filters li:not(:last-child) {
  margin-right: 1.8rem
}

.nav-filter {
  display: inline-block;
  position: relative;
  padding: .5rem 0;
  font-size: 1.4rem;
  letter-spacing: -.01em;
  line-height: 1;
  -webkit-transition: color .3s;
  -moz-transition: color .3s;
  transition: color .3s
}

.nav-filter.active {
  color: #222
}

.filter-underline .nav-filter {
  border-bottom: 1px solid transparent;
  -webkit-transition: color .3s, border-color .3s;
  -moz-transition: color .3s, border-color .3s;
  transition: color .3s, border-color .3s
}

.filter-underline .nav-filter.active {
  border-color: #222
}

@supports(-ms-ime-align:auto) {
  .product-tabs .nav-item {
    margin-bottom: 1px
  }
}

.testimonial blockquote {
  position: relative;
  margin-bottom: 2rem;
  padding: 1.5rem 2rem;
  font-size: 1.4rem;
  line-height: 1.75;
  color: #666;
  background-color: #f6f7f9;
  overflow: visible
}

.testimonial blockquote:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 30px;
  display: block;
  width: 12px;
  height: 12px;
  background-color: #f6f7f9;
  -webkit-transform: rotate(-45deg) translateY(60%);
  -moz-transform: rotate(-45deg) translateY(60%);
  transform: rotate(-45deg) translateY(60%)
}

.testimonial-info {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  padding: 0 2rem
}

.testimonial-info .testimonial-author-thumbnail {
  margin-right: 1.5rem;
  width: 5rem;
  height: 5rem;
  overflow: hidden;
  border-radius: 50%
}

.testimonial-info img {
  width: 100%;
  height: auto
}

.testimonial-info cite {
  font-size: 1.4rem;
  font-weight: 400;
  font-style: normal;
  color: #222;
  line-height: 1
}

.testimonial-info cite span {
  display: block;
  margin-top: .7rem;
  font-size: 1.2rem;
  font-weight: 400;
  text-transform: none;
  color: #999;
  line-height: 1
}

.testimonial-inversed blockquote:after {
  right: 45px;
  left: auto
}

.testimonial-inversed .testimonial-author-thumbnail {
  -moz-box-ordinal-group: 3;
  order: 2;
  margin-left: 1.5rem;
  margin-right: 0
}

.testimonial-inversed .testimonial-info {
  -moz-box-pack: end;
  justify-content: flex-end
}

.testimonial-inversed cite {
  text-align: right
}

.testimonial-centered {
  padding: 3.8rem 3rem 4rem;
  background-color: #f6f7f9;
  text-align: center
}

.testimonial-centered .testimonial-info {
  display: block;
  margin: 0;
  padding: 0
}

.testimonial-centered .testimonial-author-thumbnail {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  width: 7rem;
  height: 7rem
}

.testimonial-centered blockquote {
  margin-left: auto;
  margin-right: auto;
  background-color: transparent
}

.testimonial-centered blockquote:after {
  content: none
}

.testimonial-centered .testimonial-title {
  margin-bottom: 2.6rem;
  font-size: 1.8rem;
  letter-spacing: 0;
  line-height: 1.2
}

.testimonial-centered blockquote {
  padding: 0
}

.testimonial-centered cite span {
  margin-top: .8rem
}

.testimonial-centered.testimonial-border {
  background-color: transparent;
  -webkit-box-shadow: inset 0 0 0 2px #edeef0;
  box-shadow: inset 0 0 0 2px #edeef0
}

.testimonial-bg {
  padding: 2rem 2rem .8rem;
  background-color: transparent
}

.testimonial-bg .testimonial-author-thumbnail {
  margin-bottom: 2.3rem
}

.testimonial-bg blockquote {
  max-width: 86rem;
  color: hsla(0, 0%, 100%, .8)
}

.testimonial-bg cite {
  text-transform: uppercase;
  font-weight: 600;
  color: #fff
}

.testimonial-bg cite span {
  color: hsla(0, 0%, 100%, .8)
}

.Toastify {
  position: relative;
  z-index: 10001
}

.toast-container.Toastify__toast-container {
  width: auto;
  padding: 0;
  bottom: 1rem;
  left: 3rem
}

.toast-container.Toastify__toast-container .Toastify__toast {
  padding: 0
}

.toast-container.Toastify__toast-container .minipopup-box {
  padding: 2rem
}

.toast-container.Toastify__toast-container .product-media:before {
  padding-top: 100%
}

.toast-container.Toastify__toast-container .product.product-cart {
  margin-bottom: 0
}

.Toastify__toast--success.Toastify__toast {
  border-radius: 5px;
  max-width: 273px
}

.Toastify__toast--success.Toastify__toast .Toastify__toast-body {
  margin: auto 3rem;
  font-size: 1.5rem;
  width: 213px
}

.Toastify__toast {
  margin-bottom: 2rem
}

@media(max-width: 479px) {
  .toast-container.Toastify__toast-container {
    left: 1rem
  }

  .Toastify__toast:not(:last-child) {
    margin-bottom: 1rem
  }

  .Toastify__toast {
    margin-bottom: 0
  }
}

.tooltiptext {
  font-family: Poppins, sans-serif;
  position: absolute;
  padding: 1rem .7rem;
  bottom: 150%;
  left: 48%;
  background-color: #333;
  color: #fff;
  font-size: 1.3rem;
  font-weight: 400;
  letter-spacing: .01em;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 1;
  -webkit-transition: opacity .3s;
  -moz-transition: opacity .3s;
  transition: opacity .3s
}

.tooltip-top:after {
  content: "";
  position: absolute;
  top: 96%;
  left: 50%;
  margin-left: -6px;
  border: 6px solid transparent;
  border-top-color: #333
}

.title {
  display: -moz-box;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  -moz-box-align: center;
  align-items: center;
  text-align: center;
  margin-bottom: 2.6rem;
  text-transform: capitalize;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: -.025em;
  color: #222
}

.title.title-center {
  -moz-box-pack: center;
  justify-content: center
}

.title-line:after {
  -moz-box-flex: 1;
  flex: 1 1;
  margin-left: 3rem;
  content: "";
  height: 2px;
  background-color: #e1e1e1
}

.title-line.title-underline:after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1
}

@media(max-width: 479px) {
  .title-line:before {
    margin-right: 1.5rem
  }

  .title-line:after {
    margin-left: 1.5rem
  }
}

.title-underline {
  display: block;
  text-align: left
}

.title-underline:after {
  margin: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1
}

.title-underline span {
  display: inline-block;
  position: relative;
  z-index: 2;
  line-height: 2.28
}

.title-underline span:after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0;
  background-color: #222
}

.title-wrapper .title {
  margin-bottom: .5rem
}

.title-wrapper p {
  margin-bottom: 2.3rem;
  font-size: 1.4rem;
  line-height: 1.71;
  color: #999
}

.title-simple {
  display: block
}

.title-line:before,
.title-simple:after,
.title-simple:before {
  content: none
}

.title-sm {
  font-size: 2rem
}

.title-icon {
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column
}

.title-icon>i {
  margin-top: 1rem;
  font-size: 1.8rem;
  color: #222
}

.title-icon:after,
.title-icon:before {
  content: none
}

.title-white {
  color: #fff
}

.title-white:after,
.title-white:before {
  background-color: #fff;
  opacity: .1
}

.title-white>.title {
  color: #fff
}

.title-white>.title:after,
.title-white>.title:before {
  background-color: #fff;
  opacity: .1
}

.title-white>p {
  color: #fff;
  opacity: .5
}

.title-link {
  -moz-box-pack: justify;
  justify-content: space-between;
  padding: 1rem 0;
  border-bottom: 1px solid #e1e1e1
}

.title-link:after,
.title-link:before {
  content: none
}

.title-link a {
  color: #444;
  font-size: 1.2rem
}

.title-link a:hover {
  color: #222
}

.title-link i {
  font-size: 1rem
}

.with-link {
  display: -moz-box;
  display: flex;
  -moz-box-pack: justify;
  justify-content: space-between;
  line-height: 1.2
}

.with-link:after {
  background-color: #e1e1e1
}

.with-link a {
  display: -moz-inline-box;
  display: inline-flex;
  -moz-box-align: center;
  align-items: center;
  margin-left: auto;
  font-weight: 700;
  font-size: 13px;
  line-height: 2.05em;
  text-transform: uppercase;
  letter-spacing: -.325px;
  color: #222;
  -webkit-transition: color .3s;
  -moz-transition: color .3s;
  transition: color .3s
}

.with-link a:hover {
  color: #222
}

.with-link i {
  margin-left: .9rem;
  font-size: 1.9rem;
  line-height: 0
}

.vendor-details {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  margin-bottom: 2rem
}

.vendor-logo {
  margin-right: 1rem
}

.vendor-name {
  margin-bottom: .5rem;
  font-weight: 600;
  font-size: 1.5rem;
  letter-spacing: -.025em
}

.vendor-products-count {
  font-weight: 400;
  font-size: 1.2rem;
  color: #666
}

.vendor-products.grid-type {
  display: grid;
  grid-template-columns: repeat(auto-fill, 33.3333333333%);
  grid-auto-rows: 1fr
}

.vendor-products.grid-type .vendor-product {
  grid-column-end: span 1;
  grid-row-end: span 1
}

.store {
  position: relative;
  border-radius: .3rem;
  overflow: hidden;
  margin-bottom: 2rem
}

.store .store-content {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  padding: 2rem 2.5rem;
  background: rgba(0, 0, 0, .45);
  color: #fff
}

.store .store-title {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center
}

.store .store-title a {
  display: inline-block;
  margin-bottom: 0;
  color: #fff;
  text-shadow: rgba(0, 0, 0, .8) 0 1px 0;
  font-size: 25px
}

.store .featured-label {
  width: max-content;
  width: -moz-max-content;
  width: -webkit-max-content;
  display: inline-block;
  margin-left: 1rem;
  padding: .4rem 1rem;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 600;
  background: #2d54a3;
  color: #fff;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 25px -5px #afafaf;
  box-shadow: 0 0 25px -5px #afafaf
}

.store .ratings-container {
  margin: 0 0 1.1rem .2rem;
  font-size: 1.4rem;
  letter-spacing: .3em
}

.store .ratings-full:before {
  content: "" "" "" "" "";
  color: #ff675d
}

.store p {
  margin-bottom: .2rem;
  line-height: 1.65
}

.store .store-phone {
  font-size: 1.3rem
}

.store .store-phone i {
  margin: 0 .3rem .4rem 0;
  font-size: 1.1rem;
  -webkit-transform: rotate(93deg);
  -moz-transform: rotate(93deg);
  transform: rotate(93deg);
  vertical-align: middle
}

.store .store-footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 2.5rem 2rem 2.4rem;
  background: #fff;
  z-index: 90
}

.store .seller-avatar {
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  padding: .6rem;
  position: absolute;
  background: #fff;
  right: 2.5rem;
  bottom: 6rem;
  overflow: hidden;
  -webkit-box-shadow: 0 0 30px -6px #afafaf;
  box-shadow: 0 0 30px -6px #afafaf
}

.store .seller-avatar img {
  border-radius: 50%
}

.store .btn-link {
  text-transform: capitalize
}

.store .btn-link i {
  font-size: 2rem
}

.store-list {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center
}

.store-list .store-header {
  position: relative
}

.store-list .store-header .featured-label {
  position: absolute;
  top: 1rem;
  left: 1.5rem;
  margin-left: 0;
  padding-top: .3rem;
  padding-bottom: .3rem
}

.store-list .store-content {
  position: relative;
  background-color: #fff
}

.store-list .store-title a {
  color: #222
}

.store-list .store-footer {
  position: relative
}

.widget-title {
  margin-bottom: 2rem;
  padding: 1.1rem 0;
  font-size: 1.6rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.2;
  border-bottom: 1px solid #e1e1e1;
  letter-spacing: -.025em
}

.widget-products .widget-title {
  padding: 2.8rem .3rem 1.6rem;
  font-size: 1.8rem;
  font-weight: 600;
  letter-spacing: -.03em
}

.header {
  font-family: Poppins, sans-serif;
  letter-spacing: -.025em;
  background: #fff
}

.header .container,
.header .container-fluid,
.header .inner-wrap {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center
}

.header .inner-wrap {
  width: 100%
}

.header-transparent {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 20;
  background: transparent
}

.header-transparent .fixed {
  background: #222
}

.header-bottom,
.header-middle,
.header-top {
  display: -moz-box;
  display: flex
}

.header-border {
  border-bottom: 1px solid #e1e1e1
}

.has-center .header-left,
.has-center .header-right {
  -moz-box-flex: 1;
  flex: 1 1
}

.has-center .header-right {
  -moz-box-pack: end;
  justify-content: flex-end
}

.has-center .header-center {
  margin-left: 2rem;
  margin-right: 2rem
}

.header-left {
  -moz-box-flex: 1;
  flex: 1 1
}

.header-center,
.header-left,
.header-right {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center
}

.header-right {
  margin-left: auto
}

.header-right a {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center
}

.header-top {
  border-bottom: 1px solid #e1e1e1;
  font-size: 1.2rem;
  letter-spacing: -.025em
}

.header-top .header-right>* {
  margin-left: 2.3rem
}

.header-top .header-right>:first-child {
  margin-left: 0
}

.header-top .divider {
  margin: 0 -.3rem 0 2rem
}

.header-top .contact {
  margin-right: .2rem;
  line-height: 1
}

.header-top .contact i {
  margin-right: .7rem;
  font-size: 1.7rem
}

.header-top .help {
  line-height: 1
}

.header-top .help i {
  margin-right: .7rem;
  font-size: 1.7rem
}

.header-top .login-link i,
.header-top .register-link i {
  margin-right: .9rem;
  font-size: 1.5rem
}

.header-top .delimiter {
  margin: 0 .5rem;
  color: #aaa
}

.header-top .wishlist {
  margin-right: 2rem
}

.header-top .wishlist i {
  margin-right: .6rem;
  font-size: 1.7rem
}

.currency-dropdown a,
.language-dropdown a {
  white-space: nowrap
}

.header-middle {
  padding-top: 2.7rem;
  padding-bottom: 2.7rem;
  color: #222;
  background: #fff;
  font-size: 1.2rem;
  font-weight: 700
}

.header-middle .login-link {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  margin-right: 2rem;
  padding-bottom: .2rem
}

.header-middle .login-link span {
  cursor: pointer
}

.header-middle .login-link i {
  font-size: 2.3rem
}

.header-middle .login-link:hover {
  color: #222
}

.header-middle .logo {
  margin-right: 8rem;
  margin-bottom: .1rem
}

.header-middle .compare {
  margin-right: 3.5rem
}

.header-middle .compare i {
  font-size: 3.2rem;
  margin-bottom: .1rem
}

.header-middle .main-nav {
  margin-right: 1.5rem
}

.header-middle .header-right .cart-dropdown>a:after {
  content: none
}

.header-middle .icon-box {
  margin-right: 1.8rem;
  padding-bottom: .1rem;
  -webkit-transition: none;
  -moz-transition: none;
  transition: none
}

.header-middle .icon-box:hover .icon-box-icon,
.header-middle .icon-box:hover .icon-box-title,
.header-middle .icon-box:hover p {
  color: inherit
}

.header-middle .icon-box-icon,
.header-middle .icon-box-title,
.header-middle p {
  -webkit-transition: color .3s;
  -moz-transition: color .3s;
  transition: color .3s
}

.header-middle .icon-box-icon {
  margin-bottom: 0;
  padding: 0;
  font-size: 3rem
}

.header-middle .icon-box-title {
  margin-bottom: .5rem;
  font-size: 1.1rem;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 1;
  letter-spacing: -.027em
}

.header-middle p {
  font-size: 1.4rem;
  font-weight: 700;
  color: #222;
  letter-spacing: -.0428em;
  line-height: 1
}

.logo {
  max-width: 200px
}

.logo img {
  display: block
}

.header-bottom {
  padding-bottom: 1.5rem;
  color: #222;
  background: #fff;
  font-size: 1.4rem;
  font-weight: 700
}

.header-bottom .header-right {
  margin-right: .3rem
}

.header-bottom .header-right i {
  margin-right: 1rem;
  font-size: 2.4rem;
  font-weight: 700
}

.welcome-msg {
  margin: 0;
  padding: 1.1rem 0;
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
  letter-spacing: -.0125em;
  line-height: 1.5;
  white-space: nowrap
}

.header-search {
  position: relative
}

.header-search .input-wrapper {
  display: -moz-box;
  display: flex;
  position: relative
}

.header-search input.form-control {
  -moz-box-flex: 1;
  flex: 1 1;
  min-width: 40px;
  padding: 1.1rem 1.5rem;
  border: 0;
  background-color: #eee;
  font-size: 1.3rem;
  letter-spacing: inherit
}

.header-search .btn-search {
  padding: 0;
  min-width: 40px;
  background-color: #222;
  border: 0;
  color: #fff;
  font-size: 2rem
}

.header-search .btn-search i {
  margin: 0 .1rem .6rem 0;
  vertical-align: middle;
  font-size: 2rem
}

.header-search .select-box {
  background-color: #f4f4f4
}

.header-search .select-box select {
  position: relative;
  padding-left: 1.1rem;
  padding-right: 3rem;
  border: 0;
  font-weight: 400;
  font-size: 1.3rem;
  letter-spacing: 0;
  z-index: 1
}

.header-search.hs-toggle .input-wrapper {
  position: absolute;
  right: -1.5rem;
  top: 100%;
  min-width: 31rem;
  padding: 1.5rem;
  height: auto;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 35px rgba(0, 0, 0, .1);
  box-shadow: 0 2px 35px rgba(0, 0, 0, .1);
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translate3d(0, -10px, 0);
  -moz-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
  z-index: 100;
  -webkit-transition: visibility .4s .2s, opacity .4s .2s, -webkit-transform .4s .2s;
  transition: visibility .4s .2s, opacity .4s .2s, -webkit-transform .4s .2s;
  -moz-transition: transform .4s .2s, visibility .4s .2s, opacity .4s .2s, -moz-transform .4s .2s;
  transition: transform .4s .2s, visibility .4s .2s, opacity .4s .2s;
  transition: transform .4s .2s, visibility .4s .2s, opacity .4s .2s, -webkit-transform .4s .2s, -moz-transform .4s .2s
}

.header-search.hs-toggle:hover {
  color: #222
}

.header-search.hs-toggle .form-control {
  padding: 1.4rem 2rem;
  background-color: #f4f4f4
}

.header-search.hs-toggle .btn-search {
  background-color: #f4f4f4;
  min-width: 5rem;
  font-size: 1.8rem;
  color: #222;
  border-radius: 0
}

.header-search.hs-toggle .btn-search i {
  margin-bottom: .4rem;
  font-size: inherit
}

.header-search.hs-expanded {
  max-width: 65rem;
  width: 100%;
  margin: 0 auto;
  -moz-box-flex: 1;
  flex: 1 1
}

.header-search.hs-expanded .input-wrapper {
  width: 100%;
  border: 2px solid #222;
  border-radius: .5rem;
  height: 4.5rem
}

.header-search.hs-expanded .select-box {
  width: 13.2rem;
  border-radius: .5rem 0 0 .5rem;
  color: #666
}

.header-search.hs-expanded .select-box,
.header-search.hs-expanded .select-box select {
  cursor: pointer
}

.header-search.hs-expanded .select-box:before {
  right: 11px;
  font-size: 1rem
}

.header-search.hs-expanded .select-box:after {
  content: "";
  display: block;
  position: absolute;
  top: 8px;
  right: -3px;
  height: 25px;
  border-right: 1px solid #e1e1e1
}

.header-search.hs-expanded input.form-control {
  -moz-box-flex: 1;
  flex: 1 1;
  min-height: 100%;
  padding-left: 1.9rem;
  padding-right: 1.9rem;
  letter-spacing: 0;
  color: #ababab;
  background-color: #f4f4f4
}

.header-search.hs-expanded .btn-search {
  min-width: 4.7rem;
  background-color: #f4f4f4;
  color: #444;
  font-size: 1.6rem
}

.header-search.hs-expanded .btn-search:hover {
  color: #222
}

.header-search.search-left .input-wrapper {
  left: 0;
  right: auto
}

.header-search.hs-simple {
  -moz-box-flex: 1;
  flex: 1 1;
  margin-right: 2rem;
  max-width: 52.2rem
}

.header-search.hs-simple input.form-control {
  padding: 0 5rem 0 1.55rem;
  border-radius: 5px;
  border: 2px solid #cbcbcb;
  font-family: inherit;
  background-color: #fff;
  color: #999
}

.header-search.hs-simple .btn-search {
  position: absolute;
  background: transparent;
  color: #333;
  min-width: 48px;
  height: 100%;
  border-radius: 5px;
  right: 0
}

.header-search.hs-simple .btn-search:hover {
  color: #222
}

.header-search.hs-toggle.show .input-wrapper,
.header .header-search.hs-toggle:hover .input-wrapper {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
  visibility: visible;
  -webkit-transition: -webkit-transform .4s;
  transition: -webkit-transform .4s;
  -moz-transition: transform .4s, -moz-transform .4s;
  transition: transform .4s;
  transition: transform .4s, -webkit-transform .4s, -moz-transform .4s;
  opacity: 1
}

.mobile-search {
  display: none
}

.mobile-search .search-toggle i {
  font-size: 2rem;
  vertical-align: middle
}

.hs-toggle.dir-up:after {
  top: auto;
  bottom: 100%;
  border-bottom-color: transparent;
  border-top-color: #fff
}

.hs-toggle.dir-up .input-wrapper {
  top: auto;
  bottom: 100%;
  -webkit-transform: translate3d(0, 10px, 0);
  -moz-transform: translate3d(0, 10px, 0);
  transform: translate3d(0, 10px, 0)
}

.search-toggle {
  display: inline-block;
  vertical-align: middle;
  padding: 1.1rem 0;
  -webkit-transition: color .3s;
  -moz-transition: color .3s;
  transition: color .3s
}

.search-toggle span {
  cursor: pointer
}

.search-toggle i {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: .9
}

.search-toggle.with-label {
  display: -moz-inline-box;
  display: inline-flex;
  -moz-box-align: center;
  align-items: center
}

.search-toggle.with-label i {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 2px;
  font-size: 1.6rem
}

.search-toggle.with-label span {
  margin-left: .5rem;
  cursor: pointer;
  font-weight: inherit;
  letter-spacing: inherit;
  text-transform: uppercase
}

.live-search-list {
  display: none;
  left: 0;
  right: 0;
  width: auto;
  position: absolute;
  top: 100%;
  min-width: 200px;
  z-index: 1024;
  -webkit-box-shadow: 7px 7px 20px 2px rgba(0, 0, 0, .1);
  box-shadow: 7px 7px 20px 2px rgba(0, 0, 0, .1)
}

.header-search-popup .live-search-list {
  top: 114%
}

.live-search-list .product-price {
  font-size: 13px
}

.live-search-list .old-price {
  font-size: 12px;
  text-decoration: line-through
}

.live-search-list .product-price {
  margin-bottom: 0
}

.dir-up .live-search-list {
  top: auto;
  bottom: -moz-calc(100% + 3px);
  bottom: calc(100% + 3px)
}

.header .header-search .search-toggle {
  display: inline
}

.header-search.show-results .live-search-list {
  display: block;
  max-height: 350px;
  overflow-y: auto
}

.autocomplete-suggestions {
  -webkit-box-shadow: 0 10px 20px 5px rgba(0, 0, 0, .05);
  box-shadow: 0 10px 20px 5px rgba(0, 0, 0, .05);
  color: #8d8d8d;
  background-color: #f4f4f4;
  border-color: #fff;
  padding: 15px;
  border-radius: 0;
  max-height: 70vh;
  overflow-x: hidden;
  overflow-y: auto
}

.autocomplete-suggestions::-webkit-scrollbar {
  width: 5px
}

.autocomplete-suggestions::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: hsla(0, 0%, 80%, .5)
}

.autocomplete-suggestion {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  color: #8d8d8d;
  background-color: #f4f4f4;
  padding: 10px;
  line-height: 1.5em;
  min-height: 0;
  text-align: left;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, .05);
  white-space: normal;
  -webkit-transition: background-color .3s;
  -moz-transition: background-color .3s;
  transition: background-color .3s
}

.autocomplete-suggestion .search-price {
  color: #222529;
  font-weight: 600
}

.autocomplete-suggestion img {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 10px;
  object-fit: cover
}

.autocomplete-suggestion img[lazy=loading] {
  padding-top: 40px !important
}

.autocomplete-suggestion img+.search-name {
  padding-left: .5em;
  font-size: 1.4rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.autocomplete-suggestion .search-name {
  -moz-box-flex: 1;
  flex: 1 1;
  color: #222
}

.autocomplete-suggestion:hover {
  background-color: #ededed
}

.autocomplete-suggestion:last-child {
  border-bottom: none
}

.call {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center
}

.call span {
  font-weight: inherit;
  text-transform: uppercase;
  cursor: pointer
}

.call i+span {
  margin-left: .9rem
}

.call i {
  margin: 0 .2rem .2rem 0;
  font-size: 1.8rem;
  cursor: pointer
}

.call:hover {
  color: #222
}

.wishlist {
  margin-right: 1.5rem
}

.wishlist i {
  font-size: 2.8rem;
  cursor: pointer
}

.wishlist:hover {
  color: #222
}

@keyframes fixedTop {
  0% {
    margin-top: -60px
  }

  to {
    margin-top: 0
  }
}

@keyframes fixedBottom {
  0% {
    margin-top: -60px
  }

  to {
    margin-top: 0
  }
}

.sticky-header.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 1.4rem;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, .1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, .1);
  z-index: 1100;
  -webkit-animation: fixedTop .4s;
  -moz-animation: fixedTop .4s;
  animation: fixedTop .4s
}

@media(max-width: 991px) {
  .sticky-wrapper {
    height: auto !important
  }
}

.mobile-menu-toggle {
  display: none;
  margin-right: 2rem;
  font-size: 2.5rem;
  color: #222
}

.mobile-menu-toggle.menu-bar:after,
.mobile-menu-toggle.menu-bar:before {
  content: "";
  display: block;
  width: 100%
}

.mobile-menu-toggle.menu-bar:before {
  height: 11px;
  border-top: 2px solid;
  border-bottom: 2px solid
}

.mobile-menu-toggle.menu-bar:after {
  height: 9px;
  border-bottom: 2px solid
}

@media(max-width: 1199px) {
  .header-middle .logo {
    margin-right: 2rem
  }
}

@media(max-width: 991px) {
  .header .main-nav {
    display: none
  }

  .header-top .header-left {
    margin-right: 2rem
  }

  .header-middle .header-right {
    -moz-box-pack: end;
    justify-content: flex-end
  }

  .header-middle .header-right .divider {
    display: none
  }

  .header-middle .header-center {
    margin-left: auto;
    margin-right: auto
  }

  .header-middle .compare {
    margin-right: 1.5rem
  }

  .mobile-menu-toggle {
    display: block
  }
}

@media(max-width: 767px) {
  .header-middle .mobile-search {
    display: block
  }

  .header-middle .header-search,
  .header-top .delimiter,
  .header-top .divider,
  .header-top .login-link,
  .header-top .register-link {
    display: none
  }
}

@media(max-width: 480px) {
  .header-top .header-left {
    position: relative;
    overflow: hidden
  }

  .header-middle .logo {
    margin-right: 0
  }

  .header-middle .divider,
  .header-middle .wishlist {
    display: none
  }

  .header-middle .icon-box {
    margin-right: 1rem
  }

  .welcome-msg {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation: show_msg_first 6s linear 2s 1, show_msg 12s linear 8s infinite;
    -moz-animation: show_msg_first 6s linear 2s 1, show_msg 12s linear 8s infinite;
    animation: show_msg_first 6s linear 2s 1, show_msg 12s linear 8s infinite
  }
}

@-webkit-keyframes show_msg_first {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }

  to {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%)
  }
}

@-moz-keyframes show_msg_first {
  0% {
    -moz-transform: translateX(0);
    transform: translateX(0)
  }

  to {
    -moz-transform: translateX(-100%);
    transform: translateX(-100%)
  }
}

@keyframes show_msg_first {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    transform: translateX(0)
  }

  to {
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    transform: translateX(-100%)
  }
}

@-webkit-keyframes show_msg {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%)
  }

  to {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%)
  }
}

@-moz-keyframes show_msg {
  0% {
    -moz-transform: translateX(100%);
    transform: translateX(100%)
  }

  to {
    -moz-transform: translateX(-100%);
    transform: translateX(-100%)
  }
}

@keyframes show_msg {
  0% {
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    transform: translateX(100%)
  }

  to {
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    transform: translateX(-100%)
  }
}

.label-down {
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  -moz-box-align: center;
  align-items: center
}

.label-down i {
  display: inline-block
}

.label-down span {
  display: block
}

.label-down i+span {
  margin-top: .7rem
}

.label-block span {
  display: block
}

.label-block .cart-name {
  margin-bottom: .5rem;
  font-size: 1.1rem;
  font-weight: 400;
  text-transform: capitalize
}

.label-block .cart-name :after {
  content: none
}

.label-block .cart-price {
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: -.05em
}

.label-block.cart-toggle i {
  font-size: 3rem
}

.mobile-link {
  display: block;
  margin-right: 0;
  margin-bottom: -3px
}

.mobile-link i {
  font-size: 2.4rem
}

.mobile-link+.mobile-link {
  margin-left: 2rem
}

.mobile-link:hover {
  color: #222
}

@media(min-width: 768px) {
  .mobile-link {
    display: none
  }
}

.dropdown {
  position: relative
}

.dropdown.show .dropdown-box,
.dropdown:hover .dropdown-box {
  visibility: visible;
  opacity: 1;
  top: 100%;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0)
}

.dropdown.show>a,
.dropdown:hover>a {
  color: #222
}

.dropdown a {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center
}

.dropdown a .dropdown-image {
  max-width: 1.4rem;
  margin-right: .7rem;
  height: auto
}

.dropdown>a {
  line-height: 1;
  padding: 9px 0
}

.dropdown>a:after {
  display: inline-block;
  margin-left: 8px;
  font-family: Font Awesome\ 5 Free;
  font-weight: 600;
  font-size: 10px;
  line-height: 1;
  content: ""
}

.dropdown li.active>a,
.dropdown li:hover>a {
  color: #222
}

.dropdown.dir-up:after {
  border-bottom-color: transparent;
  border-top: 11px solid #fff;
  -webkit-transform: translate3d(-50%, 8px, 0);
  -moz-transform: translate3d(-50%, 8px, 0);
  transform: translate3d(-50%, 8px, 0)
}

.dropdown.dir-up.show .dropdown-box,
.dropdown.dir-up:hover .dropdown-box {
  top: auto;
  bottom: 100%
}

.dropdown.dir-up.show:after,
.dropdown.dir-up:hover:after {
  top: auto;
  bottom: -moz-calc(100% - 20px);
  bottom: calc(100% - 20px);
  -webkit-transform: translate3d(-50%, 0, 0);
  -moz-transform: translate3d(-50%, 0, 0);
  transform: translate3d(-50%, 0, 0)
}

.dropdown-box {
  position: absolute;
  right: 0;
  top: -9999px;
  margin: 0;
  padding: .5rem 0;
  color: #666;
  background-color: #fff;
  -webkit-box-shadow: 0 10px 30px 2px rgba(0, 0, 0, .2);
  box-shadow: 0 10px 30px 2px rgba(0, 0, 0, .2);
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity .2s, visibility .2s, -webkit-transform .2s ease-out;
  transition: opacity .2s, visibility .2s, -webkit-transform .2s ease-out;
  -moz-transition: transform .2s ease-out, opacity .2s, visibility .2s, -moz-transform .2s ease-out;
  transition: transform .2s ease-out, opacity .2s, visibility .2s;
  transition: transform .2s ease-out, opacity .2s, visibility .2s, -webkit-transform .2s ease-out, -moz-transform .2s ease-out;
  -webkit-transform: translate3d(0, -10px, 0);
  -moz-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0)
}

.dropdown-box a {
  padding: .6rem 1rem
}

.dropdown-box li {
  font-size: inherit;
  line-height: 1.1
}

@media(min-width: 992px) {
  .dropdown-expanded:after {
    content: none
  }

  .dropdown-expanded:before {
    position: absolute;
    content: "";
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);
    left: -2.1rem;
    width: 1px;
    height: 2.5rem;
    background-color: #e1e1e1
  }

  .dropdown-expanded>a {
    display: none
  }

  .dropdown-expanded .dropdown-box {
    position: static;
    display: -moz-box;
    display: flex;
    visibility: visible;
    opacity: 1;
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 0;
    padding: 9px 0;
    -webkit-transform: none;
    -moz-transform: none;
    transform: none;
    color: inherit
  }

  .dropdown-expanded .dropdown-box a {
    padding: 0;
    letter-spacing: -.025em
  }

  .dropdown-expanded .dropdown-box>li {
    margin-right: 2.3rem
  }

  .dropdown-expanded .dropdown-box>li:last-child {
    margin-right: 0
  }
}

@media(max-width: 991px) {
  .dropdown.dropdown-expanded li:hover>a {
    color: #222
  }
}

.cart-dropdown>a {
  padding: .7rem 0
}

.cart-dropdown .cart-toggle {
  padding: .6rem 0 .7rem
}

.cart-dropdown .cart-toggle:after {
  content: none
}

.cart-dropdown .cart-label {
  display: block;
  cursor: pointer;
  margin: 0 1rem 0 0;
  font-weight: inherit;
  text-transform: uppercase;
  letter-spacing: -.025em
}

.cart-dropdown .minicart-icon,
.cart-dropdown .minicart-icon2 {
  display: inline-block;
  font-size: 1.2rem;
  color: #222
}

.cart-dropdown .cart-count {
  display: inline-block;
  -webkit-transition: color .4s;
  -moz-transition: color .4s;
  transition: color .4s;
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 25px;
  color: #222
}

.cart-dropdown .cart-header {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 1.3rem
}

.cart-dropdown .cart-title {
  margin-bottom: 0;
  font-size: 1.6rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: -.018em
}

.cart-dropdown .btn-close {
  font-size: 12px;
  font-weight: 600;
  color: #999
}

.cart-dropdown .btn-close:active,
.cart-dropdown .btn-close:focus,
.cart-dropdown .btn-close:hover {
  color: #222
}

.cart-dropdown .btn-close i {
  margin-right: 0;
  font-size: 1.9rem
}

.cart-dropdown .cart-total {
  display: -moz-box;
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2.1rem;
  padding: 1.7rem 0 1.5rem;
  border-top: 1px solid #edeef0;
  border-bottom: 1px solid #edeef0;
  font-size: 1.4rem;
  line-height: 1;
  letter-spacing: normal
}

.cart-dropdown .cart-total label {
  margin: 0 auto 0 .3rem;
  line-height: inherit;
  color: #666;
  font-weight: 400
}

.cart-dropdown .cart-total .price {
  font-weight: 700;
  font-size: 1.6rem;
  color: #222
}

.cart-dropdown .cart-action {
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
  -moz-column-gap: 10px;
  column-gap: 10px;
  text-align: center
}

.cart-dropdown .cart-action .btn {
  display: -moz-box;
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
  border-radius: 3px;
  padding: .9em 2em;
  line-height: 1.5;
  letter-spacing: .01em
}

.cart-dropdown .cart-action .btn.btn-link {
  display: inline-block;
  margin-bottom: 2rem;
  padding: 0;
  border-bottom: 2px solid #222;
  border-radius: 0;
  text-transform: capitalize;
  line-height: 1.3
}

.cart-dropdown .cart-action .btn.btn-link:active,
.cart-dropdown .cart-action .btn.btn-link:focus,
.cart-dropdown .cart-action .btn.btn-link:hover {
  color: #222
}

.cart-dropdown i {
  font-size: 2.4rem
}

.cart-dropdown:hover .minicart-icon {
  background: #222
}

.cart-dropdown:hover .minicart-icon:before {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  transform: rotateY(180deg)
}

.cart-dropdown:hover .cart-count {
  color: #fff
}

.cart-dropdown .dropdown-box {
  right: -1rem;
  padding: 3rem;
  min-width: 33.5rem
}

.cart-dropdown .products {
  overflow-x: hidden;
  margin-right: -5px;
  padding-right: 5px
}

.cart-dropdown.cart-dropdown-white .cart-count,
.cart-dropdown.cart-dropdown-white .cart-label,
.cart-dropdown.cart-dropdown-white .cart-price {
  color: #fff
}

.cart-dropdown.cart-dropdown-white .minicart-icon {
  border-color: #fff
}

.cart-dropdown.cart-dropdown-white:hover .minicart-icon {
  background-color: #fff
}

.cart-dropdown.cart-dropdown-white:hover .cart-count {
  color: #222
}

.cart-dropdown.type2 .cart-toggle {
  -moz-box-align: center;
  align-items: center
}

.cart-dropdown.type2:hover {
  color: #222
}

.cart-dropdown.type2 .cart-count {
  position: absolute;
  right: -8px;
  top: 3px;
  width: 1.5rem;
  height: 1.5rem;
  font-size: .9rem;
  line-height: 1.7;
  text-align: center;
  border-radius: 50%;
  background-color: #222;
  color: #fff;
  z-index: 1
}

.cart-dropdown.type2 .label-block .cart-count {
  width: 1.9rem;
  height: 1.9rem;
  font-size: 1.1rem;
  line-height: 1.8rem
}

.cart-dropdown.type2 .products {
  max-height: 20rem
}

.cart-dropdown.cart-offcanvas .products {
  max-height: -moz-calc(100vh - 29rem);
  max-height: calc(100vh - 29rem)
}

.cart-dropdown.type3 .cart-toggle {
  padding: 1.5rem;
  background-color: #222;
  color: hsla(0, 0%, 100%, .8);
  -webkit-transition: .3s;
  -moz-transition: .3s;
  transition: .3s
}

.cart-dropdown.type3 .cart-toggle i {
  font-size: 1.5rem;
  margin-right: 7px
}

.cart-dropdown.cart-offcanvas .dropdown-box {
  position: fixed;
  top: 0;
  right: -34rem;
  max-width: 34rem;
  width: 100%;
  height: 110vh;
  min-width: auto;
  padding: 2.9rem 3rem;
  opacity: 1;
  visibility: visible;
  -webkit-transition: right .3s;
  -moz-transition: right .3s;
  transition: right .3s;
  -webkit-transform: none;
  -moz-transform: none;
  transform: none;
  z-index: 2999;
  -webkit-box-shadow: none;
  box-shadow: none
}

.cart-dropdown.cart-offcanvas .cart-overlay {
  position: fixed;
  left: 0;
  width: 100vw;
  top: -10vh;
  height: 120vh;
  background: rgba(0, 0, 0, .3);
  z-index: 2998;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity .3s;
  -moz-transition: opacity .3s;
  transition: opacity .3s
}

.cart-dropdown.cart-offcanvas .btn:not(.btn-close) {
  margin-bottom: 1rem
}

.cart-dropdown.cart-offcanvas .product-cart {
  margin-top: 2rem
}

.cart-dropdown.cart-offcanvas.opened .dropdown-box {
  right: 0
}

.cart-dropdown.cart-offcanvas.opened .cart-overlay {
  opacity: 1;
  visibility: visible
}

@media(max-width: 479px) {
  .cart-dropdown.cart-offcanvas .dropdown-box {
    max-width: 30.4rem
  }
}

.product.product-cart-header {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: justify;
  justify-content: space-between;
  padding-bottom: 1.3rem;
  border-bottom: 1px solid #edeef0
}

.product.product-cart-header a,
.product.product-cart-header span {
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: -.025em;
  line-height: 1;
  color: #222529
}

.product.product-cart-header a {
  padding: 0;
  color: #222529;
  line-height: 1
}

.cart-menu-product-details {
  font-weight: 300 !important;
  margin-bottom: 5%;
}

.cart-value {
  text-align: right;

}

.product.product-cart-header a:hover {
  text-decoration: underline
}

.product.product-cart {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  font-size: 1.3rem
}

.product.product-cart:not(:first-child) {
  margin-top: 2rem
}

.product.product-cart .product-media {
  position: static;
  width: 8rem;
  height: 9rem;
  margin-right: 1.5rem
}

.product.product-cart .product-media a {
  padding: 0
}

.product.product-cart .product-detail {
  -moz-box-flex: 1;
  flex: 1 1;
  margin: 0 1rem 0 0
}

.product.product-cart .product-name {
  white-space: normal;
  padding: 0;
  margin-bottom: .9rem;
  margin-right: 1rem;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.34;
  letter-spacing: -.35px;
  font-family: Poppins, sans-serif;
  color: #666;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical
}

.product.product-cart .product-name:hover {
  color: #222
}

.product.product-cart .price-box {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  font-size: 1.6rem;
  line-height: 1
}

.product.product-cart .product-price {
  font-size: 1.6rem;
  letter-spacing: -.35px
}

.product.product-cart .product-quantity {
  -moz-box-align: center;
  align-items: center;
  display: -moz-box;
  display: flex;
  margin-right: 1rem;
  font-weight: 400
}

.product.product-cart .product-quantity:after {
  margin-left: 1rem;
  content: "X";
  text-transform: none;
  line-height: 0;
  font-size: 1.5rem
}

.product.product-cart .product-price {
  margin: 0;
  color: #222
}

.product.product-cart .btn-close {
  position: absolute;
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  text-align: center;
  width: 2.2rem;
  height: 2.2rem;
  top: .9rem;
  right: -.4rem;
  border-radius: 50%;
  background-color: #fff;
  color: #222;
  font-size: 1.2rem;
  border: 1px solid #ccc
}

.product.product-cart .btn-close i {
  margin-right: 4px;
  margin-top: 0;
  font-size: 1.2rem
}

.product.product-cart .btn-close:hover {
  color: #222;
  border-color: #222
}

.product.product-cart:last-child {
  margin-bottom: 0
}

@media(max-width: 991px) {
  .cart-dropdown .cart-label {
    display: none
  }
}

@media(max-width: 575px) {
  .cart-dropdown .product .product-media {
    margin-right: 1rem
  }

  .cart-dropdown .dropdown-box {
    min-width: 31rem
  }

  .cart-dropdown .cart-total {
    font-size: 1.3rem
  }
}

.category-dropdown>a {
  padding: 1.7rem;
  background: #222
}

.category-dropdown>a:after {
  content: none
}

.category-dropdown>a i {
  font-size: 1.8rem
}

.category-dropdown>a span {
  margin-left: 1rem
}

.category-dropdown .dropdown-box {
  padding: 0;
  left: 0;
  min-width: 28rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #f4f4f4;
  -webkit-transition: opacity .2s, z-index 0s, -webkit-transform .2s ease-out;
  transition: opacity .2s, z-index 0s, -webkit-transform .2s ease-out;
  -moz-transition: opacity .2s, z-index 0s, transform .2s ease-out, -moz-transform .2s ease-out;
  transition: opacity .2s, z-index 0s, transform .2s ease-out;
  transition: opacity .2s, z-index 0s, transform .2s ease-out, -webkit-transform .2s ease-out, -moz-transform .2s ease-out;
  visibility: hidden;
  top: 100%
}

.category-dropdown:after,
.category-dropdown:before {
  left: 25px
}

.category-dropdown:after {
  border-bottom-color: #f4f4f4;
  visibility: hidden;
  top: -moz-calc(100% - 20px);
  top: calc(100% - 20px)
}

.category-dropdown.menu-fixed .dropdown-box,
.category-dropdown.menu-fixed:after {
  visibility: hidden
}

.category-dropdown.dropdown.show .dropdown-box {
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, .1);
  box-shadow: 0 2px 10px rgba(0, 0, 0, .1);
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition: opacity .5s, z-index 0s, -webkit-transform 0s;
  transition: opacity .5s, z-index 0s, -webkit-transform 0s;
  -moz-transition: opacity .5s, z-index 0s, transform 0s, -moz-transform 0s;
  transition: opacity .5s, z-index 0s, transform 0s;
  transition: opacity .5s, z-index 0s, transform 0s, -webkit-transform 0s, -moz-transform 0s
}

.category-dropdown.dropdown.show .dropdown-box,
.category-dropdown.dropdown.show:after {
  visibility: visible
}

.category-dropdown.dropdown.show:after {
  -webkit-transform: translate3d(-50%, 0, 0);
  -moz-transform: translate3d(-50%, 0, 0);
  transform: translate3d(-50%, 0, 0)
}

.category-dropdown.has-border:after {
  border-bottom-color: #fff
}

.category-dropdown.has-border:after,
.category-dropdown.has-border:before {
  content: "";
  position: absolute;
  z-index: 1000;
  top: -9999px;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  border: 11px solid transparent;
  border-bottom-color: #e1e1e1;
  -webkit-transition: opacity .4s ease;
  -moz-transition: opacity .4s ease;
  transition: opacity .4s ease;
  visibility: hidden;
  opacity: 0;
  cursor: pointer
}

.category-dropdown.has-border .dropdown-box {
  background-color: #fff;
  border: 1px solid #e1e1e1
}

.category-dropdown.has-border.menu-fixed:before {
  visibility: visible;
  opacity: 1
}

.sticky-header:not(.fixed) .category-dropdown.menu-fixed:after {
  top: 100%;
  -webkit-transform: translate3d(-50%, 0, 0);
  -moz-transform: translate3d(-50%, 0, 0);
  transform: translate3d(-50%, 0, 0)
}

.sticky-header:not(.fixed) .category-dropdown.menu-fixed .dropdown-box {
  top: -moz-calc(100% + 20px);
  top: calc(100% + 20px);
  -webkit-transform: none;
  -moz-transform: none;
  transform: none
}

.sticky-header:not(.fixed) .category-dropdown.menu-fixed .dropdown-box,
.sticky-header:not(.fixed) .category-dropdown.menu-fixed:after {
  visibility: visible;
  opacity: 1
}

.sticky-header:not(.fixed) .category-dropdown.menu-fixed.has-border:before {
  top: -moz-calc(100% - 1px);
  top: calc(100% - 1px);
  visibility: visible;
  opacity: 1
}

@media(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio:1.5), (min-device-pixel-ratio:1.5) {
  .sticky-header:not(.fixed) .category-dropdown.menu-fixed.has-border:before {
    top: -moz-calc(100% - 2px);
    top: calc(100% - 2px)
  }
}

.menu {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center
}

.menu a {
  display: inline-block
}

.menu .menu-title {
  margin-bottom: 1rem;
  padding: 0 1rem;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase
}

.menu ul {
  padding: 2rem 0;
  background: #fff;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0;
  color: #666
}

.menu li {
  position: relative;
  line-height: 1.5
}

.menu li a {
  padding: .7rem 0 .7rem 1rem
}

.menu li .megamenu,
.menu li>ul {
  position: absolute;
  top: -9999px;
  left: 100%;
  margin: 0;
  -webkit-box-shadow: 0 2px 35px rgba(0, 0, 0, .1);
  box-shadow: 0 2px 35px rgba(0, 0, 0, .1);
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: -webkit-transform .2s ease-out;
  transition: -webkit-transform .2s ease-out;
  -moz-transition: transform .2s ease-out, -moz-transform .2s ease-out;
  transition: transform .2s ease-out;
  transition: transform .2s ease-out, -webkit-transform .2s ease-out, -moz-transform .2s ease-out;
  -webkit-transform: translate3d(0, -10px, 0);
  -moz-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0)
}

.menu li>ul {
  min-width: 22.6rem;
  background: #fff
}

.menu li>ul li {
  padding: 0 2rem
}

.menu .active>a:not(.menu-title),
.menu li:hover>a:not(.menu-title) {
  color: #222
}

.menu .show .megamenu,
.menu .show>ul,
.menu li:hover .megamenu,
.menu li:hover>ul {
  visibility: visible;
  opacity: 1;
  top: -2rem;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0)
}

.menu>li {
  margin-right: 3rem
}

.menu>li:last-child {
  margin-right: 0
}

.menu>li>a {
  position: relative;
  padding: 1.3rem 0;
  font-weight: 700;
  letter-spacing: inherit;
  line-height: 1;
  text-transform: capitalize
}

.menu>li>a:after {
  margin-left: .8rem
}

.menu>li .megamenu,
.menu>li>ul {
  left: -1.9rem
}

.menu>li.show .megamenu,
.menu>li.show>ul,
.menu>li:hover .megamenu,
.menu>li:hover>ul {
  top: 100%;
  z-index: 1201
}

.menu .submenu>a {
  position: relative;
  display: block
}

.menu>.submenu>a {
  z-index: 1001
}

.menu>.submenu>a:after {
  position: static;
  content: "";
  margin-top: 0
}

.menu.no-arrow>li>a:after {
  content: none
}

.submenu>a:after {
  display: inline-block;
  position: absolute;
  margin-top: 2px;
  right: .5rem;
  top: 50%;
  line-height: 0;
  vertical-align: middle;
  font-family: Font Awesome\ 5 Free;
  font-weight: 700;
  font-size: 1.1rem;
  color: inherit;
  content: ""
}

.vertical-menu {
  display: block;
  min-width: 12rem
}

.vertical-menu>li {
  margin-right: 0;
  padding: 0 2rem
}

.vertical-menu>li .megamenu,
.vertical-menu>li>ul {
  -webkit-transform: translate3d(-15px, 0, 0);
  -moz-transform: translate3d(-15px, 0, 0);
  transform: translate3d(-15px, 0, 0)
}

.vertical-menu>li>a {
  display: inline-block
}

.vertical-menu>li>a:after {
  content: none
}

.vertical-menu>li.show .megamenu,
.vertical-menu>li.show>ul,
.vertical-menu>li:hover .megamenu,
.vertical-menu>li:hover>ul {
  left: 100%;
  top: -1rem
}

.vertical-menu>.submenu:after {
  border: 11px solid transparent;
  border-right-color: #fff
}

.vertical-menu>.submenu.show:after,
.vertical-menu>.submenu:hover:after {
  top: 50%;
  left: -moz-calc(100% - 20px);
  left: calc(100% - 20px);
  -webkit-transform: translate3d(0, -50%, 0);
  -moz-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0)
}

.vertical-menu>.submenu>a:after {
  position: absolute;
  line-height: 0;
  content: ""
}

.vertical-menu.no-arrow>li {
  display: -moz-box;
  display: flex
}

.vertical-menu.no-arrow>li>a:after {
  content: none
}

.toggle-menu {
  display: block
}

.toggle-menu>li {
  margin-right: 0
}

.toggle-menu .submenu:after,
.toggle-menu .submenu>a:after {
  content: none
}

.toggle-menu .submenu li {
  padding: 0 .5rem
}

.toggle-menu li>ul {
  display: none;
  position: static;
  visibility: visible;
  opacity: 1;
  -webkit-transform: none;
  -moz-transform: none;
  transform: none;
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none
}

.toggle-btn {
  display: block;
  padding: 6px;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: .6
}

.toggle-btn,
.toggle-btn:not(.parse-content) {
  position: absolute
}

.toggle-btn:not(.parse-content):before {
  content: "";
  display: block;
  font-size: 1rem;
  font-weight: 600;
  font-family: Font Awesome\ 5 Free;
  line-height: 1;
  -webkit-transition: -webkit-transform .5s;
  transition: -webkit-transform .5s;
  -moz-transition: transform .5s, -moz-transform .5s;
  transition: transform .5s;
  transition: transform .5s, -webkit-transform .5s, -moz-transform .5s
}

.toggle-btn.expanded:not(.parse-content):before {
  -webkit-transform: rotate(630deg);
  -moz-transform: rotate(630deg);
  transform: rotate(630deg)
}

.toggle-btn.collapsed:not(.parse-content):before {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  transform: rotate(0deg)
}

.toggle-btn.expanding:not(.parse-content):before {
  -webkit-transform: rotate(630deg);
  -moz-transform: rotate(630deg);
  transform: rotate(630deg)
}

.menu-active-underline>li>a:before {
  content: "";
  display: block;
  position: absolute;
  bottom: 9px;
  left: 0;
  width: 100%;
  height: 2px;
  border: 1px solid;
  -webkit-transform-origin: right center;
  -moz-transform-origin: right center;
  transform-origin: right center;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: -webkit-transform .3s;
  transition: -webkit-transform .3s;
  -moz-transition: transform .3s, -moz-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s, -moz-transform .3s
}

.menu-active-underline>li.active>a,
.menu-active-underline>li:hover>a {
  color: inherit
}

.menu-active-underline>li.active>a:before,
.menu-active-underline>li:hover>a:before {
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  transform-origin: left center;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1)
}

.main-nav {
  margin: 0 0 0 .3rem
}

.megamenu {
  display: -moz-box;
  display: flex;
  padding: 1rem;
  min-width: 61.8rem;
  background: #fff
}

.megamenu ul {
  padding: 0
}

.megamenu .row {
  -moz-box-flex: 1;
  flex: 1 1;
  padding: 0 1rem
}

.megamenu .row>* {
  padding: 1.8rem 1rem .8rem
}

.megamenu .menu-banner {
  padding: 0;
  overflow: hidden
}

.megamenu .menu-banner figure {
  height: 100%
}

.megamenu .menu-banner img {
  height: 100%;
  object-fit: cover
}

.megamenu .menu-banner .btn-link:hover {
  color: #222
}

.menu-banner1 .banner-content {
  left: 9%
}

.menu-banner1 .banner-subtitle,
.menu-banner1 .banner-title {
  font-size: 3.6rem
}

.menu-banner1 .banner-subtitle {
  margin-bottom: .4rem
}

.menu-banner1 .banner-title {
  margin-bottom: 1.8rem;
  padding-left: 1.2rem;
  position: relative
}

.menu-banner1 .banner-title span {
  display: inline-block;
  position: absolute;
  left: -.9rem;
  top: 50%;
  font-size: 1.2rem;
  line-height: 1;
  -webkit-transform: rotate(-90deg) translateX(.6rem);
  -moz-transform: rotate(-90deg) translateX(.6rem);
  transform: rotate(-90deg) translateX(.6rem);
  letter-spacing: -.1em
}

.menu-banner2 .banner-content {
  bottom: 10%
}

.menu-banner2 .banner-title {
  margin-bottom: .6rem;
  font-size: 2.6rem
}

.menu-banner2 .banner-subtitle {
  font-size: 1.6rem
}

.tip {
  display: inline-block;
  position: relative;
  top: -1px;
  left: 7px;
  padding: .3rem .5rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1;
  color: #fff;
  text-transform: uppercase;
  border-radius: 2px
}

.tip.tip-hot {
  background-color: #d26e4b
}

.tip.tip-new {
  background-color: #222
}

.mobile-menu-wrapper {
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  font-family: Poppins, sans-serif;
  -webkit-transition: visibility .4s;
  -moz-transition: visibility .4s;
  transition: visibility .4s
}

.mobile-menu-container {
  max-width: 296px;
  padding: 2rem 1.5rem;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: #222529;
  -webkit-box-shadow: 1px 0 5px rgba(0, 0, 0, .5);
  box-shadow: 1px 0 5px rgba(0, 0, 0, .5);
  -webkit-transform: translateX(-296px);
  -moz-transform: translateX(-296px);
  transform: translateX(-296px);
  -webkit-transition: -webkit-transform .4s;
  transition: -webkit-transform .4s;
  -moz-transition: transform .4s, -moz-transform .4s;
  transition: transform .4s;
  transition: transform .4s, -webkit-transform .4s, -moz-transform .4s
}

.mobile-menu-container .input-wrapper {
  display: -moz-box;
  display: flex;
  margin-bottom: .6rem;
  height: 4rem
}

.mobile-menu-container .input-wrapper .form-control {
  min-height: 4rem;
  color: #7a8088;
  border: 1px solid #2e3237;
  border-right: 0;
  background-color: transparent
}

.mobile-menu-container .input-wrapper .btn-search {
  padding: 0;
  width: 4rem;
  background-color: #222;
  border-color: #222;
  color: #fff;
  font-size: 1.4rem;
  border-radius: 3px
}

.mobile-menu-container .input-wrapper .btn-search i {
  margin: 0 0 .5rem;
  font-size: inherit
}

.mobile-menu-container .mobile-menu {
  margin-bottom: .5rem;
  background: #222529
}

.mobile-menu-container .tab {
  margin-top: 3rem
}

.mobile-menu-container .tab-content {
  background-color: transparent
}

.mobile-menu-container .nav {
  border-width: 2px
}

.mobile-menu-container .nav-link {
  margin-bottom: -2px;
  color: #fff;
  font-size: 13px;
  padding: 10px
}

.mobile-menu-container .tab-pane {
  padding-top: 10px
}

.mobile-menu-container .nav-item:hover .nav-link {
  color: #fff;
  border-color: transparent
}

.mobile-menu-container .nav-item .nav-link.active,
.mobile-menu-container .nav-item.show .nav-link {
  color: #222;
  border-color: #222
}

.mobile-menu-container .nav-item+.nav-item {
  margin-left: 1px
}

.mobile-menu-container.scrollable::-webkit-scrollbar-thumb,
.mobile-menu-container .sidebar-content::-webkit-scrollbar-thumb {
  margin-right: 2px;
  background: hsla(0, 0%, 100%, .1);
  border-radius: 7px;
  cursor: pointer
}

.mobile-menu {
  color: #e1e1e1;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: -.025em
}

.mobile-menu ul {
  width: 100%;
  overflow: hidden
}

.mobile-menu>li:first-child {
  padding-top: .5rem
}

.mobile-menu>li:last-child {
  padding-bottom: .5rem
}

.mobile-menu li a {
  display: block;
  position: relative;
  padding: 1.3rem .6rem 1.3rem 1rem
}

.mobile-menu li i {
  display: inline-block;
  margin-bottom: 1px;
  font-size: 2rem;
  margin-right: 1rem;
  line-height: 0;
  vertical-align: middle
}

.mobile-menu li li a {
  padding-left: 2.4rem
}

.mobile-menu li li li a {
  padding-left: 3.6rem
}

.mobile-menu li:not(:last-child) {
  border-bottom: 1px solid #2e3237
}

.mobile-menu-title {
  margin-bottom: .5rem;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 700;
  color: #fff
}

.mobile-menu-overlay {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: #000;
  opacity: 0;
  -webkit-transition: opacity .4s;
  -moz-transition: opacity .4s;
  transition: opacity .4s
}

.mobile-menu-close {
  position: fixed;
  left: -moz-calc(100vw - 50px);
  left: calc(100vw - 50px);
  top: 25px;
  z-index: 10001;
  -webkit-transition: opacity .3s;
  -moz-transition: opacity .3s;
  transition: opacity .3s;
  opacity: 0
}

.mobile-menu-close i {
  font-size: 2.8rem;
  color: #e1e1e1
}

.mmenu-anim {
  -webkit-transform: translateY(30%);
  -moz-transform: translateY(30%);
  transform: translateY(30%)
}

.mmenu-anim,
.mmenu-anim>li {
  -webkit-transition: opacity .3s, -webkit-transform .4s;
  transition: opacity .3s, -webkit-transform .4s;
  -moz-transition: transform .4s, opacity .3s, -moz-transform .4s;
  transition: transform .4s, opacity .3s;
  transition: transform .4s, opacity .3s, -webkit-transform .4s, -moz-transform .4s;
  -webkit-transition-timing-function: cubic-bezier(.5, 0, .3, 1);
  -moz-transition-timing-function: cubic-bezier(.5, 0, .3, 1);
  transition-timing-function: cubic-bezier(.5, 0, .3, 1)
}

.mmenu-anim>li:nth-child(0) {
  opacity: 0;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  transform: translateY(0)
}

.mmenu-anim>li:first-child {
  opacity: 0;
  -webkit-transform: translateY(50px);
  -moz-transform: translateY(50px);
  transform: translateY(50px)
}

.mmenu-anim>li:nth-child(2) {
  opacity: 0;
  -webkit-transform: translateY(100px);
  -moz-transform: translateY(100px);
  transform: translateY(100px)
}

.mmenu-anim>li:nth-child(3) {
  opacity: 0;
  -webkit-transform: translateY(150px);
  -moz-transform: translateY(150px);
  transform: translateY(150px)
}

.mmenu-anim>li:nth-child(4) {
  opacity: 0;
  -webkit-transform: translateY(200px);
  -moz-transform: translateY(200px);
  transform: translateY(200px)
}

.mmenu-anim>li:nth-child(5) {
  opacity: 0;
  -webkit-transform: translateY(250px);
  -moz-transform: translateY(250px);
  transform: translateY(250px)
}

.mmenu-anim>li:nth-child(6) {
  opacity: 0;
  -webkit-transform: translateY(300px);
  -moz-transform: translateY(300px);
  transform: translateY(300px)
}

.mmenu-anim>li:nth-child(7) {
  opacity: 0;
  -webkit-transform: translateY(350px);
  -moz-transform: translateY(350px);
  transform: translateY(350px)
}

.mmenu-anim>li:nth-child(8) {
  opacity: 0;
  -webkit-transform: translateY(400px);
  -moz-transform: translateY(400px);
  transform: translateY(400px)
}

.mmenu-anim>li:nth-child(9) {
  opacity: 0;
  -webkit-transform: translateY(450px);
  -moz-transform: translateY(450px);
  transform: translateY(450px)
}

.mmenu-anim>li:nth-child(10) {
  opacity: 0;
  -webkit-transform: translateY(500px);
  -moz-transform: translateY(500px);
  transform: translateY(500px)
}

.mmenu-anim>li:nth-child(11) {
  opacity: 0;
  -webkit-transform: translateY(550px);
  -moz-transform: translateY(550px);
  transform: translateY(550px)
}

.mmenu-anim>li:nth-child(12) {
  opacity: 0;
  -webkit-transform: translateY(600px);
  -moz-transform: translateY(600px);
  transform: translateY(600px)
}

.mmenu-active {
  overflow: hidden
}

.mmenu-active .mmenu-anim,
.mmenu-active .mmenu-anim>li {
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  transform: translateY(0)
}

.mmenu-active .page-wrapper {
  margin-left: 296px;
  margin-right: -296px
}

.mmenu-active .mobile-menu-wrapper {
  visibility: visible
}

.mmenu-active .mobile-menu-container {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  transform: translateX(0)
}

.mmenu-active .mobile-menu-overlay {
  opacity: .8
}

.mmenu-active .mobile-menu-close {
  opacity: 1
}

@media(max-width: 400px) {
  .mobile-menu-close {
    left: -moz-calc(100vw - 40px);
    left: calc(100vw - 40px);
    top: 10px
  }
}

.category-menu {
  background: #fef0e3
}

.category-menu .menu-title {
  padding: 1.8rem .2rem;
  margin: 0;
  text-transform: capitalize;
  font-size: 1.8rem;
  font-weight: 700;
  font-family: Poppins, sans-serif;
  line-height: 1;
  letter-spacing: -.05em;
  color: #222
}

.category-menu .menu-body:last-child li:last-child {
  border: 0
}

.category-menu i {
  vertical-align: middle;
  line-height: 0;
  margin-right: 1rem;
  padding-left: .2rem;
  font-size: 2rem;
  color: #666
}

.category-menu>li>a {
  display: block;
  padding: 1.2rem 0 1.3rem;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 1.46;
  letter-spacing: -.01em;
  text-transform: capitalize;
  color: #675545
}

.category-menu>li:not(:last-child)>a {
  border-bottom: 1px solid #f0e0d1
}

.category-menu li:hover>a:not(.menu-title) {
  color: #222
}

.category-menu li:hover>a:not(.menu-title) i {
  color: inherit
}

.category-menu .submenu>a:after {
  font-size: 1rem
}

.category-menu .submenu .megamenu {
  min-width: 68.5rem;
  padding: 0 0 0 .9rem
}

.category-menu .submenu .megamenu>li {
  padding: 0 1rem;
  -moz-box-flex: 1;
  flex: 1 1
}

.category-menu .submenu .megamenu>li:last-child {
  padding-right: 0;
  -moz-box-flex: 0;
  flex: none
}

.category-menu .submenu .megamenu .menu-title {
  padding: 2.7rem 0 .2rem;
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: -.025em
}

.category-menu .submenu .megamenu .divider {
  height: 1px;
  width: 100%;
  margin: 0
}

.category-menu .submenu .megamenu ul {
  position: relative;
  left: 0;
  top: 0;
  padding: 0;
  font-size: 1.3rem;
  opacity: 1;
  visibility: visible;
  -webkit-box-shadow: none;
  box-shadow: none;
  min-width: auto;
  z-index: auto;
  -webkit-transform: none;
  -moz-transform: none;
  transform: none
}

.category-menu .submenu .megamenu ul li {
  padding: 0;
  line-height: 1.2
}

.category-menu .submenu .megamenu ul li a {
  padding: .7rem 0
}

.category-menu .submenu .megamenu .menu-banner .banner-content {
  left: 3rem
}

.category-menu .submenu .megamenu .menu-banner .banner-subtitle {
  font-size: 1.4rem
}

.category-menu .submenu .megamenu .menu-banner .banner-title {
  font-size: 2.4rem;
  line-height: 1.2;
  margin-bottom: 2.8rem
}

.category-menu .submenu .megamenu .menu-banner .btn-md {
  padding: .7em 1.41em
}

.category-menu .submenu .megamenu .menu-banner5 .banner-content {
  top: 2.6rem
}

.category-menu .submenu .megamenu .menu-banner5 .banner-subtitle {
  margin-bottom: 1.1rem
}

.category-menu .submenu .megamenu .menu-banner5 .banner-title {
  line-height: 1.25
}

.category-menu .submenu .megamenu.type2 {
  display: block;
  min-width: 88rem;
  padding: 0 1rem 2rem
}

.category-menu .submenu .megamenu.type2 .menu-title {
  padding-top: 1.5rem
}

.category-menu .submenu .megamenu.type2 figure {
  text-align: center
}

.category-menu ul {
  padding: 2rem 0;
  background: #fff;
  font-size: 1.4rem;
  line-height: 1.5;
  letter-spacing: 0;
  color: #666
}

.category-menu ul,
.footer {
  font-family: Poppins, sans-serif
}

.footer {
  font-size: 1.3rem;
  background: #222
}

.footer p {
  font-size: inherit
}

.footer a:not(.social-link):hover {
  color: #fff
}

.footer .social-link {
  letter-spacing: .005em;
  color: #999;
  border: 2px solid #999
}

.footer .social-link:hover {
  color: #fff
}

.footer .widget-title {
  border-bottom: none
}

.logo-footer,
.logo-footer img {
  display: block
}

.footer-top {
  padding: 4rem 0
}

.footer-middle,
.footer-top {
  border-bottom: 1px solid #333
}

.footer-middle {
  padding: 8.6rem 0 4.6rem
}

.footer-middle .widget {
  margin-bottom: 3rem
}

.footer-middle .widget-title {
  padding: .6rem 0;
  margin-bottom: .9rem;
  font-family: inherit;
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #e1e1e1
}

.footer-middle .widget-body {
  padding: 8px 0 0;
  color: #999
}

.footer-middle .widget-body li {
  line-height: 1.2;
  margin-bottom: 15px
}

.footer-middle .widget-body li:last-child {
  margin-bottom: 0
}

.footer-middle .widget-instagram .widget-body {
  padding-top: 0;
  margin: -5px
}

.footer-middle .widget-instagram .col-3 {
  padding: 5px
}

.footer-middle .widget-instagram img {
  display: block;
  width: 100%;
  height: auto
}

.footer-middle label {
  font-weight: 500;
  text-transform: uppercase;
  color: #ccc
}

.widget.widget-info a {
  font-weight: 500
}

.footer-main {
  padding: 2.6rem 0 .4rem
}

.footer-bottom {
  padding: 2.8rem 0
}

.footer-bottom,
.footer-bottom .container,
.footer-bottom .container-fluid {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center
}

.footer-bottom .footer-left,
.footer-bottom .footer-right {
  -moz-box-flex: 1;
  flex: 1 1
}

.footer-bottom .footer-left,
.footer-bottom .footer-right {
  display: -moz-box;
  display: flex
}

.footer-bottom .footer-right {
  -moz-box-pack: end;
  justify-content: flex-end
}

@media(max-width: 991px) {
  .footer-top .logo-footer {
    margin-bottom: 2.7rem
  }

  .footer-top .logo-footer img {
    margin-left: auto;
    margin-right: auto
  }

  .newsletter-info {
    margin-bottom: 2rem
  }

  .footer-middle {
    padding: 4.8rem 0
  }

  .footer-middle .widget-body {
    padding: 0
  }

  .footer-bottom,
  .footer-bottom>.container {
    display: block
  }

  .footer-bottom .footer-left,
  .footer-bottom .footer-right {
    -moz-box-pack: center;
    justify-content: center
  }

  .footer-bottom .footer-center,
  .footer-bottom .footer-left {
    margin-bottom: 2.7rem
  }

  .footer-center {
    text-align: center
  }
}

.footer .copyright {
  margin: 0;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: -.01em;
  line-height: 1.5
}

.widget-newsletter .newsletter-info {
  max-width: 35rem;
  width: 100%
}

.widget-newsletter .input-wrapper-inline {
  height: 4.9rem
}

.widget-newsletter .input-wrapper {
  max-width: 48rem
}

.widget-newsletter input {
  min-height: 100%;
  border: 0;
  border-radius: .3rem;
  color: #999;
  background: #2c2c2c
}

.widget-newsletter .widget-title {
  padding: 0;
  margin-bottom: .5rem;
  font-family: inherit;
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: -.015em;
  text-transform: inherit;
  line-height: 1;
  color: #fff
}

.widget-newsletter p {
  margin-bottom: .1rem;
  font-family: inherit;
  font-size: 1.4rem;
  letter-spacing: -.005em;
  line-height: 1.23;
  color: #999
}

.widget-newsletter .btn {
  padding: 1.2em 1.33em 1.07em
}

.widget-newsletter .btn i {
  font-size: 1.6rem;
  margin: 0 0 0 .6rem
}

.footer .widget-category .category-box {
  display: -moz-box;
  display: flex;
  flex-wrap: wrap;
  -moz-box-align: end;
  align-items: flex-end;
  margin-bottom: 2rem
}

.footer .widget-category .category-name {
  margin-bottom: 0;
  margin-right: 2.4rem;
  font-size: 1.4rem;
  letter-spacing: 0;
  color: #ccc
}

.footer .widget-category a {
  display: inline-block;
  position: relative;
  margin-right: 2rem;
  font-size: 1.3rem;
  font-weight: 400;
  color: #999
}

.footer .widget-category a:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  border-bottom: 1px solid;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: -webkit-transform .3s;
  transition: -webkit-transform .3s;
  -moz-transition: transform .3s, -moz-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s, -moz-transform .3s
}

.footer .widget-category a:hover:before {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1)
}

.footer .widget-category a:not(:last-child):after {
  content: "";
  position: absolute;
  height: 14px;
  margin-left: 1rem;
  top: 50%;
  border-left: 1px solid #454545;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%)
}

.footer .widget-about .logo-footer {
  display: block;
  margin-bottom: 1.6rem
}

.footer .widget-about p {
  margin-bottom: 2.8rem;
  color: #999;
  line-height: 1.85;
  letter-spacing: -.025em
}

.footer .widget-about .widget-body {
  padding: 0
}

.payment img {
  display: block
}

.sticky-footer {
  display: -moz-box;
  display: flex
}

.sticky-footer>* {
  -moz-box-flex: 1;
  flex: 1 1
}

.sticky-footer .search-toggle {
  padding: 0;
  color: inherit
}

.sticky-footer .header-search.show,
.sticky-footer .header-search:hover {
  color: #222
}

.sticky-footer .hs-toggle .input-wrapper {
  min-width: 29rem;
  right: 1.5rem;
  margin-bottom: 2rem
}

.sticky-link {
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  -moz-box-align: center;
  align-items: center;
  margin: 1.5rem 0 1.3rem
}

.sticky-link i {
  width: 2.3rem;
  height: 2.4rem;
  text-align: center;
  font-size: 2.3rem;
  line-height: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: -webkit-transform .35s ease;
  transition: -webkit-transform .35s ease;
  -moz-transition: transform .35s ease, -moz-transform .35s ease;
  transition: transform .35s ease;
  transition: transform .35s ease, -webkit-transform .35s ease, -moz-transform .35s ease
}

.sticky-link i.d-icon-search {
  font-size: 1.9rem
}

.sticky-link span {
  margin-top: .8rem;
  font-size: 1rem;
  line-height: 1;
  letter-spacing: .025em;
  text-transform: uppercase
}

.sticky-link.active,
.sticky-link:hover {
  color: #222
}

.sticky-link:hover i {
  -webkit-transform: translateY(-5px);
  -moz-transform: translateY(-5px);
  transform: translateY(-5px)
}

@media(min-width: 576px) {
  .sticky-footer {
    padding: 0 4rem
  }
}

@media(min-width: 768px) {
  .sticky-footer {
    display: none
  }
}

@media(max-width: 991px) {
  .footer-middle .row>div:last-child .widget {
    margin-bottom: 1rem
  }
}

.about-us .breadcrumb {
  padding: 1.2rem 0 1.1rem
}

.about-us .page-header {
  height: 30.1rem
}

.about-us .page-subtitle {
  margin: .5rem 0 .6rem
}

.about-us .page-title {
  margin-bottom: 1.3rem;
  font-size: 5rem;
  letter-spacing: -.042em
}

.about-us .page-desc {
  font-weight: 300;
  line-height: 1.6;
  letter-spacing: .007em
}

.about-us .counter {
  padding: 4.5rem 1rem 4.2rem;
  -webkit-box-shadow: 0 5px 40px 0 rgba(0, 0, 0, .05);
  box-shadow: 0 5px 40px 0 rgba(0, 0, 0, .05)
}

.about-us .counter p {
  line-height: 1.72
}

.about-us .section-title {
  letter-spacing: -.06em
}

.about-us .store-section .banner-radius {
  border-radius: .3rem
}

.about-us .customer-section,
.about-us .store-section {
  margin-bottom: 2.4rem
}

.about-section {
  margin: .1rem 0 3.1rem
}

.about-section .section-subtitle {
  margin-bottom: .2rem
}

.about-section .section-title {
  margin-bottom: 2.2rem
}

.about-section .section-desc {
  margin-bottom: 8px;
  line-height: 1.6
}

.customer-section .section-subtitle,
.store-section .section-subtitle {
  margin-bottom: .7rem
}

.customer-section .section-title,
.store-section .section-title {
  margin-bottom: 1.9rem
}

.customer-section .section-desc,
.store-section .section-desc {
  margin-bottom: 4.2rem;
  letter-spacing: -.036em;
  line-height: 1.75
}

.customer-section .btn,
.store-section .btn {
  margin-bottom: .7rem
}

.customer-section .btn i,
.store-section .btn i {
  font-size: 2.1rem
}

.brand-section .owl-stage-outer {
  padding: 3rem 0;
  margin: -3rem 0
}

.brand-wrap {
  display: -moz-box;
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
  -moz-box-align: center;
  align-items: center;
  height: 14.2rem;
  object-fit: contain;
  -webkit-box-shadow: 0 5px 30px 0 rgba(0, 0, 0, .05);
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, .05)
}

@media(min-width: 992px) {
  .about-section .section-desc {
    max-width: 28rem
  }
}

@media(min-width: 768px) {
  .about-us .store-section .row>div:first-child {
    padding-left: 6rem
  }
}

@media(max-width: 767px) {

  .customer-section img,
  .store-section img {
    width: 100%
  }

  .order-md-first {
    -moz-box-ordinal-group: 2;
    order: 1
  }
}

@media(max-width: 575px) {
  .section-title {
    font-size: 2.5rem
  }
}

.account {
  line-height: 2.15
}

.account .title {
  font-size: 3rem;
  letter-spacing: -.035em
}

.account p {
  font-family: Poppins, sans-serif;
  line-height: 2.15;
  letter-spacing: -.017em
}

.account p span {
  color: #333
}

.account p:first-child {
  letter-spacing: -.005em
}

.account p .link-to-tab {
  text-decoration: underline
}

.account .dashboard p {
  color: #777
}

.account .btn.btn-dark {
  padding: .93em 1.97em
}

.account label {
  display: block;
  padding-left: .2rem;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  line-height: 1
}

.account .form-control {
  font-size: 1.3rem;
  font-family: Poppins, sans-serif;
  -webkit-transition: background-color .3s, border-color .3s;
  -moz-transition: background-color .3s, border-color .3s;
  transition: background-color .3s, border-color .3s;
  color: #999;
  border-radius: .3rem
}

.account .form-control:focus {
  border-color: #222
}

.account .nav-tabs {
  width: auto;
  border-right: none
}

.account .nav-item {
  border-bottom: 1px solid #eee;
  color: #222
}

.account .nav-item a {
  display: block;
  padding: 1.7rem .8rem 1.6rem;
  margin: 0;
  font-weight: 600;
  letter-spacing: -.015em;
  line-height: 1;
  text-transform: none
}

.account .nav-item .nav-link.active,
.account .nav-item.show .nav-link,
.account .nav-item:hover .nav-link {
  color: #222
}

.account .tab-pane {
  padding: .8rem 0 0
}

.account thead {
  text-align: left
}

.account thead th {
  padding: 1.5rem 0;
  background-color: #f4f4f4
}

.account tbody td {
  padding: 1.5rem 1rem 1.5rem 0
}

.account tbody tr:nth-child(2n) td {
  background-color: #f4f4f4
}

.account td:first-child {
  padding-left: 1rem
}

.account .order-total {
  width: 25.9rem;
  font-size: 1.4rem;
  font-weight: 400;
  color: #666
}

.account fieldset {
  margin: 7rem 0 2rem;
  padding: 2rem 2rem 1rem;
  border: 1px solid #e1e1e1
}

.account legend {
  color: #222
}

.account .btn {
  padding: 1em 1.87em
}

.account .order-table {
  border: 1px solid #eee
}

.account .order-action .btn,
.card-address .btn,
.downloads .btn {
  padding: 0
}

.card-address .btn i {
  font-size: 1.4rem
}

.order-number {
  width: 11.4rem;
  color: #000
}

.order-date {
  width: 24.8rem;
  color: #666
}

.order-status {
  width: 14.7rem;
  font-size: 1.2rem;
  color: #222
}

.order-status span {
  display: inline-block;
  padding: .1rem .5rem;
  background-color: #ccc;
  border-radius: .3rem;
  line-height: 1.9
}

.card-address p {
  margin-bottom: 1rem
}

@media(max-width: 767px) {
  .account .nav-tabs {
    width: 100%
  }

  .account .tab-content {
    padding-left: 1.5rem
  }
}

@media(max-width: 479px) {

  .order-action,
  .order-table thead th:last-child {
    display: none
  }
}

.section-buttons .row {
  -moz-box-pack: center;
  justify-content: center;
  text-align: center
}

.section-buttons.grey-section .title-wrapper p {
  color: #666
}

.section-buttons.background-section {
  padding: 6.6rem 0 5rem
}

.section-buttons.background-section p {
  font-size: 1rem
}

.section-buttons .btn {
  margin-bottom: 2rem
}

.btn-wrapper {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  flex-wrap: wrap
}

.btn-wrapper .btn {
  margin-left: 1rem;
  margin-right: 1rem
}

.btn-wrapper .btn-block {
  max-width: 18rem
}

@media(min-width: 992px) {
  .btn-wrapper .btn {
    margin: 1.4rem 0
  }

  .btn-wrapper .btn:not(:last-child) {
    margin-right: 2rem
  }
}

@media(max-width: 479px) {
  .btn {
    font-size: 1.2rem
  }
}

.default-section {
  margin-top: 8.8rem
}

.masonry-section .height-x1 {
  height: 21.5rem
}

.masonry-section .height-x2 {
  height: 43rem
}

.masonry-section .category-banner {
  height: 100%
}

.masonry-section .category-banner .category-media,
.masonry-section .category-banner>a {
  display: block;
  height: 100%
}

.masonry-section .category-banner img {
  height: 100%;
  width: 100%;
  object-fit: cover
}

.badge-section .height-x1 {
  height: 30rem
}

.badge-section .height-x2 {
  height: 60rem
}

.badge-section .category-badge {
  overflow: hidden;
  height: 100%
}

.badge-section .category-badge .category-media,
.badge-section .category-badge>a {
  display: block;
  height: 100%
}

.badge-section .category-badge img {
  height: 100%;
  width: 100%;
  object-fit: cover
}

@media(max-width: 767px) {

  section.badge-section .height-x1,
  section.masonry-section .height-x1 {
    height: 18rem
  }

  section.badge-section .height-x2,
  section.masonry-section .height-x2 {
    height: 36rem
  }
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {

  .badge-section .category-badge img,
  .masonry-section .category-banner img {
    height: 100%;
    width: auto
  }
}

@media (-ms-high-contrast:none)and (max-width: 767px),
screen and (-ms-high-contrast:active)and (max-width:767px) {

  .badge-section .category-badge img,
  .masonry-section .category-banner img {
    width: 100%;
    height: auto
  }
}

.countdown-coming {
  text-align: left
}

.countdown-coming .countdown-row {
  display: -moz-box;
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
  line-height: 1
}

.countdown-coming .countdown-amount {
  display: block;
  padding: 0 4.2rem;
  font-size: 3rem;
  color: #222;
  letter-spacing: -.025em
}

.countdown-coming .countdown-period {
  display: block;
  font-size: 1.6rem;
  text-align: center;
  text-transform: lowercase;
  overflow: hidden;
  text-overflow: ellipsis
}

.countdown-coming .countdown-section:not(:first-child):before {
  content: ":";
  position: absolute;
  color: #ccc;
  font-size: 3rem;
  line-height: .9;
  -webkit-transform: translate(-3px);
  -moz-transform: translate(-3px);
  transform: translate(-3px)
}

@media(max-width: 479px), (min-width:768px)and (max-width:991px) {
  .countdown-coming .countdown-amount {
    padding: 0 2.1rem
  }
}

.coming-section .row {
  min-height: 100vh
}

@media(-ms-high-contrast:active), (-ms-high-contrast:none) {
  .coming-section .row {
    height: 1px
  }
}

.coming-section h1 {
  font-size: 5rem;
  font-weight: 900
}

.coming-section p {
  line-height: 2.15;
  letter-spacing: -.01em
}

.coming-section form {
  position: relative
}

.coming-section .form-control {
  font-weight: 300;
  border-color: #dadada;
  padding: 1.15rem 13rem 1.15rem 1.5rem
}

.coming-section .btn {
  position: absolute;
  right: 0;
  top: 0;
  padding: 1.55rem 2.85rem;
  font-size: 1.3rem;
  border-radius: 0
}

.coming-section .btn:hover {
  background: #222;
  color: #fff
}

.coming-section .social-link {
  border-width: 1px;
  line-height: 28px;
  margin-right: 5px
}

@media(max-width: 479px) {
  .coming-section {
    padding-bottom: 1px
  }

  .countdown-coming .countdown-amount {
    font-size: 2.7rem
  }

  .countdown-coming .countdown-period {
    font-size: 1.3rem
  }
}

.contact-us .breadcrumb {
  padding: 1.2rem 0 1.1rem
}

.contact-us .breadcrumb li:last-child {
  color: #666
}

.contact-us .breadcrumb li:not(:last-child):after,
.contact-us .breadcrumb li:not(:last-child) a {
  opacity: .7
}

.contact-us .page-header {
  height: 30.2rem
}

.contact-us .page-title {
  font-size: 5rem
}

.contact-us .grey-section {
  background-color: #f7f7f9
}

.contact-us .store-section .title {
  font-size: 3rem
}

.contact-us .grey-section {
  padding: 2.5rem 3.2rem 1.4rem
}

.contact-us .grey-section h4 {
  font-size: 2rem;
  line-height: 1.1
}

.contact-us .grey-section p {
  margin-bottom: 3.2rem;
  line-height: 1.72
}

.contact-section form h4 {
  margin-bottom: .3rem
}

.contact-section form p {
  margin-bottom: 2.4rem;
  font-size: 1.3rem
}

.contact-section .form-control {
  border-radius: .3rem
}

.contact-section .form-control::-webkit-input-placeholder {
  color: #999
}

.contact-section .form-control:-ms-input-placeholder {
  color: #999
}

.contact-section .form-control::placeholder {
  color: #999
}

.contact-section textarea {
  min-height: 15rem;
  padding: 1rem 2rem
}

.contact-section .btn i {
  font-size: 1.6rem;
  margin-left: 1rem;
  margin-bottom: .2rem
}

.contact-section .btn i:before {
  font-weight: 600
}

.error-section {
  background: #f7f7f7;
  height: 694px
}

.error-section h1 {
  font-size: 3.4rem
}

.error-section .btn {
  padding: 1em 3.6em
}

.product-wrapper.row [class*=col-] {
  margin-bottom: 2rem
}

.card-body ul {
  padding-left: 0;
  list-style-type: none
}

.widget .owl-nav-top .owl-nav i {
  padding-left: 0;
  padding-right: 0
}

.cta-simple {
  position: relative;
  overflow: hidden;
  background-color: #edeef0
}

.cta-simple:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  padding-top: 50%;
  background: -moz-linear-gradient(315deg, #08c 0, #222 50%, transparent 50.1%);
  background: linear-gradient(135deg, #08c, #222 50%, transparent 50.1%)
}

.cta-simple .banner-content {
  position: relative;
  margin: 1.5rem;
  padding: 3.4rem 0;
  z-index: 3
}

.cta-simple .banner-header {
  position: relative;
  max-width: 29.1%;
  -moz-box-flex: 0;
  flex: 0 0 29.1%;
  text-align: right
}

.cta-simple .banner-header:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  width: .5rem;
  height: 9rem;
  background-color: #edeef0
}

.cta-simple .banner-text {
  -moz-box-flex: 1;
  flex-grow: 1
}

.cta-simple .banner-title {
  margin-bottom: .2rem;
  font-size: 3em
}

.cta-simple .banner-subtitle {
  margin-bottom: 0;
  font-size: 2em
}

.cta-simple .banner-text h4 {
  font-size: 2em;
  line-height: 1.3
}

.cta-simple .btn {
  position: relative;
  margin-right: 5.2rem;
  padding: 1.38em 2em;
  min-width: 18rem;
  overflow: hidden;
  background: transparent;
  -webkit-transition: opacity .3s;
  -moz-transition: opacity .3s;
  transition: opacity .3s;
  border: 0
}

.cta-simple .btn:before {
  content: "";
  display: block;
  position: absolute;
  left: -100%;
  width: 200%;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: -webkit-gradient(linear, left top, right top, from(#222), color-stop(#08c), to(#222));
  background: -moz-linear-gradient(left, #222, #08c, #222);
  background: linear-gradient(90deg, #222, #08c, #222);
  -webkit-transition: left .3s;
  -moz-transition: left .3s;
  transition: left .3s
}

.cta-simple .btn:hover:before {
  left: 0
}

.banner-1 img,
.banner-2 img {
  min-height: 30rem;
  object-fit: cover
}

.banner-1 {
  background-color: #4f4f4f
}

.banner-1 .banner-title {
  margin-bottom: 1.1rem;
  font-size: 4em
}

.banner-1 .banner-subtitle {
  margin-bottom: 1rem;
  white-space: nowrap;
  font-size: 3em
}

.banner-1 p {
  margin-bottom: 2.4rem;
  font-size: 1.6em;
  line-height: 1.25
}

.banner-1 .btn {
  padding: .77em 1.12em
}

.banner-2 {
  background-color: #2b579a
}

.banner-2 .banner-content {
  width: 69%
}

.banner-2 .banner-title {
  font-size: 3em;
  line-height: 1.06
}

.banner-2 p {
  margin-bottom: 1.6rem;
  font-size: 1.6em;
  line-height: 1.2
}

.banner-2 input.form-control {
  border: none;
  background-color: hsla(0, 0%, 100%, .3);
  border-radius: 2rem
}

.banner-2 .btn {
  padding: .77em 1.35em
}

.banner-2 .btn:active,
.banner-2 .btn:focus,
.banner-2 .btn:hover {
  border-color: #fff
}

.banner-group .banner-title {
  font-size: 3em;
  line-height: 1.2;
  letter-spacing: -.02em
}

.banner-group .banner-subtitle {
  margin-bottom: 1.8rem;
  font-size: 1.4em;
  line-height: 1.2;
  letter-spacing: .01em
}

.banner-group .btn {
  font-weight: 500
}

.banner-group .btn i {
  margin-left: .8rem;
  font-size: 1.9rem;
  vertical-align: middle
}

.banner-group img {
  min-height: 22rem;
  object-fit: cover
}

.banner-3 {
  background-color: #ccc
}

.banner-3 .banner-content {
  left: 7.8%
}

.banner-4 {
  background-color: #ccc
}

.banner-4 .banner-content {
  padding: 0 2.9rem
}

.banner-4 .banner-content>div {
  padding-top: .2rem
}

.banner-4 .banner-subtitle {
  margin-bottom: -.4rem;
  letter-spacing: -.0428em;
  line-height: 1.3
}

.banner-4 .banner-subtitle span {
  font-family: Segoe Script, sans-serif;
  font-size: 1.71em
}

.banner-4 .banner-title {
  font-size: 5em
}

.banner-4 .btn {
  padding: 1.5em 2.1em;
  font-size: 1.2rem
}

.banner-5 {
  background-color: #ccc
}

.banner-5 .banner-content {
  right: 10.5%
}

.banner-newsletter {
  border: 2px solid #222
}

.banner-newsletter .banner-content {
  padding: 1.8rem 0
}

.banner-newsletter .icon-box {
  -moz-box-pack: start;
  justify-content: flex-start
}

.banner-newsletter .icon-box p {
  line-height: 1.43;
  letter-spacing: .01em
}

.banner-newsletter .icon-box-icon {
  margin: 0 2.4rem 0 0;
  font-size: 4.5rem
}

.banner-newsletter .icon-box-title {
  font-size: 1.8rem;
  line-height: .9
}

.banner-newsletter .input-wrapper {
  height: 4.8rem
}

.banner-newsletter .input-wrapper .form-control {
  border: 1px solid #e4e4e4;
  border-right: 0
}

.banner-newsletter .input-wrapper .btn {
  padding: 1em 2.7em
}

.banner-background {
  padding: 9.4rem 0 10.3rem;
  background-color: #6b6b6b
}

.banner-background .banner-subtitle {
  margin-bottom: 1.6rem;
  font-family: Segoe Script, sans-serif;
  font-size: 3em;
  line-height: 1.6
}

.banner-background .banner-title {
  margin-bottom: .7rem;
  font-size: 5em;
  letter-spacing: -.025em
}

.banner-background p {
  margin-bottom: 2.8rem;
  font-weight: 500;
  font-size: 1.8em
}

.banner-background .input-wrapper {
  max-width: 60rem;
  width: 100%;
  height: 4.8rem
}

.banner-background .input-wrapper .form-control {
  position: relative;
  -moz-box-flex: 1;
  flex: 1 1;
  padding-left: 2.4rem;
  border: 1px solid #e7e7e7;
  border-right: 0;
  font-size: 1.2rem
}

.banner-background .input-wrapper .btn {
  min-width: 12.3rem
}

.banner-background .btn {
  padding: 1.22em 2.7em;
  font-weight: 600
}

.label-star {
  -moz-box-align: center;
  align-items: center;
  padding: 9px 7px 5px;
  border-radius: .3rem;
  line-height: 1
}

.label-star:after,
.label-star:before {
  -webkit-animation: rotateStar 4s linear 0s infinite;
  -moz-animation: rotateStar 4s linear 0s infinite;
  animation: rotateStar 4s linear 0s infinite;
  content: "";
  display: inline-block;
  margin: .5rem .5rem .5rem 1.5rem;
  vertical-align: middle;
  font-family: Font Awesome\ 5 Free;
  font-size: .467em;
  font-weight: 400
}

.label-star:before {
  margin-left: .5rem;
  animation-direction: reverse
}

@media(max-width: 991px) {
  .cta-simple .banner-content {
    text-align: center
  }

  .cta-simple .banner-header {
    max-width: 100%;
    text-align: center
  }

  .cta-simple .banner-header:after {
    height: .5rem;
    width: 80%;
    top: 100%;
    right: auto;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    transform: translateX(-50%)
  }

  .cta-simple .banner-text {
    max-width: 100%;
    -moz-box-flex: 0;
    flex: 0 0 100%
  }

  .cta-simple .btn {
    margin: 0
  }

  .banner-2 .banner-content {
    width: 100%;
    padding: 0 3rem
  }

  .banner-newsletter .icon-box {
    -moz-box-pack: center;
    justify-content: center
  }
}

@media(max-width: 767px) {
  .banner-4 .banner-content {
    display: block !important
  }
}

@media(max-width: 575px) {
  .banner {
    font-size: .9rem
  }

  .banner-group .banner {
    font-size: 1.2rem
  }

  .banner-newsletter .icon-box {
    display: block;
    text-align: center
  }

  .banner-newsletter .icon-box-icon {
    margin-right: 0;
    margin-bottom: 1rem
  }

  .banner-newsletter .icon-box-content {
    text-align: center
  }
}

@media(max-width: 479px) {
  .banner-group .banner {
    font-size: 1rem
  }
}

.instagram-masonry .height-x25 {
  height: 407px
}

.instagram-masonry .height-x2 {
  height: 326px
}

.instagram-masonry .height-x1 {
  height: 163px
}

.instagram-masonry .height-x15 {
  height: 244px
}

.instagram-masonry .grid-item {
  overflow: hidden;
  padding: 1rem
}

.instagram-masonry img {
  height: 100%;
  width: 100%;
  object-fit: cover
}

.instagram-masonry .instagram {
  height: 100%
}

@media(max-width: 767px) {
  .instagram-masonry .height-x25 {
    height: 305px
  }

  .instagram-masonry .height-x2 {
    height: 244px
  }

  .instagram-masonry .height-x1 {
    height: 122px
  }

  .instagram-masonry .height-x15 {
    height: 183px
  }
}

.posts {
  -webkit-transition: height .4s;
  -moz-transition: height .4s;
  transition: height .4s
}

.posts .post-meta a:not(:first-child) {
  margin-left: .5rem
}

.posts .post-cats {
  margin-bottom: 1.6rem
}

.posts .btn:after {
  margin-top: 4px
}

.posts .video-play {
  text-align: center;
  width: 6.8rem;
  height: 6.8rem;
  border: 2px solid #fff;
  border-radius: 50%;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 6.4rem;
  -webkit-transition: background .3s, color .3s, opacity .3s, -webkit-box-shadow .3s;
  transition: background .3s, color .3s, opacity .3s, -webkit-box-shadow .3s;
  -moz-transition: background .3s, color .3s, box-shadow .3s, opacity .3s;
  transition: background .3s, color .3s, box-shadow .3s, opacity .3s;
  transition: background .3s, color .3s, box-shadow .3s, opacity .3s, -webkit-box-shadow .3s
}

.posts .video-play:hover {
  -webkit-box-shadow: 0 0 0 15px hsla(0, 0%, 100%, .2);
  box-shadow: 0 0 0 15px hsla(0, 0%, 100%, .2);
  background: #fff;
  color: #222
}

.posts .post.paused .video-play:before,
.posts .post .video-play:before {
  margin-left: 4px;
  content: ""
}

.posts .post.playing .video-play:before {
  margin-left: 0;
  content: ""
}

.posts .btn i {
  margin-left: .3rem;
  font-size: 1.4rem
}

.posts .post-classic .post-content,
.posts .post-grid .post-content,
.posts .post-list .post-content {
  line-height: 1.85
}

.posts .post-classic .post-details {
  padding: 3.8rem .5rem 2rem
}

.posts .post-classic .post-title {
  margin-bottom: 1.4rem
}

.posts .post-classic .post-content {
  margin-bottom: 2.8rem
}

.posts .post-list .post-details {
  padding-top: 3.8rem
}

.posts .post-grid {
  margin-bottom: 1.5rem
}

.posts .post-grid .post-details {
  padding-left: .5rem;
  padding-right: .5rem
}

.posts .post-grid .post-meta {
  margin-bottom: .4rem
}

.posts .post-grid .post-content {
  margin-bottom: 2.8rem
}

.post-slider .owl-stage-outer {
  padding: 2rem;
  margin: -2rem
}

@media(min-width: 1600px) {
  .post-slider .owl-stage-outer {
    padding: 5rem;
    margin: -5rem
  }

  .post-slider .post-frame {
    -webkit-box-shadow: 0 5px 50px 0 rgba(0, 0, 0, .05);
    box-shadow: 0 5px 50px 0 rgba(0, 0, 0, .05)
  }
}

@media(min-width: 1200px) {
  .posts .post-list .post-media {
    margin-right: 2rem;
    width: -moz-calc(42.74% - 2rem);
    width: calc(42.74% - 2rem)
  }

  .posts .post-list .post-details {
    width: 57.26%
  }
}

@media(max-width: 767px) {
  .posts .post-list {
    display: block
  }

  .posts .post-list .post-details,
  .posts .post-list .post-media {
    margin: 0;
    width: 100%
  }
}

@media(max-width: 479px) {
  .post-slider .owl-stage-outer {
    padding: 2rem 1.5rem;
    margin: -2rem -1.5rem
  }
}

.shop-banner-default .banner-subtitle,
.shop-boxed-banner .banner-subtitle {
  margin-bottom: .6rem;
  font-size: 2em
}

.shop-banner-default .banner-title,
.shop-boxed-banner .banner-title {
  margin-bottom: 2.4rem;
  font-size: 4em;
  letter-spacing: -.035em
}

.shop-banner-default .btn,
.shop-boxed-banner .btn {
  padding: 1.2em 2.8em;
  border-color: #919eb2
}

.shop-banner-default .btn i,
.shop-boxed-banner .btn i {
  vertical-align: middle;
  margin-left: .7rem;
  font-size: 2.2rem
}

.shop-banner-default .btn:active,
.shop-banner-default .btn:focus,
.shop-banner-default .btn:hover,
.shop-boxed-banner .btn:active,
.shop-boxed-banner .btn:focus,
.shop-boxed-banner .btn:hover {
  border-color: #fff
}

.shop-banner-default {
  padding: 7.5rem 5.7% 8.3rem
}

.shop-boxed-banner {
  padding: 7.4rem 7% 8.4rem
}

.toolbox,
.toolbox-item,
.toolbox-item-full,
.toolbox-left,
.toolbox-right {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  flex-wrap: wrap
}

.toolbox {
  -moz-box-pack: justify;
  justify-content: space-between;
  font-size: 1.3rem;
  line-height: 1.5;
  padding: 3.5rem .2rem 1rem;
  -webkit-transition: margin .4s;
  -moz-transition: margin .4s;
  transition: margin .4s;
  background-color: #fff
}

.toolbox.sticky-toolbox {
  padding-top: 3rem
}

.toolbox-left>:not(:last-child),
.toolbox-right>:not(:last-child),
.toolbox>:not(:last-child) {
  margin-right: 1.8rem
}

.toolbox label {
  margin-right: .8rem;
  font-weight: 600;
  text-transform: uppercase
}

.toolbox select {
  cursor: pointer;
  padding-top: .85rem;
  padding-bottom: .85rem;
  border-color: #ccc
}

.toolbox .select-box .form-control {
  color: #222
}

.toolbox .select-box:before {
  font-size: 1rem;
  right: 1rem
}

.toolbox .toolbox-sort .form-control {
  max-width: 15rem;
  padding-left: 1.4rem
}

.toolbox .toolbox-sort:before {
  right: 1.4rem
}

.toolbox-item {
  flex-wrap: nowrap;
  margin-bottom: 1rem
}

.toolbox-item label {
  color: #222
}

.toolbox-item-full {
  flex-basis: 100%
}

.btn-layout {
  color: #dadada;
  font-size: 22px;
  padding: 0 2px
}

.btn-layout:last-child {
  padding-right: 0
}

.btn-layout.active,
.btn-layout:hover {
  color: #333
}

.btn-layout+.btn-layout {
  margin-left: 2px
}

.select-menu {
  color: #222
}

.select-menu:before {
  right: 1.25em;
  font-size: 1.2rem;
  margin-top: 1px
}

.select-menu ul,
.select-menu ul:before {
  content: "";
  position: absolute;
  border: 1px solid #eee;
  width: 11px
}

.select-menu ul {
  top: 100%;
  z-index: 100;
  width: 18rem;
  margin-top: 1rem;
  padding: 1rem 1.4rem;
  background: #fff;
  opacity: 0;
  visibility: hidden
}

.select-menu ul:before {
  top: -6px;
  left: 22px;
  height: 11px;
  border-width: 1px 0 0 1px;
  background: #fff;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg)
}

.select-menu.opened>ul {
  opacity: 1;
  visibility: visible
}

.select-menu-toggle,
.select-menu select {
  position: relative;
  border: 1px solid #ccc;
  padding: .8rem 4rem .8rem 1.4rem;
  font-size: 1.2rem;
  line-height: 1.5;
  letter-spacing: -.03em
}

.select-menu-toggle {
  border-radius: 2px
}

.select-menu select {
  max-width: 17.2rem;
  padding: .85rem 2.6rem .85rem .9rem
}

.select-item {
  padding: 6px .7rem 4px 1.2rem;
  color: #222;
  background: #eee;
  letter-spacing: -.01em
}

.select-item i {
  padding: .5rem .6rem .7rem;
  margin-left: 5px;
  font-size: 9px;
  vertical-align: middle
}

.select-items {
  display: block;
  visibility: hidden;
  opacity: 0;
  height: 0;
  -webkit-transition: .4s;
  -moz-transition: .4s;
  transition: .4s
}

.select-items .select-item {
  -webkit-transition: opacity .4s;
  -moz-transition: opacity .4s;
  transition: opacity .4s
}

.toolbox+.select-items {
  font-size: 1.2rem;
  margin: 0
}

.select-items>* {
  display: inline-block;
  margin: 0 .8rem .5rem 0
}

.select-items .filter-clean {
  margin-left: 1.2rem
}

.sticky-content-wrapper+.select-items {
  font-size: 1.2rem;
  margin: 2px 0 1.8rem
}

.toolbox-pagination {
  position: relative;
  margin-bottom: 1rem;
  padding: 2.5rem .2rem;
  border-top: 1px solid #e1e1e1
}

.toolbox-horizontal .show-info {
  color: #999;
  font-size: inherit
}

.toolbox-horizontal.fixed .sidebar-content,
.toolbox-horizontal.fixed .sidebar-overlay {
  min-height: 100vh
}

@media(min-width: 992px) {
  .toolbox-horizontal .toolbox-left {
    padding-right: 1px;
    margin-right: -1px
  }
}

.right-sidebar-toggle.btn-sm,
.sidebar-content .filter-actions .sidebar-toggle-btn,
.toolbox .toolbox-left .left-sidebar-toggle,
.toolbox .top-sidebar-toggle {
  padding: .7em .92em;
  margin-right: 2rem;
  font-weight: 700;
  line-height: 1.2
}

.right-sidebar-toggle.btn-sm .d-icon-filter-2,
.sidebar-content .filter-actions .sidebar-toggle-btn .d-icon-filter-2,
.toolbox .toolbox-left .left-sidebar-toggle .d-icon-filter-2,
.toolbox .top-sidebar-toggle .d-icon-filter-2 {
  font-size: 1.4rem
}

.right-sidebar-toggle.btn-sm .d-icon-filter-2:before,
.sidebar-content .filter-actions .sidebar-toggle-btn .d-icon-filter-2:before,
.toolbox .toolbox-left .left-sidebar-toggle,
.toolbox .toolbox-left .left-sidebar-toggle .d-icon-filter-2:before,
.toolbox .top-sidebar-toggle .d-icon-filter-2:before {
  font-weight: 600
}

.toolbox-item.right-sidebar-toggle {
  margin-right: 0
}

.toolbox-wrap {
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: reverse;
  flex-direction: column-reverse;
  line-height: 1.3
}

.toolbox-wrap .toolbox>* {
  line-height: 37px
}

.toolbox-wrap option {
  text-transform: none
}

.toolbox-wrap .toolbox-layout {
  display: -moz-box;
  display: flex
}

.toolbox-wrap .sidebar-content .widget-title {
  padding-bottom: 1rem
}

.sticky-toolbox.fixed {
  position: fixed;
  background-color: #fff;
  padding: 1rem 2rem 0
}

.widget .widget-body .filter-actions {
  display: block;
  padding: 3.2rem 0 1.5rem .2rem
}

.filter-price-text {
  font-size: 1.3rem;
  color: #222
}

.filter-items a:before {
  content: "";
  position: absolute;
  border: 1px solid #999;
  width: 18px;
  height: 18px;
  border-radius: 2px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  left: -2.7rem;
  font-size: .7em;
  font-weight: 900;
  font-family: Font Awesome\ 5 Free;
  text-align: center;
  text-indent: 1px;
  line-height: 1.7em
}

@media(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio:1.5), (min-device-pixel-ratio:1.5) {
  .filter-items a:before {
    text-indent: 0
  }
}

.toolbox .btn-link {
  padding: 0
}

.filter-clean {
  font-size: 1.4rem;
  margin-left: auto;
  color: #222;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  transition: .3s
}

.filter-clean:hover {
  color: #222
}

.toolbox-wrap .card-body .filter-clean {
  display: inline;
  opacity: 0
}

.toolbox-wrap .expanded .filter-clean {
  opacity: 1
}

.card.navigation-card.expanded {
  overflow: visible
}

.card.navigation-card:not(.collapsed)+.toolbox .toolbox-right {
  opacity: 0;
  -webkit-transform: opacity .3s;
  -moz-transform: opacity .3s;
  transform: opacity .3s
}

.shop-sidebar {
  position: relative
}

.shop-sidebar .btn-outline:not(:hover) {
  color: #222;
  background: #fff
}

.shop-sidebar .left-sidebar-toggle,
.shop-sidebar .sidebar-toggle-btn {
  padding: .8em 1.04em
}

.shop-sidebar .filter-actions {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  padding-right: .6rem;
  margin-bottom: 2rem
}

.btn-icon-right i {
  margin-left: .8rem;
  font-size: 1.8rem
}

@media(min-width: 992px) {
  .sidebar:not(.closed) .sticky-sidebar-fixed>.filter-actions {
    visibility: hidden
  }

  .sidebar-fixed {
    -webkit-transition: margin .4s;
    -moz-transition: margin .4s;
    transition: margin .4s
  }

  .sidebar-fixed .filter-actions:first-child {
    padding-top: 3rem
  }

  .sidebar-fixed+.main-content {
    -webkit-transition: flex-basis .4s, max-width .4s;
    -moz-transition: flex-basis .4s, max-width .4s, -moz-flex-basis .4s;
    transition: flex-basis .4s, max-width .4s;
    transition: flex-basis .4s, max-width .4s, -moz-flex-basis .4s
  }

  .sticky-sidebar-wrapper.closed {
    height: 0
  }

  .closed.sidebar {
    margin-left: -25%
  }

  .closed.right-sidebar {
    margin-right: -25%
  }

  .closed+.main-content {
    max-width: 100%;
    flex-basis: 100%
  }

  .sidebar-toggle-remain .toggle-remain {
    position: absolute;
    -webkit-transition: .4s;
    -moz-transition: .4s;
    transition: .4s;
    left: 0
  }
}

@media(min-width: 992px)and (-ms-high-contrast:active), (min-width:992px)and (-ms-high-contrast:none) {
  .sidebar-toggle-remain .toggle-remain {
    top: 2.7rem
  }
}

@media(min-width: 992px) {
  .sidebar-toggle-remain .filter-clean {
    line-height: 3.7rem
  }

  .sidebar-toggle-remain.sidebar .toggle-remain i {
    -webkit-transition: -webkit-transform .3s;
    transition: -webkit-transform .3s;
    -moz-transition: transform .3s, -moz-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s, -moz-transform .3s
  }

  .sidebar-toggle-remain.sidebar.closed .toggle-remain {
    left: -moz-calc(100% + 30px);
    left: calc(100% + 30px)
  }

  .sidebar-toggle-remain.sidebar.closed .toggle-remain i {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    transform: rotateY(180deg)
  }

  .sidebar-toggle-remain.sidebar.closed+*>.toolbox:first-child {
    margin-left: 10.8rem
  }

  .sidebar-toggle-remain.sidebar .toggle-remain:before {
    content: "";
    position: absolute;
    background-color: #fff;
    right: -moz-calc(100% + 2px);
    right: calc(100% + 2px);
    width: 20px;
    bottom: -2px;
    top: -2px
  }

  .sidebar-toggle-remain.right-sidebar.closed .toggle-remain {
    left: -3rem;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    transform: translateX(-100%)
  }

  .sidebar-toggle-remain.right-sidebar.closed .toggle-remain i:before {
    content: ""
  }

  .sidebar-toggle-remain.right-sidebar.closed+*>.toolbox:first-child {
    padding-right: 11rem
  }

  .toolbox-wrap .sidebar-content {
    border-top: 3px solid #eee;
    -webkit-transition: border .3s;
    -moz-transition: border .3s;
    transition: border .3s
  }

  .toolbox-wrap .sidebar-content>div {
    border-bottom: 3px solid #eee
  }

  .toolbox-wrap .sidebar-content .widget {
    border: none
  }

  .shop-sidebar .filter-actions {
    margin-bottom: 0
  }
}

@media(max-width: 479px) {
  .sticky-toolbox.fixed {
    padding: 1rem 1.5rem 0
  }

  .toolbox {
    padding-right: 0;
    padding-left: 0
  }
}

@media(max-width: 575px) {
  .toolbox-item.select-box {
    margin-right: 0
  }

  .toolbox-item.select-box~.toolbox-item.right-sidebar-toggle {
    margin-left: 1rem
  }

  .toolbox-item.toolbox-layout {
    display: none
  }

  .sticky-sidebar .filter-actions .sidebar-toggle-btn,
  .toolbox .toolbox-left .left-sidebar-toggle {
    margin-right: 1rem
  }

  .toolbox .toolbox-left {
    margin: 0
  }

  .toolbox .left-sidebar-toggle {
    margin-right: .5rem
  }

  .toolbox .toolbox-sort .form-control {
    max-width: 12.8rem
  }

  .toolbox-horizontal {
    -moz-box-pack: start;
    justify-content: flex-start;
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    flex-direction: row
  }

  .toolbox-horizontal .toolbox-left {
    margin-right: auto
  }

  .toolbox-horizontal .toolbox-right {
    margin-left: auto
  }

  .toolbox-horizontal .toolbox-item.toolbox-show {
    margin-right: 0
  }

  .toolbox-pagination {
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column
  }

  .toolbox-pagination>.show-info {
    margin-right: 0
  }

  .toolbox-pagination>:not(:last-child) {
    margin-bottom: 1rem
  }
}

@media(min-width: 576px) {
  .toolbox-horizontal .toolbox-left {
    margin-right: auto
  }

  .mr-sm-auto {
    margin-right: auto !important
  }
}

@media(max-width: 767px) {

  .toolbox-item.show-info,
  .toolbox-item>span,
  .toolbox-item label {
    display: none
  }
}

@media(max-width: 991px) {
  .toolbox .sidebar-fixed {
    margin-right: 0
  }

  .toolbox .sidebar-content {
    display: block
  }

  .sidebar-content .select-menu {
    display: block;
    margin: 0;
    font-size: 1.4rem
  }

  .sidebar-content .select-menu ul {
    position: static;
    width: 100%;
    padding: 0;
    border: 0;
    color: #666;
    margin: 0 0 1.1rem;
    opacity: 1;
    visibility: visible
  }

  .sidebar-content .select-menu ul a:before {
    width: 18px;
    height: 18px;
    margin-top: 0
  }

  .sidebar-content .select-menu:before,
  .sidebar-content .select-menu>a:before,
  .sidebar-content .select-menu ul:before {
    content: none
  }

  .sidebar-content .select-menu-toggle {
    display: block;
    border-width: 3px 0 0;
    padding: 2rem 2px 1.6rem;
    font-size: 1.5rem;
    font-weight: 600;
    text-transform: capitalize
  }

  .toolbox-item:first-child a {
    border: none
  }

  .card.navigation-card.collapsed .card-header+div {
    display: block !important
  }

  .card.navigation-card.expanded+.toolbox .toolbox-right {
    opacity: 1 !important
  }
}

@media(min-width: 992px) {
  .toolbox .sidebar-content {
    max-width: none
  }

  .toolbox-horizontal .sidebar-fixed .sidebar-content {
    padding-bottom: 0
  }

  .toolbox-wrap .filter-actions:first-child {
    padding: 0
  }

  .toolbox-wrap .sidebar-toggle-btn {
    display: none
  }

  .toolbox-wrap .sidebar.closed {
    margin: 0
  }

  .toolbox-wrap .sidebar.closed .sidebar-content {
    display: none;
    border-color: transparent;
    background: transparent
  }

  .toolbox-wrap .filter-clean {
    display: none;
    position: absolute;
    right: 0;
    top: -40px
  }
}

@media(min-width: 1200px) {

  .select-menu:not(:last-child):not(.toolbox-show),
  .toolbox .shop-sidebar {
    margin-right: 2rem
  }
}

.cart-added-alert {
  display: none;
  margin-bottom: 1rem
}

.container>.cart-added-alert:first-child {
  margin-top: -1rem
}

.cart-added-alert span {
  color: #222
}

.cart-added-alert .btn-success,
.cart-added-alert span {
  vertical-align: middle
}

.product-details .product-navigation {
  padding: .2rem 2px .3rem
}

.product-gallery {
  display: -moz-box;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3rem;
  padding-bottom: 3rem;
  position: sticky
}

.container-fluid .product-gallery.product-gallery-vertical .product-image-full {
  bottom: -moz-calc(22% + 20px);
  bottom: calc(22% + 20px)
}

.container .product-gallery.product-gallery-vertical .product-image-full {
  bottom: -moz-calc(19% + 20px);
  bottom: calc(19% + 20px)
}

.product-gallery.product-gallery-sticky {
  top: 2rem;
  padding-bottom: 3rem;
  -webkit-transition: top .3s;
  -moz-transition: top .3s;
  transition: top .3s
}

.sticky-header-active .product-gallery.product-gallery-sticky {
  top: 8rem
}

.product-gallery.row>* {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center
}

.product-gallery.row .product-image-full {
  right: 2rem
}

@media(max-width: 991px) {

  .product-gallery.product-extended .product-image-full,
  .product-gallery.row .product-image-full {
    bottom: 2rem
  }
}

.thumb-pictures .product-image-full {
  right: 1rem !important
}

.product-image-full {
  display: block;
  position: absolute;
  padding: 1rem;
  right: 1rem;
  bottom: 4rem;
  color: #999;
  font-size: 2rem;
  line-height: 1;
  opacity: 0;
  -webkit-transition: opacity .3s;
  -moz-transition: opacity .3s;
  transition: opacity .3s;
  z-index: 1
}

:hover>.product-image-full {
  opacity: 1
}

.product-navigation {
  display: -moz-box;
  display: flex;
  flex-wrap: wrap;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: justify;
  justify-content: space-between;
  padding: 2rem 2px 1.1rem
}

.product-navigation .breadcrumb {
  margin: 0 2rem 1rem 0;
  font-size: inherit
}

.product-navigation .product-nav {
  margin-bottom: 1rem
}

.product-nav {
  display: -moz-box;
  display: flex;
  position: relative;
  color: #999
}

.product-nav i {
  vertical-align: middle;
  font-size: 1.9rem;
  line-height: 0
}

.product-nav li+li {
  margin-left: 2rem
}

.product-nav .product-nav-prev i {
  margin-right: 2px
}

.product-nav .product-nav-next i {
  margin-left: 2px
}

.product-nav .product-nav-popup .product-name {
  padding-right: 0;
  color: #666;
  font-size: 1.2rem;
  font-weight: 400;
  white-space: normal
}

.product-nav li:hover .product-nav-popup {
  opacity: 1;
  visibility: visible;
  -webkit-transform: none;
  -moz-transform: none;
  transform: none
}

.product-nav-popup {
  position: absolute;
  top: 126%;
  right: 16px;
  z-index: 30;
  width: 120px;
  padding: 0 5px 5px;
  line-height: 1.5;
  text-align: center;
  background-color: #fff;
  -webkit-box-shadow: 1px 1px 7px rgba(0, 0, 0, .1);
  box-shadow: 1px 1px 7px rgba(0, 0, 0, .1);
  visibility: hidden;
  opacity: 0;
  -webkit-transform: scale(.9);
  -moz-transform: scale(.9);
  transform: scale(.9);
  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  transform-origin: top;
  -webkit-transition: opacity .3s, -webkit-transform .3s;
  transition: opacity .3s, -webkit-transform .3s;
  -moz-transition: opacity .3s, transform .3s, -moz-transform .3s;
  transition: opacity .3s, transform .3s;
  transition: opacity .3s, transform .3s, -webkit-transform .3s, -moz-transform .3s
}

.product-nav-popup:before {
  content: "";
  position: absolute;
  top: -8px;
  right: 18px;
  width: 16px;
  height: 16px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: #fff;
  -webkit-box-shadow: inherit;
  box-shadow: inherit
}

.product-nav-popup img {
  position: relative;
  padding-top: 5px;
  background-color: #fff
}

.no-next .product-nav-popup {
  right: 0
}

.product-nav-prev .product-nav-popup:before {
  right: 6.6rem
}

.product-tabs .nav-link {
  padding: 1rem 2.5rem;
  line-height: 1.2
}

.product-tabs.tab .nav-item {
  margin-right: 0
}

.product-tabs .tab-pane {
  padding: 1.5rem .2rem;
  line-height: 1.86
}

.product-footer+.product-tabs {
  margin-top: 2.5rem
}

.product-status .list-type li,
.product-tabs .tab-pane .list-type li {
  padding-left: 3rem
}

.product-status {
  line-height: 2
}

.card-description .description-title,
.product-tab-description .description-title {
  font-size: 2rem;
  line-height: 24px
}

.card-description .description-title~p,
.product-tab-description .description-title~p {
  line-height: 1.86
}

.card-description li,
.product-tab-description li {
  position: relative;
  padding-left: 2.4rem
}

.card-description li:before,
.product-tab-description li:before {
  position: absolute;
  display: block;
  left: 3px;
  top: 2px;
  content: "";
  font-family: riode;
  font-weight: 700;
  font-size: 1.2rem;
  color: #222
}

.card-description .table tbody,
.product-tab-description .table tbody {
  line-height: 1.8
}

.card-description .table td,
.card-description .table th,
.product-tab-description .table td,
.product-tab-description .table th {
  padding: 10px;
  text-align: left;
  vertical-align: top;
  border-bottom: 1px solid #e9e9e9
}

@media(min-width: 768px) {

  .card-description .pl-md-6,
  .product-tab-description .pl-md-6 {
    padding-left: 3rem !important
  }
}

.card-description .btn-play,
.product-tab-description .btn-play {
  display: -moz-box;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  padding: 0;
  font-size: 35px;
  width: 78px;
  height: 78px;
  border-radius: 50%;
  background-color: #222;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
  color: #fff
}

.card-description .icon-box .icon-box-icon,
.product-tab-description .icon-box .icon-box-icon {
  font-size: 3rem;
  border-color: #cdcdcd
}

.card-description .icon-box-content p,
.product-tab-description .icon-box-content p {
  font-size: 1.3rem;
  color: #999
}

.card-description .divider,
.product-tab-description .divider {
  margin: 1.3rem 0;
  height: 4.4rem
}

.card-description figure img,
.product-tab-description figure img {
  vertical-align: middle
}

.card-body .icon-box .icon-box-icon,
.product-tab-description .icon-box .icon-box-icon {
  width: 5.1rem;
  height: 5.1rem;
  margin-right: 2rem
}

.card-body .icon-box .icon-box-icon .d-icon-truck,
.product-tab-description .icon-box .icon-box-icon .d-icon-truck {
  font-size: 3.6rem
}

.card-body .icon-box .icon-box-title,
.product-tab-description .icon-box .icon-box-title {
  margin-bottom: .2rem
}

.product-tab-description .icon-box-wrap {
  max-width: 559px
}

.card-additional label,
.product-tab-additional label {
  display: inline-block;
  min-width: 20rem;
  color: #222
}

.card-additional p,
.product-tab-additional p {
  display: inline-block;
  margin-bottom: 0
}

.card-sizeguide,
.product-tab-size-guide {
  display: block
}

.card-sizeguide .size-image,
.product-tab-size-guide .size-image {
  -moz-box-flex: 0;
  flex: 0 0 36%;
  text-align: center;
  padding: 0 10px
}

.card-sizeguide .size-table,
.product-tab-size-guide .size-table {
  -moz-box-flex: 1;
  flex: 1 1;
  font-size: 14px;
  text-transform: uppercase;
  color: #21293c
}

.card-sizeguide .size-table thead th,
.product-tab-size-guide .size-table thead th {
  padding: 30px 0 30px 10px;
  font-weight: 600;
  background: #f4f4f2;
  text-align: left
}

.card-sizeguide .size-table tbody tr>*,
.product-tab-size-guide .size-table tbody tr>* {
  padding: 12px 0 12px 10px;
  font-weight: 700;
  text-align: left
}

.card-sizeguide .size-table tbody tr:nth-child(2n),
.product-tab-size-guide .size-table tbody tr:nth-child(2n) {
  background-color: #ebebeb
}

.card-reviews .comments>ul,
.product-tab-reviews .comments>ul {
  padding-right: 3rem
}

.card-reviews .comments li,
.product-tab-reviews .comments li {
  padding-bottom: 2rem;
  margin-bottom: 3rem
}

.card-reviews .comments li:last-child,
.product-tab-reviews .comments li:last-child {
  margin-bottom: 0
}

.card-reviews .comments li,
.product-tab-reviews .comments li {
  margin-left: 3rem
}

.card-reviews .comment p,
.product-tab-reviews .comment p {
  margin-bottom: 0
}

.card-reviews .comment-rating,
.product-tab-reviews .comment-rating {
  position: absolute;
  right: 0;
  top: 3rem;
  font-size: 1.2rem
}

.card-reviews .ratings-full,
.product-tab-reviews .ratings-full {
  margin-right: 0
}

.card-reviews .reply,
.product-tab-reviews .reply {
  margin-bottom: 0
}

.card-reviews .reply p,
.product-tab-reviews .reply p {
  margin-bottom: 2.5rem;
  font-size: 1.3rem;
  color: #666
}

.card-reviews .reply .btn,
.product-tab-reviews .reply .btn {
  padding: 1.2em 2.97em
}

.card-reviews .reply .btn i,
.product-tab-reviews .reply .btn i {
  font-size: 1.9rem;
  margin-left: 1rem
}

.card-reviews .ratings:before,
.product-tab-reviews .ratings:before {
  color: #666
}

.card-reviews .form-checkbox,
.product-tab-reviews .form-checkbox {
  font-size: 1.3rem
}

.product-details .collapse:after {
  color: #222
}

.rating-form {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2.2rem
}

.rating-form label {
  margin-right: 1rem
}

.rating-stars {
  display: -moz-box;
  display: flex;
  position: relative;
  height: 14px;
  font-size: 1.4rem
}

.rating-stars a {
  color: #999;
  text-indent: -9999px;
  letter-spacing: 1px;
  width: 16px;
  letter-spacing: .2em
}

.rating-stars a:before {
  content: "";
  position: absolute;
  left: 0;
  height: 14px;
  line-height: 1;
  font-family: riode;
  text-indent: 0;
  overflow: hidden;
  white-space: nowrap
}

.rating-stars a.active:before,
.rating-stars a:hover:before {
  content: "";
  color: #ff675d
}

.rating-stars .star-1 {
  z-index: 10
}

.rating-stars .star-2 {
  z-index: 9
}

.rating-stars .star-3 {
  z-index: 8
}

.rating-stars .star-4 {
  z-index: 7
}

.rating-stars .start-5 {
  z-index: 6
}

.rating-stars .star-1:before {
  width: 20%
}

.rating-stars .star-2:before {
  width: 40%
}

.rating-stars .star-3:before {
  width: 60%
}

.rating-stars .star-4:before {
  width: 80%
}

.rating-stars .star-5:before {
  content: ""
}

.product-sticky-content:not(.fixed) .sticky-product-details {
  display: none
}

.product-sticky-content:not(.fixed) .container {
  padding: 0
}

.product-sticky-content.fixed {
  padding: 1rem 0
}

.product-sticky-content.fixed .container {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center
}

.product-sticky-content.fixed .product-price {
  font-size: 2rem
}

.product-sticky-content.fixed .product-form {
  -moz-box-flex: 1;
  flex: 1 1
}

.product-sticky-content.fixed .product-form-group {
  -moz-box-pack: end;
  justify-content: flex-end
}

.product-sticky-content.fixed .product-form,
.product-sticky-content.fixed .product-form-group>* {
  margin-bottom: 0
}

.product-sticky-content.fixed .product-form>label {
  display: none
}

.product-sticky-content.fixed .input-group {
  margin-right: .8rem
}

.sticky-product-details {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center
}

.sticky-product-details img {
  display: block;
  width: 9rem;
  height: 9rem
}

.sticky-product-details .product-image {
  margin-right: 1rem;
  max-width: 9rem
}

.sticky-product-details .product-title {
  margin-bottom: .5rem;
  font-weight: 700
}

.sticky-product-details .product-price {
  font-weight: 600
}

.sticky-product-details .product-price,
.sticky-product-details .product-title {
  font-size: 2rem
}

.sticky-product-details .product-price,
.sticky-product-details .ratings-container {
  margin-bottom: 0
}

.sticky-product-details .product-info {
  display: -moz-box;
  display: flex
}

.sticky-product-details .product-price {
  margin-right: 2rem
}

.product-layout-gallery .product-image-full,
.product-layout-grid .product-image-full,
.product-layout-masonry .product-image-full,
.product-sticky-both .product-image-full,
.product-sticky-info .product-image-full {
  bottom: 1rem !important
}

aside .service-list {
  padding: 0 2rem;
  border: 1px solid #eee
}

aside .service-list>* {
  -moz-box-pack: start;
  justify-content: flex-start;
  padding: 2.2rem 0
}

aside .service-list>:not(:last-child) {
  border-bottom: 1px solid #e1e1e1
}

aside .service-list i {
  margin-left: 5px;
  font-size: 3.2rem
}

aside .service-list .icon-box-title {
  font-size: 1.5rem;
  letter-spacing: 0;
  line-height: 1.2
}

aside .service-list p {
  line-height: 1.2
}

aside .service-list .icon-box1 i {
  margin-left: 0;
  font-size: 3.7rem
}

aside .banner-content {
  left: 5%;
  top: 12%;
  width: 90%
}

aside .banner-subtitle {
  font-weight: 500;
  font-size: 16px;
  color: #777
}

aside .banner-title {
  font-size: 2.3rem;
  line-height: 1.4em
}

aside .owl-nav-top .owl-nav {
  top: -5.1rem
}

aside .owl-nav-top .owl-nav i {
  padding: .1rem;
  font-size: 1.2rem
}

aside .owl-nav-top .owl-nav i:before {
  font-weight: 600
}

@media(max-width: 575px) {

  .card-body .comments>ul,
  .product-tab-reviews .comments>ul {
    padding: 0
  }

  .card-body .comments li,
  .product-tab-reviews .comments li {
    margin: 0
  }

  .card-body .comment-body,
  .product-tab-reviews .comment-body {
    padding-left: 1.5rem
  }

  .product-tab-additional label {
    min-width: 13rem
  }
}

@media(min-width: 576px) {
  .product-tab-size-guide {
    display: -moz-box;
    display: flex
  }

  .product-tab-size-guide .size-table tbody th,
  .product-tab-size-guide thead th:first-child {
    padding-left: 3rem
  }
}

@media(min-width: 992px) {
  .product-form select {
    width: 20rem
  }

  .product.product-single .product-details {
    padding-left: 1rem
  }

  .product-details.row {
    padding-left: 0
  }

  .product-details.row>:last-child {
    padding-left: 2rem
  }
}

@media(min-width: 1600px) {
  .container-fluid .product-thumbs.owl-carousel {
    width: -moz-calc(100% + 20px);
    width: calc(100% + 20px);
    margin: 0 -10px
  }

  .container-fluid .product-thumb {
    margin: 0 10px
  }

  .container-fluid .product-thumbs-wrap {
    margin-top: 20px
  }

  .container-fluid .product-thumbs .owl-prev {
    left: 10px
  }

  .container-fluid .product-thumbs .owl-next {
    right: 10px
  }

  .container-fluid .pg-vertical .product-thumb {
    margin: 0 0 20px
  }

  .container-fluid .pg-vertical .product-thumbs-wrap {
    margin: 0 20px 0 0
  }

  .container-fluid .pg-vertical .product-single-carousel {
    max-width: -moz-calc(100% - 129px);
    max-width: calc(100% - 129px)
  }
}

@media(min-width: 992px)and (max-width:1199px) {
  aside .service-list {
    padding: 0 1rem
  }
}

@media(min-width: 768px)and (max-width:1035px) {
  .product-single .product-tab-size-guide {
    display: block
  }
}

.product-sticky-both .btn-cart {
  margin-bottom: 1rem
}

.product-sticky-both .product-action {
  display: -moz-box;
  display: flex
}

.product-sticky-both .btn-wishlist {
  margin-bottom: 1rem
}

@media(min-width: 992px) {
  .product-sticky-both .btn-cart {
    max-width: 100%
  }

  .product-sticky-both .product-form .p-relative,
  .product-sticky-both .product-form .select-box {
    margin-right: 0;
    width: 100%
  }

  .product-sticky-both .product-form select {
    -moz-box-flex: 1;
    flex: 1 1;
    width: 100%
  }
}

@media(max-width: 991px) {

  .product-tabs .card-body .icon-box-side,
  .product-tabs .product-tab-description .icon-box-side {
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    flex-direction: row
  }

  .product-tabs .card-body .icon-box-icon,
  .product-tabs .product-tab-description .icon-box-icon {
    display: -moz-inline-box;
    display: inline-flex;
    margin-bottom: 0;
    padding: 0
  }

  .product-tabs .card-body .icon-box-content,
  .product-tabs .product-tab-description .icon-box-content {
    text-align: left
  }

  .product-image-full {
    bottom: -moz-calc(20% + 35px);
    bottom: calc(20% + 35px)
  }
}

@media(max-width: 767px) {
  .product-gallery {
    margin-bottom: 0
  }

  .quickview-modal .product-gallery {
    margin-bottom: 3rem
  }
}

@media(max-width: 479px) {

  .card-sizeguide .size-table tbody tr>*,
  .product-tab-size-guide .size-table tbody tr>* {
    padding: 12px 0
  }

  .product-tab-size-guide .size-table tbody th {
    padding-left: 1rem
  }

  .product-tab-size-guide .size-table tbody th:last-child {
    padding-right: 1rem
  }
}

.post-single .post-details {
  padding: 4rem 0 0
}

.post-single .post-author {
  text-transform: uppercase
}

.post-single .post-title {
  margin-bottom: 2.5rem;
  font-size: 2.4rem
}

.post-single .post-body a {
  text-decoration: underline;
  color: #222
}

.post-single .post-body a:hover {
  color: #222
}

.post-single .post-body h4 {
  margin-bottom: 2rem;
  font-size: 2rem;
  line-height: 1.2
}

.post-single .post-body p {
  line-height: 1.72
}

.post-single .post-body li,
.post-single .post-body p {
  text-align: justify
}

.post-single .post-body figcaption {
  color: #999
}

.post-single .post-body figcaption a {
  color: inherit;
  border-bottom: 1px solid #999;
  text-decoration: none;
  -webkit-transition: border-color .3s, color .3s;
  -moz-transition: border-color .3s, color .3s;
  transition: border-color .3s, color .3s
}

.post-single .post-body figcaption a:hover {
  border-color: #222
}

.post-single .with-img {
  display: -moz-box;
  display: flex
}

.post-single .with-img figure {
  display: table;
  margin: 0 auto
}

.post-single .with-img .list {
  margin-left: 0;
  line-height: 1.82
}

.post-single .with-img li:not(:last-child) {
  margin-bottom: .3rem
}

.post-single blockquote {
  padding-left: 6rem
}

.post-single blockquote p {
  font-size: 1.8rem
}

.post-single blockquote:before {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  font-family: Font Awesome\ 5 Free;
  content: "";
  font-weight: 900;
  font-size: 2.5rem;
  color: #222;
  line-height: 1
}

.post-single .post-author-detail {
  position: relative;
  display: -moz-box;
  display: flex;
  -moz-box-align: start;
  align-items: flex-start;
  margin-top: 6rem;
  padding: 3rem;
  border: 1px solid #e1e1e1;
  z-index: 1
}

.post-single .author-media {
  max-width: 6rem;
  -moz-box-flex: 0;
  flex: 0 0 6rem;
  margin-right: 1.8rem;
  overflow: hidden
}

.post-single .author-media img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover
}

.post-single .author-header {
  flex-wrap: wrap;
  margin: .2rem 0 1.2rem
}

.post-single .author-header :not(i) {
  line-height: 1
}

.post-single .author-header .author-name {
  font-size: 1.8rem;
  line-height: 1.2
}

.post-single .author-header .author-title {
  font-size: 1.3rem;
  line-height: 1.86
}

.post-single .author-header .author-link {
  display: inline-block;
  font-size: 1.3rem;
  margin: 1rem 0;
  text-decoration: none
}

.post-single .author-header .author-link:hover {
  color: #222
}

.post-single .author-body {
  -moz-box-flex: 1;
  flex-grow: 1
}

@media(max-width: 575px) {
  .post-single blockquote {
    padding-left: 4rem
  }

  .post-single blockquote p {
    font-size: 1.6rem
  }

  .post-single .post-author-detail {
    padding: 1.7rem
  }
}

.post-footer {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center
}

.post-footer .social-icons {
  margin-left: auto
}

.post-footer .social-icon {
  display: inline-block;
  margin: .3rem 2.8rem .3rem 0;
  font-size: 1.5rem;
  color: #999
}

.post-footer .social-icon.social-pinterest {
  margin-right: 1rem
}

.page-nav {
  padding-bottom: 1rem;
  border-bottom: 1px solid #ebebeb;
  -moz-box-align: center;
  align-items: center;
  width: 100%
}

.page-nav,
.pager-link {
  display: -moz-box;
  display: flex
}

.pager-link {
  position: relative;
  color: #999;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  -moz-box-align: start;
  align-items: flex-start;
  -moz-box-flex: 0;
  flex: 0 0 50%;
  max-width: 50%;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-size: 1.3rem;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1.5;
  -webkit-transition: all .35s ease;
  -moz-transition: all .35s ease;
  transition: all .35s ease
}

.pager-link+.pager-link {
  border-left: .1rem solid #ebebeb
}

.pager-link:after {
  display: block;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: riode;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1;
  -webkit-transition: all .35s ease .05s;
  -moz-transition: all .35s ease .05s;
  transition: all .35s ease .05s
}

.pager-link.pager-link-prev {
  padding-right: 3rem;
  padding-left: 4.2rem
}

.pager-link.pager-link-prev:after {
  content: "";
  left: .2rem
}

.pager-link.pager-link-next {
  padding-left: 3rem;
  padding-right: 4.2rem;
  -moz-box-align: end;
  align-items: flex-end;
  text-align: right
}

.pager-link.pager-link-next:after {
  content: "";
  right: .2rem
}

.pager-link:focus,
.pager-link:hover {
  color: #999
}

.pager-link:focus .pager-link-title,
.pager-link:hover .pager-link-title {
  -webkit-box-shadow: 0 .2rem 0 #333;
  box-shadow: 0 .2rem 0 #333
}

.pager-link-title {
  display: inline-block;
  margin-top: .6rem;
  -moz-box-flex: 0;
  flex: 0 0 auto;
  color: #222;
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: none;
  -webkit-transition: all .35s ease;
  -moz-transition: all .35s ease;
  transition: all .35s ease;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

@media(max-width: 575px) {
  .pager-link-title {
    display: none
  }

  .pager-link.pager-link-next {
    border: 0
  }
}

.related-posts {
  padding: 7rem 0 4.5rem
}

.related-posts .post-details {
  padding: 2.3rem 0 1rem
}

.related-posts .post-title {
  text-transform: none
}

.related-posts .post-content {
  margin-bottom: 1.8rem;
  line-height: 1.72
}

.comments .title-simple,
.related-posts .title-simple,
.reply .title-simple {
  font-size: 2.4rem
}

.shop-table {
  text-align: left
}

.shop-table tbody {
  border-bottom: 1px solid #e1e1e1
}

.shop-table td {
  padding: 1.5rem 1rem 1.5rem 0;
  border-top: 1px solid #e1e1e1;
  font-size: 1.4rem
}

.shop-table th {
  padding: .3rem 0;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.875;
  text-transform: uppercase;
  color: #222
}

.shop-table img {
  display: block;
  max-width: 100px
}

.shop-table .product-thumbnail a {
  display: block;
  width: 100px
}

.shop-table .remove {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  width: 2.3rem;
  height: 2.3rem;
  font-size: 1.2rem;
  color: #222;
  border: 1px solid #ccc;
  border-radius: 50%
}

.shop-table .product-name {
  white-space: normal;
  color: #000
}

.shop-table td.product-remove {
  padding-right: 0;
  width: 23px
}

.shop-table .product-price {
  display: table-cell
}

.share-on>* {
  margin-right: 2.8rem
}

.share-on .social-link {
  border: none;
  width: auto;
  height: auto
}

.share-on .social-link.social-facebook {
  color: #3b5998
}

.share-on .social-link.social-twitter {
  color: #1da1f2
}

.share-on .social-link.social-pinterest {
  color: #bd081c
}

.share-on .social-link.social-email {
  color: #dd4b39
}

.share-on .social-link.social-whatsapp {
  color: #3c8a38
}

.share-on .social-link.social-icon:hover {
  background-color: transparent;
  color: #222
}

@media(min-width: 768px) {
  .wishlist-table td.product-stock-status {
    width: 130px
  }
}

@media(min-width: 992px) {
  .wishlist-table td.product-stock-status {
    width: 201px
  }

  .wishlist-table td.product-add-to-cart {
    width: 235px
  }
}

@media(max-width: 767px) {
  .shop-table {
    text-align: center
  }

  .shop-table thead {
    display: none
  }

  .shop-table tbody,
  .shop-table td,
  .shop-table tr {
    display: block
  }

  .shop-table tbody {
    border: 1px solid #e1e1e1
  }

  .shop-table tr {
    position: relative;
    padding: 4rem 1rem
  }

  .shop-table tr+tr {
    border-top: 1px solid #e1e1e1
  }

  .shop-table td {
    padding: 0;
    border: none
  }

  .shop-table .product-thumbnail {
    margin-bottom: 1rem
  }

  .shop-table .btn-product,
  .shop-table .product-thumbnail a,
  .shop-table td {
    margin-left: auto;
    margin-right: auto
  }

  .shop-table .product-stock-status {
    margin-bottom: 1rem
  }

  .shop-table .btn-product {
    max-width: 220px
  }

  .shop-table .product-remove {
    position: absolute;
    right: 2rem;
    top: 2rem;
    padding: .5rem;
    width: auto
  }

  .shop-table .product-quantity {
    margin-bottom: 1rem
  }

  .wishlist-table td.product-price {
    display: block;
    width: 100%
  }
}

.wishlist-table th.product-name {
  padding-left: 2px;
  width: 120px
}

.wishlist-table .wishlist-out-stock {
  color: #ff675d
}

.wishlist-table .wishlist-in-stock {
  color: #222
}

.wishlist-table .btn-product {
  padding: 1.4rem;
  white-space: nowrap;
  width: 13rem;
  margin: 0 auto;
  font-size: 1.2rem;
  -webkit-transition: color .3s, background-color .3s;
  -moz-transition: color .3s, background-color .3s;
  transition: color .3s, background-color .3s
}

.wishlist-table .btn-product:hover {
  color: #fff;
  background-color: #222
}

.wishlist-table .btn-product.btn-disabled {
  border-color: #f2f3f5 !important;
  background-color: #f2f3f5 !important
}

.cart.main {
  border-top: 1px solid #e1e1e1
}

.cart .accordion {
  border-bottom: 1px solid #e1e1e1
}

.cart .card-header {
  font-size: 1.4rem
}

.cart .card-header>a {
  padding-left: 2px
}

.cart .card-body {
  margin-bottom: 2.4rem;
  padding: 0
}

.cart .form-control {
  font-family: Poppins, sans-serif
}

.cart label {
  cursor: pointer
}

.cart .remove {
  margin-right: 0;
  width: 2rem;
  text-align: center
}

.cart .summary {
  line-height: 2.15
}

.cart .summary .title {
  margin-bottom: 1rem;
  padding-bottom: 1.8rem;
  border-bottom: 1px solid #e1e1e1
}

.cart .btn-checkout {
  display: block;
  padding-top: 1.06em;
  padding-bottom: 1.06em;
  border-color: #333;
  background-color: #333
}

.cart .product-thumbnail figure {
  position: relative;
  display: inline-block
}

.cart .shop-table th {
  padding: .2rem 0 1.8rem;
  font-size: 1.3rem;
  font-weight: 700;
  color: #999;
  line-height: 1;
  letter-spacing: -.025em
}

.cart .shop-table td {
  padding: 1.6rem 2rem 1.6rem 0
}

.cart .shop-table td:last-child {
  padding-right: 0
}

.cart .shop-table td:first-child {
  padding: 2rem 2rem 1.5rem 0
}

.cart .shop-table .quantity.form-control {
  font-weight: 700
}

.cart .product-remove {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  text-align: center;
  font-size: 1.2rem;
  width: 2.3rem;
  height: 2.3rem;
  border: 1px solid #ccc;
  border-radius: 50%;
  background-color: #fff;
  color: #222
}

.cart .shipping {
  margin-bottom: .3rem
}

.cart .btn {
  border-width: 1px
}

.cart .shipping-row-last .summary-subtitle {
  padding: 2.4rem 0 1.9rem
}

.cart .shipping-row-last li {
  padding-bottom: .3rem
}

.step-by {
  display: -moz-box;
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: .9rem 0
}

.step-by .title.title-step {
  margin: 0 3.4rem 0 1rem;
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 700;
  color: #666;
  letter-spacing: -.5px;
  padding: .4rem 0
}

.step-by .title.title-step:not(:last-child):after {
  content: "";
  display: inline-block;
  position: absolute;
  right: -3rem;
  top: 52%;
  line-height: 0;
  vertical-align: middle;
  font-family: Font Awesome\ 5 Free;
  font-weight: 700;
  font-size: 1.7rem;
  background-color: #fff;
  color: #ccc
}

.step-by .title.title-step.active,
.step-by .title.title-step.visited,
.step-by .title.title-step.visited~.active:before {
  color: #222
}

.step-by .title.title-step:first-child {
  margin-left: 0
}

.step-by .title.title-step:first-child:before {
  display: none
}

.step-by .title.title-step:last-child {
  margin-right: 0
}

.summary {
  padding: 2.4rem 3rem 3rem;
  border: 1px solid #e1e1e1;
  border-radius: 2px
}

.summary td:last-child,
.summary th:last-child {
  text-align: right
}

.summary th {
  text-align: left
}

.summary .form-control {
  width: 100%;
  max-width: 100%
}

.summary-title {
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: -.01em;
  color: #222;
  text-transform: uppercase;
  padding-bottom: 1.9rem;
  margin-bottom: 0
}

.summary-subtotal,
.summary-title {
  border-bottom: 1px solid #e1e1e1
}

.summary-subtitle {
  font-size: 1.6rem;
  letter-spacing: -.01em;
  padding: 2rem 0
}

.summary-subtitle,
.summary-subtotal-price {
  font-weight: 600;
  color: #222;
  margin-bottom: 0
}

.summary-subtotal-price {
  font-size: 1.4rem;
  font-family: Poppins, sans-serif
}

.summary-total-price {
  font-size: 2rem;
  letter-spacing: -.025em;
  font-weight: 700;
  color: #222;
  text-align: right;
  margin-bottom: 0;
  font-family: Poppins, sans-serif
}

.total .summary-subtitle {
  padding: 2.4rem 0 2.3rem
}

.empty-cart p {
  font-size: 1.6rem;
  font-weight: 600;
  color: #777
}

.empty-cart i.cart-empty {
  margin-bottom: 3rem;
  font-size: 8rem;
  color: #aaa
}

.empty-cart .btn.btn-md {
  border-radius: 2px;
  border: 2px solid #222;
  letter-spacing: -.025em
}

@media(max-width: 767px) {
  .summary {
    padding-left: 2rem;
    padding-right: 2rem
  }

  .cart .shop-table td {
    padding: 0
  }

  .cart .shop-table td.product-price {
    display: block;
    width: 100%;
    text-align: center
  }

  .cart .shop-table tr {
    padding: 2rem 0 3rem
  }

  .cart .shop-table .product-quantity,
  .cart .shop-table .product-subtotal,
  .cart .shop-table .product-thumbnail {
    margin-bottom: .5rem
  }
}

.shipping-address {
  padding-bottom: 2.4rem;
  border-bottom: 1px solid #e1e1e1
}

.shipping-address label {
  display: block;
  margin-bottom: 1.4rem;
  font-size: 1.5rem
}

.shipping-address .form-control {
  margin-bottom: 1.5rem;
  padding: 1rem 1.4rem;
  font-size: 1.3rem;
  border-color: #e1e1e1;
  color: #999;
  min-height: 4.1rem
}

.shipping-address .btn {
  padding: .9em 1.7em
}

.checkout .select-box,
.checkout input.form-control,
.shipping-address .select-box,
.shipping-address input.form-control {
  background: #fff
}

.checkout .select-box .form-control,
.checkout input.form-control .form-control,
.shipping-address .select-box .form-control,
.shipping-address input.form-control .form-control {
  padding: 1rem 1.4rem .8rem;
  font-size: 1.4rem;
  color: #666
}

.checkout .select-box:before,
.checkout input.form-control:before,
.shipping-address .select-box:before,
.shipping-address input.form-control:before {
  font-family: riode;
  content: "";
  right: 1.5rem;
  font-size: 1.1rem;
  z-index: 2
}

.cart-total {
  text-align: right
}

.cart-total .btn-calc {
  margin-bottom: 1.8rem
}

.cart-total td,
.cart-total th {
  border-top: 1px solid #e1e1e1
}

.cart-subtotal th {
  font-weight: 400
}

.cart-subtotal td,
.cart-subtotal th {
  padding: 1.5rem 0
}

.order-total {
  font-size: 1.6rem;
  font-weight: 600;
  color: #222
}

.order-total td,
.order-total th {
  padding-top: 1.9rem
}

.cart-table .product-name {
  font-weight: 600;
  color: #222;
  line-height: 1.86
}

.cart-table .product-price {
  width: 100px
}

.cart-table .product-quantity {
  width: 135px
}

.cart-table .product-subtotal {
  width: 85px;
  color: #222
}

.cart-table .input-group button,
.cart-table .input-group input {
  border-color: #eee
}

.cart-table .input-group .quantity-minus {
  border-radius: .3rem 0 0 .3rem
}

.cart-table .input-group .quantity-plus {
  border-radius: 0 .3rem .3rem 0
}

.cart-actions {
  display: -moz-box;
  display: flex;
  -moz-box-align: start;
  align-items: flex-start;
  -moz-box-pack: justify;
  justify-content: space-between;
  flex-wrap: wrap
}

.cart-actions .btn-icon-left {
  padding: 1.06em 2.2em
}

.cart-actions .btn-icon-left i {
  margin-right: .8rem;
  font-size: 2rem
}

.cart-actions button {
  padding: 1.07em 2.23em
}

.cart-actions button.btn-disabled {
  border-color: #222 !important;
  background-color: transparent !important;
  border-width: 1px;
  color: #222 !important;
  opacity: .4;
  cursor: default
}

.cart-coupon-box .coupon-title {
  margin-bottom: 2.3rem;
  padding-top: .8rem;
  font-size: 1.8rem
}

#coupon_code {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  font-size: 1.3rem;
  border: none;
  border-bottom: 1px solid #e1e1e1;
  -webkit-transition: border-color .3s;
  -moz-transition: border-color .3s;
  transition: border-color .3s;
  border-radius: 0
}

@media(min-width: 992px) {
  .cart.main .product-thumbnail {
    width: 12rem
  }

  .cart.main .product-price {
    width: 11rem
  }

  .cart.main .product-quantity {
    width: 17.7rem
  }

  .cart.main .product-subtotal {
    width: 13.2rem
  }

  .cart.main .product-close {
    width: 2.3rem
  }
}

.checkout {
  line-height: 1.54
}

.checkout.main {
  border-top: 1px solid #e1e1e1
}

.checkout .alert.card-header {
  padding-top: 1.2rem;
  padding-bottom: 1.3rem;
  background-color: #fff;
  border: 1px dashed #cacbcc;
  text-transform: none
}

.checkout .alert.card-header>a {
  display: inline-block;
  padding: 0
}

.checkout .alert.card-header>a:after,
.checkout .alert.card-header>a:before {
  content: none
}

.checkout .alert-icon>i {
  margin-right: .8rem
}

.checkout.expanded .text-body:after {
  width: .8rem;
  height: .8rem;
  left: .5rem;
  background: #666
}

.checkout .alert-body {
  -webkit-transition: display .3s;
  -moz-transition: display .3s;
  transition: display .3s
}

.checkout .alert-body p {
  font-size: 1.3rem
}

.checkout .alert-body.expanding {
  display: none
}

.checkout .alert-body.collapsing {
  display: block
}

.checkout .alert-body .form-control {
  margin: 0
}

.checkout .checkbox {
  margin: .6rem 0 1.6rem .2rem
}

.checkout .link-group .btn {
  padding: 1em 2.5em
}

.checkout .link-group span {
  margin: 0 1.1rem;
  font-size: 1.4rem
}

.checkout .lost-link {
  font-size: 1.3rem;
  color: #000
}

.checkout .social-link {
  border-radius: .3rem;
  color: #fff
}

.checkout .social-link.social-google {
  background-color: #dd4b39;
  border-color: #dd4b39
}

.checkout .social-link.social-facebook {
  background-color: #3b5998;
  border-color: #3b5998
}

.checkout .social-link.social-twitter {
  background-color: #1da1f2;
  border-color: #1da1f2
}

.checkout .check-coupon-box .form-control {
  max-width: 42.5rem
}

.checkout .check-coupon-box .btn {
  padding: 1.04em 2.05em;
  border-width: 1px
}

.checkout label {
  display: block;
  padding-left: .2rem;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  line-height: .9
}

.checkout .form .title.title-simple {
  margin-top: .3rem;
  font-size: 1.8rem
}

.checkout .select-box select {
  max-width: 100%;
  width: 100%
}

.checkout .form-control {
  margin-bottom: 2.1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: 1.3rem;
  font-family: Poppins, sans-serif;
  -webkit-transition: background-color .3s, border-color .3s;
  -moz-transition: background-color .3s, border-color .3s;
  transition: background-color .3s, border-color .3s;
  color: #999;
  min-height: 4.1rem;
  border-radius: .3rem
}

.checkout .form-control:not(:focus) {
  background-color: #fff
}

.checkout .form-control:not(select):focus {
  border-color: #000
}

.checkout textarea.form-control {
  padding-top: 1.5rem
}

.checkout .form-checkbox {
  padding-top: .7rem;
  margin-bottom: 2.8rem
}

.checkout .form-control-label {
  padding-left: 2.6rem;
  margin-bottom: 0;
  font-size: 1.4rem;
  line-height: 2.58
}

.checkout .product-name {
  font-size: 1.4rem;
  letter-spacing: 0;
  white-space: normal
}

.checkout .btn-order {
  width: 100%;
  border-color: #333;
  background-color: #333;
  -webkit-transition: opacity .3s;
  -moz-transition: opacity .3s;
  transition: opacity .3s
}

.checkout .btn-order:hover {
  opacity: .9
}

.checkout .card {
  background: transparent
}

.checkout .card-header {
  font-size: 1.4rem;
  line-height: 3rem;
  text-transform: capitalize;
  font-weight: 400
}

.checkout .card-header a {
  padding: 0 0 0 2.5rem
}

.checkout .card-header a:after,
.checkout .card-header a:before {
  position: absolute;
  content: "";
  display: inline-block;
  border-radius: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%)
}

.checkout .card-header a:before {
  left: 0;
  width: 1.8rem;
  height: 1.8rem;
  background: #fff;
  border: 1px solid #cdcdcd
}

.checkout .card-header a.collapse:after {
  width: .8rem;
  height: .8rem;
  left: .5rem;
  background: #666
}

.checkout .card-body {
  padding: .2rem 0 1.1rem 2.9rem;
  line-height: 1.4
}

.checkout .payment.accordion {
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 2rem
}

.checkout .payment.accordion .summary-subtitle {
  font-size: 1.5rem
}

.checkout .checkout-info {
  padding: 1.5rem 0;
  margin: 0;
  font-size: 1.3rem;
  font-weight: 400;
  letter-spacing: .01em;
  font-family: Poppins, sans-serif
}

.checkout .product-quantity {
  margin-left: .4rem
}

.checkout .title.title-simple {
  font-weight: 700
}

.checkout .summary .form-control-label {
  line-height: 1.4
}

.checkout .summary .form-control-label:before {
  top: 0;
  -webkit-transform: none;
  -moz-transform: none;
  transform: none
}

.checkout .summary .form-control-label a {
  color: #000
}

.checkout .summary .form-control-label a:hover {
  color: #222
}

.checkout .toggle-button.expanded:after,
.checkout .toggle-button.expanding:after {
  width: .8rem;
  height: .8rem;
  left: .5rem;
  background: #666
}

.summary .title {
  margin-bottom: 1.9rem;
  padding-bottom: 2.2rem;
  border-bottom: 1px solid #e1e1e1
}

.order-table {
  color: #222
}

.order-table th {
  padding: 0 0 .7rem;
  font-size: 1.6rem;
  font-weight: 600
}

.order-table td {
  padding-bottom: .6rem;
  line-height: 2.2
}

.order-table td time {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical
}

.order-table .order-total td {
  border-bottom: none;
  padding: 1.2rem 0 0
}

.order-table .shipping-row label {
  margin-bottom: 1.5rem
}

.order-table .shipping-row-last {
  border-bottom: 1px solid #e1e1e1
}

.order-table .shipping-row-last label {
  margin-bottom: 2.1rem;
  color: #666;
  cursor: pointer
}

.order-table .summary-subtotal {
  border-top: 1px solid #e1e1e1
}

.order-table .summary-subtotal td:first-child {
  padding: .4rem 0 .3rem
}

.order-table .summary-subtotal-price {
  padding-top: 1rem
}

.order-table tbody>tr:nth-child(2) td {
  padding-bottom: 1.6rem
}

.sumnary-shipping ul {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left
}

.sumnary-shipping .summary-subtitle {
  padding-bottom: 2.2rem;
  text-align: left
}

.summary-total {
  border-bottom: 1px solid #e1e1e1
}

.summary-total .summary-subtitle {
  padding: 2.3rem 0 2.1rem
}

@media(max-width: 479px) {

  .order-table tbody .product-name,
  .order-table tbody .product-total {
    padding-bottom: 2rem;
    line-height: 1.2
  }

  .order-table tbody>tr:nth-child(2) td {
    padding-bottom: 2rem
  }
}

.order .title {
  font-size: 2rem
}

.order .order-message {
  max-width: 34rem;
  padding: 3rem;
  -webkit-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .1);
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .1);
  border-radius: 1rem
}

.order .order-message .icon-box-icon {
  margin-right: 3.2rem
}

.order .order-message svg {
  width: 5rem;
  height: 5rem;
  stroke: #222
}

.order .order-message .icon-box-title {
  font-size: 1.8rem
}

.order .order-results {
  margin: 4.8rem 0 1.9rem
}

.order .order-results,
.order .overview-item {
  display: -moz-box;
  display: flex;
  flex-wrap: wrap
}

.order .overview-item {
  position: relative;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  margin-bottom: 3rem
}

.order .overview-item:not(:last-child) {
  margin-right: 3rem;
  padding-right: 3.1rem
}

.order .overview-item:not(:last-child):after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  display: block;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 1px;
  height: 42px;
  background: #e1e1e1
}

.order .overview-item span {
  text-transform: uppercase
}

.order .overview-item strong {
  color: #222;
  font-weight: 600;
  font-size: 2rem
}

.order .btn.btn-back {
  font-size: 1.3rem
}

.order .summary-subtotal:last-child {
  border-bottom: none
}

.order .address-info {
  border-bottom: 1px solid #e1e1e1
}

.order .address-info p {
  color: #666;
  font-family: Poppins, sans-serif;
  line-height: 2.15
}

.order-details {
  border: 1px solid #e1e1e1;
  border-radius: 3px;
  padding: .4rem 3rem
}

.order-details-table .product-name {
  padding: 1.6rem 0 1.8rem;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.42;
  color: #000;
  white-space: inherit
}

.order-details-table .product-name i {
  font-size: 1rem
}

.order-details-table .product-name span {
  font-weight: 700;
  color: #222
}

.order-details-table th>.summary-subtitle {
  font-size: 1.8rem;
  font-weight: 600;
  text-align: left
}

.order-details-table .product-price {
  display: block
}

.order-details-table .product-price,
.order-details-table .summary-subtotal-price {
  text-align: right
}

.order-details-table .summary-subtotal-price {
  font-weight: 600;
  color: #666
}

.order-details-table .product-price {
  font-size: 1.4rem;
  color: #666;
  font-weight: 400;
  padding-top: 1.2rem
}

.order-details-table thead {
  border-bottom: 1px solid #e1e1e1
}

.order-details-table thead .summary-subtitle {
  font-size: 1.8rem
}

.order-details-table tbody tr:first-child td {
  padding-top: 3.2rem
}

.order-details-table tbody tr:first-child td.product-price {
  padding-top: 2.8rem
}

@media(max-width: 767px) {
  .order .order-message {
    font-size: 2rem
  }

  .order .overview-item {
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    flex-direction: row;
    width: 100%;
    -moz-box-align: center;
    align-items: center
  }

  .order .overview-item:not(:last-child):after {
    content: none
  }

  .order .overview-item span {
    margin-right: 2rem
  }
}

@media(max-width: 575px) {
  .order .order-details {
    padding: 0 2rem
  }

  .order .order-message {
    font-size: 1.8rem
  }

  .order .overview-item {
    font-size: 1.6rem
  }
}

@media(max-width: 479px) {
  .order .order-details {
    padding: 0 1.5rem
  }

  .order .order-message {
    font-size: 1.6rem
  }

  .order .overview-item {
    font-size: 1.4rem
  }

  .step-by .title.title-step {
    font-size: 1.8rem
  }

  .step-by .title.title-step:after {
    font-size: 1.4rem;
    top: 50%
  }
}

.sample-icons .tab-pane {
  overflow: hidden
}

.sample-icon-circle {
  margin-bottom: 2rem
}

.sample-icon-circle i {
  -moz-box-pack: center;
  justify-content: center;
  margin: 0 auto;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  font-size: 30px;
  background: #222;
  color: #fff
}

.sample-icon,
.sample-icon-circle i {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center
}

.sample-icon {
  line-height: 41px
}

.sample-icon span {
  font-size: 12px;
  white-space: nowrap
}

.sample-icon i {
  display: inline-block;
  margin-right: 10px;
  width: 32px;
  font-size: 20px;
  text-align: center
}

.sample-icon:hover {
  color: #222;
  background: #f2f3f5;
  border-radius: 4px
}

.sample-icon:hover i {
  font-size: 30px
}

.sample-icon-list i {
  font-size: 22px
}

@media(min-width: 1200px) {
  .cols-xl-12>* {
    -moz-box-flex: 0;
    flex: 0 0 8.3333%;
    max-width: 8.3333%
  }
}

.faq .breadcrumb {
  padding: 1.2rem 0 1.1rem
}

.faq .page-header {
  height: 30.3rem
}

.faq .page-subtitle {
  margin-bottom: .3rem;
  font-size: 3rem
}

.faq .page-title {
  margin-bottom: .2rem;
  font-size: 5rem
}

.faq section {
  padding-top: 4.1rem
}

.faq .card {
  border-width: 1px 0 0
}

.faq .card,
.faq .card:last-child {
  border-color: #ebebeb
}

.faq .card-header {
  font-size: 1.6rem;
  text-transform: none;
  letter-spacing: -.025em
}

.faq .card-header a {
  padding: 1.7rem 1rem
}

.faq .card-header a:after {
  font-size: 1.5rem;
  right: 1.3rem;
  color: #999
}

.faq .card-body {
  padding: .2rem 1rem
}

.cart-dropdown.type2 .cart-count {
  background-color: #ff675d
}

.intro-slider img {
  min-height: 55rem;
  object-fit: cover
}

@media(min-width: 576px) {
  .d-sm-flex {
    display: -moz-box !important;
    display: flex !important
  }

  .mr-sm-4 {
    margin-right: 2rem !important
  }
}

@media(min-width: 768px) {
  .d-md-block {
    display: block !important
  }

  .mr-md-0 {
    margin-right: 0 !important
  }
}

@media(max-width: 991px) {
  .intro-slider img {
    min-height: 50rem
  }
}

@media(max-width: 767px) {
  .intro-slider img {
    min-height: 45rem
  }
}

.intro-slide1 img {
  object-position: 20% center
}

.intro-slide1 .banner-content {
  max-width: 64.5rem
}

.intro-slide1 .banner-subtitle {
  font-size: 3em
}

.intro-slide1 .banner-title {
  margin-bottom: .1rem;
  font-weight: 800;
  font-size: 8em;
  line-height: 1.1;
  letter-spacing: .2em
}

.intro-slide1 h5 {
  font-size: 3em
}

.intro-slide2 .banner-content {
  left: 8.3%
}

.intro-slide2 .banner-subtitle {
  margin-bottom: .2rem;
  font-size: 3em;
  color: hsla(0, 0%, 100%, .6)
}

.intro-slide2 .banner-title {
  margin-bottom: 1.7rem;
  font-size: 5em
}

.intro-slide2 h5 {
  font-size: 3em
}

.intro-slide2 p {
  font-size: 1.4em;
  color: hsla(0, 0%, 100%, .6);
  line-height: 1.5
}

.intro-slide2 .btn {
  font-size: 1.4em
}

.intro-banner img {
  min-height: 18rem;
  object-fit: cover
}

.intro-banner .banner-content {
  margin-top: .2rem
}

.intro-banner .banner-subtitle {
  margin-bottom: .7rem;
  font-size: 1.8rem
}

.intro-banner .banner-title {
  margin-bottom: 2.4rem;
  font-size: 3em;
  line-height: .95
}

.intro-banner .btn i {
  margin-left: .9rem
}

.intro-banner1 .banner-content {
  right: 6%
}

.intro-banner2 .banner-content {
  right: 8%
}

.category-section .title {
  margin-bottom: 2.7rem
}

.category-section .banner-content {
  padding: 3rem 0 2rem
}

.category-section .banner-title {
  font-size: 2.4rem;
  line-height: 1.5em
}

.category-section .banner-subtitle {
  font-size: 2.4rem
}

.category-section .btn-group a {
  text-transform: capitalize;
  opacity: .9
}

.category-section .btn-group a:first-child {
  margin-right: .7rem
}

.category-section .category-content {
  bottom: 2rem
}

.height-x1 {
  height: 30rem
}

.height-x2 {
  height: 60rem
}

.banner-sale {
  padding: 6.8rem 5.8rem 6.2rem
}

.banner-sale .banner-subtitle {
  font-size: 2.6rem
}

.banner-sale .divider {
  width: 19rem;
  height: .2rem;
  background-color: #fff;
  opacity: .2
}

.banner-sale .banner-title {
  font-size: 7.6rem;
  font-weight: 800;
  line-height: 1;
  letter-spacing: .05em
}

.banner-sale .banner-price-info {
  position: absolute;
  left: 23.7%;
  top: -10%;
  border: 3px solid hsla(0, 0%, 100%, .6);
  border-radius: 50%;
  width: 14rem;
  height: 14rem;
  -webkit-transform: rotate(-15deg);
  -moz-transform: rotate(-15deg);
  transform: rotate(-15deg);
  text-indent: 18px
}

.banner-sale .banner-price-info h5 {
  font-size: 1.8em;
  font-weight: 500;
  line-height: 1.3
}

.banner-sale .banner-price-info span {
  font-size: 1.944em;
  font-weight: 800
}

.home .service-list {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee
}

.home .service-list .icon-box-content {
  padding: 2.4rem 0
}

.home .service-list .icon-box-title {
  font-size: 1.6rem
}

.home .service-list p {
  line-height: 1.6
}

.widget.widget-about {
  margin-top: -.8rem
}

@media(max-width: 1024px) {
  .banner-sale {
    background-position: 0
  }

  .banner-sale .banner-price-info {
    left: 36%
  }
}

@media(max-width: 991px) {
  .footer-middle {
    padding: 7.6rem 0 4.6rem
  }
}

@media(max-width: 767px) {

  .intro-slide1,
  .intro-slide2 {
    font-size: .7rem
  }

  .height-x1 {
    height: 22.5rem
  }

  .height-x2 {
    height: 45rem
  }

  .banner-sale {
    padding: 3rem 2rem 17rem;
    background-position: 50%
  }

  .banner-sale .banner-price-info {
    left: 0;
    top: 100%
  }
}

@media(max-width: 575px) {
  .height-x1 {
    height: 45rem
  }

  .height-x1>.category {
    height: 50%
  }

  .intro-slide1 {
    font-size: .5rem
  }

  .intro-slide1 .banner-title {
    letter-spacing: 0
  }

  .intro-slide2 {
    font-size: .65rem
  }
  .product-name {
    white-space: pre-wrap;
    text-overflow: clip;
  }
  
}

@media(max-width: 479px) {
  .intro-banner {
    font-size: .8rem
  }

  .intro-slide1 .banner-content {
    left: 1.5rem
  }
}

.breadcrumb {
  line-height: 1.9
}

.shop-banner {
  padding: 5.2rem 0 5.1rem
}

.shop-banner .banner-content {
  right: 8.5%
}

.shop-banner .banner-title {
  margin-bottom: .4rem
}

.shop-banner .banner-price-info {
  margin-bottom: 1.6rem
}

.shop-banner .banner-price-info,
.shop-banner .banner-subtitle {
  font-size: 2em
}

.shop-banner .banner-title {
  font-size: 6em
}

.shop-banner p {
  margin-bottom: 2.3rem;
  padding: 0 1.1rem;
  line-height: 3rem
}

@media(max-width: 479px) {
  .shop-banner .banner-content {
    right: 1.5rem
  }

  .overflow-ellipse-for-productName {
    width: 30vw !important;
  }
}

@media(max-width:932) and (max-height:430) {
  .overflow-ellipse-for-productName {
    width: 60%;
  }
}

.product-details .product-navigation {
  padding: 0 2px 1.2rem
}

.mobile-menu li>a:hover {
  color: #ff675d
}