@media (max-width: 768px) {
    .reverse-form {
        flex-direction: column-reverse;
    }

    .border-bottom {
        width: 128px !important;
    }
}

.border-bottom {
    border-bottom: 2px solid black;
    width: 48%;
    margin: auto;
}

.width-10 {
    width: 10%;
}

.text-align-right {
    text-align: right !important;
}

.text-align-center {
    text-align: center !important;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="gray"><path d="M7 10l5 5 5-5H7z"/></svg>') no-repeat right 10px center;
    background-color: white;
    background-size: 20px;
    padding-right: 40px;
    border: 1px solid #ccc;
    font-size: 16px;
}

select:focus {
    border-color: #666;
    outline: none;
}

@media print {
    .hide-on-print {
        display: none;
    }
    .page-break {
        page-break-after: always; /* Forces a page break after this element */
    }
    .no-break {
        page-break-inside: avoid; /* Prevents a page break inside this element */
    }
}